import { useCallback, useEffect } from "react";
import { authActions, useAction } from "../../state";
import { useGetGoogleCredentialsQuery } from "../../state/rtk-query/state/google";

export function OneTap() {
  const login = useAction(authActions.socialLogin);
  const { currentData } = useGetGoogleCredentialsQuery();

  const handleCredentialResponse = useCallback(
    async function (response: { credential: any }) {
      await login({ credential: response.credential, sign_in_type: "google" });
    },
    [login],
  );
  useEffect(() => {
    /** See here https://stackoverflow.com/a/69060489/12892971 */
    window.handleCredentialResponse = handleCredentialResponse;
  }, [handleCredentialResponse]);
  /**
   * See
   * https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_id_g_id_onload
   * for all possible options
   * */
  return currentData?.client_id ? (
    <div
      id="g_id_onload"
      data-client_id={currentData?.client_id}
      data-callback="handleCredentialResponse"
      data-sign_in_type="google"
    ></div>
  ) : (
    <div></div>
  );
}
