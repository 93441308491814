import { AppState } from "../types";

export const clientSelectors = {
  currentOrg(state: AppState) {
    return state.client.org;
  },
  currentSupportStep(state: AppState) {
    return state.client.support;
  },
  taskPreference(state: AppState) {
    return state.client.taskPreference;
  },
};
