import { Org, client, ClientState } from "./state";
const { actions } = client;

export const clientActions = {
  ...actions,
  /**Orgs start */
  setCurrentOrg(org: Org) {
    return actions.setOrg(org);
  },
  clearCurrentOrg() {
    return actions.setOrg(undefined);
  },
  /**Orgs End */
  /* Support Modal Start */
  setSupportSessionId(id?: number) {
    return actions.setSession(id);
  },
  setSupportTaskId(id: number) {
    return actions.setTask(id);
  },
  setSupportScreenAction(screen: ClientState["support"]["screen"]) {
    return actions.setSupportScreen(screen);
  },
  /* Support Modal End */
  /* Task Modal Pref Start */
  setTaskPreferenceAction(pref: ClientState["taskPreference"]) {
    return actions.setTaskPreference(pref);
  },
  /* Task Modal Pref End */
};
