import { Typography, Stack } from "@mui/material";
import {
  OrgProfileCard,
  CardHeaderStyled,
  CardStyled,
} from "../../../components";
import { memo } from "react";

import { UsersTable } from "../../../components/tables/UsersTable";
export const AccountSettingPage = memo(() => {
  return (
    <UsersTable mode="client">
      {({ AddUserButton, AddUserModel, UsersTable }) => (
        <Stack height={"100%"}>
          <Stack>
            <Typography variant="h2" fontWeight={"bold"} mb={1}>
              Account Settings
            </Typography>
            <OrgProfileCard />
          </Stack>
          <CardStyled sx={{ marginBottom: 2 }}>
            <Stack height={"100%"}>
              <CardHeaderStyled
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                USERS {AddUserButton}
              </CardHeaderStyled>
              {UsersTable}
            </Stack>
          </CardStyled>
          {AddUserModel}
        </Stack>
      )}
    </UsersTable>
  );
});
