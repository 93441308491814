import { ReactNode, memo } from "react";
import { useMobile, useTablet } from "../../themes";
import {
  CardStyled,
  CardHeaderStyled,
  CardContentContainerStyled,
  CardContentItemStyled,
  CardContentIconStyled,
} from "../styled";
import { Icon } from "@iconscout/react-unicons/dist/icon";
import {
  Box,
  Link,
  PaletteColor,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "../../lib";

interface SplitCardProps {
  children?: ReactNode;
  header?: ReactNode;
  sx?: SxProps<Theme>;
}

export const StatusCard = memo(({ header, children, sx }: SplitCardProps) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  return (
    <CardStyled
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      <CardHeaderStyled
        display={"flex"}
        justifyContent={"space-between"}
        height={"max-content"}
      >
        {header}
      </CardHeaderStyled>
      <CardContentContainerStyled container mt={isTablet ? 1 : 0}>
        <CardContentItemStyled
          item
          display={"flex"}
          justifyContent={isMobile || isTablet ? "flex-start" : "space-between"}
          px={2}
          py={isMobile ? 1 : 2}
          alignItems={isMobile || isTablet ? "flex-start" : "center"}
          flexDirection={isMobile || isTablet ? "column" : "row"}
          gap={1}
          overflow={"auto"}
        >
          {children}
        </CardContentItemStyled>
      </CardContentContainerStyled>
    </CardStyled>
  );
});
export type DashAction =
  | "mine"
  | "comments"
  | "consultant"
  | "client"
  | "critical"
  | "cw"
  | "new_tasks"
  | "backlog";
interface StatusContentProps {
  Icon: Icon;
  value: string;
  caption?: string;
  color?: PaletteColor | { 100: string | undefined; main: string | undefined };
  to?: string;
  removeClick?: boolean;
  contentSx?: SxProps<Theme>;
  captionSx?: SxProps<Theme>;
  action?: DashAction;
}
export const StatusContent = ({
  Icon,
  value,
  caption = "",
  color,
  to = "",
  removeClick,
  contentSx,
  captionSx,
}: StatusContentProps) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const loc = useLocation();

  const DisplayCaption = (
    <Box
      component={"span"}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      justifyContent={"center"}
      sx={{
        fontWeight: 600,
        fontSize: 16,
        ...contentSx,
      }}
    >
      {value}
      <Box
        component={"span"}
        sx={{
          fontSize: isMobile ? "12px" : ".43rem",
          lineHeight: "18px",
          letterSpacing: "0.02em",
          fontWeight: 400,
          whiteSpace: "nowrap",
          ...captionSx,
        }}
      >
        {caption}
      </Box>
    </Box>
  );

  const iconIsSelected = to.replace("?", "") === loc.search.replace("?", "");
  const Comp = (
    <Stack
      direction={"row"}
      width={"100%"}
      alignItems={"flex-start"}
      sx={{
        backgroundColor: iconIsSelected ? color?.[100] : "",
        pl: 0.5,
        pr: 1.5,
        py: 1,
        borderRadius: "6px",
        width: "fit-content",
      }}
    >
      <CardContentIconStyled
        sx={{
          backgroundColor: color?.[100] || "secondary",
          flexShrink: 0,
        }}
      >
        <Icon color={color?.main || "primary"} />
      </CardContentIconStyled>

      <Typography fontSize="18px">{DisplayCaption}</Typography>
    </Stack>
  );

  if (removeClick) return Comp;

  return (
    <Link
      to={to}
      component={RouterLink}
      width={"100%"}
      sx={{
        "&:hover, &:hover > *": {
          color: to === "" ? undefined : color?.main,
        },
        color: palette.grey[900],
      }}
    >
      {Comp}
    </Link>
  );
};
