import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { LoginPage } from "./LoginPage";
import { LogoutPage } from "./LogoutPage";
import { LoginAfterResetPage } from "./LoginAfterResetPage";
import { NewPasswordPage } from "./NewPasswordPage";
import { RecoverySentPage } from "./RecoverySentPage";
import { MultifactorAuthPage } from "./MultifactorAuthPage";
import { PageProps } from "../../lib";

export const AuthPages: Record<string, PageProps> = {
  forgotPassword: {
    anon: true,
    path: "/auth/forgot-password",
    title: "Forgot Password",
    view: ForgotPasswordPage,
    roles: [],
  },
  multiFactorAuth: {
    anon: true,
    path: "/auth/multi-factor-auth",
    title: "Multifactor Auth",
    view: MultifactorAuthPage,
    roles: [],
  },
  login: {
    anon: true,
    path: "/auth/login",
    title: "Login",
    view: LoginPage,
    roles: [],
  },
  loginAfterReset: {
    anon: true,
    path: "/auth/login-after-reset",
    title: "Login",
    view: LoginAfterResetPage,
    roles: [],
  },
  logout: {
    anon: true,
    path: "/auth/logout",
    title: "Logout",
    view: LogoutPage,
    roles: [],
  },
  newPassword: {
    anon: true,
    path: "/auth/new-password",
    title: "New Password",
    view: NewPasswordPage,
    roles: [],
  },
  recoverySent: {
    anon: true,
    path: "/auth/recovery-sent",
    title: "Recovery Sent",
    view: RecoverySentPage,
    roles: [],
  },
};

export default AuthPages;
