import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { useToggleTaskActionPendingMutation } from "../../state/rtk-query/state/tasks";
import { BuildingIcon } from "../icons";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import admireIconGrey from "../../assets/img/AdmireIconGrey.svg";
import admireIconWhite from "../../assets/img/AdmireIconWhite.svg";

interface ToggleActionPendingButtonProps {
  taskId?: number;
  orgId?: number;
  action_pending?: "admire" | "client" | null;
  justShowOne?: boolean;
}

export function ToggleActionPendingButton({
  orgId,
  taskId,
  action_pending,
  justShowOne,
}: ToggleActionPendingButtonProps) {
  const [toggleActionPending] = useToggleTaskActionPendingMutation();
  const isInternal = useSelector(authSelectors.isInternal);
  const { palette } = useTheme();
  if (justShowOne && !action_pending) {
    return null;
  }
  return (
    <ToggleButtonGroup
      color="primary"
      value={action_pending}
      sx={{
        width: justShowOne ? "32px" : "64px",
        height: "24px",
      }}
      exclusive
      onChange={(_e, v: "admire" | "client" | null) => {
        isInternal &&
          orgId &&
          taskId &&
          toggleActionPending({
            id: taskId,
            orgId,
            body: { action_pending: v },
          });
      }}
      disabled={!isInternal || justShowOne}
    >
      {(!justShowOne || action_pending === "admire") && (
        <ToggleButton
          value="admire"
          sx={{
            width: "32px",
            height: "24px",
            ...(justShowOne
              ? { px: "5px", borderRadius: "4px" }
              : { pl: "6px", pr: "4px", borderRadius: "6px" }),
          }}
        >
          <img
            src={action_pending === "admire" ? admireIconWhite : admireIconGrey}
            alt="admire-icon"
          />
        </ToggleButton>
      )}
      {(!justShowOne || action_pending === "client") && (
        <ToggleButton
          value="client"
          sx={{
            width: "32px",
            height: "24px",
            ...(justShowOne
              ? { px: "5px", borderRadius: "4px" }
              : { pl: "4px", pr: "6px", borderRadius: "6px" }),
          }}
        >
          <BuildingIcon
            color={action_pending !== "client" ? palette.grey[800] : undefined}
          />
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
