import { Link, Stack, Typography, useTheme } from "@mui/material";
import { BuildingIcon, LockIcon } from "../icons";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { castZero, formatDate, RouterLink } from "../../lib";
import { StartCwTimerButton, TaskActivityButton } from "../buttons";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { useCurOrg } from "../hooks";

interface Props {
  orgId?: number;
  taskId?: number;
  type?: GetTaskApiResponse["type"];
  taskTimeSpent?: number | null;
  taskCode?: string;
  taskTitle?: string;
  createdAt: Date;
  createdBy: string;
}
export const ModalTimerBanner = ({
  orgId,
  taskId,
  type,
  taskCode,
  taskTitle,
  createdAt,
  createdBy,
  taskTimeSpent,
}: Props) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const orgInfo = useCurOrg(orgId);
  const isInternalTask = type === "internal_task";

  if (!taskId) return null;

  return (
    <Stack
      px={isMobile ? 2 : 4}
      py={1.5}
      sx={{
        bgcolor: isInternalTask ? palette.warning[100] : palette.primary[100],
      }}
      direction={isMobile ? "column" : "row"}
      alignItems={isMobile ? "flex-start" : "center"}
      justifyContent={!isInternal ? "flex-end" : "space-between"}
      gap={1}
    >
      {isInternal ? (
        <Stack direction={"row"} alignItems={"center"}>
          {isInternalTask ? <LockIcon size={18} /> : <BuildingIcon size={18} />}
          <Link
            to={`/organizations/${orgInfo?.id}`}
            component={RouterLink}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              ml: 2,
            }}
            color={palette.grey[900]}
          >
            {orgInfo?.name}
          </Link>
        </Stack>
      ) : null}
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        gap={isMobile ? 0 : 1}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Typography
            variant="subtitle2"
            fontSize={isMobile ? 10 : 12}
            fontWeight={600}
          >
            {`Created ${isMobile ? "" : "on"} ${formatDate(
              new Date(createdAt),
              "M.d.yy",
            )} at ${formatDate(new Date(createdAt), "h:mm a")}  by ${
              createdBy ?? ""
            }`}
          </Typography>
          {taskId ? <TaskActivityButton id={taskId} /> : null}
        </Stack>
        {!isMobile && (
          <StartCwTimerButton
            type={type}
            taskTimeSpent={taskTimeSpent}
            orgId={castZero(orgId)}
            taskId={castZero(taskId)}
            taskCode={taskCode ?? ""}
            taskTitle={taskTitle ?? ""}
          />
        )}
      </Stack>
    </Stack>
  );
};
