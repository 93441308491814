import { useDispatch, useSelector } from "react-redux";
import { authActions, clientActions, clientSelectors } from "../../../state";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { SelectOrgBarStyled, SelectOrgFieldStyled } from "./AppBar.styles";
import { ChevronDown } from "../../../components";
import { useCallback } from "react";
import { useGetUserProfileQuery } from "../../../state/rtk-query/state/user";

export const OrgSelectBar = () => {
  const { currentData: userInfo } = useGetUserProfileQuery();
  const { orgs = [] } = userInfo || {};
  const org = useSelector(clientSelectors.currentOrg);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      dispatch(
        clientActions.setOrg(
          orgs?.find((o) => o.id === Number(event.target.value)),
        ),
      );
      dispatch(authActions.setOrgRoles(Number(event.target.value)));
    },
    [org],
  );
  return (
    <SelectOrgBarStyled>
      <SelectOrgFieldStyled
        label="org"
        value={org?.id.toString() || ""}
        onChange={handleChange}
        autoWidth
        IconComponent={ChevronDown}
        disableUnderline
        variant="standard"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {orgs?.map((o) => {
          return (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          );
        })}
      </SelectOrgFieldStyled>
    </SelectOrgBarStyled>
  );
};
