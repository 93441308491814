import { Tabs, TabsProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface TabsStyledProps extends TabsProps {
  color?: "primary" | "secondary" | "info";
  edge?: "line" | "pill";
  size?: "small" | "medium" | "large";
}

export const TabsStyled = styled(Tabs, {
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "fill" && prop !== "size",
})<TabsStyledProps>(
  ({ theme, color = "primary", edge = "line", size = "medium" }) => {
    let height = "";
    switch (edge) {
      case "pill":
        height =
          size === "small" ? "32px" : size === "medium" ? "38px" : "44px";
        break;
      default:
        height =
          size === "small" ? "40px" : size === "medium" ? "50px" : "60px";
        break;
    }

    return {
      minHeight: height,
      "& .MuiTab-root": {
        minHeight: height,
        height,
        padding: "10px 16px",
        color: theme.palette.grey[800],
        "&.Mui-disabled": {
          color: theme.palette.grey[600],
        },
        "&.Mui-selected": {
          color: theme.palette[color].main,
          ...(edge === "pill"
            ? {
                backgroundColor: theme.palette[color][100],
                borderRadius: "6px",
              }
            : {}),
        },
        "&:hover": {
          color: theme.palette.black[500],
          ...(edge === "pill"
            ? {
                backgroundColor: theme.palette.grey[100],
              }
            : {}),
        },
      },
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette[color].main,
        ...(edge === "pill"
          ? {
              display: "none",
            }
          : {}),
      },
    };
  },
);
