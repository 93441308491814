import { ArrayElement } from "../../global";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { Stack, Typography, Button, useTheme } from "@mui/material";
import { ClipboardIcon } from "../icons";
import { useState, useCallback } from "react";
import { AddTaskForm } from "../forms/AddTaskForm";
import { Modal } from "../modal";
import { useStatusesList } from "../hooks/useLists";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

interface Props {
  session: ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
  disabled?: Boolean;
  removeAddButton?: Boolean;
  containerWidth?: number | string;
  width?: number | string;
  height?: number | string;
}
export const AddTaskButton = ({
  session,
  disabled = false,
  removeAddButton = false,
  containerWidth = "220px",
  width = 20,
  height = 20,
}: Props) => {
  const { palette } = useTheme();
  const { tasks } = session;
  const [openTasks, setOpenTasks] = useState(false);
  const isInternal = useSelector(authSelectors.isInternal);
  const openTasksModal = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      setOpenTasks(!openTasks);
    },
    [openTasks],
  );
  const finishedTasks = tasks?.filter(
    (t) => t.status?.internal_name === "complete",
  );
  const statuses = useStatusesList("session");
  const sessionStatus = statuses.getStatus(session.status_id);
  const isResolved =
    sessionStatus?.transition_phase === "blocked" ||
    sessionStatus?.transition_phase === "done";

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      width={containerWidth}
      justifyContent={"flex-start"}
    >
      <Stack direction={"row"} alignItems={"center"}>
        <ClipboardIcon
          fontSize="small"
          color={palette.primary.main}
          width={width}
          height={height}
        />

        <Typography
          pl={1}
          fontSize={14}
          color={palette.grey[900]}
          width={"max-content"}
        >
          {finishedTasks?.length
            ? `${finishedTasks?.length}/${tasks?.length} tasks completed `
            : `${tasks?.length ?? 0} tasks`}
        </Typography>
      </Stack>
      {!sessionStatus || (!isResolved && !removeAddButton) ? (
        <Button onClick={openTasksModal} disabled={Boolean(disabled)}>
          {tasks?.length ? "Add" : "Add task"}
        </Button>
      ) : null}
      {session.id && (
        <Modal
          open={openTasks}
          onClose={openTasksModal}
          props={{
            onClick: (e: React.MouseEvent<HTMLDivElement>) =>
              e.preventDefault(),
          }}
        >
          <AddTaskForm
            onClose={openTasksModal}
            sessionId={session.id}
            submitAndClose
            orgId={isInternal ? session.org_id : undefined}
          />
        </Modal>
      )}
    </Stack>
  );
};
