import { useGetMyNotificationsQuery } from "../../../state/rtk-query/state/notification";
import {
  NotificationCard,
  SocketMessage,
  useRefetchSocket,
} from "../../../components";
import { Fragment, useEffect } from "react";
import { Stack } from "@mui/material";
import { useOrgId, useTaskDrawerIsOpen } from "../../../components/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const NotificationsPage = () => {
  const orgId = useOrgId();
  const drawerIsOpen = useTaskDrawerIsOpen();

  const { currentData, refetch } = useGetMyNotificationsQuery(
    orgId ? { orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const shouldRefetch = async (d: SocketMessage) => {
    if (d.tag === "notification" && d.message === "refresh" && !drawerIsOpen) {
      refetch();
    }
  };
  useRefetchSocket({ tag: "notification", cb: shouldRefetch });

  useEffect(() => {
    refetch();
  }, [orgId]);
  return (
    <Stack direction={"column"} height={"100%"}>
      <>
        {currentData?.rows
          ? currentData?.rows.map((r) => {
              return (
                <Fragment key={r.id}>
                  <NotificationCard notification={r} />
                </Fragment>
              );
            })
          : null}
      </>
    </Stack>
  );
};
