import {
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { RouterLink, castZero, formatDate, isDate, pluralize } from "../../lib";
import { Fragment, useCallback } from "react";
import { EditUnderlineIcon, TimerIcon } from "../icons";
import { intervalToDuration } from "date-fns";
import { useOpenTaskDrawer, useQuery } from "../hooks";
import { Timer } from "../../global";
import { useMobile } from "../../themes";
interface Props {
  timer: Timer;
  next?: Timer;
  expand: number[];
}
interface QueryParams {
  timerId: number | undefined;
  taskId?: number | null;
  open: boolean;
  mode?: "edit" | "new";
}
export const TimerCard = ({ timer, next, expand }: Props) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isExpanded = expand?.includes(Number(timer?.id));
  const openTaskDrawer = useOpenTaskDrawer();
  const isSameAsNext = next && timer?.org?.id === next?.org?.id;
  const [q, setTimerQuery] = useQuery<QueryParams>(
    "timerId",
    "taskId",
    "open",
    "mode",
  );
  const border = `2px solid ${palette.grey[500]}`;

  const getTimeDif = (date?: any, date2?: any) => {
    if (!isDate(new Date(date)) || !isDate(new Date(date2))) return 0;
    return date && date2
      ? intervalToDuration({
          start: new Date(date),
          end: new Date(date2),
        })
      : 0;
  };

  const timeDifFoTimer = timer?.duration
    ? {
        hours: Math.floor(Number(timer.duration ?? 0) / 60),
        minutes: Number(timer.duration ?? 0) % 60,
      }
    : getTimeDif(timer?.start_time, timer?.end_time);
  const editTimer = useCallback(() => {
    setTimerQuery({
      timerId: timer?.id,
      open: true,
      mode: "edit",
      taskId: timer?.task_id,
    });
  }, [timer?.id, q]);

  const isEditable = timer?.status === "new" || timer?.status === "reviewed";

  return (
    <Stack direction={"column"}>
      {timer && (timer?.start_time || timer?.duration) && isExpanded ? (
        <Stack
          sx={{
            py: 2,
          }}
          bgcolor={isEditable ? "white" : palette.grey[200]}
          width={"100%"}
          direction={"column"}
          borderLeft={isSameAsNext ? border : ""}
          borderRight={isSameAsNext ? border : ""}
          borderBottom={isSameAsNext ? `1px solid ${palette.grey[500]}` : ""}
          border={!isSameAsNext ? border : ""}
          borderRadius={isSameAsNext ? 0 : "0 0 6px 6px"}
          borderTop={0}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            px={2}
            justifyContent={"space-between"}
            gap={isMobile ? 1 : 0}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <TimerIcon />
              <Typography fontWeight={600} pl={1}>
                {timer.activity_type?.name}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              width={isMobile ? "100%" : "fit-content"}
              justifyContent={isMobile ? "space-between" : ""}
            >
              {timer.start_time ? (
                <Typography
                  fontWeight={600}
                  px={isMobile ? 0 : 1}
                  color={palette.grey[900]}
                >
                  {formatDate(new Date(timer.start_time), "h:mm aaa")} -{" "}
                  {timer.end_time
                    ? formatDate(new Date(timer.end_time), "h:mm aaa")
                    : ""}
                </Typography>
              ) : null}
              {timeDifFoTimer ? (
                <>
                  {Number(timeDifFoTimer?.hours ?? 0) > 0 ? (
                    <Typography variant="subtitle1" color={palette.grey[900]}>
                      {pluralize(Number(timeDifFoTimer?.hours ?? 0), "hour")}
                    </Typography>
                  ) : null}
                  <Typography
                    variant="subtitle1"
                    pl={1}
                    color={palette.grey[900]}
                  >
                    {pluralize(Number(timeDifFoTimer?.minutes ?? 0), "minute")}
                  </Typography>
                </>
              ) : null}

              {isEditable && (
                <IconButton onClick={editTimer} sx={{ ml: 2 }}>
                  <EditUnderlineIcon size={22} />
                </IconButton>
              )}
            </Stack>
          </Stack>
          {timer?.notes && timer?.notes?.trim() !== "" && (
            <Typography px={2} py={1} fontSize={14} fontWeight={500}>
              Notes: {timer?.notes}
            </Typography>
          )}
          <Stack direction={"column"} px={2} gap={0.5}>
            {timer.user_activities?.map((act) => (
              <Fragment key={act.id}>
                {act?.created_at && act?.action ? (
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography variant="subtitle2">
                      {formatDate(new Date(act?.created_at), "h:mm:aa")}{" "}
                      &middot; {act?.action?.name}
                    </Typography>
                    {act.entity !== "org" && act.title && (
                      <Link
                        to={
                          act.entity === "session"
                            ? `/sessions/${act.entity_id}`
                            : "/timer"
                        }
                        component={
                          act.entity === "session" ? RouterLink : Button
                        }
                        onClick={() =>
                          act.entity === "task"
                            ? openTaskDrawer(act.entity_id)
                            : null
                        }
                        sx={{ height: "20px" }}
                      >
                        {act?.title}
                      </Link>
                    )}
                  </Stack>
                ) : null}
              </Fragment>
            ))}
            {timer?.task_id && timer.task_title && (
              <Link
                component={Button}
                onClick={() => openTaskDrawer(castZero(timer?.task_id))}
                sx={{ height: "20px", width: "fit-content", px: 0 }}
              >
                {timer?.task_title ?? ""}
              </Link>
            )}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};
