import { useState, useCallback } from "react";
import { GetSupportModal } from "../modal";
import { authSelectors, useSelector } from "../../state";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { Tooltip } from ".";
import { IconButton } from "@mui/material";
import moveToSessionIcon from "../icons/move-to-session-icon.svg";

interface Props {
  taskId: number;
  orgId: number;
  type: GetTaskApiResponse["type"];
}
export const MoveToSessionIcon = ({ taskId, orgId, type }: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const [moveToSessionModal, setMoveToSessionModal] = useState(false);

  const toggleMoveToSession = useCallback(() => {
    setMoveToSessionModal((move) => !move);
  }, [moveToSessionModal]);
  if (type === "internal_task") return null;
  return (
    <>
      <Tooltip title="Move to Session">
        <IconButton onClick={toggleMoveToSession} size="small">
          <img src={moveToSessionIcon} alt={"moveToSessionIcon"} />
        </IconButton>
      </Tooltip>
      <GetSupportModal
        open={moveToSessionModal}
        onClose={toggleMoveToSession}
        defaultScreen="SessionSelection"
        movingTaskId={taskId}
        orgId={isInternal ? orgId : 0}
        isNotSessionTask={type !== "task"}
      />
    </>
  );
};
