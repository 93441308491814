import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { ToggleActionPendingButton } from "../buttons/ToggleActionPendingButton";
import { PriorityChip, StatusChip } from "../chips";
import { DrawerHeader } from "../drawer";
import { MoveToSessionIcon, TaskTypeIcon } from "../icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetSessionsByIdQuery } from "../../state/rtk-query/state/session";
import { CompleteTaskButton } from "./CompleteTaskButton";

interface TaskHeaderProps {
  taskData: GetTaskApiResponse | undefined;
  id: number;
  orgId: number;
  isComplete: boolean;
  CloseButton: ReactNode;
  AvatarAndMenu: ReactNode;
}

export const TaskHeader = ({
  taskData,
  id,
  orgId: _orgId,
  isComplete,
  CloseButton,
  AvatarAndMenu,
}: TaskHeaderProps) => {
  const isMobile = useMobile();
  const orgId = _orgId > 0 ? _orgId : taskData?.org_id ?? _orgId;
  const { priority, action_pending, type, internal_priority } = taskData || {};
  const activeSession = taskData?.sessions?.find(
    (s) => s?.session_tasks?.is_active,
  );
  const { data: sessionInfo } = useGetSessionsByIdQuery(
    activeSession?.id && _orgId ? { id: activeSession.id, orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const psd = activeSession?.pending_session_dates;

  const { booking, status_id, title } = sessionInfo ?? {};
  if (isMobile) {
    return (
      <DrawerHeader width={"100%"} gap={1} px={"16px !important"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          width={"100%"}
        >
          <Stack gap={1} direction={"row"} justifyContent={"flex-start"}>
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="internal"
              priority={internal_priority}
            />
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="external"
              priority={priority}
              showPriorityName
            />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            maxHeight={"100%"}
          >
            {AvatarAndMenu}
            <Stack
              direction={"column"}
              height={"100%"}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              {CloseButton}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
        >
          {type !== "internal_task" && !isComplete && (
            <ToggleActionPendingButton
              orgId={orgId}
              taskId={id}
              action_pending={action_pending}
            />
          )}

          <TaskTypeIcon
            type={type}
            psd={psd}
            start_time={booking?.start_time}
            end_time={booking?.end_time}
            status_id={status_id}
            session_id={booking?.session_id ?? activeSession?.id}
            session_title={title}
            show_session_details
          />
          <MoveToSessionIcon taskId={id} orgId={orgId} type={type} />
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
        >
          <StatusChip
            statusId={taskData?.status?.id}
            type={taskData?.type}
            dropDown
            id={id}
            orgId={orgId}
          />
          {!isComplete && id && orgId && (
            <CompleteTaskButton
              id={id}
              orgId={orgId}
              isFromPaymo={taskData?.is_from_paymo || false}
            />
          )}
        </Stack>
      </DrawerHeader>
    );
  }
  return (
    <DrawerHeader minWidth={"604px"} gap={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"auto"}
        minWidth={"fit-content"}
        gap={2}
      >
        <Stack direction={"row"} width={"auto"} gap={2}>
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "space-between" : "center"}
            justifyContent={isMobile ? "space-between" : "center"}
            width={"auto"}
            gap={2}
            height={isMobile ? "100%" : "auto"}
          >
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="internal"
              priority={internal_priority}
            />
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="external"
              priority={priority}
              showPriorityName
            />
            <StatusChip
              statusId={taskData?.status?.id}
              type={taskData?.type}
              dropDown
              id={id}
              orgId={orgId}
            />
            {type !== "internal_task" && !isComplete && (
              <ToggleActionPendingButton
                orgId={orgId > 0 ? orgId : taskData?.org_id}
                taskId={id}
                action_pending={action_pending}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          width={"auto"}
          height={"100%"}
          alignItems={isMobile ? "flex-end" : "center"}
          gap={1}
        >
          {AvatarAndMenu}
          <Stack
            direction={"column"}
            height={"100%"}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            {CloseButton}
          </Stack>
        </Stack>
      </Stack>
      {!isComplete && id && orgId && (
        <CompleteTaskButton
          id={id}
          orgId={orgId}
          isFromPaymo={taskData?.is_from_paymo || false}
        />
      )}
    </DrawerHeader>
  );
};
