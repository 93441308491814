import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import * as yup from "yup";
import { Switch, TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import { AppThunkDispatch, microsoftActions } from "../../../state";
import { useDispatch } from "../../../state";
import { GridStyled } from "./UserPage.styles";
import { AlertIcon } from "../../../components";
import { useCustomEditor } from "../../../components/hooks";
import { CustomEditor } from "../../../components/inputs";

interface Props {
  eventID: string;
  eventData: any;
  isEdit: boolean;
  closeModal: () => void;
}
export const MicrosoftOutlookEvent = ({
  eventID,
  eventData,
  isEdit,
  closeModal,
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editor = useCustomEditor({});

  const validationSchema = yup.object({
    startDate: yup.date(),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date can't be before start date")
      .when("isAllDay", {
        is: true,
        then: yup
          .date()
          .test(
            "equals",
            "All day event must end on the following day",
            function (value) {
              if (value) {
                const { startDate } = this.parent;
                if (value.toString() === startDate.toString()) return false;
                else return true;
              } else return true;
            },
          ),
      }),
    startTime: yup.string().when("isAllDay", {
      is: true,
      then: yup
        .string()
        .test("equals", "All day event must be set to 12 AM", function (value) {
          if (value !== "00:00:00") return false;
          else return true;
        }),
    }),
    endTime: yup
      .string()
      .when("isAllDay", {
        is: true,
        then: yup
          .string()
          .test(
            "equals",
            "All day event must be set to 12 AM",
            function (value) {
              if (value !== "00:00:00") return false;
              else return true;
            },
          ),
      })
      .test(
        "is-greater",
        "End time can't be before start time",
        function (value) {
          if (value) {
            const { startTime, startDate, endDate } = this.parent;
            const start = startDate.toString();
            const end = endDate.toString();
            const end_time = new Date("01/01/2000 " + value);
            const start_time = new Date("01/01/2000 " + startTime);
            return (
              start !== end || end_time.toString() >= start_time.toString()
            );
          } else return true;
        },
      ),
    reminderMinutesBeforeStart: yup.string().when("isReminderOn", {
      is: true,
      then: yup.string().required("Set reminder time"),
    }),
  });
  const [initialValues, setInitialValues] = useState({
    subject: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    isAllDay: false,
    location: "",
    description: "",
    reminderMinutesBeforeStart: 15,
    isReminderOn: true,
  });
  const handleSubmit = (
    values: typeof initialValues & unknown,
    { setSubmitting }: any,
  ) => {
    const {
      startDate,
      startTime,
      endDate,
      endTime,
      location,
      reminderMinutesBeforeStart,
      ..._rest
    } = values;
    const rest = _rest as unknown as Record<string, any>;
    ["start", "end", "body", "description"].forEach((e) => delete rest[e]);
    const startDateTime = `${startDate}T${startTime}`;
    const endDateTime =
      endDate !== ""
        ? `${endDate}T${endTime}`
        : new Date(
            new Date(startDateTime).getTime() + 30 * 60000,
          ).toISOString();
    const timeString = new Date().toTimeString()?.match(/\((.+)\)/);
    const timeZone = timeString
      ? timeString[1].replace("Daylight", "Standard") //Microsoft requires 'standard' time zones regardless of daylight savings
      : "Eastern Standard Time";
    const description = editor?.getHTML();
    const eventValues = {
      start: {
        dateTime: startDateTime,
        timeZone: timeZone,
      },
      end: {
        dateTime: endDateTime,
        timeZone: timeZone,
      },
      location: { displayName: location },
      body: { contentType: "HTML", content: description },
      reminderMinutesBeforeStart: values.isReminderOn
        ? reminderMinutesBeforeStart
        : -1,
      ...rest,
    };
    if (isEdit) {
      dispatch(microsoftActions.updateOutlookEvent(eventID, eventValues));
    } else {
      dispatch(microsoftActions.createOutlookEvent(eventValues));
    }
    setSubmitting(false);
    closeModal();
  };

  useEffect(() => {
    if (isEdit) {
      const description = eventData.body.content;
      const location = eventData.location.displayName;
      const startString = eventData.start.dateTime;
      const endString = eventData.end.dateTime;
      const startUTC = new Date(startString);
      const endUTC = new Date(endString);
      const startLocal = new Date(`${startString}Z`); //Z is added so UTC conversion is done properly
      const endLocal = new Date(`${endString}Z`);
      const startDate = startLocal.toISOString().split("T")[0];
      const startTime = eventData.isAllDay
        ? startUTC.toLocaleTimeString("it-IT")
        : startLocal.toLocaleTimeString("it-IT");
      const endDate = endLocal.toISOString().split("T")[0];
      const endTime = eventData.isAllDay
        ? endUTC.toLocaleTimeString("it-IT")
        : endLocal.toLocaleTimeString("it-IT");
      const reminderMinutesBeforeStart = eventData.isReminderOn
        ? eventData.reminderMinutesBeforeStart
        : "";
      setInitialValues({
        ...eventData,
        description,
        location,
        startDate,
        endDate,
        startTime,
        endTime,
        reminderMinutesBeforeStart,
      });
    }
  }, [dispatch, isEdit]);

  return (
    <Grid container direction="row" justifyContent="center" paddingBottom={3}>
      <Grid item>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Grid alignItems={"center"} container>
                <Grid container>
                  <GridStyled item>
                    <Typography variant="h4">{`${
                      isEdit ? "Edit" : "Add"
                    } Event`}</Typography>
                  </GridStyled>
                </Grid>
                <Grid container>
                  <Field
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    id="subject"
                    name="subject"
                    label="Title"
                    required
                  />
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      Start
                      <div style={{ display: "flex" }}>
                        <Field
                          component={TextField}
                          fullWidth
                          name="startDate"
                          required
                          type="date"
                          style={{ marginRight: 10 }}
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          name="startTime"
                          required
                          type="time"
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      End
                      <div style={{ display: "flex" }}>
                        <Field
                          component={TextField}
                          fullWidth
                          name="endDate"
                          type="date"
                          style={{ marginRight: 10 }}
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          name="endTime"
                          type="time"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container paddingBottom={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="isAllDay"
                            checked={values.isAllDay}
                            onChange={() => {
                              setFieldValue("isAllDay", !values.isAllDay);
                              setFieldValue("startTime", "00:00:00");
                              setFieldValue("endTime", "00:00:00");
                            }}
                          />
                        }
                        label="All day"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid container paddingBottom={3}>
                    <div style={{ display: "flex" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              type="checkbox"
                              name="isReminderOn"
                              checked={values.isReminderOn}
                              onChange={() => {
                                setFieldValue(
                                  "isReminderOn",
                                  !values.isReminderOn,
                                );
                                setFieldValue("reminderMinutesBeforeStart", "");
                              }}
                            />
                          }
                          label="Set reminder"
                        />
                      </FormGroup>
                      <Field
                        component={TextField}
                        fullWidth
                        variant="outlined"
                        name="reminderMinutesBeforeStart"
                        label={
                          <div style={{ display: "flex" }}>
                            <AlertIcon style={{ paddingRight: 3 }} />
                            {`${values.reminderMinutesBeforeStart} minutes
                          before`}
                          </div>
                        }
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid container paddingBottom={3}>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      name="location"
                      label="Location"
                    />
                  </Grid>
                  <Grid container paddingBottom={3}>
                    <CustomEditor editor={editor} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
