import {
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { useState, useCallback, useReducer, useEffect } from "react";
import { MoreVertIcon } from "../icons";
import {
  useCreateTaskInstantZoomMeetingMutation,
  useDeleteTaskMutation,
  GetTaskApiResponse,
  useRestoreTaskMutation,
  useConvertTaskMutation,
} from "../../state/rtk-query/state/tasks";
import { MergeUnion, Navigation, useLocation } from "../../lib";
import {
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../state";
import { Modal } from "../modal";
import {
  ConfirmDeleteModal,
  ConfirmationModal,
  ConvertTaskForm,
  UploadCWManualForm,
  useOpenTaskDrawer,
  useOrgsList,
} from "..";
import { GetOrgTasksApiResponse } from "../../state/rtk-query/state/organization";
import { BaseModal } from "../forms/BaseForm";

type Task =
  | { [K in keyof GetTaskApiResponse]?: GetTaskApiResponse[K] }
  | {
      [K in keyof GetOrgTasksApiResponse["rows"][0]]?: GetOrgTasksApiResponse["rows"][0][K];
    };

interface Props {
  task?: Task;
  isComplete?: boolean;
  isArchived?: boolean;
  manageAttendeeClick: () => void;
}

interface ModalState {
  uploadCw: boolean;
  confirmArchive: boolean;
  criticalQ: boolean;
  confirmConvertToQQ: boolean;
}
type ActionState = keyof ModalState;

const reducer = (state: ModalState, action: ActionState) => {
  if (action) {
    return {
      ...state,
      [`${action}`]: !state[action],
    };
  }
  throw Error("Unknown action.");
};
export const TaskMenu = ({
  task,
  manageAttendeeClick,
  isComplete,
  isArchived,
}: Props) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { pathname, query } = useLocation();
  const isInternal = useSelector(authSelectors.isInternal);
  const openTask = useOpenTaskDrawer();

  const [state, openModal] = useReducer(reducer, {
    uploadCw: false,
    confirmArchive: false,
    criticalQ: false,
    confirmConvertToQQ: false,
  });

  const {
    org_id: orgId,
    type,
    title,
    description,
    id,
    assigned_to_user,
    client_assignee_user,
    linkedTasks,
    topic,
  } = (task as MergeUnion<Task>) || {};

  const { getOrg } = useOrgsList(!isInternal);
  const taskOrg = getOrg(orgId);

  const [archiveTask] = useDeleteTaskMutation();
  const [restoreTask] = useRestoreTaskMutation();
  const [createZoomMeeting] = useCreateTaskInstantZoomMeetingMutation();
  const [convertTask, { isSuccess, data }] = useConvertTaskMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openSubModal, setOpenSubModal] = useState<
    { id: number } | undefined
  >();

  useEffect(() => {
    if (isSuccess && data?.id) {
      openTask(data.id);
    }
  }, [isSuccess, data]);

  const taskId = id ?? Number(query.task);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleConvertTask = useCallback(() => {
    if (taskId) {
      setOpenSubModal({ id: taskId });
    }
  }, [openSubModal, taskId]);

  const closeModal = useCallback(() => {
    setOpenSubModal(undefined);
  }, [openSubModal]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(location.href);
    dispatch(uiActions.showSuccess("link copied"));
  }, [pathname]);

  const onSessionTasks = type === "task";
  const quickQuestion = type === "quick_question";
  const isCustomWork = type === "custom_work";
  const linkToCall =
    (isCustomWork || quickQuestion || onSessionTasks) && !isArchived;

  const styles: SxProps<Theme> = {
    fontWeight: 500,
    py: 2,
    "&:hover": {
      backgroundColor: palette.accent[50],
    },
  };

  const menuClick = useCallback(
    (modalName: ActionState) => {
      openModal(modalName);
    },
    [state],
  );

  const archiveConfirmationClick = useCallback(async () => {
    delete query.task;
    Navigation.replace(pathname, { query });
    if (taskId && orgId) {
      await archiveTask({ id: taskId, orgId });
      // TODO: when we have an archive page add link to there
      dispatch(uiActions.showSuccess("task archived"));
    }
  }, [taskId, query?.task, state?.confirmArchive, orgId, isArchived]);

  const createZoomClick = useCallback(async () => {
    await createZoomMeeting({ id: taskId });
  }, [taskId]);

  const restoreTaskClick = useCallback(async () => {
    if (task?.org_id) await restoreTask({ id: taskId, orgId: task?.org_id });
  }, [taskId, task?.org_id, isArchived]);

  const base_url = "https://www.jotform.com/231564649392061";
  const formLink = `${base_url}
    ?account=${encodeURIComponent(taskOrg?.name ?? "")}
    &name%5Bfirst%5D=${encodeURIComponent(
      client_assignee_user?.first_name ?? "",
    )}
    &name%5Blast%5D=${encodeURIComponent(client_assignee_user?.last_name ?? "")}
    &clientEmail=${encodeURIComponent(client_assignee_user?.email ?? "")}
    &consultant=${encodeURIComponent(
      `${assigned_to_user?.first_name} ${assigned_to_user?.last_name}`,
    )}
    &consultantEmail=${encodeURIComponent(assigned_to_user?.email ?? "")}
    &requestDetails=${encodeURIComponent(`${title} - ${description}`)}
`
    // https://stackoverflow.com/a/70253407 so spaces don't show in url
    .split("\n")
    .map((s) => s.trim())
    .filter(Boolean)
    .join("\n");

  const allowConvertToCw =
    topic &&
    ((orgId && type === "quick_question") ||
      (type === "task" && !linkedTasks?.length));

  return (
    <>
      <IconButton
        onClick={handleClick}
        style={{
          backgroundColor: palette.grey[200],
          borderRadius: 4,
          width: 24,
          height: 24,
        }}
      >
        <MoreVertIcon style={{ width: 20, height: 20 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            "& > .MuiList-padding": { py: 0 },
          },
        }}
      >
        {isInternal && !isComplete ? (
          <MenuItem onClick={manageAttendeeClick} sx={styles}>
            Manage people
          </MenuItem>
        ) : null}

        <MenuItem onClick={copyToClipboard} sx={styles}>
          Copy task link
        </MenuItem>
        {/*TODO: Should internal users be able to convert closed tasks? */}
        {isInternal && !isComplete && (
          <span>
            {/* Fix mui warning that fragment cannot appear as descendant of Menu */}
            {type !== "internal_task" && (
              <span>
                {allowConvertToCw && (
                  <MenuItem onClick={() => handleConvertTask()} sx={styles}>
                    Link to custom work
                  </MenuItem>
                )}
                {isCustomWork && (
                  <MenuItem onClick={() => menuClick("uploadCw")} sx={styles}>
                    Add custom work manual
                  </MenuItem>
                )}
                {quickQuestion && (
                  <MenuItem onClick={() => menuClick("criticalQ")} sx={styles}>
                    Complete critical question assessment
                  </MenuItem>
                )}
              </span>
            )}
            {linkToCall && (
              <span>
                {/* TODO: phase 2  */}
                {/* <MenuItem onClick={handleClose} sx={styles}>
                  Link to call
                </MenuItem> */}
                <MenuItem onClick={createZoomClick} sx={styles}>
                  Start instant meeting
                </MenuItem>
              </span>
            )}
          </span>
        )}
        {!isComplete && type === "custom_work" && (
          <MenuItem onClick={() => menuClick("confirmConvertToQQ")} sx={styles}>
            Convert to quick question
          </MenuItem>
        )}
        {!isArchived ? (
          <MenuItem
            onClick={() => menuClick("confirmArchive")}
            sx={{ ...styles, color: palette.error.main }}
          >
            Archive
          </MenuItem>
        ) : (
          <MenuItem onClick={restoreTaskClick} sx={styles}>
            Restore
          </MenuItem>
        )}
      </Menu>
      {allowConvertToCw && openSubModal?.id && (
        <BaseModal
          open={Boolean(openSubModal)}
          onClose={closeModal}
          title={"Link to Custom Work"}
          modalProps={{ pcWidth: "35%" }}
        >
          <ConvertTaskForm
            onClose={closeModal}
            taskId={openSubModal.id}
            orgId={orgId!}
          />
        </BaseModal>
      )}
      <Modal open={state.uploadCw} onClose={() => menuClick("uploadCw")}>
        <UploadCWManualForm
          onClose={() => menuClick("uploadCw")}
          taskId={Number(query.task)}
        />
      </Modal>

      <ConfirmationModal
        open={state.criticalQ}
        onClose={() => menuClick("criticalQ")}
        title="Complete critical question assessment"
        subtext="If you already completed the form there is no need to do it again.
            Completed assessments can be viewed in JotForm."
        linkUrl={formLink}
        closeOnLinkClick
        linkText="Go to form"
      />

      <ConfirmDeleteModal
        open={state.confirmArchive}
        onClose={() => menuClick("confirmArchive")}
        handleConfirm={archiveConfirmationClick}
        titleText="Are you sure you want to archive this task?"
        subTitleText=" "
        confirmActionButtonText="Archive"
      />

      <ConfirmDeleteModal
        open={state.confirmConvertToQQ}
        onClose={() => menuClick("confirmConvertToQQ")}
        handleConfirm={() => {
          orgId &&
            convertTask({
              id: taskId,
              orgId: orgId,
              body: { type: "quick_question" },
            });
          menuClick("confirmConvertToQQ");
        }}
        titleText="Are you sure you want to convert this task to a Quick Question?"
        subTitleText="Converting to Quick Question will automatically close out this task."
        confirmActionButtonText="Convert"
      />
    </>
  );
};
