import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { WrenchIcon, QuestionIcon, LockIcon, CalendarIcon, Tooltip } from ".";
import { useGetStatus, useGetStatusId } from "../hooks";
import completedIcon from "./calendar-icons/attended-calendar.svg";
import upcomingIcon from "./calendar-icons/upcoming-calendar.svg";
import noShowIcon from "./calendar-icons/no-show-calendar.svg";
import cancelledIcon from "./calendar-icons/cancelled-calendar.svg";
import { capitalizeSentence, formatDate } from "../../lib";
import { IconButton, PaletteColor, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SessionTimeRange } from "../misc";

interface SessionIconProps {
  start_time?: string | undefined;
  end_time?: string | null | undefined;
  psd?:
    | {
        value?: string | null | undefined;
        inclusive?: boolean | null | undefined;
      }[]
    | null;
  status_id?: number | undefined;
  session_id?: number;
  session_title?: string | null;
  show_session_details?: boolean;
}
export const SessionIcon = ({
  start_time,
  end_time,
  psd,
  status_id,
  session_id,
  session_title,
  show_session_details,
}: SessionIconProps) => {
  const { palette } = useTheme();
  const nav = useNavigate();
  const goToSession = () => {
    if (session_id) {
      nav(`/sessions/${session_id}`);
    }
  };
  const meetingStatus = useGetStatus(status_id);
  const completedStatus = useGetStatusId("complete", "session");
  const noShowStatus = useGetStatusId("no_show", "session");
  const canceledStatus = useGetStatusId("canceled", "session");
  let iconUrl = upcomingIcon;
  let iconAlt = "upcoming-icon";
  switch (meetingStatus && meetingStatus?.id) {
    case completedStatus:
      iconUrl = completedIcon;
      iconAlt = "completed-icon";
      break;
    case noShowStatus:
      iconUrl = noShowIcon;
      iconAlt = "no-show-icon";
      break;
    case canceledStatus:
      iconUrl = cancelledIcon;
      iconAlt = "cancelled-icon";
      break;
    default:
      iconUrl = upcomingIcon;
      iconAlt = "upcoming-icon";
  }
  if (!psd?.length && !start_time) return null;
  const isPending = psd?.length && !start_time;
  const sessionTitle =
    show_session_details && start_time ? (
      <>
        {session_title ?? ""}
        <br />
        {`${formatDate(new Date(start_time), "EEE, LLL d")}`}{" "}
        <SessionTimeRange startTime={start_time} endTime={end_time} />
      </>
    ) : (
      capitalizeSentence(
        `${iconAlt.split("-icon")[0]?.replace("-", " ")} session`,
      )
    );

  return (
    <>
      {isPending ? (
        <Tooltip title={"Pending"}>
          <Stack direction={"column"} alignItems={"center"}>
            {session_id ? (
              <IconButton onClick={goToSession} size="small">
                <CalendarIcon color={palette.grey[700]} height={20} />
              </IconButton>
            ) : (
              <CalendarIcon color={palette.grey[700]} height={20} />
            )}
          </Stack>
        </Tooltip>
      ) : (
        <Tooltip title={sessionTitle}>
          {session_id ? (
            <IconButton onClick={goToSession} size="small">
              {<img src={iconUrl} alt={iconAlt} />}
            </IconButton>
          ) : (
            <img src={iconUrl} alt={iconAlt} />
          )}
        </Tooltip>
      )}
    </>
  );
};

interface Props extends SessionIconProps {
  type: GetTaskApiResponse["type"];
  show_session_details?: boolean;
}

interface IconProps {
  type: GetTaskApiResponse["type"];
  Icon: typeof WrenchIcon;
  color: PaletteColor;
}
const IconWithTooltip = ({ type, Icon, color }: IconProps) => {
  return (
    <Tooltip title={capitalizeSentence(type?.replace("_", " ") ?? "")}>
      <Stack direction={"column"} alignItems={"center"}>
        {<Icon color={color[500]} />}
      </Stack>
    </Tooltip>
  );
};

export const TaskTypeIcon = ({ type, ...rest }: Props) => {
  const { palette } = useTheme();
  return (
    <>
      {type === "custom_work" ? (
        <IconWithTooltip Icon={WrenchIcon} color={palette.info} type={type} />
      ) : type === "quick_question" ? (
        <IconWithTooltip
          Icon={QuestionIcon}
          color={palette.orange as PaletteColor}
          type={type}
        />
      ) : type === "internal_task" ? (
        <IconWithTooltip Icon={LockIcon} color={palette.warning} type={type} />
      ) : (
        <SessionIcon {...rest} />
      )}
    </>
  );
};
