import { useMemo, useState } from "react";
import React from "react";
import { QueryParams } from "../../lib/requestBuilder";

export const useRtkQueryParams = <
  AdditionalParams extends Record<string, any> | undefined = undefined,
>(
  additionalParams?: AdditionalParams,
  defaultParams?: QueryParams,
): [
  params: AdditionalParams extends Record<string, any>
    ? QueryParams & AdditionalParams
    : QueryParams,
  setParams: React.Dispatch<React.SetStateAction<QueryParams | undefined>>,
] => {
  const defaultP = defaultParams || {
    linkType: "and",
    range: [0, 25],
  };
  const [_params, setParams] = useState<QueryParams | undefined>(defaultP);

  const params = useMemo(() => {
    const queryParams = _params
      ? {
          sort: _params.sort,
          field: _params.field,
          filter: _params.filter,
          range: _params.range,
          linkType: _params.linkType,
          q: _params.q,
        }
      : defaultP;
    return additionalParams
      ? { ...queryParams, ...additionalParams }
      : queryParams;
  }, [
    additionalParams,
    _params?.field,
    _params?.filter,
    _params?.linkType,
    _params?.q,
    _params?.range,
    _params?.sort,
  ]);
  return [
    params as AdditionalParams extends Record<string, any>
      ? QueryParams & AdditionalParams
      : QueryParams,
    setParams,
  ];
};
