import {
  useConvertTaskMutation,
  ConvertTaskApiArg,
} from "../../state/rtk-query/state/tasks";
import { SingleDatePickerField } from "../inputs";
import { BaseForm } from "./BaseForm";
import { TopLabel } from "./FormFields";

interface Props {
  onClose: () => void;
  taskId: number;
  orgId: number;
}

export function ConvertTaskForm({ onClose, taskId, orgId }: Props) {
  const [convertTask] = useConvertTaskMutation();
  const initialValues: ConvertTaskApiArg["body"] = {
    type: "custom_work",
    requested_by_date: null,
  };
  const onSubmit = (values: typeof initialValues) => {
    convertTask({ id: taskId, orgId, body: values });
    onClose();
  };
  return (
    <BaseForm
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonName="Submit"
    >
      {() => {
        return (
          <TopLabel label="Requested by" fullWidth>
            <SingleDatePickerField name="requested_by_date" disablePast />
          </TopLabel>
        );
      }}
    </BaseForm>
  );
}
