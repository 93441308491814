import { Check } from "@mui/icons-material";
import { Checkbox, CheckboxProps, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChangeEvent } from "react";
const CheckBoxStyled = styled("span")(({ theme }) => ({
  width: 21,
  height: 21,
  backgroundColor: "#FFF",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.grey[600]}`,
}));

interface CheckBoxProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  props?: CheckboxProps;
}
export const CustomCheckBox = ({ onChange, checked, props }: CheckBoxProps) => {
  const { palette } = useTheme();
  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      {...props}
      icon={<CheckBoxStyled sx={{ ...props?.sx }} />}
      checkedIcon={
        <CheckBoxStyled
          sx={{
            backgroundColor: palette.primary.main,
            color: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...props?.sx,
          }}
        >
          <Check sx={{ width: "19px", height: "19px" }} />
        </CheckBoxStyled>
      }
    />
  );
};
