import { ChartDataset, ChartOptions } from "chart.js";
import { defaultBarOptions } from "../ChartConstants";

export const CHART_DURATION = 12;
export const monthLabels = [
  { id: 1, label: "Jan" },
  { id: 2, label: "Feb" },
  { id: 3, label: "Mar" },
  { id: 4, label: "Apr" },
  { id: 5, label: "May" },
  { id: 6, label: "Jun" },
  { id: 7, label: "Jul" },
  { id: 8, label: "Aug" },
  { id: 9, label: "Sep" },
  { id: 10, label: "Oct" },
  { id: 11, label: "Nov" },
  { id: 12, label: "Dec" },
];

export const defaultChartOptions: ChartOptions<"bar"> = {
  ...defaultBarOptions,
  animation: { easing: "linear", duration: 500 },
  plugins: {
    ...defaultBarOptions.plugins,
    legend: {
      ...defaultBarOptions.plugins?.legend,

      labels: {
        ...defaultBarOptions.plugins?.legend?.labels,
        // if we start playing around with the order of the bars and lines (try to get "your average" line above the bars)
        // the legend gets mess up, a simpler solution is to reorder the legend an set the order of just the bars
        generateLabels(chart) {
          const datasets = chart.data.datasets as any;
          return datasets
            .map((dataset: any, i: number) => {
              return {
                type: dataset.type,
                text: dataset.label,
                fillStyle: dataset.backgroundColor,
                hidden: !chart.isDatasetVisible(i),
                lineCap: dataset.borderCapStyle,
                lineDash: dataset.borderDash,
                lineDashOffset: dataset.borderDashOffset,
                lineJoin: dataset.borderJoinStyle,
                lineWidth: dataset.borderWidth,
                strokeStyle: dataset.borderColor,
                pointStyle: dataset.pointStyle,
                rotation: dataset.rotation,
                datasetIndex: i,
              };
            })
            .sort((a: any, _b: any) => a.type === "line");
        },
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const { dataset, raw } = context;
          return `${dataset.label}: ${Number(raw ?? 0).toFixed(2)}`;
        },
      },
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  scales: {
    x: {
      ...defaultBarOptions.scales?.x,
      stacked: true,
    },
    y: {
      ...defaultBarOptions.scales?.y,
      stacked: true,
    },
  },
} as ChartOptions<"bar">;

export const suggestedAverage: (
  color: string,
  average: number,
  xAxisLength?: number,
) => ChartDataset<"line"> = (color, average, xAxisLength = CHART_DURATION) => ({
  type: "line" as const,
  borderColor: color,
  data: Array(xAxisLength).fill(average),
  borderDash: [5, 5],
  label: "Suggested average",
  xAxisID: "suggestedAverage",
  borderWidth: 0.75,
  // pointRadius: 0,
  // pointHitRadius: 0,
  pointStyle: "dash",
});
