import {
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Divider,
  useTheme,
  Box,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import {
  UpdateMyPreferencesApiArg,
  useUpdateMyPreferencesMutation,
} from "../../state/rtk-query/state/preferences";
import { useOrgId } from "../hooks";
import { CardStyled, StyledSwitch } from "../styled";
import { useMobile, useTablet } from "../../themes";

interface PrefenceCardContainerProps {
  children: ReactNode;
  title: string;
}
export const PreferenceCardsContainer = ({
  children,
  title,
}: PrefenceCardContainerProps) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  return (
    <Stack
      sx={{
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: "6px",
        my: 2,
      }}
      direction={isMobile ? "column" : "row"}
      bgcolor={"#FFF"}
      p={isMobile ? 1 : 2}
      divider={
        isMobile ? (
          <Divider sx={{ width: "95%", alignSelf: "center" }} flexItem />
        ) : (
          <Divider
            orientation="vertical"
            sx={{ height: "95%", alignSelf: "center" }}
            flexItem
          />
        )
      }
    >
      <Box sx={{ p: 2, width: isMobile ? "100%" : "15%" }}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Stack pl={3} width={isMobile ? "100%" : "85%"} direction={"column"}>
        {children}
      </Stack>
    </Stack>
  );
};

interface PrefProps {
  name: UpdateMyPreferencesApiArg["preference"] | undefined;
  active: boolean;
}
export const PreferenceCard = ({ name, active }: PrefProps) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;

  let title = "";
  let description = "";
  const [activeLocal, setActiveLocal] = useState(active);
  const [updatePref] = useUpdateMyPreferencesMutation();
  const orgId = useOrgId();
  switch (name) {
    case "comment_added":
      title = "Comment added";
      description =
        "Get an email when a comment is added to a task that you are watching";
      break;
    case "session_invite":
      title = "Invited to session";
      description = "Get an email when someone invites you to a session";
      break;
    case "upcoming_session_day":
      title = "Upcoming session reminder - 1 day";
      description = "Get an email reminder 1 day before a scheduled session";
      break;
    case "upcoming_session_hour":
      title = "Upcoming session reminder - 1 hour";
      description = "Get an email reminder 1 hour before a scheduled session";
      break;
    case "late_to_session":
      title = "Late to session";
      description =
        "Get an email 5 minutes into a session when you haven't joined yet.";
      break;
    case "inactive_reminder":
      title = "Inactivity reminder";
      description =
        "Get an email reminder when you haven't responded to a task that is waiting on you for 3 days";
      break;
    case "task_auto_closed":
      title = "Task auto-closed";
      description =
        "Get an email when your task is auto-closed due to inactivity";
      break;
    case "task_close":
      title = "Task Closing Notification";
      description =
        "Get notified that your task will close within 24 hours. (Note: Task can be reopened by commenting on the task.)";
      break;
    case "announcement_global":
      title = "Global announcements";
      description = "Get an email when a global announcement goes out";
      break;
    case "announcement_org":
      title = "Personal announcements";
      description =
        "Get an email when an announcement goes out to your organization";
      break;
    case "all_task_activity":
      title = "All task activity";
      description = "Get a notification for all activity on all tasks.";
      break;
    case "view_task_as_modal":
      title = "Display tasks in center of screen";
      description =
        "Tasks will now appear in the center of the screen, instead of a drawer on the right side.";
      break;

    default:
      break;
  }

  const updatePreferences = () => {
    if (name) {
      updatePref({ orgId, preference: name });
    }
    setActiveLocal(!activeLocal);
  };

  useEffect(() => {
    if (active !== activeLocal) {
      setActiveLocal(!!active);
    }
  }, [active]);

  return (
    <CardStyled
      sx={{
        py: 2,
        px: isSmallerThanPc ? 0 : 2,
        border: "none",
        width: "100%",
      }}
    >
      {isSmallerThanPc ? (
        <Stack direction={"column"} width={"100%"}>
          <Stack
            direction={"row"}
            gap={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={600} fontSize={16} width={"100%"}>
              {title}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <StyledSwitch
                    sx={{ m: 1 }}
                    checked={activeLocal}
                    onClick={updatePreferences}
                  />
                }
                label=""
              />
            </FormGroup>
          </Stack>

          <Typography fontWeight={500} fontSize={14} lineHeight={1.5}>
            {description}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"column"}>
            <Typography fontWeight={600} fontSize={16} lineHeight={1.5}>
              {title}
            </Typography>
            <Typography fontWeight={500} fontSize={16} lineHeight={2}>
              {description}
            </Typography>
          </Stack>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledSwitch
                  sx={{ m: 1 }}
                  checked={activeLocal}
                  onClick={updatePreferences}
                />
              }
              label=""
            />
          </FormGroup>
        </Stack>
      )}
    </CardStyled>
  );
};
