import { Stack, Typography } from "@mui/material";
import { useMobile } from "../../themes";
import { DarkGreyTextStyled } from "../styled";

interface Props {
  field: string;
  value?: string;
  isOrgProfile?: boolean;
}
export const ProfileField = ({ field, value, isOrgProfile }: Props) => {
  const isMobile = useMobile();
  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      py={1}
      width={isMobile ? "100%" : "max-content"}
    >
      <DarkGreyTextStyled
        fontSize={isMobile ? 14 : 16}
        width={isMobile ? 295 : isOrgProfile ? 150 : 75}
        fontWeight={500}
      >
        {field}
      </DarkGreyTextStyled>
      <Typography
        ml={1}
        mr={0.5}
        fontSize={isMobile ? 14 : 16}
        width={isMobile ? "100%" : "max-content"}
        fontWeight={500}
      >
        {value ?? ""}
      </Typography>
    </Stack>
  );
};
