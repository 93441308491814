import { api } from "../api";
export const addTagTypes = ["booking"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createWebhook: build.mutation<
        CreateWebhookApiResponse,
        CreateWebhookApiArg
      >({
        query: () => ({ url: `/calendly`, method: "POST" }),
        invalidatesTags: ["booking"],
      }),
      createBookingFromWebhook: build.mutation<
        CreateBookingFromWebhookApiResponse,
        CreateBookingFromWebhookApiArg
      >({
        query: () => ({ url: `/calendly/webhook`, method: "POST" }),
        invalidatesTags: ["booking"],
      }),
      getCalendlyUserByOrgId: build.query<
        GetCalendlyUserByOrgIdApiResponse,
        GetCalendlyUserByOrgIdApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/calendly/consultant-info`,
        }),
        providesTags: ["booking"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type CreateWebhookApiResponse = /** status 204 An empty response */ void;
export type CreateWebhookApiArg = void;
export type CreateBookingFromWebhookApiResponse =
  /** status 204 An empty response */ void;
export type CreateBookingFromWebhookApiArg = void;
export type GetCalendlyUserByOrgIdApiResponse =
  /** status 200 Show single CalendlyUser */ {
    id: number;
    personal_link: string;
  };
export type GetCalendlyUserByOrgIdApiArg = {
  /** organization id */
  orgId: number;
};
export const {
  useCreateWebhookMutation,
  useCreateBookingFromWebhookMutation,
  useGetCalendlyUserByOrgIdQuery,
  useLazyGetCalendlyUserByOrgIdQuery,
} = injectedRtkApi;
