import { Typography, Stack, IconButton } from "@mui/material";
import { StyledFormBox } from "./SupportForms.styles";
import { Fragment, useCallback, useState } from "react";
import { ClientState } from "../../state/client/state";
import { SupportForm } from "./SupportForm";
import { CloseIcon } from "../icons";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
type Screen = ClientState["support"]["screen"];

interface ScreenSelection {
  title: string;
  to: Screen;
  skip?: boolean;
}
interface Props {
  orgId: number;
  onClose: () => void;
  removeSession?: boolean;
}

export const InternalAddForm = ({ orgId, onClose, removeSession }: Props) => {
  const [defaultPage, setDefaultPage] = useState<Screen | undefined>();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const SelectionScreens: ScreenSelection[] = [
    {
      title: "Session",
      to: "CreateSession",
      skip: removeSession,
    },
    {
      title: "On session task",
      to: "SessionSelection",
    },
    {
      title: "Custom work task",
      to: "AddCustomWork",
    },
    {
      title: "Quick question",
      to: "AddQuickQuestion",
    },
    {
      title: "Internal task",
      to: "AddInternalTask",
      skip: !isInternal,
    },
    {
      title: "Apply template",
      to: "ApplyTemplateToOrg",
      skip: !isAdmin,
    },
  ];

  const changeScreenSelection = useCallback(
    (screen: Screen) => {
      setDefaultPage(screen);
    },
    [orgId, defaultPage],
  );

  return (
    <>
      {!defaultPage ? (
        <Stack direction={"column"} gap={2} width={"100%"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h1">Add new</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack height={"max-content"} overflow={"auto"} gap={1}>
            {SelectionScreens.map(({ title, to, skip }) => {
              return (
                <Fragment key={to}>
                  {skip ? null : (
                    <StyledFormBox
                      color={"inherit"}
                      onClick={() => changeScreenSelection(to)}
                    >
                      <Typography variant="h3" lineHeight={"1.2rem"}>
                        {title}
                      </Typography>
                    </StyledFormBox>
                  )}
                </Fragment>
              );
            })}
          </Stack>
        </Stack>
      ) : (
        <SupportForm
          open={Boolean(defaultPage)}
          onClose={onClose}
          defaultScreen={defaultPage}
          orgId={orgId}
          continueMovingFlow
        />
      )}
    </>
  );
};
