import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100%",
  display: "flex",
  height: "100vh",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    backgroundColor: "#FFF",
  },

  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  display: "flex",

  "& > div > img": {
    height: "100%",
    maxWidth: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    flexDirection: "column",
    justifySelf: "center",
    padding: "12px",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginBottom: "24px",
    "& > img": {
      width: "42px",
      height: "48px",
    },
  },
  [theme.breakpoints.up("md")]: {
    height: "100vh",
    width: "50%",
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 8,
  maxWidth: "calc(100vw - 8px)",
  [theme.breakpoints.up("md")]: {
    marginBottom: 100,
    height: "auto",
    minWidth: 520,
    width: "34%",
  },
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "#FFF",
    borderRadius: "20px 20px 0 0",
    maxWidth: "100%",
    padding: "12px",
    height: "100%",
  },
}));
export const OutletPanel = styled(`div`)(() => ({
  width: 360,
}));
