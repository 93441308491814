import { useMemo } from "react";
import {
  authActions,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { Button } from "@mui/material";

function _ExitImpersonationButton() {
  const dispatch = useDispatch();
  const _returnUrl = useSelector(authSelectors.impersonationReturnUrl);

  const returnUrl = useMemo(() => {
    return _returnUrl;
  }, []);

  return (
    <Button
      variant="contained"
      color="warning"
      sx={{ ml: 1 }}
      onClick={() => {
        dispatch(authActions.exitImpersonation());
        // eslint-disable-next-line no-self-assign
        setTimeout(
          () => (window.location.href = returnUrl || window.location.href),
          300,
        );
      }}
    >
      Exit impersonation
    </Button>
  );
}

function ExitImpersonationButton() {
  const inImpersonation = useSelector(authSelectors.inImpersonation);

  if (!inImpersonation) {
    return null;
  }
  return <_ExitImpersonationButton />;
}

export default ExitImpersonationButton;
