import { Box, Button, Typography, Link } from "@mui/material";
import { memo, useState } from "react";
import {
  AddIcon,
  EditIcon,
  ConfirmDeleteDialog,
  Modal,
} from "../../../components";
import { MicrosoftSharePointUpload } from "./MicrosoftSharePointUpload";
import {
  useDeleteSharePointFileMutation,
  useGetSharePointFilesQuery,
} from "../../../state/rtk-query/state/microsoft";
export const MicrosoftSharePointPage = memo(function () {
  const [openModal, setOpenModal] = useState(false);
  const modalClick = () => {
    setOpenModal(!openModal);
  };
  const [deleteSharePointFile] = useDeleteSharePointFileMutation();
  const deleteFile = (fileName: string) => {
    deleteSharePointFile({ id: fileName });
  };

  const { currentData: microsoftSharePointData } = useGetSharePointFilesQuery();
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Typography variant="h2">SharePoint Directory</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Button variant="outlined" color="primary" onClick={modalClick}>
          <AddIcon /> Upload File to SharePoint
        </Button>
      </Box>
      <Modal open={openModal} onClose={modalClick}>
        <MicrosoftSharePointUpload closeModal={modalClick} />
      </Modal>
      <Box>
        {microsoftSharePointData?.value &&
          microsoftSharePointData?.value.map(
            (c: (typeof microsoftSharePointData.value)[0], idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "450px",
                  }}
                >
                  <div style={{ paddingRight: 10 }}>{c.name}</div>
                  <div>
                    <Link href={c?.webUrl ?? ""} target="_blank">
                      <EditIcon />
                    </Link>
                    <ConfirmDeleteDialog
                      handleConfirm={() => deleteFile(c?.name ?? "")}
                    />
                  </div>
                </div>
              );
            },
          )}
      </Box>
    </Box>
  );
});
