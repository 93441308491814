import { Stack, Typography, Button } from "@mui/material";
import { BannerStyled } from "../styled";

export interface RowsSelectedBannerProps {
  selectedRowsCt: number;
  onClear: () => void;
  onSelectAll: () => void;
  children?: React.ReactNode;
}

export default function RowsSelectedBanner({
  selectedRowsCt,
  onClear,
  onSelectAll,
  children,
}: RowsSelectedBannerProps) {
  if (!selectedRowsCt) return null;
  return (
    <BannerStyled color="tertiary" type="accent" mb={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={0.5}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Typography color="inherit" variant="h3" mr={2}>
            {selectedRowsCt} Selected
          </Typography>
          <Button color="info" sx={{ p: 0 }} onClick={onClear}>
            Clear
          </Button>
          <Button color="info" sx={{ p: 0 }} onClick={onSelectAll}>
            Select all
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="min-content"
          gap={1}
        >
          {children}
        </Stack>
      </Stack>
    </BannerStyled>
  );
}
