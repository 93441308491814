import { Modal } from "./Modal";
import { useSelector } from "react-redux";
import { clientSelectors } from "../../state";
import { useTablet, useMobile } from "../../themes";
import { SupportForm, SupportFormProps } from "../forms";

interface Props extends SupportFormProps {
  open: boolean;
  onClose: () => void;
}
export const GetSupportModal = ({ open, onClose, ...rest }: Props) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  const { screen } = useSelector(clientSelectors.currentSupportStep) ?? {};
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width:
          (screen === "CreateSession" || screen === "Done") && !isSmallerThanPc
            ? "60%"
            : isSmallerThanPc
            ? isMobile
              ? "100%"
              : "90%"
            : "40%",
        height: isMobile ? "100%" : "max-content",
        overflow: "auto",
      }}
      props={{
        sx: {
          overflow: "auto",
        },
      }}
    >
      <SupportForm open={open} onClose={onClose} {...rest} />
    </Modal>
  );
};
