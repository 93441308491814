import {
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Popper,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { CloseIcon, SearchIcon } from "../../components";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  GlobalSearchApiResponse,
  useLazyGlobalSearchQuery,
} from "../../state/rtk-query/state/global_search";
import { Navigation, debounce, useLocation } from "../../lib";
import { MAX_SEARCH_RESULTS_ON_PAGE } from "../../pages/main/shared/SearchPage";
import { useMobile } from "../../themes";
import { MainPages } from "../../pages/main";
import { useOrgId } from "../../components/hooks";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import SearchResultBox from "./SearchResultBox";

interface SearchFieldProps {
  open: boolean;
  openSearch?: () => void;
  setSearchResults: Dispatch<SetStateAction<GlobalSearchApiResponse["rows"]>>;
  setSearchCount: Dispatch<SetStateAction<GlobalSearchApiResponse["count"]>>;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
}

const GlobalSearchField = ({
  open,
  openSearch,
  setSearchResults,
  setSearchCount,
  setIsSearching,
  sx,
}: SearchFieldProps) => {
  const [globalSearch] = useLazyGlobalSearchQuery();
  const { pathname, query } = useLocation();
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const pages = MainPages(isInternal);
  const orgId = useOrgId();

  const clearSearch = useCallback(() => {
    setSearchResults([]);
    setSearchCount(0);
    const restOfSearch = query;
    delete restOfSearch?.q;
    delete restOfSearch?.fuzzySearch;
    Navigation.go(pathname, {
      query: restOfSearch,
    });
  }, [pathname, query]);

  const fetchSearch = useCallback(
    debounce(async (searchTerm) => {
      setIsSearching(true);

      if (orgId) {
        const { count, rows } = (await globalSearch({
          fuzzySearch: false,
          q: searchTerm,
          orgId,
          range: [0, MAX_SEARCH_RESULTS_ON_PAGE],
          maxWordsInSearch: 7,
        }).unwrap()) ?? { count: 0, rows: [] };
        setSearchResults(rows);
        setSearchCount(count);
      }
      setIsSearching(false);
    }, 500),
    [orgId],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = e.target.value;

      setIsSearching(true);
      setSearchResults([]);
      setSearchCount(0);
      fetchSearch(searchTerm.trim());

      if (searchTerm.trim() === "") {
        const restOfSearch = query;
        delete restOfSearch?.q;
        delete restOfSearch?.fuzzySearch;
        Navigation.replace(pathname, { query: restOfSearch });
      } else if (query.q !== searchTerm) {
        Navigation.replace(pathname, { query: { ...query, q: searchTerm } });
      }
    },
    [orgId, query],
  );

  useEffect(() => {
    if (open && query.q) {
      setIsSearching(true);
      fetchSearch(query.q);
    }
  }, [open]);
  const goToSearchPage = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        Navigation.go(`/search?q=${query?.q}`);
      }
    },
    [query.q, pathname],
  );
  return (
    <TextField
      name="global_search"
      sx={{
        width: open ? "100%" : 300,
        height: 36,
        borderRadius: open ? "4px 4px 0 0" : 4,
        transition: " all 0.15s",
        position: "relative",
        "& :focus": {
          backgroundColor: "#FFF",
        },
      }}
      placeholder={"Search"}
      onClick={openSearch}
      onChange={handleSearch}
      onKeyUp={goToSearchPage}
      value={query?.q ?? ""}
      disabled={pathname === pages.search.path}
      inputRef={(input) => input && isMobile && input.focus()}
      InputProps={{
        sx,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: isMobile ? "1em" : "1.2em" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {open ? (
              <IconButton onClick={clearSearch}>
                <CloseIcon style={{ fontSize: isMobile ? "1em" : "1.1em" }} />
              </IconButton>
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default () => {
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<
    GlobalSearchApiResponse["rows"]
  >([]);
  const [searchCount, setSearchCount] =
    useState<GlobalSearchApiResponse["count"]>(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const { query, pathname } = useLocation();
  const { palette } = useTheme();
  const isInternal = useSelector(authSelectors.isInternal);
  const pages = MainPages(isInternal);
  const openSearch = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  const onClickAway = useCallback((e: MouseEvent | TouchEvent) => {
    if (
      ref.current !== e.target &&
      !ref?.current?.contains(e.target as Node) // if a user double clicks search field don't close
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (pathname === pages.search.path) setOpen(false);
  }, [pathname]);

  {
    /* The ref is on the stack instead of the input so popper doesn't shift when the search expands  */
  }
  return (
    <Stack direction={"row"} width={460} justifyContent={"flex-end"} ref={ref}>
      <GlobalSearchField
        open={open}
        openSearch={openSearch}
        setSearchResults={setSearchResults}
        setSearchCount={setSearchCount}
        setIsSearching={setIsSearching}
      />

      {open && ref.current && query.q ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            open={open}
            placement="bottom-start"
            anchorEl={ref.current}
            sx={{
              bgcolor: "#FFF",
              borderRadius: "0 0 16px 16px",
              zIndex: 999,
              maxHeight: 575,
              width: 460,
              marginTop: `4px !important`,
              boxShadow: "0 1px 4px 1px rgba(99,100,105,0.2)",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              height={"100%"}
            >
              <Stack
                divider={<Divider flexItem />}
                direction={"column"}
                overflow={"auto"}
                maxHeight={526}
                sx={{
                  "& > :last-child": {
                    borderBottom: `1px solid ${palette.grey[500]}`,
                  },
                }}
              >
                {searchResults?.length > 0 && searchCount ? (
                  searchResults.slice(0, 10).map((sr) => (
                    <SearchResultBox
                      sr={sr}
                      // There can be multiple comments that match under the same task, there can also be multiple with the same sum
                      key={Number(sr.task_id) + `${Number(sr.comment_id)}`}
                    />
                  ))
                ) : (
                  <Stack p={2} gap={1}>
                    <Typography fontSize={16} fontWeight={600}>
                      {isSearching ? "Searching" : "No results"} for "{query?.q}
                      "
                    </Typography>
                    {isSearching && <LinearProgress />}
                    {isSearching ? null : (
                      <Typography variant="subtitle2">
                        Please use different keywords and try again
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
              {searchResults.length > 0 ? (
                <Link
                  component={RouterLink}
                  p={2}
                  to={`/search?q=${query?.q}`}
                  position={"sticky"}
                  bottom={0}
                  zIndex={100}
                >
                  View all results
                </Link>
              ) : null}
            </Stack>
          </Popper>
        </ClickAwayListener>
      ) : null}
    </Stack>
  );
};
