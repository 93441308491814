import { api } from "../api";
export const addTagTypes = [
  "media",
  "tasks",
  "task_media",
  "tasks_chat",
  "tasks_media",
  "notifications",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      saveMedia: build.mutation<SaveMediaApiResponse, SaveMediaApiArg>({
        query: (queryArg) => ({
          url: `/media`,
          method: "POST",
          body: queryArg.body.file,
          params: { name: queryArg.name, label: queryArg.label },
          formData: true,
        }),
        invalidatesTags: ["media"],
      }),
      saveBulkMedia: build.mutation<
        SaveBulkMediaApiResponse,
        SaveBulkMediaApiArg
      >({
        query: (queryArg) => ({
          url: `/media/bulk-upload`,
          method: "POST",
          body: queryArg.body.file,
          formData: true,
        }),
        invalidatesTags: ["media"],
      }),
      deleteMedia: build.mutation<DeleteMediaApiResponse, DeleteMediaApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/media/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["media", "tasks", "task_media", "tasks_chat"],
      }),
      getSignedUrlByMediaId: build.query<
        GetSignedUrlByMediaIdApiResponse,
        GetSignedUrlByMediaIdApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/media/${queryArg.id}/signed`,
          params: {
            download: queryArg.download,
            compact: queryArg.compact,
            pdf_img: queryArg.pdfImg,
          },
        }),
        providesTags: ["media"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type SaveMediaApiResponse = /** status 200 Show single file */ {
  id?: number;
  path?: string;
  filename?: string | null;
  internal_name?: string;
  label?: string;
  mime_type?: string | null;
  size?: number | null;
  status_id?: number | null;
  status?: string | null;
  uploaded_by?: number;
  created_at?: string | null;
  [key: string]: any;
};
export type SaveMediaApiArg = {
  /** Name */
  name: string;
  /** Label */
  label: string;
  /** file */
  body: {
    name?: string | null;
    label?: string | null;
    file: FormData;
  };
};
export type SaveBulkMediaApiResponse =
  /** status 200 catch all response response */ {
    count: number;
    rows: {
      id?: number;
      path?: string;
      filename?: string | null;
      internal_name?: string;
      label?: string;
      mime_type?: string | null;
      size?: number | null;
      status_id?: number | null;
      status?: string | null;
      uploaded_by?: number;
      created_at?: string | null;
      [key: string]: any;
    }[];
  };
export type SaveBulkMediaApiArg = {
  /** file */
  body: {
    name?: string | null;
    label?: string | null;
    file: FormData;
  };
};
export type DeleteMediaApiResponse = /** status 204 An empty response */ void;
export type DeleteMediaApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetSignedUrlByMediaIdApiResponse = /** status 200 signed url */ {
  mediaId: number;
  signedUrl: string;
  originalExt: string;
};
export type GetSignedUrlByMediaIdApiArg = {
  /** record id */
  id: number;
  /** Should Download */
  download: boolean;
  /** Boolean if signed url should be mini version for comments */
  compact?: boolean;
  /** get image representation of a pdf */
  pdfImg?: boolean;
  /** organization id */
  orgId: number;
};
export type DeleteTaskMediaApiResponse =
  /** status 204 An empty response */ void;
export type DeleteTaskMediaApiArg = {
  /** record id */
  id: number;
  /** chat id */
  chatId: number;
  /** media id */
  mediaId: number;
  /** organization id */
  orgId: number;
};
export type GetOrgTaskMediaApiResponse = /** status 200 TaskMedia */ {
  count: number;
  rows: {
    id: number;
    media_id: number;
    session_id?: number | null;
    task_id: number;
    is_custom_work_manual?: boolean;
    task?: {
      id?: number;
      status_id?: number;
      topic_id?: number;
      org_id?: number;
      title?: string;
      description?: string | null;
      priority?: "normal" | "high" | "critical";
      internal_priority?: "low" | "medium" | "high";
      assigned_to?: number;
      reported_by?: number;
      type?: "task" | "quick_question" | "internal_task" | "custom_work";
      requested_by_date?: string | null;
      instant_meeting_url?: string | null;
      instant_meeting_occurred?: boolean | null;
      client_assignee?: number | null;
      billing_status?: ("no_charge" | "making_standard" | null) | null;
      description_search?: string | null;
      action_pending?: ("admire" | "client" | null) | null;
      code?: string | null;
      created_at?: string;
      updated_at?: string;
      deleted_at?: string | null;
    };
    media?: {
      id?: number;
      path?: string;
      filename?: string;
      internal_name?: string;
      label?: string;
      mime_type?: string | null;
      size?: number | null;
      status_id?: number;
      uploaded_by?: number;
      created_at?: string;
      updated_at?: string;
      deleted_at?: string | null;
    };
  }[];
};
export type GetOrgTaskMediaApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** task type */
  type: "all" | "custom_work";
  /** organization id */
  orgId: number;
};
export type GetTaskMediaApiResponse = /** status 200 Show single file */ {
  id?: number;
  path?: string;
  filename?: string | null;
  internal_name?: string;
  label?: string;
  mime_type?: string | null;
  size?: number | null;
  status_id?: number | null;
  status?: string | null;
  uploaded_by?: number;
  created_at?: string | null;
  [key: string]: any;
};
export type GetTaskMediaApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type CreateTaskMediaApiResponse =
  /** status 204 An empty response */ void;
export type CreateTaskMediaApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** task media */
  body: {
    media_id: number;
    chat_id?: number | null;
    is_internal?: boolean;
    is_custom_work_manual?: boolean | null;
  };
};
export const {
  useSaveMediaMutation,
  useSaveBulkMediaMutation,
  useDeleteMediaMutation,
  useGetSignedUrlByMediaIdQuery,
  useLazyGetSignedUrlByMediaIdQuery,
} = injectedRtkApi;
