const timezones = [
  { name: "Pacific/Midway", offset: -11, abbreviation: "SST" },
  { name: "America/Adak", offset: -9, abbreviation: "HST" },
  { name: "America/Anchorage", offset: -8, abbreviation: "AKDT" },
  { name: "America/Los_Angeles", offset: -7, abbreviation: "PDT" },
  { name: "America/Denver", offset: -6, abbreviation: "MDT" },
  { name: "America/Chicago", offset: -5, abbreviation: "CDT" },
  { name: "America/New_York", offset: -4, abbreviation: "EST" },
  { name: "Atlantic/Bermuda", offset: -3, abbreviation: "ADT" },
  { name: "America/Sao_Paulo", offset: -3, abbreviation: "BRT" },
  { name: "Atlantic/Azores", offset: 0, abbreviation: "AZOT" },
  { name: "Europe/London", offset: 1, abbreviation: "BST" },
  { name: "Europe/Berlin", offset: 2, abbreviation: "CEST" },
  { name: "Asia/Jerusalem", offset: 3, abbreviation: "IST" },
  { name: "Asia/Dubai", offset: 4, abbreviation: "GST" },
  { name: "Asia/Karachi", offset: 5, abbreviation: "PKT" },
  { name: "Asia/Dhaka", offset: 6, abbreviation: "BST" },
  { name: "Asia/Jakarta", offset: 7, abbreviation: "WIB" },
  { name: "Asia/Shanghai", offset: 8, abbreviation: "CST" },
  { name: "Asia/Tokyo", offset: 9, abbreviation: "JST" },
  { name: "Australia/Sydney", offset: 10, abbreviation: "AEST" },
  { name: "Pacific/Auckland", offset: 12, abbreviation: "NZST" },
].sort((a, b) => a.name.localeCompare(b.name));
const timezoneNames = timezones.map((tz) => tz.name);
export { timezones, timezoneNames };
