import { useMemoObj } from "./useMemoObj";

export const useDatagridProps = () => {
  const slotProps = useMemoObj({
    panel: {
      sx: {
        "& .MuiIconButton-root, .MuiSvgIcon-root": {
          fontSize: "20px !important",
        },
      },
    },

    columnMenu: {
      sx: {
        "& .MuiIconButton-root, .MuiSvgIcon-root": {
          fontSize: "1em !important",
        },
      },
    } as any,
  });
  const autosizeOptions = useMemoObj({
    includeHeaders: true,
    outliersFactor: 100,
    includeOutliers: false,
  });
  return {
    slotProps,
    autosizeOptions,
  };
};
