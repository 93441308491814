import { TasksTableInternal } from "../../../components";
import { Box } from "@mui/material";

export const TasksPage = () => {
  return (
    <Box height={"100%"} overflow={"hidden"}>
      <TasksTableInternal height="100%" />;
    </Box>
  );
};
