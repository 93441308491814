import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ErrorTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));

export const SuccessTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.dark,
}));

export const GreyTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

export const DarkGreyTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

export const BlueTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue[300],
}));

export const HoverTypographyStyled = styled(Typography)(({ theme }) => ({
  padding: "5px 12px",
  marginLeft: "-12px",
  ":hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.grey[200],
    borderRadius: "4px",
  },
}));

export const EllipsisTypographyStyled = styled(Typography)<{
  fontSize?: number | string;
}>(({ theme: _theme, fontSize }) => ({
  fontWeight: 500,
  fontSize: fontSize ?? 14,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));
