import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const DrawerHeader = styled(Stack)(({ theme }) => ({
  "&.MuiStack-root": {
    backgroundColor: theme.palette.grey[50],
    padding: "24px 32px",
    paddingBottom: 24,
  },
}));

export const DrawerSubSection = styled(Stack)(({ theme: _theme }) => ({
  "&.MuiStack-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "8px 32px",
  },
}));

export const DrawerSubSectionHeaderStyled = styled(Stack)(({ theme }) => ({
  "&.MuiStack-root": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h4": {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.grey[700],
    },
  },
}));
