import { Stack, Toolbar, Typography } from "@mui/material";
import { PaperBar } from "./AppBar.styles";
import useDrawerWidth from "../../../components/hooks/useDrawerWidth";
import { MainPages } from "../../../pages/main";
import { useLocation } from "../../../lib";
import GlobalSearchBarInput from "../GlobalSearchInput";
import { GetSupportButton } from "../../../components";
import { useSelector } from "react-redux";
import { uiSelectors, authSelectors } from "../../../state";
import ExitImpersonationButton from "./ExitImpersonationButton";

export default function TopBar() {
  const currentDrawerWidth = useDrawerWidth();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { pathname } = useLocation();
  const IGNORE_TITLE_PAGES = ["Search"];
  const dynamicTitle = useSelector(uiSelectors.dynamicTitle);
  const { path, value } = dynamicTitle ?? {};

  const pages = MainPages(isInternal, isAdmin);
  const pageTitle =
    path && path === pathname
      ? value || ""
      : Object.values(pages).find(
          (v) =>
            v?.path === pathname && !IGNORE_TITLE_PAGES?.includes(v?.title),
        )?.title ?? "";
  return (
    <PaperBar
      elevation={0}
      position="fixed"
      sx={{
        width: `calc(100% - ${currentDrawerWidth}px)`,
        ml: `${currentDrawerWidth}px`,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h1"
          noWrap
          fontWeight={600}
          fontSize={".72rem !important"}
        >
          {pageTitle}
        </Typography>
        <Stack direction={"row"}>
          <GlobalSearchBarInput />
          {isInternal ? null : <GetSupportButton />}
          <ExitImpersonationButton />
        </Stack>
      </Toolbar>
    </PaperBar>
  );
}
