import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface BannerStyledProps extends Omit<BoxProps, "color"> {
  color:
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning"
    | "info"
    | "tertiary"
    | "accent";
  size?: "small" | "medium" | "large";
  type?: "accent" | "solid";
}

export const BannerStyled = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "size" && prop !== "type",
})<BannerStyledProps>(
  ({ theme, color = "primary", size = "medium", type = "solid" }) => {
    let accentColor: string | undefined = "",
      solidColor: string | undefined = "",
      textColor: string | undefined = "";

    switch (color) {
      case "secondary":
        accentColor = theme.palette.secondary[200];
        solidColor = theme.palette.secondary[800];
        textColor = theme.palette.secondary[900];
        break;
      case "error":
        accentColor = theme.palette.error[200];
        solidColor = theme.palette.error[700];
        textColor = theme.palette.error[900];
        break;
      case "success":
        accentColor = theme.palette.success[200];
        solidColor = theme.palette.success[800];
        textColor = theme.palette.success[900];
        break;
      case "warning":
        accentColor = theme.palette.warning[200];
        solidColor = theme.palette.warning[500];
        textColor = theme.palette.black[500];
        break;
      case "info":
        accentColor = theme.palette.info[200];
        solidColor = theme.palette.info[600];
        textColor = theme.palette.info[900];
        break;
      case "accent":
        accentColor = theme.palette.accent[300];
        solidColor = theme.palette.accent[600];
        textColor = theme.palette.black[500];
        break;
      case "tertiary":
        accentColor = theme.palette.info[100] ?? theme.palette.info.light;
        solidColor = theme.palette.info[600] ?? theme.palette.info.main;
        textColor = theme.palette.info[900] ?? theme.palette.info.dark;
        break;
      case "primary":
      default:
        accentColor = theme.palette.primary[100];
        solidColor = theme.palette.primary[100];
        textColor = theme.palette.primary[800];
        break;
    }

    const styles = {
      display: "flex",
      alignItems: "center",
      padding: `${
        size === "small" ? "6px" : size === "medium" ? "9px" : "12px"
      } 12px`,
      backgroundColor: type === "solid" ? solidColor : accentColor,
      color: type === "solid" ? theme.palette.background.default : textColor,
      fontWeight: 400,
      borderRadius: "6px",
    };

    return styles;
  },
);
