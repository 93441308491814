import React from "react";
// Local
import { VITE_VERSION } from "../../config";

const aboutVersionText = `App Name v${VITE_VERSION}`;

const style = {
  cursor: "pointer",
};

function onClickAboutVersion(e: any) {
  e.preventDefault();
  window.alert(aboutVersionText);
}

export const VersionText = React.memo(function _VersionText() {
  return (
    <span onClick={onClickAboutVersion} title={aboutVersionText} style={style}>
      App Name
    </span>
  );
});
