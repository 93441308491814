import {
  PreviewFileExtensions,
  formatDateLong,
  formatDateToHuman,
  formatTimeToHuman,
} from "../../../../lib";
import {
  Stack,
  Typography,
  useTheme,
  Box,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Skeleton,
} from "@mui/material";
import { UserAvatar } from "../../../icons/Avatar";
import {
  GetTaskChatsApiResponse,
  GetTaskWatchersApiResponse,
} from "../../../../state/rtk-query/state/tasks";
import { ArrayElement } from "../../../../global";
import { useCallback, useEffect, useState } from "react";
import {
  CloseIcon,
  FileIcon,
  MediaDownload,
  MoreVertIcon,
  Tooltip,
} from "../../../icons";
import { useSelector } from "react-redux";
import { authSelectors, actions } from "../../../../state";
import { ChatEditor } from "./ChatEditor";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { HtmlWrapper } from "../../../text";
import { GetSignedUrlByMediaIdApiResponse } from "../../../../state/rtk-query/state/media";
import { ConfirmDeleteDialog } from "../../../tables";
import { EllipsisTypographyStyled } from "../../../styled";
import { useOrgId } from "../../../hooks";
const {
  useDeleteTaskChatMutation,
  useLazyGetSignedUrlByMediaIdQuery,
  useDeleteTaskMediaMutation,
} = actions;
type ChatMsg = ArrayElement<GetTaskChatsApiResponse["rows"]> | undefined;

type TaskWatcherUser = GetTaskWatchersApiResponse["rows"][number];

interface Props {
  chatDetails: ChatMsg;
  previousChat?: ChatMsg;
  isFirst?: boolean;
  isComplete?: boolean;
  orgId?: number;
  assigned_to_user?: TaskWatcherUser | undefined;
  client_assignee_user?: Partial<TaskWatcherUser> | undefined | null;
}
export const Chat = ({
  orgId: _orgId,
  chatDetails,
  previousChat,
  isFirst,
  isComplete,
  assigned_to_user,
  client_assignee_user,
}: Props) => {
  const { palette } = useTheme();
  const { user } = chatDetails ?? {};
  const userId = useSelector(authSelectors.userId);
  const [msg, setMsg] = useState(chatDetails?.message);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteIcons, setShowDeleteIcons] = useState(0);
  const [attachments, setAttachments] = useState<
    (ArrayElement<NonNullable<ChatMsg>["task_media"]> & {
      preview?: string;
    })[]
  >([]);

  const orgId = useOrgId();
  const onHover = useCallback(
    (id?: number) => setShowDeleteIcons(id ?? 0),
    [showDeleteIcons],
  );
  const [deleteChat] = useDeleteTaskChatMutation();
  const [deleteChatMedia] = useDeleteTaskMediaMutation();
  const [getMediaSignedUrl] = useLazyGetSignedUrlByMediaIdQuery();
  const [lightBoxSlides, setLightBoxSlides] = useState<
    GetSignedUrlByMediaIdApiResponse[] | undefined
  >();
  const [lightBoxIndex, setLightBoxIndex] = useState(0);

  const isPreviewable = (filename: string) => {
    if (chatDetails?.has_media) {
      return PreviewFileExtensions.filter((ext) => {
        return filename?.endsWith(ext);
      }).length;
    }
  };

  useEffect(() => {
    // needed to update message if user edits
    if (chatDetails?.message !== msg) {
      setMsg(() => chatDetails?.message);
    }
    // link tag that has `@` in context
    if (msg?.match(/<a.*@.*<\/a>/) && msg === chatDetails?.message) {
      const newMsg = chatDetails?.message.replaceAll(
        /<a/g,
        '<a class="mention"',
      );
      setMsg(newMsg);
    }
    (async () => {
      chatDetails?.task_media?.map(async (tcm) => {
        const filename = tcm.media?.filename ?? "";
        const canPreview = isPreviewable(filename);
        if (tcm) {
          if (
            attachments.find(
              (a) => a?.media?.id === tcm?.media?.id && a.preview,
            )
          ) {
            return;
          }
          if (canPreview && tcm?.media?.id) {
            const getPreview = await getMediaSignedUrl({
              id: tcm?.media.id,
              download: false,
              compact: true,
              orgId,
              pdfImg: true,
            }).unwrap();

            setAttachments((old) => [
              ...old,
              {
                ...tcm,
                preview: getPreview?.signedUrl,
              },
            ]);
          } else {
            setAttachments((old) => [...old, tcm]);
          }
        }
      });
    })();
  }, [chatDetails?.message, chatDetails?.task_media?.length]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateChatClick = useCallback(() => {
    setEditMode(true);
    handleClose();
  }, []);

  const deleteChatClick = useCallback(() => {
    if (chatDetails?.id) {
      deleteChat({
        id: chatDetails?.id,
        orgId,
      });
    }
    handleClose();
  }, [chatDetails?.task_id]);

  const mediaClick = useCallback(
    async (media: { filename?: string; id?: number } | undefined) => {
      if (media?.filename?.endsWith(".pdf") && media?.id) {
        const getPdf = await getMediaSignedUrl({
          id: media?.id,
          download: false,
          orgId,
        }).unwrap();
        window.open(getPdf.signedUrl);
      } else {
        const inLightBox =
          chatDetails?.task_media?.filter((mf) => {
            const filename = mf.media?.filename;
            const extension = filename?.split(".").pop();

            if (extension === "pdf") return;

            return PreviewFileExtensions.includes(
              `.${extension}` as ArrayElement<typeof PreviewFileExtensions>,
            );
          }) ?? [];

        const getSignedUrlData = await Promise.all(
          inLightBox.map((s) => {
            if (s?.media?.id) {
              return getMediaSignedUrl({
                id: s?.media.id,
                download: false,
                orgId,
              }).unwrap();
            }
          }),
        );
        const data = getSignedUrlData.filter(
          //https://stackoverflow.com/a/54317362  typescript doesn't behave with Array.filter
          (s): s is GetSignedUrlByMediaIdApiResponse => typeof s !== "boolean",
        );
        if (data.length) {
          const index =
            inLightBox.map((img) => img?.media?.id).indexOf(media?.id) ?? 0;
          setLightBoxIndex(index);
          setLightBoxSlides(() => data);
        }
      }
    },
    [chatDetails?.id, chatDetails?.task_media],
  );
  const downloadMedia = useCallback(
    async (mediaId?: number) => {
      if (mediaId) {
        const data = await getMediaSignedUrl({
          id: mediaId,
          download: true,
          orgId,
        }).unwrap();
        const link = document.createElement("a");
        link.href = data.signedUrl;
        link.click();
      }
    },
    [chatDetails?.id],
  );

  const deleteChatMediaClick = useCallback(
    async (mediaId?: number) => {
      if (mediaId && chatDetails?.id && chatDetails?.task_id) {
        await deleteChatMedia({
          id: chatDetails?.task_id,
          chatId: chatDetails?.id,
          orgId,
          mediaId,
        });
        setAttachments(
          (old) => old.filter((o) => o.media?.id !== mediaId) ?? [],
        );
      }
    },
    [chatDetails?.id, chatDetails?.task_id],
  );
  // if we want to continue users chat implement if theres a design
  const _sameSender =
    !isFirst && previousChat?.user?.id === chatDetails?.user?.id;

  return (
    <>
      {chatDetails ? (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          key={chatDetails.id}
          bgcolor={chatDetails.is_internal ? palette.warning[100] : "inherit"}
          p={1}
          borderRadius={"3px"}
        >
          <UserAvatar
            sx={{ mr: 2, ml: "1px" }}
            first_name={user?.first_name || ""}
            last_name={user?.last_name || ""}
            id={user?.id}
            dimensions={40}
          />
          <Stack direction={"column"} width={"100%"}>
            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"}>
                <Box mb={1}>
                  <Typography fontSize={14} fontWeight={600}>
                    {user?.first_name} {user?.last_name}
                  </Typography>
                  {chatDetails?.created_at && (
                    <Typography
                      fontSize={12}
                      color={palette.grey[800]}
                      fontWeight={500}
                    >
                      {formatDateLong(chatDetails?.created_at, "MMMM dd")}{" "}
                      &middot;{" "}
                      {formatTimeToHuman(new Date(chatDetails?.created_at))}
                    </Typography>
                  )}
                </Box>
                {chatDetails.is_internal && (
                  <Box
                    component={"span"}
                    sx={{
                      bgcolor: palette.warning[200],
                      color: palette.warning[900],
                      borderRadius: 0,
                      height: 18,
                      ml: 1,
                    }}
                    fontSize={12}
                    fontWeight={500}
                    px={1}
                  >
                    Internal
                  </Box>
                )}
              </Stack>
              {chatDetails.user?.id === userId && !isComplete && (
                <Box>
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon style={{ fontSize: ".65em" }} />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={updateChatClick}
                      sx={{ fontWeight: 500 }}
                    >
                      Edit
                    </MenuItem>

                    <MenuItem
                      onClick={deleteChatClick}
                      sx={{ color: palette.error[500], fontWeight: 500 }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Stack>
            {!editMode ? (
              <HtmlWrapper message={msg ?? ""} sx={{ fontSize: ".9em" }} />
            ) : (
              <ChatEditor
                taskId={chatDetails.task_id}
                chat={chatDetails}
                orgId={_orgId ?? 0}
                closeUpdate={() => {
                  setEditMode(false);
                }}
                assigned_to_user={assigned_to_user}
                client_assignee_user={client_assignee_user}
                showFullEditor
              />
            )}
            {chatDetails?.updated_at &&
              chatDetails.updated_at !== chatDetails.created_at && (
                <Tooltip
                  title={`${formatDateToHuman(
                    new Date(chatDetails?.updated_at ?? ""),
                    true,
                  )} at ${formatTimeToHuman(chatDetails?.updated_at)}`}
                  props={{ placement: "bottom" }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={500}
                    color={palette.grey[800]}
                    width={"fit-content"}
                  >
                    (edited)
                  </Typography>
                </Tooltip>
              )}
            {chatDetails?.has_media && attachments?.length ? (
              <Stack width={"100%"} direction={"column"} gap={0.5}>
                {attachments?.map((tcm, idx) => {
                  const filename = tcm?.media?.filename ?? "";
                  const canPreview = isPreviewable(filename);
                  return (
                    <Stack
                      direction={"row"}
                      key={Number(tcm?.media?.id ?? 0) + idx}
                      alignItems={"center"}
                      onMouseOver={() => onHover(tcm.media?.id)}
                      onMouseOut={() => onHover()}
                    >
                      {canPreview && !tcm?.preview ? (
                        <Skeleton
                          variant="rectangular"
                          width={150}
                          height={150}
                        />
                      ) : tcm?.preview ? (
                        <img
                          src={`${tcm.preview}`}
                          alt={tcm.media?.filename}
                          height={150}
                          onClick={() => mediaClick(tcm?.media)}
                          style={{ cursor: "pointer", maxWidth: "90%" }}
                        />
                      ) : (
                        <Tooltip
                          title={
                            !canPreview
                              ? `Cannot preview ${tcm.media?.filename}`
                              : ""
                          }
                        >
                          <Box component={"span"}>
                            <Button
                              onClick={() => mediaClick(tcm?.media)}
                              sx={{
                                paddingLeft: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              disabled={!canPreview}
                            >
                              <FileIcon width={18} height={22} />
                              <EllipsisTypographyStyled
                                ml={1}
                                textAlign={"left"}
                                style={{ maxWidth: "250px" }}
                              >
                                {tcm.media?.filename}
                              </EllipsisTypographyStyled>
                            </Button>
                          </Box>
                        </Tooltip>
                      )}
                      {showDeleteIcons === tcm.media?.id ? (
                        <>
                          <IconButton
                            onClick={() => downloadMedia(tcm.media?.id)}
                            size="small"
                            sx={{ mb: 0.5, color: palette.grey[900] }}
                          >
                            <MediaDownload width={18} height={22} />
                          </IconButton>
                          {!isComplete && (
                            <ConfirmDeleteDialog
                              handleConfirm={() =>
                                deleteChatMediaClick(tcm.media?.id)
                              }
                              titleText={`Are you sure you want to delete ${tcm.media?.filename}`}
                              ButtonIcon={
                                <Box sx={{ color: palette.grey[900] }}>
                                  <CloseIcon
                                    width={18}
                                    height={22}
                                    color="inherit"
                                  />
                                </Box>
                              }
                            />
                          )}
                        </>
                      ) : null}
                    </Stack>
                  );
                })}
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      ) : null}{" "}
      <Lightbox
        open={Boolean(lightBoxSlides)}
        close={() => setLightBoxSlides(undefined)}
        index={lightBoxIndex}
        slides={lightBoxSlides?.map((s) => {
          return { src: s?.signedUrl };
        })}
      />
    </>
  );
};
