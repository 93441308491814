import { useLocation } from "../../lib";
import { useGetOrgByIdQuery } from "../../state/rtk-query/state/organization";
import { useOrgId } from "./useOrgId";

export function useCurOrg(internalFallbackOrgId?: number) {
  const location = useLocation();
  const orgParamId =
    Number(location.params?.id) &&
    location.pathname === `/organizations/${location.params?.id}`
      ? Number(location.params?.id)
      : undefined;
  const orgId = useOrgId(internalFallbackOrgId ?? orgParamId);
  const { currentData } = useGetOrgByIdQuery({ orgId }, { skip: orgId < 1 });

  return currentData;
}
