import { AppThunk } from "..";
import { authClient, uiActions } from "../states";
import { microsoft } from "./state";
const { actions } = microsoft;

export const microsoftActions = {
  ...actions,
  createOutlookEvent(values: any): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(
        `/microsoft/calendar-events`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("event saved"));
      } else {
        dispatch(uiActions.showError("failed to save event"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateOutlookEvent(id: string, values: any): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(
        `/microsoft/calendar-events/${id}`,
        values,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("event updated"));
      } else {
        dispatch(uiActions.showError("failed to update event"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
