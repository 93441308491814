import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Field, Form, Formik } from "formik";
import { Switch, TextField } from "formik-mui";
import {
  // useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import * as yup from "yup";
//local
// import { Navigation } from "../../lib/routing";
import // AppThunkDispatch,
// actions,
// useDispatch
"../../../state";
import { GridStyled, RootStyled } from "./UserPage.styles";
// const { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation } =
//   actions;

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const CreateUsersPage = () => {
  //const dispatch = useDispatch<AppThunkDispatch>();
  const params: any = useParams();
  const [initialValues, _setInitialValues] = useState<{
    first_name: string;
    last_name: string;
    phone: string | null;
    email: string;
    mfa_required: boolean;
    is_primary: boolean;
    roles: number[];
  }>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    mfa_required: true,
    is_primary: true,
    roles: [],
  });
  const { currentData: user } =
    // !isNaN(params?.id)
    // ? useGetUserQuery(
    //     { id: Number(params?.id) },
    //     { refetchOnMountOrArgChange: true },
    //   )
    //   :
    { currentData: undefined };

  // const [updateUser, { isFetching: upIsLoading, isSuccess: upIsSuccess }] =
  //   useUpdateUserMutation();
  // const [createUser, { isFetching: crIsLoading, isSuccess: crIsSuccess }] =
  //   useCreateUserMutation();

  // useEffect(() => {
  //   if (params?.id && user) {
  //     const roles = user.roles
  //       .map((role: { id?: number }) => role.id)
  //       .filter((id?: number): id is number => Boolean(id));

  //     setInitialValues({
  //       ...initialValues,
  //       ...user,
  //       roles,
  //       mfa_required: user?.mfa_required ?? true,
  //       is_primary: user?.is_primary ?? true,
  //     });
  //   }
  // }, [dispatch, params.id, user]);

  const availableRoles = [
    { id: 1, name: "super_admin" },
    { id: 2, name: "admin" },
    { id: 3, name: "agent" },
    { id: 4, name: "user" },
  ];

  const handleSubmit = async () =>
    // values: typeof initialValues,
    // { setSubmitting }: any,
    {
      //setSubmitting(upIsLoading ?? crIsLoading);
      if (params?.id && user) {
        //return await updateUser({ id: Number(params.id), body: values });
      } else {
        //return await createUser({ body: values });
      }
    };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleSelectChange = (event: any, setFieldValue: any) => {
    const {
      target: { value },
    } = event;
    setFieldValue("roles", value);
  };
  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched, values, setFieldValue }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      required
                      error={
                        touched.first_name && Boolean(errors.first_name)
                          ? true
                          : undefined
                      }
                      helperText={touched.first_name && errors.first_name}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="last_name"
                      label="Last Name"
                      required
                      error={
                        touched.last_name && Boolean(errors.last_name)
                          ? true
                          : undefined
                      }
                      helperText={touched.last_name && errors.last_name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="phone"
                      name="phone"
                      label="Cell Phone"
                      error={
                        touched.phone && Boolean(errors.phone)
                          ? true
                          : undefined
                      }
                      helperText={touched.phone && errors.phone}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="mfa_required"
                            checked={values?.mfa_required}
                            onChange={() =>
                              setFieldValue(
                                "mfa_required",
                                !values.mfa_required,
                              )
                            }
                            error={
                              touched.mfa_required &&
                              Boolean(errors.mfa_required)
                                ? true
                                : undefined
                            }
                          />
                        }
                        label="Mfa Required"
                      />
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="is_primary"
                            checked={values.is_primary}
                            onChange={() =>
                              setFieldValue("is_primary", !values.is_primary)
                            }
                            error={
                              touched.is_primary && Boolean(errors.is_primary)
                                ? true
                                : undefined
                            }
                          />
                        }
                        label="Is primary"
                      />
                    </FormGroup>
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      type="email"
                      id="email"
                      name="email"
                      label="Email address"
                      required
                      //disabled={Boolean(user?.email)}
                      error={
                        touched.email && Boolean(errors.email)
                          ? true
                          : undefined
                      }
                    />
                  </GridStyled>
                  <GridStyled item>
                    <FormControl fullWidth>
                      <InputLabel>Roles *</InputLabel>
                      <Select
                        // labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={values?.roles ?? []}
                        onChange={(e) => handleSelectChange(e, setFieldValue)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        required
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  availableRoles.find((r) => r.id === value)
                                    ?.name
                                }
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {availableRoles.map((role) => (
                          <MenuItem
                            key={`role-${role.id}`}
                            value={role?.id ?? ""}
                          >
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};

export default CreateUsersPage;
