import { Stack, Typography } from "@mui/material";
import Menu from "./Menu";
import {
  authSelectors,
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import MobileTopBar from "./MobileTopBar";
import { MobileDrawerHeaderStyled, MobileDrawerStyled } from "./Drawer.styles";
import logo from "../../../assets/img/logo.svg";
import { MainPages } from "../../../pages";
import { useLocation } from "../../../lib";
export const MobileDrawer = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };
  const userInfo = useSelector(authSelectors.userInfo);
  const { pathname } = useLocation();
  const TITLE_PAGES = ["Notifications", "Quicklinks"];
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const pages = MainPages(isInternal, isAdmin);
  const pageTitle =
    Object.values(pages).find(
      (v) => v.path === pathname && TITLE_PAGES.includes(v.title),
    )?.title ?? "";
  return (
    <>
      <MobileTopBar />
      <MobileDrawerStyled
        anchor="left"
        open={menuOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 4, //timer bar adds 3 to handle for task drawer, but menu should always be on top
        })}
      >
        <MobileDrawerHeaderStyled
          onClick={handleDrawerClose}
          sx={{ marginBottom: 1, px: 2 }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <img src={logo} alt="logo" />
            <Typography>
              {userInfo.first_name} {userInfo.last_name}
            </Typography>
          </Stack>
        </MobileDrawerHeaderStyled>
        <Menu />
      </MobileDrawerStyled>
      {pageTitle.trim() !== "" ? (
        <Typography sx={{ ml: 2, mt: 2 }} variant="h1" fontSize={16}>
          {pageTitle}
        </Typography>
      ) : null}
    </>
  );
};
