import { useEffect, useRef } from "react";
// Primary source for this file https://stackoverflow.com/a/72210710/12892971
const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

export function GoogleIdentityAuth({ width = 0 }) {
  const googleButton = useRef(null);
  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    loadScript(src)
      .then(() => {
        const g = window.google;
        g?.accounts?.id.renderButton(
          googleButton.current, //this is a ref hook to the div in the official example
          { theme: "outline", size: "large", width: width }, // customization attributes
        );
      })
      .catch((e) => console.error("GoogleIdentityAuth", { e }));
  }, [width]);
  return <div ref={googleButton}></div>;
}
