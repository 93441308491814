import { styled } from "@mui/material/styles";

export const BoxStyled = styled(`div`)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: "12px",
  padding: "24px 16px",
  width: "100%",
  boxShadow: "0px 1px 4px rgba(99, 100, 105, 0.2);",
  marginTop: "24px",
  [theme.breakpoints.down("sm")]: {
    padding: "16px 12px",
  },
}));
