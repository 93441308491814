import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledEditorContainer = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: "4px",
    margin: "18px 0",
    // selected Icons
    "& [aria-selected=true] , & .rdw-link-wrapper > .rdw-option-wrapper:has(+ .rdw-link-modal) ":
      {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.grey[500],
        boxShadow: "none",
        borderRadius: "4px",
      },
    // remove default icon hover effect
    "& .rdw-option-wrapper:hover": {
      boxShadow: "none",
    },
    // default icon non-selected
    "& .rdw-option-wrapper[aria-selected=false] , & .rdw-link-wrapper > .rdw-option-wrapper":
      {
        backgroundColor: theme.palette.grey[200],
        "& > img": {
          filter: "invert(0.6)",
        },
        boxShadow: "none",
      },
    "& .editorHeader , .editorFooter ": {
      backgroundColor: theme.palette.grey[200],
    },
    ".editorFooter": {
      padding: "0 12px",
    },
    // text field
    ".editorMain": {
      padding: "0 12px",
      minHeight: 82,
      overflow: "auto",
      // prevent suggestions from being hidden due to overflow, however if user scrolls this will stick
      // ".rdw-suggestion-wrapper": {
      //   position: "fixed",
      // },
      // suggestion container
      ".rdw-suggestion-dropdown": {
        position: "inherit",
        tabIndex: 1,
        width: "max-content",
        height: "max-content",
        boxShadow: "0px 1px 4px rgba(99, 100, 105, 0.2);",
        borderRadius: 6,
        ".rdw-suggestion-option": {
          padding: 16,
          fontSize: 16,
          fontWeight: 600,
        },
      },
      ".rdw-mention-link": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary[100],
        borderRadius: 4,
        fontWeight: 500,
      },
    },
    ".rdw-link-modal": {
      boxShadow: "0px 1px 4px rgba(99, 100, 105, 0.2);",
      "& .rdw-link-modal-btn:hover ": {
        boxShadow: "none",
      },
      "& .rdw-link-modal-label , .rdw-link-modal-target-option": {
        fontWeight: 500,
      },
    },
  },
}));
