import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useMobile, useTablet } from "../../../themes";
import {
  HomepageContainerStyled,
  HomepageSectionStyled,
} from "../shared/HomePage.styles";
import {
  DashReportsModal,
  EditUnderlineIcon,
  PageTabs,
  ServerDataGrid,
  useQueryParam,
  useReportDynamicTable,
  useRtkQueryParams,
  AdminReportsCard,
  EmptyState,
} from "../../../components";
import { useGetMyDashboardReportsQuery } from "../../../state/rtk-query/state/reports";
import { formatDate } from "../../../lib";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { buildFilterModel } from "../../../lib/requestBuilder";
import { skipToken } from "@reduxjs/toolkit/query";
import { useHeightOffset } from "../../../components/hooks/useHeightOffset";

const tabValues = [
  { id: "overview" as const, label: "Overview" },
  { id: "tasks" as const, label: "Tasks" },
  { id: "sessions" as const, label: "Sessions" },
  { id: "consultants" as const, label: "Consultants" },
  { id: "organizations" as const, label: "Organizations" },
];

export const AdminDashboard = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const { palette } = useTheme();

  const [dashReport, setDashReport] = useQueryParam<number>("dash_report");
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [openDashReportModal, setOpenDashReportModal] = useState(false);
  const isSmallerThanPc = isMobile || isTablet;

  const [tab = "overview"] =
    useQueryParam<(typeof tabValues)[number]["id"]>("tab");
  const [params, setParams] = useRtkQueryParams({
    id: dashReport,
  });

  const paramsSearch = useMemo(() => {
    return {
      ...params,
      id: dashReport,
    };
  }, [dashReport, params]);
  const { reportData, columns, isFetching, count } = useReportDynamicTable({
    id: dashReport,
    paramsSearch,
  });
  const [portalContainer, sePortalContainer] = useState<HTMLElement | null>(
    null,
  );

  const { currentData: reports, fulfilledTimeStamp } =
    useGetMyDashboardReportsQuery(
      tabValues.map((tv) => tv.id).includes(tab) ? { tab } : skipToken,
      {
        refetchOnMountOrArgChange: true,
        pollingInterval: 30000,
      },
    );

  useEffect(() => {
    if (!portalContainer) {
      const container = document.getElementById("homepage-container");
      if (container) sePortalContainer(container);
    }
  }, []);

  useEffect(() => {
    if (reports?.rows.length && !dashReport) {
      const firstReport = reports.rows[0];
      if (firstReport && !dashReport) {
        setDashReport(firstReport?.id);
      }
    }
  }, [dashReport, reports?.rows]);

  const viewReportClick = useCallback(
    (id?: number) => {
      if (!id) return;
      setDashReport(id);
    },
    [dashReport, tab, params],
  );

  const reportsToShow = useMemo(
    () => reports?.rows?.filter((r) => r.tab === tab),
    [reports?.rows],
  );

  const handleFilterChange = useCallback((newFilterModel: GridFilterModel) => {
    const filter = buildFilterModel(newFilterModel);
    if (params?.filter !== filter) {
      setParams({ filter });
      setFilterModel(newFilterModel);
    }
  }, []);

  const openDashModal = () => setOpenDashReportModal((o) => !o);
  const heightOffset = useHeightOffset();
  return (
    <Stack direction={isSmallerThanPc ? "column" : "row"} pr={0}>
      <HomepageContainerStyled
        id="homepage-container"
        sx={{
          minHeight: isSmallerThanPc
            ? "100%"
            : `calc(100vh - ${65 + heightOffset}px)`,
          height: "100%",
          width: "100%",
          bgcolor: palette.grey[100],
          borderColor: palette.grey[600],
          maxWidth: "100%",
        }}
        fullScreen={true}
      >
        <HomepageSectionStyled
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <PageTabs
            tabValues={tabValues}
            defaultTab={"overview"}
            EndButton={
              <IconButton onClick={openDashModal} size="small">
                <EditUnderlineIcon />
              </IconButton>
            }
          />
          {reportsToShow?.length ? (
            <Stack height={"100%"} width={"100%"} gap={2}>
              <AdminReportsCard
                reports={reportsToShow}
                rowClick={viewReportClick}
              />

              {fulfilledTimeStamp && (
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"flex-end"}
                >
                  <Typography variant="subtitle2">{`Last updated ${formatDate(
                    new Date(fulfilledTimeStamp),
                    "h:mm aaa",
                  )}`}</Typography>
                </Stack>
              )}
              <Stack height={"100%"} width={"100%"} sx={{ overflow: "hidden" }}>
                <ServerDataGrid
                  isFetching={isFetching}
                  rowCount={count ?? 0}
                  rows={reportData ?? []}
                  columns={columns}
                  setQueryParams={setParams}
                  currentParams={params}
                  props={{
                    density: "standard",
                    sx: {
                      border: `1px solid ${palette.grey[600]} !important`,
                      height: "100%",
                      ".MuiDataGrid-columnHeaders": {
                        textTransform: "uppercase",
                      },
                      ".MuiDataGrid-virtualScroller": {
                        height: "100% !important",
                        minHeight: "400px !important",
                        overflow: "auto",
                        backgroundColor: "#FFF",
                      },
                      ".MuiDataGrid-footerContainer": {
                        backgroundColor: "#FFF",
                      },
                      ".MuiDataGrid-row": {
                        cursor: "pointer",
                      },
                      ".MuiDataGrid-toolbarContainer": {
                        display: "none",
                      },
                    },
                    filterModel,
                    onFilterModelChange: handleFilterChange,
                  }}
                />{" "}
              </Stack>
            </Stack>
          ) : (
            <EmptyState type="reports" />
          )}
          {!isSmallerThanPc && <Box height={"100%"}></Box>}
        </HomepageSectionStyled>
      </HomepageContainerStyled>
      {portalContainer &&
        isSmallerThanPc &&
        createPortal(
          <Stack width={"100%"} px={1}></Stack>,
          portalContainer,
        )}{" "}
      <DashReportsModal
        open={openDashReportModal}
        onClose={openDashModal}
        tab={tab}
        myReports={reportsToShow ?? []}
      />
    </Stack>
  );
};
