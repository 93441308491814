import { AppThunk } from "../types";
import { timer, TimerState, Timer } from "./state";
const { actions } = timer;

export const timerActions = {
  ...actions,

  setTimerAction(timer?: Timer) {
    return actions.setTimer(timer);
  },
  setTimerIdAction(id?: number) {
    return actions.setTimerId(id);
  },
  setTimerOrgIdAction(id?: number) {
    return actions.setTimerOrgId(id);
  },
  setTimerCwAction({
    taskId,
    taskTitle,
    taskCode,
    reset,
  }: {
    reset?: boolean;
    taskId?: number;
    taskCode?: string;
    taskTitle?: string;
  }) {
    return actions.setTimerCw(
      reset ? undefined : { taskCode, taskId, taskTitle },
    );
  },
  setTimerStateAction(state?: TimerState["timer"]["state"]) {
    return actions.setTimerState(state);
  },
  setTimerInferredOrgIdAction(oldId?: number) {
    return actions.setTimerInferredOrgId(oldId);
  },

  setTimerCancelledSwitchAction(currentTimerOrgId?: number): AppThunk<any> {
    return (dispatch) => {
      dispatch(actions.setTimerCancelledSwitch(true));
      dispatch(actions.setTimerStayOnOrgId(currentTimerOrgId));
    };
  },
  setTimerMidSwitchAction(midSwitch = false) {
    return actions.setTimerMidSwitch(midSwitch);
  },
  resetTimerAction() {
    return actions.resetTimer();
  },

};
