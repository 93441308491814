import {
  Grid,
  Button,
  Stack,
  Divider,
  Typography,
  IconButton,
  FormControlLabel,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Formik, Form } from "formik";
import { actions, generatedApis } from "../../state/rtk-query/state";
import { rolesWithPermissions, yup } from "../../lib";
import { CloseIcon, CustomCheckBox, Tooltip } from "..";
import { InputField, SelectField, TopLabel } from "./FormFields";
import { SQLEditor } from "../inputs";
import {
  useCreateReportMutation,
  useDeleteReportMutation,
  useGetReportInfoByIdQuery,
} from "../../state/rtk-query/state/reports";
import { useCallback, useEffect, useState } from "react";
import { Diagnostic } from "@codemirror/lint";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { skipToken } from "@reduxjs/toolkit/query";

const { useUpdateReportMutation } = actions;

const validationSchema = yup.object({
  name: yup.string().required("name required"),
});

interface Props {
  onClose: () => void;
  reportId?: number;
  reportName?: string;
  roles?: number[];
  duplicateReport?: boolean;
}
type ToggleValue = "sql" | "agg";
export const EditReportForm = ({
  onClose,
  reportId,
  reportName,
  roles,
  duplicateReport,
}: Props) => {
  const isAdmin = useSelector(authSelectors.isAdmin);

  const [toggle, setToggle] = useState<ToggleValue>("sql");
  const { currentData } = useGetReportInfoByIdQuery(
    reportId && isAdmin ? { id: reportId } : skipToken,
  );

  const initialValues = {
    name: reportName ?? "",
    permissions: roles ?? [],
    danger_threshold: currentData?.data?.danger_threshold ?? 50,
  };

  const [sql, setSql] = useState("");
  const [countQuery, setCountQuery] = useState("");
  const [dashOnly, setDashOnly] = useState(
    currentData?.data?.dashboard_only ?? false,
  );

  const [linterErrors, setLinterErrors] = useState<Diagnostic[]>([]);
  const [updateReport] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [createReport] = useCreateReportMutation();
  const handleSubmit = async (values: typeof initialValues) => {
    const { name, permissions, danger_threshold } = values;

    if (reportId && !duplicateReport) {
      await updateReport({
        id: reportId,
        body: {
          name,
          roles: permissions,
          dash_only: dashOnly,
          danger_threshold,
          sql,
          aggregate_sql: countQuery,
        },
      });
      generatedApis.reportsApi.actions.util.invalidateTags(["reports"]);
    } else {
      await createReport({
        body: {
          name,
          roles: permissions,
          sql,
          dash_only: dashOnly,
          danger_threshold,
          aggregate_sql: countQuery,
        },
      });
    }
    onClose();
  };

  const deleteReportClick = async () => {
    if (!reportId) return onClose();
    if (!isAdmin) return onClose();
    await deleteReport({ id: reportId });
    onClose();
  };
  const markForDashOnly = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (!isAdmin) return;
    setDashOnly(checked);
  };
  useEffect(() => {
    if (currentData?.data?.sql && sql === "") {
      setSql(currentData?.data.sql);
    }
    if (currentData?.data?.aggregate_sql && countQuery === "") {
      setCountQuery(currentData?.data.aggregate_sql);
    }
  }, [currentData]);
  const handleToggle = useCallback(
    (_event: React.SyntheticEvent, newValue: ToggleValue) => {
      setToggle(newValue);
    },
    [toggle],
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid alignItems={"center"} container p={2}>
            <Grid
              item
              xs={12}
              md={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h1">
                {reportId
                  ? duplicateReport
                    ? `Duplicating report "${reportName}"`
                    : "Edit report"
                  : "Add report"}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={12} gap={2} display={"flex"} py={2}>
              <TopLabel label="Name">
                <InputField name="name" />
              </TopLabel>

              <TopLabel label="Permissions">
                <SelectField
                  name="permissions"
                  items={rolesWithPermissions}
                  multiple
                />
              </TopLabel>
            </Grid>
            {isAdmin && (
              <>
                <Grid item xs={12} md={12} gap={2} display={"flex"} py={2}>
                  <Stack minWidth={"100%"}>
                    <Box width={"100%"} mb={2}>
                      <ToggleButtonGroup
                        value={toggle}
                        exclusive
                        onChange={handleToggle}
                        color="info"
                        sx={{ width: "100%" }}
                      >
                        <ToggleButton value="sql" sx={{ width: "100%" }}>
                          <Typography fontSize={16} fontWeight={500}>
                            Raw query
                          </Typography>
                        </ToggleButton>

                        <ToggleButton value="agg" sx={{ width: "100%" }}>
                          <Typography fontSize={16} fontWeight={500}>
                            Count query
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    {toggle === "sql" ? (
                      <SQLEditor
                        value={sql}
                        setValue={setSql}
                        setLinterErrors={setLinterErrors}
                      />
                    ) : (
                      <SQLEditor
                        value={countQuery}
                        setValue={setCountQuery}
                        setLinterErrors={setLinterErrors}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} display={"flex"} flexDirection={"column"}>
                  <Tooltip title="Minimum amount of records before the dashboard should display red">
                    <Typography fontSize={12} lineHeight={0} my={2}>
                      Danger threshold
                    </Typography>
                  </Tooltip>
                  <InputField name="danger_threshold" type="number" />
                </Grid>
                <Grid item xs={12} display={"flex"}>
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        onChange={markForDashOnly}
                        checked={dashOnly}
                      />
                    }
                    label={`Only available on admin dashboard`}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Divider
            sx={{ width: "100%", my: 2, position: "absolute", left: 0 }}
          />

          <Stack
            direction={"row"}
            justifyContent={reportId ? "space-between" : "flex-end"}
            mt={4}
            px={2}
          >
            {!!reportId && (
              <Stack direction={"row"} justifyContent={"flex-start"}>
                {isAdmin && !duplicateReport && (
                  <Button
                    sx={{ width: "100px", fontWeight: 600 }}
                    variant="text"
                    color="error"
                    onClick={deleteReportClick}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            )}
            <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
              <Button
                sx={{ width: "100px", fontWeight: 600 }}
                variant="outlined"
                color="white"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: "100px", fontWeight: 600 }}
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting ||
                  !values.permissions ||
                  (!reportId && sql.trim() === "") ||
                  Boolean(linterErrors?.length)
                }
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
