import { Typography, useTheme } from "@mui/material";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
//local
import { AppState, actions, useSelector } from "../../state";
import { useCurOrg } from "../hooks";

const { useCreateSessionMutation } = actions;

export function ScheduleSession({ sessionId }: { sessionId?: number }) {
  const { palette } = useTheme();
  const userInfo = useSelector((state: AppState) => state.auth.user);

  const [createSession] = useCreateSessionMutation();

  const org = useCurOrg();

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      if (org?.consultant?.id && org?.id) {
        await createSession({
          orgId: org.id,
          body: {
            assigned_to: org.consultant?.id,
            session_id: sessionId,
            ...e.data.payload,
          },
        });
      }
    },
  });

  // const [createWebhook] = useCreateWebhookMutation();
  return (
    <>
      {org?.consultant?.calendly_link ? (
        <>
          <InlineWidget
            url={org.consultant.calendly_link ?? ""}
            styles={{
              backgroundColor: palette.primary.main,
              margin: 12,
              borderRadius: "3px",
              minHeight: "650px",
              height: "650px",
            }}
            prefill={{
              email: userInfo?.email,
              firstName: userInfo?.first_name,
              lastName: userInfo?.last_name,
              name: `${userInfo?.first_name} ${userInfo?.last_name}`,
              customAnswers: {
                a1: org?.name ?? "",
              },
            }}
          />
          {/*  FOR TESTING BUT IN THEORY WE SHOULD CREATE WEBHOOK IF DOESN'T EXIST
          <Button onClick={() => createWebhook()} sx={{ m: 2 }}>
            Create Webhook
          </Button>*/}
        </>
      ) : (
        <Typography fontSize={20} fontWeight={"bold"} color="primary.main">
          No consultant is associated to this org. Please reach out to get
          setup.
        </Typography>
      )}
    </>
  );
}
