import {
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Divider,
  Typography,
  Box,
  Link,
} from "@mui/material";

import { Formik, Form, FormikHelpers } from "formik";
import {
  InputField,
  SelectField,
  SingleDatePickerField,
  SmallDownIcon,
} from "../inputs/InputField";
import { Priorities, yup } from "../../lib";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectors,
  clientActions,
  clientSelectors,
  uiActions,
} from "../../state";
import { useCallback, useEffect, useMemo, useState } from "react";
import { actions } from "../../state/rtk-query/state";
import { useMobile } from "../../themes";
import { CustomEditor } from "../inputs";
import { useCustomEditor, useOpenTaskDrawer, useOrgId } from "../hooks";
import {
  CreateTaskApiResponse,
  useCreateTaskMediaMutation,
} from "../../state/rtk-query/state/tasks";
import { useOrgUsersList } from "../hooks/useLists";
import { CalendarBlankIcon, NameWithAvatar } from "../icons";
import { UploadButton } from "../buttons";
import { GreyTextStyled } from "../styled";
import { InlineAttachmentsList } from "../misc";
import { useSaveMediaMutation } from "../../state/rtk-query/state/media";
const { useCreateTaskMutation, useGetTopicsQuery } = actions;

const validationSchema = yup.object({
  topic: yup.string().required("Topic is required"),
  title: yup.string().required("Title required"),
  description: yup.string().required("Description is required"),
  client_assignee: yup.number().required("Client Assignee is required"),
});

interface Props {
  onClose: () => void;
  sessionId?: number;
  submitAndClose?: boolean;
  orgId?: number;
}
export const AddTaskForm = ({
  onClose,
  sessionId: _sessionId,
  submitAndClose = false,
  orgId: _orgId,
}: Props) => {
  const userId = useSelector(authSelectors.userId);
  const isMobile = useMobile();
  const orgId = _orgId ?? useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const { orgUsers } = useOrgUsersList(orgId!);
  const [saveMedia] = useSaveMediaMutation();
  const [createTaskMedia] = useCreateTaskMediaMutation();
  const { sessionId: stepperSessionId, screen } = useSelector(
    clientSelectors.currentSupportStep,
  );
  const sessionId = _sessionId ?? stepperSessionId;

  const [dateRequested, setDateRequested] = useState<string | undefined>();
  const [files, setFiles] = useState<File[]>([]);
  const editor = useCustomEditor({ setAttachments: setFiles });
  const { currentData } = useGetTopicsQuery();
  const [createTask] = useCreateTaskMutation();
  const openTaskDrawer = useOpenTaskDrawer();

  const dispatch = useDispatch();
  const initialValues = {
    topic: "",
    title: "",
    description: "",
    priority: "normal" as const,
    client_assignee: !isInternal
      ? userId || ""
      : screen === "AddInternalTask"
      ? -1
      : "",
    saveAndAdd: false,
  };
  useEffect(() => {
    if (!isInternal && screen === "AddInternalTask") onClose();
  }, [isInternal, screen]);

  const displayNotification = (taskId?: number) => {
    if (taskId) {
      dispatch(
        uiActions.showSuccess(
          <Stack width={"100%"} direction={"row"} alignItems={"center"}>
            Task created
            <Link
              onClick={() => openTaskDrawer(taskId)}
              fontWeight={"600"}
              ml={0.5}
            >
              View task
            </Link>
          </Stack>,
        ),
      );
    }
  };

  const uploadAttachments = useCallback(
    async (taskId?: number) => {
      if (files.length && taskId) {
        [...files].map(async (file: any) => {
          const { name } = file ?? {};
          const formData = new FormData();
          formData.append("file", file);

          const missingLabel = name.split(".")[0];
          const retFile = await saveMedia({
            body: { file: formData },
            label: missingLabel,
            name: name || missingLabel,
          }).unwrap();

          if (taskId && retFile.id) {
            await createTaskMedia({
              id: taskId,
              orgId,
              body: {
                media_id: retFile.id,
                is_internal: false,
              },
            });
          }
          return;
        });
      }
      setFiles([]);
    },
    [files],
  );

  const handleSubmit = useCallback(
    async (
      { saveAndAdd, ...values }: typeof initialValues,
      { resetForm }: Pick<FormikHelpers<typeof initialValues>, "resetForm">,
    ) => {
      if (values === initialValues) {
        onClose();
      }
      let newTask: CreateTaskApiResponse | undefined;

      if (orgId) {
        const topicId =
          currentData?.rows.find((top) => top.internal_name === values.topic)
            ?.id ?? 2; // TODO: find what the default topic id should be
        newTask = await createTask({
          orgId,
          body: {
            ...values,
            client_assignee: Number(values.client_assignee || 0),
            description: editor?.getHTML(),
            type: "task",
            topic_id: topicId,
            priority: "normal",
            reported_by: userId,
            session_id: sessionId,
          },
        }).unwrap();
        const taskId = newTask?.id;
        await uploadAttachments(taskId);
      }
      if (!saveAndAdd) {
        onClose();
        displayNotification(newTask?.id);
      }
      editor?.commands.setContent("");
      resetForm();
    },
    [editor, onClose, sessionId, orgId, files],
  );

  const submitQuickQuestion = useCallback(
    async (values: typeof initialValues) => {
      if (values === initialValues) {
        onClose();
      }

      if (orgId) {
        const topicId =
          currentData?.rows.find((top) => top.internal_name === values.topic)
            ?.id ?? 2;

        const submit = await createTask({
          orgId,
          body: {
            ...values,
            client_assignee: Number(values.client_assignee || 0),
            description: editor?.getHTML(),
            session_id: _sessionId,
            type:
              screen === "AddQuickQuestion"
                ? "quick_question"
                : screen === "AddCustomWork"
                ? "custom_work"
                : screen === "AddInternalTask" && isInternal
                ? "internal_task"
                : "task",
            topic_id: topicId,
            priority: values?.priority ?? "normal",
            reported_by: userId,
            requested_by_date:
              (screen === "AddCustomWork" || screen === "AddInternalTask") &&
              dateRequested
                ? dateRequested
                : undefined,
          },
        });
        if (!("error" in submit)) {
          const newTaskId = submit.data.id;
          await uploadAttachments(newTaskId);
          if (newTaskId) {
            dispatch(clientActions.setSupportTaskId(newTaskId));
          }
          if (submitAndClose) {
            displayNotification(newTaskId);
            onClose();
          }
        }
      }
      dispatch(clientActions.setSupportScreenAction("RequestSubmitted"));
    },
    [editor, dateRequested, sessionId, orgId, screen, files],
  );

  const cancelClick = useCallback(() => {
    onClose();
  }, []);
  const onCalendarChange = useCallback((values: any) => {
    setDateRequested(values);
  }, []);

  const orgAssignees = useMemo(
    () =>
      orgUsers.map((u) => {
        return (
          <MenuItem key={u.id} value={u.id}>
            <NameWithAvatar user={u} />
          </MenuItem>
        );
      }),
    [orgUsers],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={screen === "AddTask" ? handleSubmit : submitQuickQuestion}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        submitForm,
        errors,
        setFieldTouched,
        touched,
      }) => {
        return (
          <Form>
            <Grid
              alignItems={"center"}
              container
              spacing={4}
              px={isMobile ? 0 : 4}
            >
              <Grid item xs={12} md={12} gap={2} display={"flex"}>
                <FormControl fullWidth>
                  <Typography fontSize={12} lineHeight={0} my={2}>
                    Topic
                  </Typography>

                  <Select
                    labelId="topic-select"
                    name="topic"
                    onChange={(e) => setFieldValue("topic", e.target.value)}
                    value={values.topic}
                    IconComponent={SmallDownIcon}
                  >
                    {currentData?.rows.map((r) => {
                      return (
                        <MenuItem key={r.id} value={r.internal_name}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Typography fontSize={12} lineHeight={0} my={2}>
                    Title
                  </Typography>
                  <InputField name="title" />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography fontSize={12} lineHeight={0}>
                  Description
                </Typography>
                <CustomEditor
                  editor={editor}
                  onBlur={async () => {
                    await setFieldValue(
                      "description",
                      editor?.isEmpty ? "" : editor?.getHTML(),
                    );
                    setFieldTouched("description", true, true);
                  }}
                  error={touched.description && errors.description}
                />
                <Grid item xs={12} md={12} gap={2} display={"flex"}>
                  {screen === "AddCustomWork" ||
                  screen === "AddInternalTask" ? (
                    <Box width={"100%"}>
                      <FormControl fullWidth>
                        <Typography fontSize={12} lineHeight={0} my={2}>
                          Requested by
                        </Typography>
                        <SingleDatePickerField
                          name="requested_by"
                          onChange={onCalendarChange}
                          sx={{ width: "100%" }}
                          Icon={CalendarBlankIcon}
                          disablePast
                        />
                      </FormControl>
                    </Box>
                  ) : screen === "AddQuickQuestion" ? (
                    <Box width={"100%"}>
                      <FormControl fullWidth>
                        <Typography fontSize={12} lineHeight={0} my={2}>
                          Priority
                        </Typography>
                        <Select
                          labelId="priority-select"
                          name="priority"
                          onChange={(e) =>
                            setFieldValue("priority", e.target.value)
                          }
                          value={values.priority}
                          sx={{ textTransform: "capitalize" }}
                          IconComponent={SmallDownIcon}
                        >
                          {Priorities.map((r) => {
                            return (
                              <MenuItem
                                key={r}
                                value={r}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {r}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : null}

                  {screen !== "AddInternalTask" && (
                    <FormControl fullWidth>
                      <Typography fontSize={12} lineHeight={0} my={2}>
                        Client Assignee
                      </Typography>

                      <SelectField
                        labelId="client_assignee-select"
                        name="client_assignee"
                        onChange={(e) =>
                          setFieldValue("client_assignee", e.target.value)
                        }
                        MenuProps={{ sx: { maxHeight: "50%" } }}
                        value={values.client_assignee || ""}
                      >
                        {orgAssignees}
                      </SelectField>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              {/* TODO: wait till we re-arrange task media that it doesn't require taskId */}

              <Grid item xs={12} md={12}>
                <Stack gap={1} direction={"column"}>
                  <GreyTextStyled fontSize={16} fontWeight={600}>
                    Attachments
                  </GreyTextStyled>
                  <UploadButton taskId={-1} setAttachments={setFiles} />
                  <InlineAttachmentsList
                    attachments={files}
                    setAttachments={setFiles}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Divider
              sx={{ width: "100%", my: 2, position: "absolute", left: 0 }}
            />
            {screen === "AddTask" ? (
              <Stack
                direction={isMobile ? "column" : "row"}
                gap={2}
                mt={4}
                justifyContent={"flex-end"}
              >
                <Button
                  sx={{ width: isMobile ? "100%" : "100px" }}
                  color="white"
                  variant="outlined"
                  onClick={cancelClick}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: isMobile ? "100%" : "fit-content",
                    wordWrap: "none",
                    fontWeight: 600,
                  }}
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await setFieldValue("saveAndAdd", true);
                    await submitForm();
                  }}
                  disabled={
                    isSubmitting ||
                    !values.topic ||
                    !values.title ||
                    editor?.isEmpty
                  }
                >
                  Save and add another
                </Button>
                <Button
                  sx={{
                    width: isMobile ? "100%" : "fit-content",
                    wordWrap: "none",
                    fontWeight: 600,
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.topic ||
                    !values.title ||
                    editor?.isEmpty
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                mt={4}
                gap={1}
              >
                <Button
                  sx={{ width: "100px", fontWeight: 600 }}
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.topic ||
                    !values.title ||
                    editor?.isEmpty ||
                    !values.priority
                  }
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
