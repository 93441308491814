/** @file this hook is in charge of setting inferredOrgId and open/close tabs */
import { useEffect } from "react";
import {
  authSelectors,
  timerActions,
  timerSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import {
  useGetMyActiveTimerQuery,
  useUpdateOpenTabsMutation,
} from "../../state/rtk-query/state/timer";

export const useTimerSetter = (orgId?: number) => {
  const dispatch = useDispatch();
  const [updateOpenTabs] = useUpdateOpenTabsMutation();
  const isCw = useSelector(authSelectors.isCustomWorkUser);
  const isInternal = useSelector(authSelectors.isInternal) && !isCw;
  const { data } =
    useGetMyActiveTimerQuery(undefined, { skip: !isInternal }) ?? {};
  const {
    id: timerId,
    orgId: timerOrgId,
    state: timerState,
    cancelledSwitch,
    stayOnOrgId,
  } = useSelector(timerSelectors.currentTimer) ?? {};

  useEffect(() => {
    if (!isInternal || !orgId) return;
    dispatch(timerActions.setTimerInferredOrgIdAction(orgId));
    if (document.visibilityState === "visible") {
      updateOpenTabs({ orgId, tabCountDirection: "increment" });
    } else {
      // in a switching state
      if (![timerOrgId, stayOnOrgId].includes(orgId)) {
        updateOpenTabs({ orgId, tabCountDirection: "decrement" });
      }
    }
  }, [orgId, document.visibilityState]);

  return useEffect(() => {
    if (!orgId || !isInternal) return;
    if (
      timerOrgId &&
      ![timerOrgId, stayOnOrgId].includes(orgId) &&
      timerState === "active" &&
      !cancelledSwitch &&
      data?.id === timerId
    ) {
      dispatch(timerActions.setTimerStateAction("switching"));
    }
  }, [
    orgId,
    stayOnOrgId,
    timerOrgId,
    cancelledSwitch,
    timerState,
    timerId,
    data?.id,
  ]);
};
