import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemProps,
  SwipeableDrawer,
} from "@mui/material";
import { styled, Theme, CSSObject } from "@mui/material/styles";

export const drawerWidth = 200; // originally 240
export const collapsedDrawerWidth = 72;

// Drawer styled components
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: collapsedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

export const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    border: "none",
  },
  ...(open
    ? {
        "& .MuiDrawer-paper": openedMixin(theme),
      }
    : {
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
}));

export const DrawerHeaderStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
  marginBottom: "48px",
  width: collapsedDrawerWidth,
}));

export const MobileDrawerStyled = styled(SwipeableDrawer)({
  "& .MuiDrawer-paperAnchorLeft": {
    maxWidth: "90vw",
    width: "293px",
  },
});

export const MobileDrawerHeaderStyled = styled(Box)(({ theme }) => ({
  margin: "24px 12px 40px 12px",
  "& .MuiTypography-root:nth-of-type(1)": {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    marginBottom: "4px",
  },
  "& .MuiTypography-root:nth-of-type(2)": {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.palette.grey[800],
  },
}));

// Drawer Menu styled components
export const MenuContainerStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
}));

export const MenuListStyled = styled(List)(() => ({
  height: "100%",
  width: "100%",
}));

export const MenuItemStyled = styled(ListItemButton)<ListItemProps>(
  ({ theme, selected }) => ({
    borderRadius: "6px",
    margin: "0px 18px",
    height: "36px",

    justifyContent: "center",

    backgroundColor: `${
      selected ? theme.palette.info[100] : theme.palette.background.default
    } !important`,

    "& .MuiSvgIcon-root, & .MuiListItemIcon-root > svg ": {
      color: selected ? theme.palette.info.main : theme.palette.grey[700],
    },

    "& .MuiListItemIcon-root": {
      minWidth: "24px",
    },
    "& .MuiListItemText-root": {
      marginLeft: "24px",
    },

    [theme.breakpoints.down("sm")]: {
      height: "36px",
      margin: "0px 16px 24px 16px",
    },
  }),
);

export const MobileMenuFooterStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  width: "100%",
  backgroundColor: theme.palette.background.default,
  "& .MuiListItemButton-root:first-of-type": {
    marginTop: "24px",
    marginBottom: "24px",
  },
}));

export const NotificationContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "changeBackground",
})<{ changeBackground: boolean }>(({ theme, changeBackground }) => ({
  backgroundColor: changeBackground
    ? theme.palette.primary[50]
    : theme.palette.background.default,
  "&:last-child": {
    marginBottom: 12,
  },
}));

export const PopoverItemStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "height",
})<{ height?: number | string }>(({ theme: theme, height }) => ({
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
  padding: "16px 18px",
  height: height ?? "100px",
  [theme.breakpoints.down("sm")]: {
    padding: "16px 16px",
  },
}));
