import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ArrayElement } from "../../global";
import {
  GetSessionsByOrgIdApiResponse,
  useCancelSessionMutation,
  useDeleteSessionMutation,
} from "../../state/rtk-query/state/session";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useMobile } from "../../themes";
import {
  ConfirmDeleteModal,
  Modal,
  CreateSessionForm,
  MoreVertIcon,
  CloseIcon,
  AttendeeAndAssigneeForm,
} from "..";

import { useGetStatusId, useOrgId } from "../hooks";
import { BaseModal } from "../forms/BaseForm";
import { useStatusesList } from "../hooks/useLists";
import { Navigation, useLocation } from "../../lib";
interface Props {
  status: string;
  session: ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
  id: number;
  openAttModalFromAvatar?: boolean;
}
interface ModalState {
  attendeeModal: boolean;
  rescheduleModal: boolean;
  cancelModal: boolean;
}
type ActionState = keyof ModalState;

const reducer = (state: ModalState, action: ActionState) => {
  if (action) {
    return {
      ...state,
      [`${action}`]: !state[action],
    };
  }
  throw Error("Unknown action.");
};
export const SessionMenu = ({
  status,
  session,
  id,
  openAttModalFromAvatar,
}: Props) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const cancelId = useGetStatusId("canceled");
  const orgId = useOrgId(session.org_id);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [cancelReason, setCancelReason] = useState("");
  const [showError, setShowError] = useState(false);

  const [state, openModal] = useReducer(reducer, {
    attendeeModal: openAttModalFromAvatar ?? false,
    rescheduleModal: false,
    cancelModal: false,
  });
  const statuses = useStatusesList("session");
  const isResolved = [
    ...statuses.statuses
      .filter((s) => s.transition_phase === "done")
      .map((s) => s.internal_name),
    "rescheduled",
  ].includes(status);

  const menuClick = useCallback(
    (
      modalName: ActionState,
      e?: React.MouseEvent<HTMLLIElement | HTMLButtonElement>,
    ) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      openModal(modalName);
    },
    [state],
  );

  const [cancelSession] = useCancelSessionMutation();

  const { tasks } = session;
  const isActiveSession =
    (Boolean(!tasks?.length) ||
      Boolean(tasks?.filter((t) => t.session_tasks?.is_active).length)) &&
    !["unscheduled", "rescheduled"].includes(status);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [anchorEl],
  );
  const handleClose = useCallback((e: any) => {
    if (e) {
      e?.stopPropagation();
      e?.preventDefault();
    }
    setAnchorEl(null);
  }, []);

  const handleCancel = useCallback(
    async (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      if (id && orgId && cancelReason.trim() !== "") {
        await cancelSession({
          id,
          orgId,
          body: { reason: cancelReason },
        });
        setAnchorEl(null);
        menuClick("cancelModal");
      }
    },
    [id, cancelReason, cancelId, showError],
  );
  const setReason = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;

      setCancelReason(val);
      if (val.trim() === "") {
        setShowError(true);
      } else {
        setShowError(false);
      }
    },
    [id, cancelReason, showError],
  );
  useEffect(() => {
    if (
      openAttModalFromAvatar &&
      openAttModalFromAvatar !== state.attendeeModal
    ) {
      menuClick("attendeeModal");
    }
  }, [openAttModalFromAvatar]);

  return (
    <>
      {!isResolved ? (
        <IconButton
          onClick={handleClick}
          style={{
            backgroundColor: palette.grey[200],
            borderRadius: 4,
            width: 24,
            height: 24,
          }}
        >
          <MoreVertIcon style={{ width: 20, height: 20 }} />
        </IconButton>
      ) : null}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!isResolved && isInternal && (
          <MenuItem
            onClick={(e) => menuClick("attendeeModal", e)}
            sx={{ fontWeight: 500 }}
          >
            Manage attendees
          </MenuItem>
        )}
        {isActiveSession ? (
          <MenuItem
            onClick={(e) => menuClick("rescheduleModal", e)}
            sx={{ fontWeight: 500 }}
          >
            Reschedule
          </MenuItem>
        ) : null}
        <Modal
          open={state.rescheduleModal}
          onClose={(e) => menuClick("rescheduleModal", e)}
          sx={{
            width: isMobile ? "100%" : "60%",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={isMobile ? "16px" : "50px"}
          >
            <Typography variant="h1">Reschedule session</Typography>
            <IconButton onClick={(e) => menuClick("rescheduleModal", e)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <CreateSessionForm
            removeHeader
            session_id={id}
            orgId={session?.org_id}
            isRescheduling
          />
        </Modal>
        {!isResolved && !["unscheduled", "canceled"].includes(status) && (
          <MenuItem
            onClick={(e) => menuClick("cancelModal", e)}
            sx={{
              color: palette.error[500],
              fontWeight: 500,
              fontSize: 14,
              width: "100%",
              height: "100%",
            }}
          >
            Cancel
          </MenuItem>
        )}
        {isAdmin && <DeleteSessionItem id={id} orgId={orgId} />}
      </Menu>
      <ConfirmDeleteModal
        open={state.cancelModal}
        onClose={() => menuClick("cancelModal")}
        handleConfirm={(e) => handleCancel(e)}
        titleText="Are you sure you want to cancel this session?"
        confirmActionButtonText="Confirm"
        skipActionButtonText="Do not cancel"
        disableConfirmButton={showError || cancelReason.trim() === ""}
      >
        <Stack mt={2}>
          <Typography fontSize={14} lineHeight={0} my={2}>
            Reason for cancellation
          </Typography>

          <TextField
            onChange={setReason}
            value={cancelReason}
            error={showError}
            required
            helperText={showError ? "Cancellation reason is required" : ""}
            sx={{
              "& > .Mui-error": {
                fontSize: 10,
              },
            }}
          />
        </Stack>
      </ConfirmDeleteModal>
      <BaseModal
        open={state.attendeeModal && isInternal}
        onClose={(e) => menuClick("attendeeModal", e)}
        title="Manage people"
      >
        <AttendeeAndAssigneeForm
          onClose={() => menuClick("attendeeModal")}
          session={session}
        />
      </BaseModal>
    </>
  );
};

function DeleteSessionItem({ id, orgId }: { id: number; orgId: number }) {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  const [deleteSession, { isSuccess }] = useDeleteSessionMutation();
  const loc = useLocation();

  useEffect(() => {
    if (isSuccess && loc.pathname === `/sessions/${id}`) {
      Navigation.go(`/organizations/${orgId}/?tab=sessions`);
    }
  }, [isSuccess]);

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          toggleOpen();
        }}
        sx={{
          color: palette.error[500],
          fontWeight: 500,
          fontSize: 14,
          width: "100%",
          height: "100%",
        }}
      >
        Archive
      </MenuItem>
      <ConfirmDeleteModal
        open={open}
        onClose={toggleOpen}
        handleConfirm={() => {
          deleteSession({ id, orgId });
          toggleOpen();
        }}
        titleText="Are you sure you want to archive this session?"
        confirmActionButtonText="Confirm"
        skipActionButtonText="Cancel"
      />
    </>
  );
}
