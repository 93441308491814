import { Button, Link, Stack, Typography, useTheme } from "@mui/material";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import {
  BookIcon,
  CalendarBlankIcon,
  GetSupportModal,
  JoinZoomMeetingButton,
  LaptopIcon,
  TaskTypeIcon,
} from "..";
import { Navigation, formatDate, useLocation } from "../../lib";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { actions } from "../../state/rtk-query";
import { useOrgId, useStatusesList } from "../hooks";
import { Link as RouterLink } from "react-router-dom";
import { TaskActivityButton } from "../buttons/TaskActivityButton";
import { SessionTimeRange } from "../misc";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { MainPages } from "../../pages";

const { useGetSessionsByIdQuery } = actions;
type Prop = { details: GetTaskApiResponse };
export const TaskDetails = ({ details }: Prop) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const { palette } = useTheme();
  const orgId = useOrgId();
  const id = useLocation({ parseNumbers: true }).query.task;
  const [moveToSessionModal, setMoveToSessionModal] = useState(false);
  const {
    type,
    created_at,
    reported_by_user,
    requested_by_date,
    sessions,
    instant_meeting_occurred,
    media,
    status,
    linkedTasks,
    deleted_at,
  } = details;
  const isArchived = Boolean(deleted_at);
  const activeSession = sessions?.find((s) => s?.session_tasks?.is_active);
  const psd = activeSession?.pending_session_dates;
  const { data: sessionInfo } = useGetSessionsByIdQuery(
    activeSession?.id && orgId ? { id: activeSession.id, orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { booking, status_id } = sessionInfo ?? {};

  const hasCustomWorkManual = media?.find(
    (m) => m.task_media?.is_custom_work_manual,
  );

  const cmId = hasCustomWorkManual?.id;

  const mediaClick = async () => {
    if (hasCustomWorkManual && cmId) {
      Navigation.go(
        isInternal
          ? `/organizations/${details.org_id}?tab=documents&mediaTab=custom_work`
          : MainPages(false).media.path,
      );
    }
  };

  const toggleMoveToSession = useCallback(() => {
    setMoveToSessionModal((move) => !move);
  }, [moveToSessionModal]);

  const { getStatus } = useStatusesList();

  const canMoveToSession =
    (type === "custom_work" || status?.transition_phase !== "done") &&
    type !== "internal_task" &&
    !isArchived &&
    (type === "task" ||
      !linkedTasks?.find(
        (lt) => getStatus(lt.linkedTask.status_id)?.transition_phase !== "done",
      ));

  return (
    <Stack
      paddingY={"24px"}
      direction={"column"}
      alignContent={"flex-start"}
      gap={1}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <TaskTypeIcon
          type={type}
          psd={psd}
          start_time={booking?.start_time}
          status_id={status_id}
        />

        <Typography fontWeight={600} fontSize={16} color={palette.grey[700]}>
          {type === "custom_work"
            ? "CUSTOM WORK TASK"
            : type === "task"
            ? sessions?.length
              ? "ON SESSION TASK"
              : "UN-CATEGORIZED TASK"
            : type?.replace("_", " ").toUpperCase()}
        </Typography>
        {canMoveToSession && (
          <Button onClick={toggleMoveToSession}>Move to session</Button>
        )}
      </Stack>
      <Stack direction={"column"} alignItems={"flex-start"} gap={2}>
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
          {!!created_at && (
            <Typography
              variant="subtitle2"
              fontSize={10}
              fontWeight={500}
              color={palette.grey[900]}
            >
              {`Created on ${formatDate(
                new Date(created_at),
                "M.d",
              )} at ${formatDate(new Date(created_at), "h:mm a")}  by ${
                reported_by_user?.first_name
              } ${reported_by_user?.last_name}`}
            </Typography>
          )}
          {id ? <TaskActivityButton id={id} /> : null}
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          {(type === "custom_work" || type === "internal_task") &&
          requested_by_date ? (
            <>
              <CalendarBlankIcon color={palette.primary.main} size={22} />

              <Typography
                fontSize={14}
                fontWeight={500}
                ml={1}
                color={palette.grey[900]}
              >
                Requested by {formatDate(requested_by_date, "M.d")}
              </Typography>
            </>
          ) : null}
        </Stack>
        {instant_meeting_occurred ? (
          <Stack direction={"row"} alignItems={"center"}>
            <LaptopIcon color={palette.primary.main} size={22} />

            <Typography
              fontSize={14}
              fontWeight={500}
              ml={1}
              color={palette.grey[900]}
            >
              Discussed on an instant meeting
            </Typography>
          </Stack>
        ) : null}
        {hasCustomWorkManual ? (
          <Stack direction={"row"} alignItems={"center"}>
            <BookIcon color={palette.primary.main} size={22} />

            <Typography
              fontSize={14}
              fontWeight={500}
              ml={1}
              color={palette.grey[900]}
            >
              Custom work manual uploaded{" "}
              <Button onClick={mediaClick}>View</Button>
            </Typography>
          </Stack>
        ) : null}
        {(booking?.start_time || Array.isArray(psd)) && activeSession?.id ? (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              fontSize={14}
              fontWeight={500}
              color={palette.grey[900]}
            >
              Linked to{" "}
              <Link
                component={RouterLink}
                to={`/sessions/${activeSession?.id}`}
              >
                {activeSession?.title} &middot;{" "}
                {booking?.start_time ? (
                  <>
                    {formatDate(new Date(booking.start_time), "EEE, LLL d")}{" "}
                    &middot;{" "}
                    <SessionTimeRange
                      startTime={booking?.start_time}
                      endTime={booking?.end_time}
                    />
                  </>
                ) : psd?.length ? (
                  <>
                    {`Pending: ${formatDate(psd[0]?.value ?? "")} -
                    ${formatDate(psd[1]?.value ?? "")}`}
                  </>
                ) : null}
              </Link>
              {booking?.play_url ? (
                <Link
                  href={`${booking?.play_url}?pwd=${booking?.meeting_password}`}
                  target="_blank"
                  sx={{ pl: 2, pr: 0 }}
                >
                  Recording
                </Link>
              ) : (
                <JoinZoomMeetingButton booking={booking} />
              )}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <GetSupportModal
        open={moveToSessionModal}
        onClose={toggleMoveToSession}
        defaultScreen="SessionSelection"
        movingTaskId={id}
        orgId={isInternal ? details?.org_id : 0}
        isNotSessionTask={type === "custom_work" || type === "quick_question"}
      />
    </Stack>
  );
};
