import { Bar } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  Tooltip,
  Decimation,
  ChartData,
  ChartOptions,
  ScriptableContext,
  TooltipModel,
} from "chart.js";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { createRoot, Root } from "react-dom/client";
import {
  createDiagonalGradient,
  getOrCreateTooltip,
  HORIZONTAL_BAR_THICKNESS,
} from "../../../components/charts/ChartConstants";
import { castZero, filterBoolean } from "../../../lib";
import { getLabelClickPlugin, HorizontalBarBackgroundPlugin } from "../../../components/charts";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  adminChartOptions,
  ADMIN_BAR_CHART_THICKNESS,
  ADMIN_DISPLAY_TOP_AXIS,
} from "./AdminChartConstants";

import {
  GetTimeSpentOnAllOrgsApiArg,
  useGetTimeSpentOnAllOrgsQuery,
} from "../../../state/rtk-query/state/timer";
import { useQueryParam } from "../../hooks";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { DateRange } from "@mui/x-date-pickers-pro";
type FilterType = GetTimeSpentOnAllOrgsApiArg["filterTime"];
type TooltipContext = {
  chart: Chart;
  tooltip: TooltipModel<any>;
};
interface TooltipRowProps {
  label: string;
  value: number;
  boldLabel?: boolean;
}

const TooltipRow = memo(({ label, value, boldLabel }: TooltipRowProps) => {
  const val = Number(value)?.toFixed(1);
  return (
    <Stack direction={"row"} alignItems={"center"} gap={0.5} key={label}>
      <Box width={40}>
        <Typography fontSize={12} fontWeight={600}>
          {castZero(Number(val))}
        </Typography>{" "}
      </Box>
      <Typography fontSize={12} fontWeight={boldLabel ? 600 : 400}>
        {label}
      </Typography>
    </Stack>
  );
});

export const AdminTimeChart = () => {
  Chart.register(
    [HorizontalBarBackgroundPlugin],
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Decimation,
  );
  const { palette } = useTheme();
  const chartRef = useRef<ChartJSOrUndefined<"bar", number[], string> | null>(
    null,
  );
  const [filterTime, _f] = useQueryParam<FilterType>("time_filter");
  const [ticks, setTicks] = useState<string[]>([]);
  const [dateRange, _d] = useQueryParam<DateRange<any> | undefined>(
    "dateRange",
  );
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );
  const { currentData, status } = useGetTimeSpentOnAllOrgsQuery(
    {
      dateRange: hasDateRange ? dateRange : undefined,
      filterTime,
    },
    { refetchOnMountOrArgChange: true },
  );

  const arrData = useMemo(
    () => currentData ?? [],
    [currentData, status, filterTime, dateRange],
  );

  const ClickPlugin = useMemo(
    () =>
      getLabelClickPlugin((label) => {
        const foundOrg = arrData.find((d) => d.label === label.label)?.org_id;

        if (foundOrg) {
          return window.open(`/organizations/${foundOrg}`);
        }
        return;
      }),
    [arrData],
  );

  let reactRoot: Root | null = null;

  const CustomToolTip = useCallback(
    (context: TooltipContext): void => {
      const { chart, tooltip } = context;

      if (tooltip.body) {
        const tooltipEl = getOrCreateTooltip(chart, tooltip);
        const { scales } = chart;
        const tooltipContainer = tooltipEl.querySelector("div");

        if (!reactRoot && tooltipContainer) {
          reactRoot = createRoot(tooltipContainer);
        } else {
          // If the user stopped hovering delete the tooltip
          if (tooltip.opacity === 0) {
            if (reactRoot) {
              reactRoot.unmount();
              reactRoot = null;
            }
            tooltipEl.remove();
            return;
          }
        }
        // we only send back one line with the index
        const body = tooltip.body[0].lines;
        const whichBar = arrData[Number(body)];

        const text = body[1];
        const yLabel = scales.y.ticks[Number(body)] as any;
        if (yLabel) yLabel.fontStyle = "bold";

        if (tooltipContainer && reactRoot) {
          return reactRoot.render(
            <Stack direction={"column"} gap={1} p={1}>
              <Typography fontWeight={700} fontSize={14}>
                {tooltip.title[0]}
              </Typography>
              <Stack direction={"column"} gap={1} key={text}>
                <TooltipRow label={"Sessions"} value={whichBar?.sessions} />
                <TooltipRow label={"Tasks"} value={whichBar?.tasks} />
                <TooltipRow
                  boldLabel
                  label={"Total support"}
                  value={whichBar?.total}
                />
              </Stack>
            </Stack>,
          );
        }
      }
    },
    [status, filterTime, dateRange],
  );

  useEffect(() => {
    return () => {
      if (reactRoot) {
        reactRoot.unmount();
        reactRoot = null;
      }
    };
  }, [filterTime]);

  useEffect(() => {
    if (chartRef.current && arrData.length) {
      const yScale = chartRef.current.scales.y;
      const xScale = chartRef.current.scales.x;
      const isDecimal = xScale.ticks[xScale.ticks.length - 2].value;
      const t = xScale.ticks
        .map((tick) => {
          return (yScale as any)?._valueRange < ADMIN_DISPLAY_TOP_AXIS
            ? ""
            : +tick.value === 0
            ? "0"
            : isDecimal < 1
            ? castZero(+tick.value).toFixed(1)
            : `${tick.value}`;
        })
        .filter((t) => t !== "");

      setTicks(t);
      if (chartRef.current.isPluginEnabled("LabelClickPlugin")) {
        Chart.unregister(ClickPlugin);
      }
      Chart.register(ClickPlugin);
    }
    return () => {
      if (reactRoot) {
        reactRoot.unmount();
        reactRoot = null;
      }
    };
  }, [chartRef.current, arrData]);
  const time = useMemo(
    () => arrData.map((v) => v.total),
    [status, filterTime, dateRange],
  );

  const averageData = useMemo(
    () => arrData.map((v) => v.avg_range),
    [arrData, filterTime, dateRange],
  );
  const height = useMemo(
    () => castZero(currentData?.length) * (HORIZONTAL_BAR_THICKNESS * 2),
    [status, filterTime, dateRange],
  );

  const data = useMemo(
    () =>
      ({
        labels: arrData.map((v) => v.label),
        datasets: [
          {
            data: time,
            backgroundColor: palette.accent[600],
            barThickness: ADMIN_BAR_CHART_THICKNESS,
            order: 2,
            borderRadius: 3,
          },
          {
            // unofficially data can take an array of arrays [min, max]
            data: averageData,
            backgroundColor(context: ScriptableContext<"bar">) {
              const chart = context.chart;
              const { ctx } = chart;
              return createDiagonalGradient(ctx);
            },
            barThickness: ADMIN_BAR_CHART_THICKNESS + 4,

            order: 1,
          },
        ],
      } as ChartData<"bar", number[], string>),
    [status, filterTime, dateRange],
  );

  const options = useMemo(
    () =>
      ({
        ...adminChartOptions,
        plugins: {
          ...adminChartOptions.plugins,
          tooltip: {
            ...adminChartOptions.plugins?.tooltip,
            external: CustomToolTip,
          },
        },
      } as ChartOptions<"bar">),
    [status, filterTime, dateRange],
  );

  return (
    <>
      {arrData?.length >= ADMIN_DISPLAY_TOP_AXIS && chartRef.current && (
        <Stack
          pt={2}
          pb={0.5}
          pl={"200px"} //200 is based on the width of the y-axis scale
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"sticky"}
          sx={{ top: 0, zIndex: 1, bgcolor: "#FFF" }}
        >
          {ticks.map((t) => (
            <Typography key={t} fontSize={12}>
              {t}
            </Typography>
          ))}
        </Stack>
      )}
      <Stack
        //  This controls th height of the chart
        direction={"column"}
        width="100%"
        height={"50vh"}
        minHeight={height}
        maxHeight={"100vh"}
        overflow={"auto"}
      >
        <Bar data={data} options={options} ref={chartRef} />
      </Stack>
    </>
  );
};
