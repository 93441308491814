import {
  Grid,
  Select,
  FormControl,
  Typography,
  MenuItem,
  Box,
  InputAdornment,
  Link,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { actions } from "../../state/rtk-query/state";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { ArrayElement } from "../../global";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { ChevronDown, NameWithAvatar } from "../icons";
import { SelectField } from "../inputs/InputField";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { useOrgId } from "../hooks";
import { useAdmireUsersList, useOrgUsersList } from "../hooks/useLists";
import { BaseForm } from "./BaseForm";
import { filterBoolean } from "../../lib";
const { useGetTaskWatchersQuery, useManageAssigneesMutation } = actions;

interface Props {
  onClose: () => void;
  task?: GetTaskApiResponse | undefined;
  session?: ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
}

export const AttendeeAndAssigneeForm = ({ onClose, task, session }: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const [updateAssignees] = useManageAssigneesMutation();
  const { admireUsers, getAdmireUser } = useAdmireUsersList();
  const curOrg = useOrgId();
  const orgId = isInternal ? task?.org_id || session?.org_id : curOrg;
  const { orgUsers } = useOrgUsersList(orgId!);

  const type = task?.id ? "task" : session?.id ? "session" : null;
  const { currentData: taskWatchers } = useGetTaskWatchersQuery(
    task?.id && curOrg ? { id: task.id, orgId: curOrg } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const initialValues = {
    admire_assignee_id: task
      ? task?.assigned_to_user?.id || (task as any)?.assigned_to || ""
      : session
      ? session?.assigned_to_user.id || ""
      : "",
    client_assignee_id: task
      ? task?.client_assignee_user?.id || ""
      : session
      ? session?.client_assignee_user?.id || session?.created_by_user?.id || ""
      : "",
    other_users: (type === "task"
      ? taskWatchers?.rows?.map((tw) => tw.id).filter(Boolean)
      : filterBoolean(
          session?.session_invitees?.map((ss) => ss.user_id) ?? [],
        )) as number[],
  };
  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (values === initialValues) {
        onClose();
      }
      if (type && (task?.id || session?.id) && orgId) {
        await updateAssignees({
          id: Number(type === "task" ? task?.id : session?.id),
          orgId,
          body: {
            ...values,
            admire_assignee_id: Number(values?.admire_assignee_id ?? 0),
            client_assignee_id:
              type === "task" && task?.type === "internal_task"
                ? -1
                : Number(values?.client_assignee_id ?? 0),
            assignee_type: type,
          },
        });
      }
      onClose();
    },
    [onClose, orgId, task?.id, session?.id],
  );

  const availableUsers = isInternal
    ? orgUsers || taskWatchers?.rows || session?.session_invitees
      ? [
          ...orgUsers,
          ...(taskWatchers?.rows ?? []),
          ...(session?.session_invitees ?? []),
          session?.client_assignee_user ?? {},
          session?.created_by_user ?? {},
          task?.client_assignee_user ?? {},
        ]
      : []
    : orgUsers ?? [];
  const ids = filterBoolean(availableUsers).map(({ id }) => id);
  const nonUserInvitees =
    session?.session_invitees?.filter(
      (si) => !si.user_id || !ids.includes(si?.user_id),
    ) ?? [];
  const removeDupes =
    (
      availableUsers.filter((au, idx) => !ids.includes(au.id, idx + 1)) ?? []
    )?.filter(
      (si) => !nonUserInvitees?.map((m) => m.email).includes(si?.email ?? ""),
    ) ?? [];

  const orgAssignees = useMemo(
    () =>
      orgUsers.map((u) => {
        return (
          <MenuItem key={u.id} value={u.id}>
            <NameWithAvatar user={u} />
          </MenuItem>
        );
      }),
    [orgUsers],
  );
  return (
    <BaseForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      onClose={onClose}
    >
      {({ values, setFieldValue }) => {
        const admireAssignees = useMemo(
          () =>
            isInternal ? (
              [
                <MenuItem key={-1} value={-1}>
                  <NameWithAvatar user={getAdmireUser(-1)} />
                </MenuItem>,
                ...admireUsers
                  .filter((au) => au.id !== -1)
                  .map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                      <NameWithAvatar user={r} />
                    </MenuItem>
                  )),
              ]
            ) : (
              <MenuItem value={values.admire_assignee_id}>
                <NameWithAvatar
                  user={getAdmireUser(+values.admire_assignee_id)}
                />
              </MenuItem>
            ),
          [admireUsers, isInternal],
        );

        return (
          <Grid alignItems={"center"} container spacing={4}>
            <Grid item xs={12} md={12} gap={2}>
              <FormControl fullWidth>
                <Typography fontSize={12} lineHeight={0} my={2}>
                  Admire Assignee
                </Typography>
                <SelectField
                  labelId="admire_assignee-select"
                  name="admire_assignee"
                  onChange={(e) =>
                    setFieldValue("admire_assignee_id", e.target.value)
                  }
                  value={getAdmireUser(+values.admire_assignee_id)?.id || ""}
                  inputProps={{ readOnly: !isInternal }}
                  MenuProps={{ sx: { maxHeight: "50%" } }}
                >
                  {admireAssignees}
                </SelectField>
              </FormControl>
              <FormControl fullWidth disabled={task?.type === "internal_task"}>
                <Typography fontSize={12} lineHeight={0} my={2}>
                  Client Assignee
                </Typography>

                <SelectField
                  labelId="client_assignee-select"
                  name="client_assignee"
                  onChange={(e) =>
                    setFieldValue("client_assignee_id", e.target.value)
                  }
                  MenuProps={{ sx: { maxHeight: "50%" } }}
                  value={values.client_assignee_id || ""}
                >
                  {orgAssignees}
                </SelectField>
              </FormControl>
              <FormControl fullWidth>
                <Typography fontSize={12} lineHeight={0} my={2}>
                  {task ? "Watchers" : "Invitees"}
                </Typography>
                <Select
                  labelId="other_users-select"
                  name="other_users"
                  onChange={(e) => setFieldValue("other_users", e.target.value)}
                  value={values.other_users || []}
                  MenuProps={{ sx: { maxHeight: "50%" } }}
                  multiple
                  sx={{
                    height: "100% !important",
                    width: "100%",
                    "& > .MuiSvgIcon-root": { display: "none" },
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                        height: "100%",
                      }}
                    >
                      <ChevronDown />
                    </InputAdornment>
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, i) => {
                        const who = removeDupes?.find((ou) => ou?.id === value);
                        return (
                          <Box key={i}>
                            {who ? (
                              <NameWithAvatar
                                user={who}
                                sx={{ m: 1 }}
                                badgeStyles={{ right: 4, top: -2 }}
                              />
                            ) : null}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                >
                  {(task?.type === "internal_task"
                    ? admireUsers
                    : removeDupes
                  )?.map((r, i) => {
                    return (
                      <MenuItem key={r?.id ?? i} value={r?.id}>
                        {r && Reflect.ownKeys(r)?.length ? (
                          <NameWithAvatar user={r} />
                        ) : null}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {!!nonUserInvitees.length && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Typography fontSize={12} lineHeight={0} my={2}>
                    Non User Invitees
                  </Typography>
                  {nonUserInvitees?.map((si) => {
                    return (
                      <Typography
                        fontSize={12}
                        lineHeight={0}
                        my={1}
                        key={si.email}
                      >
                        {si.email}
                        <Link
                          href={`/users?userType=client&action=add_user&email=${si.email}&orgId=${orgId}`}
                          target="_blank"
                          ml={1}
                        >
                          Add as user
                        </Link>
                      </Typography>
                    );
                  })}
                </FormControl>
              )}
            </Grid>
          </Grid>
        );
      }}
    </BaseForm>
  );
};
