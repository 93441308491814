import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link, Typography } from "@mui/material";
import {
  PreScreenRootStyle,
  SelectOrgBox,
  SelectOrgBoxContainer,
} from "../shared/HomePage.styles";
import logo from "../../../assets/img/logo.svg";
import { authActions, authSelectors, clientActions } from "../../../state";
import { useLazyGetUserProfileQuery } from "../../../state/rtk-query/state/user";
import { useNavigate } from "react-router-dom";
import { Org } from "../../../state/client/state";
import { useMobile } from "../../../themes";
import { MainPages } from "../..";
export const PreScreenPage = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const [getProfile, { data: profile, status }] = useLazyGetUserProfileQuery();
  const pages = MainPages(isInternal);

  const [showLogout, setShowLogout] = useState(false);
  const { orgs = [] } = profile || {};

  const { first_name } = useSelector(authSelectors.userInfo);

  const selectOrgOnClick = (org: Org) => {
    dispatch(clientActions.setCurrentOrg(org));
    dispatch(authActions.setOrgRoles(org.id));
    nav("/");
  };

  useEffect(() => {
    if (isInternal) {
      nav(pages.sessions.path);
    }
  }, [isInternal]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    let timer1: any;
    let timer2: any;
    if (
      profile === undefined &&
      status !== "uninitialized" &&
      status !== "pending"
    ) {
      console.error("data is undefined");
      timer1 = setTimeout(() => {
        if (profile === undefined) {
          getProfile();
        }
      }, 1500);
      timer2 = setTimeout(() => {
        if (profile === undefined) {
          setShowLogout(true);
        }
      }, 2500);
    }
    return () => {
      if (timer1) clearTimeout(timer1);
      if (timer2) clearTimeout(timer2);
    };
  }, [profile, status]);

  return (
    <PreScreenRootStyle sx={{ overflow: "hidden" }}>
      <img src={logo} alt="admire-logo" />
      <Typography variant="h2" fontWeight={"bold"} my={2}>
        {`Welcome, ${first_name ?? ""}`}
      </Typography>
      <Typography variant="subtitle1" fontSize={16} fontWeight={500}>
        Select an organization
      </Typography>
      {profile !== undefined ? (
        <SelectOrgBoxContainer
          sx={{ height: "min-content", maxHeight: 500, overflow: "auto" }}
        >
          {orgs?.length
            ? orgs?.map((o: (typeof orgs)[0]) => {
                return (
                  <Link
                    key={o.id}
                    onClick={() => selectOrgOnClick(o)}
                    color={"inherit"}
                  >
                    <SelectOrgBox>
                      <Typography
                        fontWeight={600}
                        fontSize={isMobile ? 18 : 20}
                      >
                        {o.name}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => selectOrgOnClick(o)}
                        color="white"
                      >
                        Select
                      </Button>
                    </SelectOrgBox>
                  </Link>
                );
              })
            : null}
        </SelectOrgBoxContainer>
      ) : showLogout === true ? (
        <Button
          onClick={() => {
            window.location.href = "/auth/logout";
          }}
        >
          Logout
        </Button>
      ) : (
        <div></div>
      )}
    </PreScreenRootStyle>
  );
};
