import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { NODE_ENV, VITE_API_DOMAIN } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, generatedApis } from "../../state";

type SocketContextType = {
  socket: any | null;
  isConnected: boolean;
};

const SocketContext = createContext<SocketContextType>({
  socket: null,
  isConnected: false,
});

export const useSocket = () => {
  return useContext(SocketContext);
};

export interface SocketMessage {
  tag: string;
  message: string;
  entity_id: number;
}

interface FullMessage extends SocketMessage {
  pushNotification?: string;
  pushUrl?: string;
}
interface RefetchSocketProps {
  tag: any;
  cb: (msg: SocketMessage) => Promise<void> | void;
}
export const useRefetchSocket = ({ tag, cb }: RefetchSocketProps) => {
  const { isConnected, socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const eventTrigger = useCallback(
    async (d: FullMessage) => {
      // eslint-disable-next-line no-console
      console.log("eventTrigger", d);
      const { tag, message, entity_id } = d;

      await cb({ tag, message, entity_id });
    },
    [tag, cb, dispatch],
  );
  useEffect(() => {
    if (!socket || !isConnected) {
      return;
    }

    Object.values(generatedApis).forEach((api) => {
      return dispatch(api.actions.util.invalidateTags([tag]));
    });

    socket.on("message", eventTrigger);
    return () => {
      return socket.off("message", eventTrigger);
    };
  }, [isConnected, socket, tag]);
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket<any, any> | null>(null);
  const token = useSelector(authSelectors.authToken);
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    if (token !== null) {
      if (!VITE_API_DOMAIN && NODE_ENV === "development") return;
      /** per socket.io docs @see  https://socket.io/how-to/use-with-react */
      const URL = NODE_ENV === "production" ? undefined : VITE_API_DOMAIN;
      const socketInstance = io(URL, {
        auth: {
          token,
        },
        addTrailingSlash: false,
      });

      socketInstance.on("connect", () => {
        setIsConnected(true);
      });
      socketInstance.on("disconnect", () => {
        setIsConnected(false);
      });

      setSocket(socketInstance);

      return () => {
        socketInstance.disconnect();
      };
    }
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket, isConnected }}>
      {children}
    </SocketContext.Provider>
  );
};
