import { AppThunk } from "..";
import { authClient, uiActions } from "../states";
import { admin } from "./state";
const { actions } = admin;

export const adminActions = {
  ...actions,
  /**GSuite start */
  getOAuthTokens(params: Record<string, any>): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/google/oauth-code`, params);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Hang tight"));
      } else {
        dispatch(uiActions.showError("failed to getOAuthTokens"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGoogleCalendars(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(`/google/calendars`);
      if (status === 200) {
        dispatch(actions.setGoogleCalendars(data.calendars));
      } else {
        dispatch(uiActions.showError("failed to getGoogleCalendars"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /**GSuite End */
};
