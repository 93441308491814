import { ReactNode } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { DragIcon } from "../";
import { useTheme } from "@mui/material";
import { createPortal } from "react-dom";
interface DragItemProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}
interface Props {
  children: ReactNode;
  draggableId: string;
  idx: number;
  style?: React.CSSProperties | undefined;
  hideDrag?: boolean;
}
export const DraggableItem = ({
  hideDrag,
  children,
  draggableId,
  idx,
  style,
}: Props) => {
  const { palette } = useTheme();
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    width: "100%",
    background: isDragging ? palette.primary[50] : "#FFF",
    boxShadow: isDragging ? "0 2px 12px 0 #DADFF6, 0 2px 40px 0 #F9FAFC" : " ",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    padding: "12px",
    height: "100%",
    border: `1px solid ${palette.grey[600]}`,
    backgroundColor: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...style,
    ...draggableStyle,
  });

  const portal = document.createElement("div");
  document.body.appendChild(portal);

  const DragItem = ({ provided, snapshot }: DragItemProps) => (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      {children}
      <div
        {...provided.dragHandleProps}
        style={{
          display: hideDrag ? "none !important" : "block",
          visibility: hideDrag ? "hidden" : "visible",
        }}
      >
        <DragIcon size={20} />
      </div>
    </div>
  );
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={idx}
      isDragDisabled={hideDrag}
    >
      {(provided, snapshot) => {
        // dnd has issues in modal... https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-840196054
        if (snapshot.isDragging) {
          return createPortal(
            <DragItem provided={provided} snapshot={snapshot} />,
            portal,
          );
        }
        return <DragItem provided={provided} snapshot={snapshot} />;
      }}
    </Draggable>
  );
};
