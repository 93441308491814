import {
  debounce,
  Grid,
  TextField,
  InputAdornment,
  Tab,
  Button,
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { ChangeEvent, useCallback } from "react";
import { TabsStyled } from "../styled";
import { Navigation, useLocation } from "../../lib";
import { useMobile } from "../../themes";
import { AddIcon, SearchIcon } from "../icons";
import { Link } from "react-router-dom";
export interface ToolBarProps {
  showSearchBar: boolean;
  showFilterOptions: boolean;
  handleQuickSearch?: (_event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  tabViewNames?: readonly string[];
  addButton?: { name: string; path: string };
  hideToolbar: boolean;
  hideExport: boolean;
}

export const ToolBar = ({
  showSearchBar = false,
  showFilterOptions = false,
  handleQuickSearch,
  placeholder = "Search",
  tabViewNames = [],
  addButton,
  hideToolbar = false,
  hideExport = false,
}: ToolBarProps) => {
  const location = useLocation();
  const isMobile = useMobile();
  const handleTabChange = useCallback(
    (_a: unknown, tab: string) => {
      if (tabViewNames.length) {
        Navigation.replace(location.pathname, {
          query: {
            tab,
          },
        });
      }
    },
    [location.pathname, location.query],
  );

  return (
    <>
      {!hideToolbar ? (
        <GridToolbarContainer
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          {tabViewNames?.length ? (
            <TabsStyled
              value={location.query.tab || tabViewNames[0]}
              onChange={handleTabChange}
            >
              {tabViewNames.map((n) => {
                return <Tab key={n} label={n} value={n} />;
              })}
            </TabsStyled>
          ) : null}

          {showSearchBar && handleQuickSearch && (
            <Grid item>
              <TextField
                sx={{ width: 300 }}
                placeholder={placeholder}
                onChange={debounce(handleQuickSearch, 300)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {showFilterOptions && (
            <Grid item>
              <GridToolbarFilterButton
                slotProps={{
                  arrow: {
                    sx: {
                      fontSize: "12px !important",
                      "& .MuiIconButton-root, .MuiSvgIcon-root": {
                        fontSize: "12px !important",
                      },
                    },
                  },
                  popper: {
                    sx: {
                      fontSize: "12px !important",
                      "& .MuiIconButton-root, .MuiSvgIcon-root": {
                        fontSize: "12px !important",
                      },
                    },
                  },
                }}
                sx={{
                  "& .MuiIconButton-root, .MuiSvgIcon-root": {
                    fontSize: "20px",
                  },
                }}
              />
              <GridToolbarColumnsButton />
              {!hideExport && <GridToolbarExport />}
              {addButton?.name && addButton?.path ? (
                <Link
                  style={{ width: isMobile ? "100%" : undefined }}
                  to={addButton.path}
                >
                  <Button variant="contained" color="primary">
                    <AddIcon /> {`Add ${addButton.name}`}
                  </Button>
                </Link>
              ) : null}
            </Grid>
          )}
        </GridToolbarContainer>
      ) : null}
    </>
  );
};
