import { styled, Box } from "@mui/material";

export const PopoverDetailsContentStyled = styled(Box)(() => ({
  padding: "20px 16px 20px",
  maxHeight: "350px",
  overflowY: "auto",
}));

export const PopoverFormContentStyled = styled(Box)(() => ({
  padding: "24px 20px 24px",
}));

export const PopoverDetailsHeaderStyled = styled(Box)(() => ({
  marginBottom: "8px",
}));

export const PopoverFormHeaderStyled = styled(Box)(() => ({
  marginBottom: "16px",
}));
