import { TaskCard, CardStyled } from "../";
import {
  useGetOrgTasksQuery,
  GetOrgTasksApiArg,
} from "../../state/rtk-query/state/organization";
import { Stack, Pagination, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useQueryParam, useTaskDrawerIsOpen } from "../hooks";
import { useTaskParams } from "../hooks/useTaskParams";
import { useMobile } from "../../themes";
import { Emptiable, Loadable } from "../misc";
import { useStatusesList } from "../hooks/useLists";
import { TaskFilters } from "../misc/TaskFilters";
import { authSelectors, useSelector } from "../../state";
interface Props {
  orgId: number;
  limit?: number;
}
export function TasksView({ orgId, limit = 5 }: Props) {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const [openTask] = useQueryParam("task");
  const [initialLoad, setInitialLoad] = useState(true);
  const drawerIsOpen = useTaskDrawerIsOpen();
  const userId = useSelector(authSelectors.userId);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const shouldDefaultToSelf = isConsultant || isCW;

  const [params, setParams] = useTaskParams();

  const {
    taskAssignees,
    dateRange,
    taskStatuses,
    taskTopics,
    taskType,
    taskOpenTasks,
    taskAwaiting,
    page,
  } = params;

  const { statuses, getStatusByName } = useStatusesList("all_tasks");
  const openStatuses = statuses.filter((s) => s.transition_phase !== "done");

  const params_ = useMemo(() => {
    const range = [page ? page - 1 : 0, limit];
    const statuses = taskStatuses?.length
      ? taskStatuses?.map((s) => getStatusByName(s)).flat()
      : taskOpenTasks !== false
      ? openStatuses.map((s) => s.id)
      : undefined;

    return {
      type: taskType,
      assignees: taskAssignees,
      awaiting: taskAwaiting,
      dateRange,
      topics: taskTopics,
      orgId,
      range,
      statuses,
    } as GetOrgTasksApiArg;
  }, [params, orgId, limit, userId, shouldDefaultToSelf]);
  const {
    data: tasks,
    isFetching,
    isSuccess,
  } = useGetOrgTasksQuery(params_, {
    refetchOnMountOrArgChange: !drawerIsOpen,
  });

  useEffect(() => {
    if (isFetching && initialLoad) {
      setInitialLoad(false);
    }
  }, [isFetching]);

  const hasFilter = Boolean(
    Object.entries(params).filter(
      ([key, val]) => !["page", "openTasks"].includes(key) && val !== undefined,
    ).length,
  );

  const selectedProps = useMemo(
    () => ({
      backgroundColor: palette.primary[50],
      cursor: "pointer",
      borderColor: palette.primary[400],
    }),
    [],
  );

  return (
    <Stack gap={3} height={"100%"} justifyContent={"space-between"}>
      <TaskFilters orgId={orgId} isTable={false} />
      <Loadable isLoading={isFetching && initialLoad}>
        <Emptiable
          type="tasks"
          hasFilters={Boolean(hasFilter)}
          isEmpty={isSuccess && !tasks?.rows?.length}
          orgId={orgId}
        >
          <Stack
            flexGrow={1}
            overflow={"auto"}
            sx={{
              "::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Stack justifyContent={"flex-start"} gap={2}>
              {tasks?.rows.map((t) => {
                return (
                  <CardStyled
                    key={t.id}
                    sx={{
                      ...(t.id === openTask ? selectedProps : {}),
                      "&:hover": selectedProps,
                    }}
                  >
                    <TaskCard task={t} />
                  </CardStyled>
                );
              })}
            </Stack>
          </Stack>
        </Emptiable>
      </Loadable>
      <Stack
        height="auto"
        direction={"row"}
        justifyContent={isMobile ? "center" : "flex-end"}
        pb={isMobile ? 2 : 4}
      >
        <Pagination
          count={Math.ceil(Number(tasks?.count ?? 0) / limit)}
          onChange={(e, p) => {
            setParams({ page: p });
          }}
          page={params.page || 1}
          color="primary"
          shape="rounded"
          size={isMobile ? "small" : "medium"}
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "1em",
              "& .MuiSvgIcon-root": {
                fontSize: "1em",
              },
            },
          }}
        />
      </Stack>
    </Stack>
  );
}
