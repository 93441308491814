import {
  AdminTimeFiltersCard,
  AdminTimeTable,
  CardStyled,
  ChartIcon,
  ListIcon,
  SortableButton,
  useQuery,
} from "../../../components";
import { AdminTimeChart } from "../../../components/charts";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useCallback, useEffect, useMemo } from "react";
import { filterBoolean } from "../../../lib";
import { GetTimeSpentOnAllOrgsApiArg } from "../../../state/rtk-query/state/timer";

type ViewType = "chart" | "table";
type FilterType = GetTimeSpentOnAllOrgsApiArg["filterTime"];
interface QueryParams {
  time_filter: FilterType;
  dateRange: DateRange<any> | undefined;
  view: ViewType;
}
export const AdminTimerPage = () => {
  const [query, setQuery] = useQuery<QueryParams>(
    "dateRange",
    "view",
    "time_filter",
  );
  const { view, dateRange, time_filter } = query;
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );
  useEffect(() => {
    if (!view) setQuery({ view: "chart" });
    if (!time_filter) {
      setQuery({
        time_filter: view === "chart" || !view ? "below_avg" : "all_orgs",
      });
    }
  }, [view, time_filter]);

  const handleView = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newView: ViewType) => {
      if (newView !== null) {
        setQuery({ view: newView, time_filter: undefined });
      }
    },
    [query],
  );
  return (
    <Stack direction={"column"} gap={4} height={"100%"}>
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <SortableButton
          title={"All dates"}
          onChange={(range) => {
            setQuery({
              dateRange: range[0] && range[1] ? range : undefined,
              view,
            });
          }}
          isDropDown
          isDate
          currentSort={dateRange as any as Date[]}
          removeTitle={hasDateRange}
        />
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          color="info"
        >
          <ToggleButton value="chart">
            <ChartIcon />
          </ToggleButton>

          <ToggleButton value="table">
            <ListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <AdminTimeFiltersCard />

      {view === "chart" ? (
        <CardStyled
          sx={{
            px: 2,
            pb: 0,
            gap: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "auto",
            position: "relative",
          }}
        >
          <AdminTimeChart />
        </CardStyled>
      ) : (
        <AdminTimeTable />
      )}
    </Stack>
  );
};
