import { Box, Button, Typography } from "@mui/material";
import { Fragment, memo, useEffect, useState } from "react";
//local
import {
  AddIcon,
  ConfirmDeleteDialog,
  EditIcon,
  Modal,
} from "../../../components";

import { actions } from "../../../state/rtk-query";

import { MicrosoftOutlookEvent } from "./MicrosoftOutlookEvent";

const {
  useGetOutlookCalendarUrlQuery,
  useDeleteOutlookCalendarEventMutation,
  useGetOutlookCalendarEventsQuery,
} = actions;
export const MicrosoftOutlookCalendarPage = memo(function () {
  const { currentData: outlookCalendarEvents } =
    useGetOutlookCalendarEventsQuery() ?? {};
  const { currentData } = useGetOutlookCalendarUrlQuery() ?? {};
  const [deleteOutlookEvent] = useDeleteOutlookCalendarEventMutation() ?? {};
  const URL = currentData ? currentData?.oauthRedirectUrl : "";
  const [openModal, setOpenModal] = useState(false);
  const [eventID, setEventID] = useState("");
  const [eventData, setEventData] = useState();
  const iFrame = document?.getElementById("calendar") as HTMLIFrameElement;
  const updateEvent = (data: any) => {
    setEventData(data);
    setEventID(data.id);
    setOpenModal(true);
  };

  const deleteEvent = (id: string) => {
    deleteOutlookEvent({ id });
    if (iFrame) iFrame.src = URL; //refresh iframe
  };

  useEffect(() => {
    if (!openModal) {
      if (iFrame) iFrame.src = URL; //refresh iframe
      setEventData(undefined);
      setEventID("");
    }
  }, [openModal]);

  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          <AddIcon /> Add Event
        </Button>
      </Box>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <MicrosoftOutlookEvent
          closeModal={() => setOpenModal(false)}
          eventID={eventID}
          eventData={eventData}
          isEdit={!!eventData}
        />
      </Modal>
      <Box>
        {outlookCalendarEvents?.value &&
          outlookCalendarEvents?.value.length > 0 && (
            <Typography variant="h1" fontSize={18} fontWeight={500}>
              List of Events
            </Typography>
          )}
        {outlookCalendarEvents?.value?.map((c: any, idx) => {
          const startLocal = `${c.start.dateTime}Z`;
          const startUTC = c.start.dateTime;
          const startDate = c.isAllDay
            ? new Date(startUTC).toLocaleDateString()
            : new Date(startLocal).toLocaleDateString();
          const startTime = c.isAllDay
            ? new Date(startUTC).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              })
            : new Date(startLocal).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
          return (
            <div
              key={idx}
              style={{
                display: "flex",
                width: "500px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ paddingRight: 10 }}>
                {`${startDate}: ${c.subject} at ${startTime}`}
              </div>
              <div>
                <Button>
                  <EditIcon onClick={() => updateEvent(c)} />
                </Button>
                <ConfirmDeleteDialog handleConfirm={() => deleteEvent(c.id)} />
              </div>
            </div>
          );
        })}
      </Box>
      <iframe
        id="calendar"
        style={{
          width: "100%",
          height: 800,
          overflow: "hidden",
          border: "none",
        }}
        src={URL}
      />
    </Fragment>
  );
});
