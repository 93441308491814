import { useState, useEffect, useCallback } from "react";
import {
  LazyQueryTrigger,
  MutationTrigger,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ConfirmDeleteModal } from "../tables";

export namespace QuickbooksLoginModal {
  export interface Props {
    open: boolean;
    handleClose: () => void;
    apiCall: () => ReturnType<MutationTrigger<any> | LazyQueryTrigger<any>>;
  }
}

export function QuickbooksLoginModal({
  open,
  handleClose,
  apiCall,
}: QuickbooksLoginModal.Props) {
  const [isError, setIsError] = useState(false);
  const call = async () => {
    const res = await apiCall();
    if (
      "error" in res &&
      res.error &&
      typeof res.error === "object" &&
      "status" in res.error &&
      res.error.status === 411
    ) {
      setIsError(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    open && call();
  }, [open]);

  if (isError) {
    return (
      <QuickbooksLogin
        handleClose={(retry) => {
          handleClose();
          setIsError(false);
          retry && apiCall();
        }}
      />
    );
  }
  return null;
}

function QuickbooksLogin({
  handleClose,
}: {
  handleClose: (retry: boolean) => void;
}) {
  const [open, setOpen] = useState(true);
  const [oauthWindow, setOauthWindow] = useState<WindowProxy | null>(null);

  const closePopup = useCallback(() => {
    oauthWindow?.close();
    setOauthWindow(null);
  }, [oauthWindow, setOauthWindow]);

  const close = useCallback(
    (windowClosed?: boolean) => {
      handleClose(windowClosed === true);
      closePopup();
      setOpen(false);
    },
    [handleClose, setOpen, closePopup],
  );

  useEffect(() => {
    if (oauthWindow) {
      const interval = setInterval(() => {
        if (oauthWindow.closed) {
          close(true);
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [oauthWindow, close]);

  return (
    <ConfirmDeleteModal
      open={open}
      handleConfirm={() => {
        if (!oauthWindow || oauthWindow.closed) {
          const width = 600;
          const height = 700;
          const left = window.innerWidth / 2 - width / 2;
          const top = window.innerHeight / 2 - height / 2;
          const popup = window.open(
            "/api/V1/quickbooks/auth",
            "OAuthWindow",
            `width=${width},height=${height},top=${top},left=${left}`,
          );
          setOauthWindow(popup);
        } else {
          oauthWindow.focus();
        }
      }}
      onClose={close}
      confirmActionButtonColor="primary"
      confirmActionButtonText="Sign in"
      titleText="QuickBooks sign in"
      subTitleText="Please sign in with QuickBooks to continue."
      pcWidth={"40%"}
    />
  );
}
