import {
  Tooltip as MuiTooltip,
  Box,
  Typography,
  Popover as MuiPopover,
  TooltipProps,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
interface Props {
  children: ReactNode | ReactElement<any, any>;
  title: string | ReactNode;
}
export const Popover = ({ children, title }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Box>
      <MuiPopover
        id={"mouse-over-popover"}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 0.75 }}>{title}</Typography>
      </MuiPopover>
    </div>
  );
};

export const Tooltip = ({
  children,
  title,
  props,
  backgroundColor,
}: Props & { props?: Partial<TooltipProps>; backgroundColor?: string }) => {
  return (
    <MuiTooltip
      title={title}
      arrow
      placement={props?.placement ?? "top"}
      componentsProps={{
        ...props,
        tooltip: {
          sx: {
            fontSize: "12px",
            fontWeight: 600,
            bgcolor: backgroundColor ?? "rgba(53,56,71,0.9)",
            "& .MuiTooltip-arrow": {
              color: "rgba(53,56,71,0.9)",
            },
          },
        },
      }}
    >
      {children as ReactElement<any, any>}
    </MuiTooltip>
  );
};
