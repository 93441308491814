import {
  ActiveElement,
  Chart,
  ChartDataset,
  ChartEvent,
  ChartOptions,
  TooltipModel,
} from "chart.js";

// for now the chart is always in 12 month increments

export const HORIZONTAL_BAR_THICKNESS = 12;

export const defaultBarOptions: ChartOptions<"bar"> = {
  maintainAspectRatio: false, // allows us to set height
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        borderRadius: 12,
        usePointStyle: true,
      },
    },
  },

  scales: {
    x: {
      grid: { display: false },
      border: { display: false },
    },
    y: {
      grid: { display: false },
      border: { display: false },
    },
  },
};

export const baseBarDataSet = ({
  label,
  backgroundColor,
  pointStyle = "rectRounded",
  data,
  ...rest
}: ChartDataset<"bar">) => ({
  type: "bar" as const,
  label,
  backgroundColor,
  data,
  pointStyle: pointStyle,
  borderWidth: 0,
  borderRadius: 10,
  ...rest,
});
//https://chatgpt.com/share/b34942be-44b4-4bc2-afff-d12c98cccf50

export const createDiagonalGradient = (ctx: CanvasRenderingContext2D) => {
  const patternCanvas = document.createElement("canvas");
  patternCanvas.width = 40;
  patternCanvas.height = 40;
  const patternContext = patternCanvas.getContext("2d") as any;

  patternContext.fillStyle = "rgba(0, 0, 0, 0.3)";
  patternContext.fillRect(0, 0, patternCanvas.width, patternCanvas.height);

  patternContext.strokeStyle = "grey";
  patternContext.lineWidth = 3;

  // Drawing more diagonal lines
  for (let i = -40; i < 40; i += 8) {
    patternContext.beginPath();
    patternContext.moveTo(i, 0);
    patternContext.lineTo(i + 40, 40);
    patternContext.stroke();
  }

  const pattern = ctx.createPattern(patternCanvas, "repeat");
  return pattern;
};

export const getOrCreateTooltip = (
  chart: Chart,
  tooltip: TooltipModel<any>,
): HTMLDivElement => {
  let tooltipEl = chart.canvas.parentNode?.querySelector(
    "div",
  ) as HTMLDivElement;

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.borderRadius = "6px";
    tooltipEl.style.boxShadow = "0 1px 4px 1px rgba(99,100,105,0.2)";

    tooltipEl.style.backgroundColor = "white";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    tooltipEl.style.minWidth = "190px";
    tooltipEl.style.width = "100%";
    tooltipEl.style.maxWidth = "max-content";

    const container = document.createElement("div");
    const containerRect = container.getBoundingClientRect();

    const centerX = ((tooltip as any)?._eventPosition?.x ?? 200) + 140;

    const centerY =
      tooltip.caretY +
      containerRect.top -
      tooltipEl.offsetHeight -
      HORIZONTAL_BAR_THICKNESS;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1";
    tooltipEl.style.zIndex = "20";
    tooltipEl.style.left = `${centerX}px`;
    tooltipEl.style.top = `${centerY}px`;
    tooltipEl.appendChild(container);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;

  return tooltipEl;
};

export const onHoverUpdateCursor = (
  event: ChartEvent,
  chartElement: ActiveElement[],
  isAdmin: boolean,
) => {
  if (!event.native?.target || !isAdmin) return;
  if (chartElement.length === 1) {
    (event.native.target as any).style.cursor = "pointer";
  } else {
    (event.native.target as any).style.cursor = "default";
  }
};

// https://www.chartjs.org/docs/latest/developers/api.html#getelementsateventformode-e-mode-options-usefinalposition
export const onClickGetLabel = (event: any) => {
  const points = event.chart.getElementsAtEventForMode(
    event,
    "nearest",
    { intersect: true },
    true,
  );

  if (points.length) {
    const firstPoint = points[0];
    return event.chart.data.labels[firstPoint.index];
  }
};
