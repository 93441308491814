import { Grid, Typography, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { PaperDropzone } from "../../../components/inputs/PaperDropzone";
import { useUploadSharePointFileMutation } from "../../../state/rtk-query/state/microsoft";

export type UploadType = "single" | "bulk";
type Prop = { closeModal: () => void };
export const MicrosoftSharePointUpload = ({ closeModal }: Prop) => {
  const initialValues = {
    file: [],
  };

  const [uploadFiles] = useUploadSharePointFileMutation();
  const handleSubmit = async ({ file }: { file?: string[] }) => {
    if (Array.isArray(file) && file.length === 1) {
      const formData = new FormData();
      formData.append("file", file[0]);
      await uploadFiles({ body: { file: formData } });
      closeModal();
    }
  };

  return (
    <Grid
      container
      spacing={12}
      direction="row"
      justifyContent="center"
      paddingBottom={3}
    >
      <Grid item>
        <Typography variant="h3">Upload File to SharePoint</Typography>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Grid alignItems={"center"} container spacing={4}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  gap={2}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <PaperDropzone
                    filesCB={(files: unknown) => setFieldValue("file", files)}
                    fileType={"any"}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
