import { useEffect, memo } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyle,
  LeftPanel,
  RightPanel,
  OutletPanel,
} from "./AuthLayout.styles";
import { useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation, useLocation } from "../../lib";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import sidebar from "../../assets/img/sidebar.png";
import logo from "../../assets/img/logo.svg";
import { useMobile } from "../../themes";
import { AuthPages } from "../../pages";

export const AuthLayout = memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  const loading = useSelector(uiSelectors.loading);
  const isMobile = useMobile();
  const { query, pathname } = useLocation();
  useEffect(() => {
    //if user authenticates with MFA backend will change value to false in response (highlight and cmd+shift+f to see ref)
    if (pathname === AuthPages.logout.path) {
      Navigation.go(pathname);
    } else if (isLoggedIn && requiresMfa === false) {
      Navigation.go(query?.after || "/");
    } else if (requiresMfa === true) {
      Navigation.go("/auth/multi-factor-auth");
    }
  }, [isLoggedIn, requiresMfa]);
  return (
    <RootStyle>
      <LeftPanel>
        {isMobile ? (
          <>
            <img src={logo} alt="sidebar" />
            <Typography color={"#FFF"} fontWeight={"bold"} pt={1}>
              Welcome to the Admire Help Center
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "max-content",
            }}
          >
            <img src={sidebar} alt="sidebar" />

            <Box
              position={"absolute"}
              left={"8%"}
              top={"25%"}
              sx={{
                width: "100%",
                transform: "translateY(-50%)",
                display: "block",
                pt: isMobile ? 0 : 2,
              }}
            >
              <Typography
                color={"#FFF"}
                fontWeight={"bold"}
                fontSize={30}
                lineHeight={1.25}
                width={"fit-content"}
              >
                <Box component={"span"} display={"block"}>
                  Welcome to
                </Box>
                <Box component={"span"} display={"block"}>
                  the Admire{" "}
                </Box>
                <Box component={"span"} display={"block"}>
                  Help Center.{" "}
                </Box>
              </Typography>
            </Box>
          </Box>
        )}
      </LeftPanel>
      <RightPanel>
        <OutletPanel>
          <Outlet />
        </OutletPanel>
      </RightPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
