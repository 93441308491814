import { TableCell, TableHead, TableHeadProps, styled } from "@mui/material";

interface TableHeadStyledProps extends TableHeadProps {
  borderTop?: boolean;
  condensed?: boolean;
  shadedHeaders?: boolean;
}

export const TableHeadStyled = styled(TableHead, {
  shouldForwardProp: (prop) =>
    prop !== "borderTop" && prop !== "condensed" && prop !== "shadedHeaders",
})<TableHeadStyledProps>(
  ({ borderTop = false, condensed = false, shadedHeaders = false, theme }) => ({
    "& .MuiTableCell-root": {
      ...(borderTop
        ? { borderTop: `1px solid ${theme.palette.grey[500]}` }
        : {}),
      ...(condensed ? { padding: "10px 12px !important" } : {}),
      ...(shadedHeaders ? { backgroundColor: theme.palette.grey[100] } : {}),
    },
  }),
);

export const TableCellWithInputStyled = styled(TableCell)(() => ({
  "&.MuiTableCell-sizeSmall": {
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  "&.MuiTableCell-sizeMedium": {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}));

export const TableCellWithIconStyled = styled(TableCell)(() => ({
  "&.MuiTableCell-sizeSmall": {
    paddingTop: 0,
    paddingBottom: 0,
    verticalAlign: "middle",
  },
}));
