import React from "react";
import { Button, Box, TextField, Typography } from "@mui/material";
// Assets
// Local
import {
  Form,
  Navigation,
  passwordStrength,
  useFormik,
  useLocation,
  yup,
} from "../../lib";
import { AuthPages } from "./";
import { TextFlexStyle, ContainerStyle, FormStyle } from "./Auth.styles";
import { PasswordMeter } from "./PasswordMeter";
import { actions } from "../../state/rtk-query";
import { useMobile } from "../../themes";
import { uiActions, useDispatch } from "../../state";

const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password1 === value;
    }),
});

export const NewPasswordPage = React.memo(
  /**
   *
   */
  function NewPasswordPage() {
    const { useAuthResetPasswordMutation } = actions;
    const dispatch = useDispatch();
    const [resetPassword] = useAuthResetPasswordMutation();
    const location = useLocation();
    const isMobile = useMobile();
    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        const response = await resetPassword({
          body: {
            newPassword: values.password1,
            token: location.query.token,
          },
        });
        if ("error" in response) {
          console.error(response.error);
        } else {
          dispatch(uiActions.showSuccess("Password successfully updated"));
          Navigation.replace(AuthPages.login);
        }
      },
    });

    // useEffect(() => {
    //   if (Object.keys(frm.errors).length) {
    //     console.error(frm.errors);
    //     console.error("touched", frm);
    //   }
    // }, [frm.errors]);

    return (
      <ContainerStyle>
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            fontWeight={600}
            fontSize={isMobile ? 24 : 28}
          >
            Change password
          </Typography>
          <Typography variant="body1" fontWeight={500} fontSize={16}>
            Your new password must be different than previously used passwords.
          </Typography>
          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <TextField
                  fullWidth
                  id="password1"
                  name="password1"
                  label="New password"
                  type="password"
                  autoComplete="new-password"
                  value={frm.values.password1}
                  onChange={frm.handleChange}
                  onBlur={frm.handleBlur}
                  error={frm.touched.password1 && Boolean(frm.errors.password1)}
                  helperText={frm.touched.password1 && frm.errors.password1}
                />
                <PasswordMeter password={frm.values.password1} />
              </TextFlexStyle>
              <TextFlexStyle>
                <TextField
                  fullWidth
                  id="password2"
                  name="password2"
                  label="Repeat new password"
                  type="password"
                  autoComplete="reenter-new-password"
                  value={frm.values.password2}
                  onChange={frm.handleChange}
                  onBlur={frm.handleBlur}
                  error={frm.touched.password2 && Boolean(frm.errors.password2)}
                  helperText={frm.touched.password2 && frm.errors.password2}
                />
              </TextFlexStyle>

              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  frm.isSubmitting ||
                  passwordStrength(frm.values.password1) <= 2 ||
                  frm.values.password1 !== frm.values.password2
                }
              >
                Reset Password
              </Button>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
