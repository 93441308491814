import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface ChipStyledProps extends Omit<ChipProps, "variant" | "color"> {
  edge: "badge" | "pill";
  shade:
    | "green"
    | "dark-green"
    | "red"
    | "dark-red"
    | "yellow"
    | "dark-yellow"
    | "grey"
    | "primary"
    | "tertiary"
    | "secondary"
    | "lightGrey"
    | "blue"
    | "dark-grey"
    | "orange";
  type: "accent" | "outline" | "solid";
  lighterText?: boolean;
}

export const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== "edge" &&
    prop !== "shade" &&
    prop !== "type" &&
    prop !== "lighterText",
})<ChipStyledProps>(
  ({ theme, edge, shade = "primary", type = "solid", lighterText = false }) => {
    let accentColor = "",
      solidColor = "",
      textColor = "";

    const lightText = lighterText && 500;

    switch (shade) {
      case "orange":
        accentColor = theme.palette.orange[100] ?? theme.palette.info.light;
        solidColor = theme.palette.orange[500] ?? theme.palette.info.main;
        textColor =
          theme.palette.orange[lightText || 900] ?? theme.palette.info.dark;
        break;
      case "dark-grey":
        accentColor = theme.palette.grey[200] ?? theme.palette.text.primary;
        solidColor = theme.palette.grey[900] ?? theme.palette.text.primary;
        textColor = theme.palette.grey[900] ?? theme.palette.text.primary;
        break;
      case "blue":
        accentColor = theme.palette.blue[100] ?? theme.palette.secondary.light;
        solidColor = theme.palette.blue[500] ?? theme.palette.secondary.main;
        textColor = theme.palette.blue[500] ?? theme.palette.secondary.dark;
        break;
      case "green":
        accentColor = theme.palette.success[100] ?? theme.palette.success.light;
        solidColor = theme.palette.success[800] ?? theme.palette.success.dark;
        textColor =
          theme.palette.success[lightText || 900] ?? theme.palette.success.dark;
        break;
      case "dark-green":
        accentColor = theme.palette.success[700] ?? theme.palette.success.dark;
        solidColor = theme.palette.success[900] ?? theme.palette.success.main;
        textColor =
          theme.palette.success[lightText || 50] ?? theme.palette.success.light;
        break;
      case "red":
        accentColor = theme.palette.error[100] ?? theme.palette.error.light;
        solidColor = theme.palette.error[700] ?? theme.palette.error.main;
        textColor =
          theme.palette.error[lightText || 900] ?? theme.palette.error.dark;
        break;
      case "dark-red":
        accentColor = theme.palette.error[700] ?? theme.palette.error.dark;
        solidColor = theme.palette.error[900] ?? theme.palette.error.main;
        textColor =
          theme.palette.error[lightText || 50] ?? theme.palette.error.light;
        break;
      case "yellow":
        accentColor = theme.palette.warning[100] ?? theme.palette.warning.light;
        solidColor = theme.palette.warning[500] ?? theme.palette.warning.main;
        textColor =
          theme.palette.warning[lightText || 900] ?? theme.palette.warning.dark;
        break;
      case "dark-yellow":
        accentColor = theme.palette.warning[600] ?? theme.palette.warning.dark;
        solidColor = theme.palette.warning[800] ?? theme.palette.warning.main;
        textColor = theme.palette.warning[50] ?? theme.palette.warning.light;
        break;
      case "grey":
        accentColor = theme.palette.grey[500] ?? theme.palette.error.main;
        solidColor = theme.palette.black[500] ?? theme.palette.common.black;
        textColor = theme.palette.black[500] ?? theme.palette.common.black;
        break;
      case "lightGrey":
        accentColor = theme.palette.grey[100] ?? theme.palette.error.main;
        solidColor = theme.palette.grey[500] ?? theme.palette.common.black;
        textColor =
          theme.palette.grey[lightText || 900] ?? theme.palette.common.black;
        break;
      case "tertiary":
        accentColor = theme.palette.info[100] ?? theme.palette.info.light;
        solidColor = theme.palette.info[600] ?? theme.palette.info.main;
        textColor =
          theme.palette.info[lightText || 900] ?? theme.palette.info.dark;
        break;
      case "secondary":
        accentColor =
          theme.palette.secondary[100] ?? theme.palette.secondary.light;
        solidColor =
          theme.palette.secondary[800] ?? theme.palette.secondary.main;
        textColor =
          theme.palette.secondary[lightText || 900] ??
          theme.palette.secondary.dark;
        break;
      case "primary":
      default:
        accentColor = theme.palette.primary[100] ?? theme.palette.primary.light;
        solidColor = theme.palette.primary[500] ?? theme.palette.primary.main;
        textColor = theme.palette.primary[500] ?? theme.palette.primary.dark;
        break;
    }

    const styles = {
      border: "none",
      borderRadius: edge === "badge" ? "4px" : "34px",
      backgroundColor: solidColor,
      color: theme.palette.background.default,
    };

    switch (type) {
      case "accent":
        styles.backgroundColor = accentColor;
        styles.color = textColor;
        break;
      case "outline":
        styles.backgroundColor = theme.palette.background.default;
        styles.color = textColor;
        styles.border = `2px solid ${accentColor}`;
        break;
      default:
        styles.backgroundColor = theme.palette.background.default;
        styles.color = textColor;
    }

    return styles;
  },
);
