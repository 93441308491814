import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetUserProfileApiResponse } from "../rtk-query/state/user";

export type Org = Exclude<GetUserProfileApiResponse["orgs"][number], undefined>;
export type Orgs = Org[];

export interface ClientState {
  org?: Org;
  support: {
    screen:
      | "AddTask"
      | "TypeSelection"
      | "TypeSelectionInternal"
      | "SessionSelection"
      | "CreateSession"
      | "CreatePendingSession"
      | "AddCustomWork"
      | "AddQuickQuestion"
      | "AddInternalTask"
      | "RequestSubmitted"
      | "MoveTask"
      | "MoveTaskComplete"
      | "Done"
      | "ApplyTemplateToOrg";
    sessionId: number | undefined;
    taskId: number | undefined;
  };
  taskPreference: "modal" | "drawer";
}

const initialState: ClientState = {
  org: undefined,
  support: { screen: "TypeSelection", sessionId: undefined, taskId: undefined },
  taskPreference: "drawer",
};

export const client = {
  ...createSlice({
    name: "client",
    initialState,
    reducers: {
      setOrg(state, { payload }: PayloadAction<Org | undefined>) {
        if (!payload) {
          return initialState;
        }
        state.org = payload;
      },
      setSupportScreen(
        state,
        { payload }: PayloadAction<ClientState["support"]["screen"]>,
      ) {
        state.support = { ...state.support, screen: payload };
      },
      setSession(
        state,
        { payload }: PayloadAction<ClientState["support"]["sessionId"]>,
      ) {
        state.support = { ...state.support, sessionId: payload };
      },
      setTask(
        state,
        { payload }: PayloadAction<ClientState["support"]["taskId"]>,
      ) {
        state.support = { ...state.support, taskId: payload };
      },
      setTaskPreference(
        state,
        { payload }: PayloadAction<ClientState["taskPreference"]>,
      ) {
        state.taskPreference = payload;
      },
    },
  }),
  persist: true,
};
