import {
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
  Button,
  DialogContentText,
  DialogContent,
  DialogActions,
  SxProps,
  Theme,
  Link,
  Divider,
} from "@mui/material";
import React, { ReactNode, useCallback } from "react";
import { Delete } from "@mui/icons-material";
import { Modal } from "../modal";
import { CloseIcon } from "../icons";
import { useMobile } from "../../themes";
import { PropsOf } from "@emotion/react";

interface Base {
  handleConfirm: () => void;
  titleText?: string;
  subTitleText?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  additionalActions?: ModalProps["additionalActions"];
  modalProps?: Omit<
    Partial<ModalProps>,
    "open" | "handleConfirm" | "onClose" | "additionalActions"
  >;
}
interface ButtonInterface extends Base {
  buttonText?: string;
  buttonColor?: PropsOf<typeof Button>["color"];
}
interface IconButtonInterface extends Base {
  ButtonIcon?: ReactNode;
  buttonColor?: PropsOf<typeof IconButton>["color"];
}
// interface ConfirmDialogProps {
//   handleConfirm: () => void;
//   buttonText?: string;
//   titleText?: string;
//   subTitleText?: string;
//   ButtonIcon?: ReactNode;
//   buttonColor?: string;
//   sx?: SxProps<Theme>;
//   children?: ReactNode;
// }

export type ConfirmDialogProps = ButtonInterface & IconButtonInterface;

export function ConfirmDeleteDialog({
  buttonText,
  buttonColor = "error",
  titleText,
  subTitleText,
  handleConfirm,
  ButtonIcon,
  sx,
  children,
  modalProps,
  additionalActions,
}: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false);

  const confirmClick = () => {
    handleConfirm();
    setOpen(false);
  };
  const cancelClick = useCallback(() => {
    setOpen(false);
  }, []);
  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      {buttonText ? (
        <Button
          onClick={openModal}
          color={buttonColor}
          sx={{
            p: 0,
            m: 0,
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            ...sx,
          }}
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton onClick={openModal} color={buttonColor} size="small">
          {ButtonIcon ? ButtonIcon : <Delete />}
        </IconButton>
      )}
      <ConfirmDeleteModal
        open={open}
        onClose={cancelClick}
        handleConfirm={confirmClick}
        titleText={titleText}
        subTitleText={subTitleText}
        children={children}
        sx={sx}
        additionalActions={additionalActions}
        {...modalProps}
      />
    </>
  );
}

interface ModalProps {
  open: boolean;
  handleConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
  titleText?: string;
  subTitleText?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  confirmActionButtonText?: string;
  confirmActionButtonColor?: PropsOf<typeof Button>["color"];
  skipActionButtonText?: string;
  disableConfirmButton?: boolean;
  pcWidth?: string | number;
  additionalActions?: ReactNode[] | ReactNode;
}
export function ConfirmDeleteModal({
  open,
  titleText,
  subTitleText,
  handleConfirm,
  onClose,
  children,
  confirmActionButtonText = "Delete",
  skipActionButtonText = "Cancel",
  disableConfirmButton = false,
  confirmActionButtonColor = "error",
  pcWidth = "28%",
  additionalActions,
}: ModalProps) {
  const { palette } = useTheme();
  const isMobile = useMobile();

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        pcWidth={pcWidth}
        props={{
          onClick: (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
      >
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          justifyContent={"space-between"}
          alignItems={isMobile ? "flex-end" : "flex-start"}
          width={"100%"}
        >
          <DialogTitle
            sx={{
              wordBreak: "break-word",
              whiteSpace: "break-spaces",
              display: "flex",
              width: "85%",
            }}
          >
            {titleText ? titleText : "Delete Row"}{" "}
          </DialogTitle>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{ pt: 0 }}>
          <DialogContentText
            id="alert-dialog-description"
            fontWeight={"500"}
            color={palette.grey[900]}
          >
            {subTitleText
              ? subTitleText
              : "Please note that this action cannot be undone"}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {additionalActions}
          <Button onClick={onClose} variant="outlined" color="white" autoFocus>
            {skipActionButtonText}
          </Button>
          <Button
            variant="contained"
            color={confirmActionButtonColor}
            onClick={handleConfirm}
            disabled={disableConfirmButton}
          >
            {confirmActionButtonText}
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
}

interface ConfirmationModalProps {
  open: boolean;
  closeOnLinkClick: boolean;
  onClose: () => void;
  title: string;
  subtext?: string;
  linkUrl?: string;
  linkText: string;
}
export function ConfirmationModal({
  open,
  onClose,
  title,
  subtext,
  linkUrl,
  linkText,
  closeOnLinkClick = false,
}: ConfirmationModalProps) {
  const isMobile = useMobile();
  const { palette } = useTheme();
  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        direction={isMobile ? "column-reverse" : "row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <DialogTitle variant="h1">{title}</DialogTitle>
        <IconButton onClick={onClose} sx={{ mr: 2 }}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent sx={{ pt: 0 }}>
        <DialogContentText
          id="custom-dialog-description"
          fontWeight={"500"}
          color={palette.grey[900]}
          lineHeight={"24px"}
        >
          {subtext}
        </DialogContentText>
      </DialogContent>
      <Divider sx={{ width: "100%", my: 2, position: "absolute", left: 0 }} />
      <DialogActions>
        <Button
          sx={{ mt: 2 }}
          component={Link}
          onClick={closeOnLinkClick ? onClose : undefined}
          href={linkUrl}
          target="_blank"
          variant="contained"
          bgcolor={palette.primary.main}
        >
          {linkText}
        </Button>
      </DialogActions>
    </Modal>
  );
}
