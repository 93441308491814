import { api } from "../api";
export const addTagTypes = [
  "tasks",
  "notifications",
  "task_media",
  "task_chat",
  "tasks_chat",
  "tasks_media",
  "media",
  "timer",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTasks: build.query<GetTasksApiResponse, GetTasksApiArg>({
        query: (queryArg) => ({
          url: `/tasks`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            type: queryArg["type"],
            topics: queryArg.topics,
            statuses: queryArg.statuses,
            assignees: queryArg.assignees,
            awaiting: queryArg.awaiting,
            priority: queryArg.priority,
            internal_priority: queryArg.internalPriority,
            orgs: queryArg.orgs,
            filter_by_my_org: queryArg.filterByMyOrg,
            filter_new_comments: queryArg.filterNewComments,
            archived: queryArg.archived,
          },
        }),
        providesTags: ["tasks"],
      }),
      createTask: build.mutation<CreateTaskApiResponse, CreateTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/create`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}`,
        }),
        providesTags: ["tasks", "task_media", "task_chat"],
      }),
      updateTask: build.mutation<UpdateTaskApiResponse, UpdateTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      deleteTask: build.mutation<DeleteTaskApiResponse, DeleteTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["tasks_chat", "tasks", "tasks_media"],
      }),
      updateTaskOrg: build.mutation<
        UpdateTaskOrgApiResponse,
        UpdateTaskOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/change-org`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      toggleTaskActionPending: build.mutation<
        ToggleTaskActionPendingApiResponse,
        ToggleTaskActionPendingApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/action_pending`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      convertTaskToQuickQuestion: build.mutation<
        ConvertTaskToQuickQuestionApiResponse,
        ConvertTaskToQuickQuestionApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/${queryArg.id}/convert/quick-question`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      getCwTasks: build.query<GetCwTasksApiResponse, GetCwTasksApiArg>({
        query: () => ({ url: `/tasks/custom_work` }),
        providesTags: ["tasks"],
      }),
      convertTaskToCustomWork: build.mutation<
        ConvertTaskToCustomWorkApiResponse,
        ConvertTaskToCustomWorkApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/${queryArg.id}/convert/custom-work`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      moveTaskToSession: build.mutation<
        MoveTaskToSessionApiResponse,
        MoveTaskToSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/convert/move-to-session/${queryArg.sessionId}`,
          method: "PUT",
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      convertTask: build.mutation<ConvertTaskApiResponse, ConvertTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/convert`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      updateTaskStatus: build.mutation<
        UpdateTaskStatusApiResponse,
        UpdateTaskStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/status`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      restoreTask: build.mutation<RestoreTaskApiResponse, RestoreTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/restore`,
          method: "POST",
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      updateTaskPriority: build.mutation<
        UpdateTaskPriorityApiResponse,
        UpdateTaskPriorityApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/priority`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
      createTaskInstantZoomMeeting: build.mutation<
        CreateTaskInstantZoomMeetingApiResponse,
        CreateTaskInstantZoomMeetingApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/${queryArg.id}/create-instant-meeting`,
          method: "POST",
        }),
        invalidatesTags: ["tasks", "notifications"],
      }),
      getTaskChats: build.query<GetTaskChatsApiResponse, GetTaskChatsApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/chat`,
        }),
        providesTags: ["tasks_chat", "tasks", "tasks_media"],
      }),
      createTaskChat: build.mutation<
        CreateTaskChatApiResponse,
        CreateTaskChatApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/chat`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "tasks_chat",
          "tasks",
          "tasks_media",
          "notifications",
        ],
      }),
      markTaskChatAsRead: build.mutation<
        MarkTaskChatAsReadApiResponse,
        MarkTaskChatAsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/chat`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks_chat", "tasks", "tasks_media"],
      }),
      deleteTaskMedia: build.mutation<
        DeleteTaskMediaApiResponse,
        DeleteTaskMediaApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/chat/${queryArg.chatId}/media/${queryArg.mediaId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["tasks", "task_media", "tasks_chat"],
      }),
      getTaskWatchers: build.query<
        GetTaskWatchersApiResponse,
        GetTaskWatchersApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/watchers`,
        }),
        providesTags: ["tasks", "tasks_chat", "tasks_media"],
      }),
      getTaskChat: build.query<GetTaskChatApiResponse, GetTaskChatApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/chat/${queryArg.id}`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["tasks_chat", "tasks"],
      }),
      updateTaskChat: build.mutation<
        UpdateTaskChatApiResponse,
        UpdateTaskChatApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/chat/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks_chat", "tasks"],
      }),
      deleteTaskChat: build.mutation<
        DeleteTaskChatApiResponse,
        DeleteTaskChatApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/chat/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["tasks_chat", "tasks"],
      }),
      getMyTasks: build.query<GetMyTasksApiResponse, GetMyTasksApiArg>({
        query: () => ({ url: `/tasks/internal/mine` }),
        providesTags: ["tasks"],
      }),
      getMyTasksInProgress: build.query<
        GetMyTasksInProgressApiResponse,
        GetMyTasksInProgressApiArg
      >({
        query: () => ({ url: `/tasks/progress/mine` }),
        providesTags: ["tasks"],
      }),
      getOrgTasksInProgress: build.query<
        GetOrgTasksInProgressApiResponse,
        GetOrgTasksInProgressApiArg
      >({
        query: (queryArg) => ({ url: `/tasks/${queryArg.orgId}/progress` }),
        providesTags: ["tasks"],
      }),
      getOrgTaskMedia: build.query<
        GetOrgTaskMediaApiResponse,
        GetOrgTaskMediaApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/task-media`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            type: queryArg["type"],
          },
        }),
        providesTags: ["media", "tasks", "task_media", "tasks_chat"],
      }),
      getTaskMedia: build.query<GetTaskMediaApiResponse, GetTaskMediaApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/media`,
        }),
        providesTags: ["tasks_media", "tasks", "tasks_chat"],
      }),
      createTaskMedia: build.mutation<
        CreateTaskMediaApiResponse,
        CreateTaskMediaApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/media`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: [
          "tasks_media",
          "tasks",
          "tasks_chat",
          "notifications",
        ],
      }),
      getOrgTaskActivities: build.query<
        GetOrgTaskActivitiesApiResponse,
        GetOrgTaskActivitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/activities`,
        }),
        providesTags: ["tasks"],
      }),
      getTaskActivities: build.query<
        GetTaskActivitiesApiResponse,
        GetTaskActivitiesApiArg
      >({
        query: (queryArg) => ({ url: `/tasks/${queryArg.id}/activities` }),
        providesTags: ["tasks"],
      }),
      getTaskTimeSpent: build.query<
        GetTaskTimeSpentApiResponse,
        GetTaskTimeSpentApiArg
      >({
        query: (queryArg) => ({ url: `/tasks/${queryArg.id}/time/spent` }),
        providesTags: ["tasks", "timer"],
      }),
      closeTask: build.mutation<CloseTaskApiResponse, CloseTaskApiArg>({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/tasks/${queryArg.id}/close`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetTasksApiResponse = /** status 200 Internal tasks response */ {
  count: number;
  rows: {
    id?: number;
    status_id?: number;
    topic_id?: number;
    org_id?: number;
    title?: string;
    description?: string | null;
    priority?: "normal" | "high" | "critical";
    internal_priority?: "low" | "medium" | "high";
    assigned_to?: number;
    reported_by?: number;
    type?: "task" | "quick_question" | "internal_task" | "custom_work";
    requested_by_date?: string | null;
    instant_meeting_url?: string | null;
    instant_meeting_occurred?: boolean | null;
    client_assignee?: number | null;
    billing_status?: ("no_charge" | "making_standard" | null) | null;
    description_search?: string | null;
    action_pending?: ("admire" | "client" | null) | null;
    code?: string | null;
    allow_org_change?: boolean;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
    task_watchers?: {
      id?: number;
      task_id?: number;
      user_id?: number;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    pending_session_dates?:
      | {
          value?: string | null;
          inclusive?: boolean | null;
        }[]
      | null;
    session_start_time?: string;
    session_title?: string | null;
    session_end_time?: string | null;
    session_duration?: number | null;
    has_active_session?: boolean;
    session_status_id?: number | null;
    task_chat_count?: number;
    media_count?: number;
    new_chats_count?: number;
    time_spent?: number | null;
    /** task_chat schema */
    latest_chat?: {
      id?: number;
      user_id?: number;
      message?: string;
      task_id?: number;
      is_internal?: boolean;
      has_media?: boolean;
      message_search?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } | null;
    assigned_to_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    reported_by_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    client_assignee_user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    } | null;
    /** topics */
    topic?: {
      id?: number;
      internal_name: string;
      name: string;
      description: string;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    org?: {
      id?: number;
      name?: string;
    };
  }[];
};
export type GetTasksApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** task type */
  type?: ("quick_question" | "on_session" | "custom_work" | "unscheduled")[];
  /** topics to filter by */
  topics?: number[];
  /** statuses to filter by */
  statuses?: number[];
  /** assignees to filter by */
  assignees?: number[];
  /** defines action_pending */
  awaiting?: "admire" | "client";
  /** task external priority */
  priority?: ("normal" | "high" | "critical")[];
  /** task internal priority */
  internalPriority?: ("low" | "medium" | "high")[];
  /** orgs to filter by */
  orgs?: number[];
  /** filter by my orgs */
  filterByMyOrg?: boolean;
  /** filter by new comments */
  filterNewComments?: boolean;
  /** get all archived */
  archived?: boolean;
};
export type CreateTaskApiResponse = /** status 200 id */ {
  id: number;
};
export type CreateTaskApiArg = {
  /** organization id */
  orgId: number;
  /** tasks */
  body: {
    id?: number;
    status_id?: number;
    session_id?: number | null;
    topic_id: number;
    title: string;
    description?: string | null;
    priority: "normal" | "high" | "critical";
    assigned_to?: number;
    reported_by?: number;
    client_assignee?: number;
    type: "task" | "quick_question" | "internal_task" | "custom_work";
    requested_by_date?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type GetTaskApiResponse = /** status 200 Show single Task */ {
  id?: number;
  org_id?: number;
  code?: string;
  /** statuses schema */
  status?: {
    id?: number;
    internal_name?: string;
    name?: string;
    description?: string;
    type?:
      | (
          | "all"
          | "session"
          | "all_tasks"
          | "task"
          | "internal_task"
          | "custom_work"
          | "quick_question"
          | null
        )
      | null;
    is_automated?: boolean | null;
    transition_phase?:
      | "todo"
      | "in_progress"
      | "action_required"
      | "done"
      | "blocked";
    color?:
      | "green"
      | "red"
      | "yellow"
      | "grey"
      | "primary"
      | "tertiary"
      | "secondary"
      | "lightGrey";
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
  sessions?:
    | {
        id?: number;
        org_id: number;
        created_by: number;
        client_assignee?: number | null;
        title?: string | null;
        assigned_to?: number;
        status_id: number;
        pending_session_dates?:
          | {
              value?: string | null;
              inclusive?: boolean | null;
            }[]
          | null;
        session_tasks?: {
          is_active?: boolean | null;
        } | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[]
    | null;
  media?: ({
    id?: number;
    path?: string;
    filename?: string | null;
    internal_name?: string;
    label?: string;
    mime_type?: string | null;
    size?: number | null;
    status_id?: number | null;
    status?: string | null;
    uploaded_by?: number;
    created_at?: string | null;
    [key: string]: any;
  } & {
    uploaded_by_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
    };
  } & ({
      task_media?: {
        is_custom_work_manual?: boolean | null;
      };
    } | null))[];
  /** topics */
  topic?: {
    id?: number;
    internal_name: string;
    name: string;
    description: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
  task_chat_count?: number;
  watchers?:
    | {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
      }[]
    | null;
  title: string;
  description?: string | null;
  type?: "task" | "quick_question" | "internal_task" | "custom_work";
  priority: "normal" | "high" | "critical";
  internal_priority?: "low" | "medium" | "high";
  assigned_to_user?: {
    id?: number;
    email: string;
    phone?: string | null;
    first_name: string;
    last_name: string;
    [key: string]: any;
  } & {
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  };
  reported_by_user?: {
    id?: number;
    email: string;
    phone?: string | null;
    first_name: string;
    last_name: string;
    [key: string]: any;
  } & {
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  };
  client_assignee_user?: {
    id?: number;
    email?: string;
    phone?: string | null;
    first_name?: string;
    last_name?: string;
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  } | null;
  requested_by_date?: string | null;
  description_search?: string | null;
  session_tasks?: {
    is_active?: boolean | null;
  } | null;
  billing_status?: ("no_charge" | "making_standard" | null) | null;
  action_pending?: ("admire" | "client" | null) | null;
  instant_meeting_url?: string | null;
  instant_meeting_occurred?: boolean | null;
  linkedTasks?:
    | {
        thisTaskId: number;
        linkedTaskId: number;
        linkTypeId: number;
        linkType?: string | null;
        /** tasks schema */
        linkedTask: {
          id?: number;
          status_id?: number;
          topic_id?: number;
          org_id?: number;
          title?: string;
          description?: string | null;
          priority?: "normal" | "high" | "critical";
          internal_priority?: "low" | "medium" | "high";
          assigned_to?: number;
          reported_by?: number;
          type?: "task" | "quick_question" | "internal_task" | "custom_work";
          requested_by_date?: string | null;
          instant_meeting_url?: string | null;
          instant_meeting_occurred?: boolean | null;
          client_assignee?: number | null;
          billing_status?: ("no_charge" | "making_standard" | null) | null;
          description_search?: string | null;
          action_pending?: ("admire" | "client" | null) | null;
          code?: string | null;
          allow_org_change?: boolean;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        originalRecord?: any;
      }[]
    | null;
  allow_org_change?: boolean;
  is_from_paymo?: boolean | null;
  time_spent?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type GetTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateTaskApiResponse = /** status 204 An empty response */ void;
export type UpdateTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** tasks */
  body: {
    id?: number;
    status_id?: number;
    session_id?: number | null;
    topic_id: number;
    title: string;
    description?: string | null;
    priority: "normal" | "high" | "critical";
    assigned_to?: number;
    reported_by?: number;
    client_assignee?: number;
    type: "task" | "quick_question" | "internal_task" | "custom_work";
    requested_by_date?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type DeleteTaskApiResponse = /** status 204 An empty response */ void;
export type DeleteTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateTaskOrgApiResponse = /** status 204 An empty response */ void;
export type UpdateTaskOrgApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** update task org */
  body: {
    org_id: number;
  };
};
export type ToggleTaskActionPendingApiResponse =
  /** status 204 An empty response */ void;
export type ToggleTaskActionPendingApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** task toggle action pending */
  body: {
    action_pending?: ("admire" | "client" | null) | null;
  };
};
export type ConvertTaskToQuickQuestionApiResponse =
  /** status 204 An empty response */ void;
export type ConvertTaskToQuickQuestionApiArg = {
  /** record id */
  id: number;
  /** update task to quick question */
  body: {
    status_id: number;
    priority: "normal" | "high" | "critical";
  };
};
export type GetCwTasksApiResponse = /** status 200 Generic list */ {
  count: number;
  rows: {
    id: number;
    name: string;
    org_id?: number;
  }[];
};
export type GetCwTasksApiArg = void;
export type ConvertTaskToCustomWorkApiResponse =
  /** status 204 An empty response */ void;
export type ConvertTaskToCustomWorkApiArg = {
  /** record id */
  id: number;
  /** update task to custom work */
  body: {
    status_id: number;
    requested_by_date: string;
  };
};
export type MoveTaskToSessionApiResponse =
  /** status 204 An empty response */ void;
export type MoveTaskToSessionApiArg = {
  /** record id */
  id: number;
  /** session id */
  sessionId: number;
  /** organization id */
  orgId: number;
};
export type ConvertTaskApiResponse = /** status 200 id */ {
  id: number;
};
export type ConvertTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** update task to custom work */
  body:
    | {
        type: "custom_work";
        requested_by_date?: string | null;
      }
    | {
        type: "task";
        session_id: number;
      }
    | {
        type: "quick_question";
      };
};
export type UpdateTaskStatusApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTaskStatusApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** update status */
  body: {
    status_id: number;
  };
};
export type RestoreTaskApiResponse = /** status 204 An empty response */ void;
export type RestoreTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateTaskPriorityApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTaskPriorityApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** update priority */
  body:
    | {
        type: "external";
        priority: "normal" | "high" | "critical";
      }
    | {
        type: "internal";
        priority: "low" | "medium" | "high";
      };
};
export type CreateTaskInstantZoomMeetingApiResponse =
  /** status 204 An empty response */ void;
export type CreateTaskInstantZoomMeetingApiArg = {
  /** record id */
  id: number;
};
export type GetTaskChatsApiResponse = /** status 200 TaskChat */ {
  count: number;
  rows: {
    id?: number;
    user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    task_media?: {
      chat_id?: number;
      media?: {
        filename?: string;
        id?: number;
      };
    }[];
    message: string;
    task_id: number;
    is_internal: boolean;
    has_media: boolean;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTaskChatsApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type CreateTaskChatApiResponse = /** status 200 id */ {
  id: number;
};
export type CreateTaskChatApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** task_chat */
  body: {
    id?: number;
    user_id?: number;
    message: string;
    task_id?: number;
    is_internal: boolean;
    has_media?: boolean | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type MarkTaskChatAsReadApiResponse =
  /** status 204 An empty response */ void;
export type MarkTaskChatAsReadApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** notifications */
  body: {
    is_read?: boolean;
  };
};
export type DeleteTaskMediaApiResponse =
  /** status 204 An empty response */ void;
export type DeleteTaskMediaApiArg = {
  /** record id */
  id: number;
  /** chat id */
  chatId: number;
  /** media id */
  mediaId: number;
  /** organization id */
  orgId: number;
};
export type GetTaskWatchersApiResponse =
  /** status 200 Multiple user response with only simple data */ {
    count: number;
    rows: ({
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    })[];
  };
export type GetTaskWatchersApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetTaskChatApiResponse = /** status 200 Show single TaskChat */ {
  id?: number;
  user?: {
    id?: number;
    email: string;
    phone?: string | null;
    first_name: string;
    last_name: string;
    [key: string]: any;
  } & {
    roles?:
      | {
          internal_name?: string;
        }[]
      | null;
  };
  task_media?: {
    chat_id?: number;
    media?: {
      filename?: string;
      id?: number;
    };
  }[];
  message: string;
  task_id: number;
  is_internal: boolean;
  has_media: boolean;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type GetTaskChatApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateTaskChatApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTaskChatApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** task_chat */
  body: {
    id?: number;
    user_id?: number;
    message: string;
    task_id?: number;
    is_internal: boolean;
    has_media?: boolean | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type DeleteTaskChatApiResponse =
  /** status 204 An empty response */ void;
export type DeleteTaskChatApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetMyTasksApiResponse = /** status 200 Task */ {
  count: number;
  rows: {
    id?: number;
    org_id?: number;
    code?: string;
    /** statuses schema */
    status?: {
      id?: number;
      internal_name?: string;
      name?: string;
      description?: string;
      type?:
        | (
            | "all"
            | "session"
            | "all_tasks"
            | "task"
            | "internal_task"
            | "custom_work"
            | "quick_question"
            | null
          )
        | null;
      is_automated?: boolean | null;
      transition_phase?:
        | "todo"
        | "in_progress"
        | "action_required"
        | "done"
        | "blocked";
      color?:
        | "green"
        | "red"
        | "yellow"
        | "grey"
        | "primary"
        | "tertiary"
        | "secondary"
        | "lightGrey";
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    sessions?:
      | {
          id?: number;
          org_id: number;
          created_by: number;
          client_assignee?: number | null;
          title?: string | null;
          assigned_to?: number;
          status_id: number;
          pending_session_dates?:
            | {
                value?: string | null;
                inclusive?: boolean | null;
              }[]
            | null;
          session_tasks?: {
            is_active?: boolean | null;
          } | null;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        }[]
      | null;
    media?: ({
      id?: number;
      path?: string;
      filename?: string | null;
      internal_name?: string;
      label?: string;
      mime_type?: string | null;
      size?: number | null;
      status_id?: number | null;
      status?: string | null;
      uploaded_by?: number;
      created_at?: string | null;
      [key: string]: any;
    } & {
      uploaded_by_user?: {
        id?: number;
        email: string;
        phone?: string | null;
        first_name: string;
        last_name: string;
      };
    } & ({
        task_media?: {
          is_custom_work_manual?: boolean | null;
        };
      } | null))[];
    /** topics */
    topic?: {
      id?: number;
      internal_name: string;
      name: string;
      description: string;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    task_chat_count?: number;
    watchers?:
      | {
          id?: number;
          email: string;
          phone?: string | null;
          first_name: string;
          last_name: string;
        }[]
      | null;
    title: string;
    description?: string | null;
    type?: "task" | "quick_question" | "internal_task" | "custom_work";
    priority: "normal" | "high" | "critical";
    internal_priority?: "low" | "medium" | "high";
    assigned_to_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    reported_by_user?: {
      id?: number;
      email: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
      [key: string]: any;
    } & {
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    };
    client_assignee_user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      roles?:
        | {
            internal_name?: string;
          }[]
        | null;
    } | null;
    requested_by_date?: string | null;
    description_search?: string | null;
    session_tasks?: {
      is_active?: boolean | null;
    } | null;
    billing_status?: ("no_charge" | "making_standard" | null) | null;
    action_pending?: ("admire" | "client" | null) | null;
    instant_meeting_url?: string | null;
    instant_meeting_occurred?: boolean | null;
    linkedTasks?:
      | {
          thisTaskId: number;
          linkedTaskId: number;
          linkTypeId: number;
          linkType?: string | null;
          /** tasks schema */
          linkedTask: {
            id?: number;
            status_id?: number;
            topic_id?: number;
            org_id?: number;
            title?: string;
            description?: string | null;
            priority?: "normal" | "high" | "critical";
            internal_priority?: "low" | "medium" | "high";
            assigned_to?: number;
            reported_by?: number;
            type?: "task" | "quick_question" | "internal_task" | "custom_work";
            requested_by_date?: string | null;
            instant_meeting_url?: string | null;
            instant_meeting_occurred?: boolean | null;
            client_assignee?: number | null;
            billing_status?: ("no_charge" | "making_standard" | null) | null;
            description_search?: string | null;
            action_pending?: ("admire" | "client" | null) | null;
            code?: string | null;
            allow_org_change?: boolean;
            created_at?: string;
            updated_at?: string | null;
            deleted_at?: string | null;
          };
          originalRecord?: any;
        }[]
      | null;
    allow_org_change?: boolean;
    is_from_paymo?: boolean | null;
    time_spent?: number | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetMyTasksApiArg = void;
export type GetMyTasksInProgressApiResponse =
  /** status 200 My Tasks in progress */ {
    awaitingClientCount: number;
    awaitingConsultantCount: number;
    unscheduledCount: number;
    openTasksCount: number;
    criticalQuestionsCount: number | null;
    assignedToMeCount: number | null;
    newCommentsCount: number | null;
    customWorkCount: number | null;
    newTasksCount: number | null;
    backlogCount: number | null;
    unassignedQueueCount?: number | null;
  };
export type GetMyTasksInProgressApiArg = void;
export type GetOrgTasksInProgressApiResponse =
  /** status 200 Tasks in progress */ {
    awaitingClientCount: number;
    awaitingConsultantCount: number;
    unscheduledCount?: number;
    openTasksCount: number;
    criticalQuestionsCount?: number | null;
    assignedToMeCount?: number | null;
    newCommentsCount?: number | null;
    customWorkCount?: number | null;
    newTasksCount?: number | null;
    backlogCount?: number | null;
    unassignedQueueCount?: number | null;
  };
export type GetOrgTasksInProgressApiArg = {
  /** organization id */
  orgId: number;
};
export type GetOrgTaskMediaApiResponse = /** status 200 TaskMedia */ {
  count: number;
  rows: {
    id: number;
    media_id: number;
    session_id?: number | null;
    task_id: number;
    is_custom_work_manual?: boolean;
    task?: {
      id?: number;
      status_id?: number;
      topic_id?: number;
      org_id?: number;
      title?: string;
      description?: string | null;
      priority?: "normal" | "high" | "critical";
      internal_priority?: "low" | "medium" | "high";
      assigned_to?: number;
      reported_by?: number;
      type?: "task" | "quick_question" | "internal_task" | "custom_work";
      requested_by_date?: string | null;
      instant_meeting_url?: string | null;
      instant_meeting_occurred?: boolean | null;
      client_assignee?: number | null;
      billing_status?: ("no_charge" | "making_standard" | null) | null;
      description_search?: string | null;
      action_pending?: ("admire" | "client" | null) | null;
      code?: string | null;
      allow_org_change?: boolean;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
    media?: {
      id?: number;
      path?: string;
      filename?: string;
      internal_name?: string;
      label?: string;
      mime_type?: string | null;
      size?: number | null;
      status_id?: number;
      uploaded_by?: number;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
  }[];
};
export type GetOrgTaskMediaApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** task type */
  type: "other" | "custom_work";
  /** organization id */
  orgId: number;
};
export type GetTaskMediaApiResponse = /** status 200 Show single file */ {
  id?: number;
  path?: string;
  filename?: string | null;
  internal_name?: string;
  label?: string;
  mime_type?: string | null;
  size?: number | null;
  status_id?: number | null;
  status?: string | null;
  uploaded_by?: number;
  created_at?: string | null;
  [key: string]: any;
};
export type GetTaskMediaApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type CreateTaskMediaApiResponse =
  /** status 204 An empty response */ void;
export type CreateTaskMediaApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** task media */
  body: {
    media_id: number;
    chat_id?: number | null;
    is_internal?: boolean;
    is_custom_work_manual?: boolean | null;
  };
};
export type GetOrgTaskActivitiesApiResponse =
  /** status 200 activities response */ {
    activities: ({
      id: number;
      type: string;
      title: string;
      entity: string;
      entity_id: number;
      created_by: number;
      value?: string | null;
      old_value?: string | null;
      created_at: string;
    } & {
      created_by_user: {
        id: number;
        first_name: string;
        last_name: string;
      };
    })[];
  };
export type GetOrgTaskActivitiesApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetTaskActivitiesApiResponse =
  /** status 200 activities response */ {
    activities: ({
      id: number;
      type: string;
      title: string;
      entity: string;
      entity_id: number;
      created_by: number;
      value?: string | null;
      old_value?: string | null;
      created_at: string;
    } & {
      created_by_user: {
        id: number;
        first_name: string;
        last_name: string;
      };
    })[];
  };
export type GetTaskActivitiesApiArg = {
  /** record id */
  id: number;
};
export type GetTaskTimeSpentApiResponse =
  /** status 200 time spent on task  response */ {
    timers: {
      id: number;
      task_id: number;
      org_id: number;
      duration_date?: string | null;
      start_time?: string | null;
      end_time?: string | null;
      duration?: number | null;
      time_spent: number;
      status?: "new" | "reviewed" | "billing" | "billed" | "old";
      user: {
        id: number;
        first_name: string;
        last_name: string;
      };
      activity_type?: {
        id: number;
        name: string;
      };
    }[];
  };
export type GetTaskTimeSpentApiArg = {
  /** record id */
  id: number;
};
export type CloseTaskApiResponse = /** status 204 An empty response */ void;
export type CloseTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** close task without notification */
  body: {
    silent?: boolean | null;
  };
};
export const {
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useCreateTaskMutation,
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskOrgMutation,
  useToggleTaskActionPendingMutation,
  useConvertTaskToQuickQuestionMutation,
  useGetCwTasksQuery,
  useLazyGetCwTasksQuery,
  useConvertTaskToCustomWorkMutation,
  useMoveTaskToSessionMutation,
  useConvertTaskMutation,
  useUpdateTaskStatusMutation,
  useRestoreTaskMutation,
  useUpdateTaskPriorityMutation,
  useCreateTaskInstantZoomMeetingMutation,
  useGetTaskChatsQuery,
  useLazyGetTaskChatsQuery,
  useCreateTaskChatMutation,
  useMarkTaskChatAsReadMutation,
  useDeleteTaskMediaMutation,
  useGetTaskWatchersQuery,
  useLazyGetTaskWatchersQuery,
  useGetTaskChatQuery,
  useLazyGetTaskChatQuery,
  useUpdateTaskChatMutation,
  useDeleteTaskChatMutation,
  useGetMyTasksQuery,
  useLazyGetMyTasksQuery,
  useGetMyTasksInProgressQuery,
  useLazyGetMyTasksInProgressQuery,
  useGetOrgTasksInProgressQuery,
  useLazyGetOrgTasksInProgressQuery,
  useGetOrgTaskMediaQuery,
  useLazyGetOrgTaskMediaQuery,
  useGetTaskMediaQuery,
  useLazyGetTaskMediaQuery,
  useCreateTaskMediaMutation,
  useGetOrgTaskActivitiesQuery,
  useLazyGetOrgTaskActivitiesQuery,
  useGetTaskActivitiesQuery,
  useLazyGetTaskActivitiesQuery,
  useGetTaskTimeSpentQuery,
  useLazyGetTaskTimeSpentQuery,
  useCloseTaskMutation,
} = injectedRtkApi;
