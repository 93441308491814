import { GetMyDashboardReportsApiResponse } from "../../state/rtk-query/state/reports";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { castZero, useLocation } from "../../lib";
import { InfoIcon } from "../icons";
import { CardStyled, EllipsisTypographyStyled } from "../styled";
import { useQueryParam } from "../hooks";
import { useMobile } from "../../themes";
interface Props {
  reports: GetMyDashboardReportsApiResponse["rows"];
  rowClick: (id: number) => void;
}
const MAX_ON_A_ROW = 5;
export const AdminReportsCard = ({ reports, rowClick }: Props) => {
  const loc = useLocation();
  const isMobile = useMobile();
  const { tab = "overview" } = loc.query;
  const { palette } = useTheme();
  const [dashReport] = useQueryParam<number>("dash_report");
  const rowsDisplaying = reports?.filter((r) => r.tab === tab);
  const rowCount = rowsDisplaying.length;

  return (
    <Stack width={"100%"}>
      <CardStyled
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          flex={1}
          flexWrap={"wrap"}
          gap={isMobile ? 1 : 0}
        >
          {rowsDisplaying.map((r, index) => {
            const midRows =
              (rowCount % MAX_ON_A_ROW === 0 &&
                index < rowCount - MAX_ON_A_ROW) ||
              (rowCount % MAX_ON_A_ROW > 0 &&
                index < rowCount - (rowCount % MAX_ON_A_ROW));
            const firstInRow = index % MAX_ON_A_ROW === 0;
            const lastInRow =
              (index + 1) % MAX_ON_A_ROW === 0 || index === rowCount - 1;
            const selected = dashReport === r.id;
            return (
              <Grid
                item
                xs
                container
                direction="column"
                minWidth={isMobile ? "100%" : `calc(100% / ${MAX_ON_A_ROW})`}
                // maxWidth={"20% !important"}
                border={isMobile ? `1px solid ${palette.grey[500]}` : ""}
                borderRight={
                  !lastInRow && !isMobile
                    ? `1px solid ${palette.grey[500]}`
                    : ""
                }
                borderLeft={
                  !firstInRow && !isMobile
                    ? `1px solid ${palette.grey[500]}`
                    : ""
                }
                borderBottom={
                  midRows && !isMobile ? `1px solid ${palette.grey[500]}` : ""
                }
                p={1}
                key={r.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack
                  direction={"column"}
                  justifyContent={"center"}
                  width={"100%"}
                  minWidth={"20%"}
                  overflow={"hidden"}
                  p={2}
                  borderRadius={selected ? "5px" : ""}
                  bgcolor={selected ? palette.primary[50] : "#FFF"}
                  sx={{
                    cursor: "pointer",
                    color:
                      r.agg_count > castZero(r?.danger_threshold)
                        ? palette.error[500]
                        : palette.grey[900],
                  }}
                  onClick={() => rowClick(r?.id)}
                >
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="h2">{r.agg_count}</Typography>
                    {r.agg_count > castZero(r?.danger_threshold) ? (
                      <InfoIcon
                        size={20}
                        style={{ marginLeft: 6, marginBottom: 4 }}
                      />
                    ) : null}
                  </Stack>
                  <EllipsisTypographyStyled variant="subtitle2">
                    {r.name.replace("_", " ")}
                  </EllipsisTypographyStyled>
                </Stack>
              </Grid>
            );
          })}
        </Stack>
      </CardStyled>
    </Stack>
  );
};
