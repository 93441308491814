import { api } from "../api";
export const addTagTypes = ["quickbooks", "timer"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      quickbooksAuth: build.query<
        QuickbooksAuthApiResponse,
        QuickbooksAuthApiArg
      >({
        query: () => ({ url: `/quickbooks/auth` }),
        providesTags: ["quickbooks"],
      }),
      quickbooksCallback: build.query<
        QuickbooksCallbackApiResponse,
        QuickbooksCallbackApiArg
      >({
        query: () => ({ url: `/quickbooks/callback` }),
        providesTags: ["quickbooks"],
      }),
      getQuickbooksCustomerNames: build.query<
        GetQuickbooksCustomerNamesApiResponse,
        GetQuickbooksCustomerNamesApiArg
      >({
        query: () => ({ url: `/quickbooks/customers` }),
        providesTags: ["quickbooks"],
      }),
      invoiceClients: build.mutation<
        InvoiceClientsApiResponse,
        InvoiceClientsApiArg
      >({
        query: (queryArg) => ({
          url: `/quickbooks/invoice-clients`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["quickbooks", "timer"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type QuickbooksAuthApiResponse = /** status 200 quickbooks auth url */ {
  oauthUrl: string;
};
export type QuickbooksAuthApiArg = void;
export type QuickbooksCallbackApiResponse = /** status 200 text/html */ string;
export type QuickbooksCallbackApiArg = void;
export type GetQuickbooksCustomerNamesApiResponse =
  /** status 200 users list response */ {
    data: {
      id: string;
      name: string;
    }[];
  };
export type GetQuickbooksCustomerNamesApiArg = void;
export type InvoiceClientsApiResponse =
  /** status 204 An empty response */ void;
export type InvoiceClientsApiArg = {
  body: {
    orgs: number[];
    dateRange?: string[] | null;
  };
};
export const {
  useQuickbooksAuthQuery,
  useLazyQuickbooksAuthQuery,
  useQuickbooksCallbackQuery,
  useLazyQuickbooksCallbackQuery,
  useGetQuickbooksCustomerNamesQuery,
  useLazyGetQuickbooksCustomerNamesQuery,
  useInvoiceClientsMutation,
} = injectedRtkApi;
