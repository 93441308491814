import { Box, styled } from "@mui/material";

export const ListBoxStyled = styled(Box)(() => ({
  maxHeight: "unset !important",
  overflow: "unset !important",
  padding: "8px 0px 0px 0px !important",
}));

export const ListBoxHeaderStyled = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderBottom: `1px solid ${theme.palette.grey[500]}`,
  position: "sticky",
  top: 0,
}));

export const ListBoxBodyStyled = styled(Box)(() => ({
  maxHeight: "30vh",
  overflow: "auto",
  paddingBottom: "8px",
}));

export const ListBoxFooterStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[500]}`,
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  bottom: 0,
  padding: "8px 0",
  position: "sticky",
  textAlign: "center",
  "& button": { fontSize: "16px" },
  "& svg": { marginRight: "8px" },
}));
