import { Button, SxProps, Theme } from "@mui/material";
import { useState, useCallback } from "react";
import { GetSupportModal } from "../modal";

type Props = { sx?: SxProps<Theme> };
export const GetSupportButton = ({ sx }: Props) => {
  const [openModal, setOpenModel] = useState(false);
  const onClose = useCallback(() => {
    setOpenModel(() => !openModal);
  }, [openModal]);
  return (
    <>
      <Button
        variant="contained"
        sx={{ ml: 2, width: 125, height: 36, ...sx }}
        onClick={onClose}
      >
        Get Support
      </Button>
      <GetSupportModal open={openModal} onClose={onClose} />
    </>
  );
};
