import { Divider, Stack, Badge, Typography, Link, Button } from "@mui/material";
import {
  NotificationContainerStyled,
  PopoverItemStyled,
} from "../../layouts/main/drawer";
import { formatDate } from "../../lib";
import { EllipsisTypographyStyled } from "../styled";
import {
  GetMyNotificationsApiResponse,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
} from "../../state/rtk-query/state/notification";
import { ArrayElement } from "../../global";
import { useCallback, useEffect } from "react";
import { HtmlWrapper } from "../text";
import { useOrgId } from "../hooks";
import { Link as RouterLink } from "react-router-dom";
interface Props {
  notification: ArrayElement<GetMyNotificationsApiResponse["rows"]>;
}
export const NotificationCard = ({ notification }: Props) => {
  const [updateToRead] = useUpdateNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const orgId = useOrgId();
  let timeout: any;
  const interval = useCallback(
    (action: "start" | "clear") => {
      if (action === "start") {
        if (!timeout) {
          timeout = setTimeout(async () => {
            updateToRead({
              id: notification.id,
              orgId,
              body: { is_read: true },
            });
          }, 5000);
        }
      } else {
        if (timeout) clearTimeout(timeout);
      }
    },
    [orgId, timeout],
  );
  useEffect(() => {
    interval("start");

    return () => interval("clear");
  }, [orgId]);
  const postDate = notification.created_at && new Date(notification.created_at);
  const clearNotification = async (
    e?: React.MouseEvent<HTMLButtonElement>,
    id?: number,
  ) => {
    if (e && id) {
      e.preventDefault();
      e.stopPropagation();
      await deleteNotification({ id, orgId });
    }
  };
  return (
    <NotificationContainerStyled
      key={notification.id}
      changeBackground={!notification.is_read}
    >
      <Link
        to={`/${notification.link}`}
        component={RouterLink}
        color={"inherit"}
      >
        <Divider sx={{ width: "100%" }} />
        <PopoverItemStyled height={"100%"}>
          {postDate ? (
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                justifyContent={"space-between"}
                width={"100%"}
                position={"relative"}
              >
                {!notification.is_read ? null : (
                  <Badge
                    color="info"
                    badgeContent=" "
                    variant="dot"
                    sx={{
                      top: 12,
                      left: -8,
                      position: "absolute",
                    }}
                  />
                )}
                <Stack
                  direction={"column"}
                  width={"80%"}
                  minWidth={"80%"}
                  maxWidth={"80%"}
                >
                  <EllipsisTypographyStyled fontSize={"1em"}>
                    <HtmlWrapper message={notification.message} ellipsis />
                  </EllipsisTypographyStyled>
                  <Typography variant="subtitle2" fontSize={12}>
                    {formatDate(postDate, "MMMM do · h:mm aa")}{" "}
                  </Typography>
                </Stack>
                <Button onClick={(e) => clearNotification(e, notification.id)}>
                  Clear
                </Button>
              </Stack>
            </Stack>
          ) : null}

          <Typography
            fontWeight={"medium"}
            fontSize={".9em"}
            mt={1}
            sx={{ wordWrap: "break-word" }}
          >
            <HtmlWrapper message={notification?.context ?? ""} />
          </Typography>
        </PopoverItemStyled>{" "}
      </Link>
    </NotificationContainerStyled>
  );
};
