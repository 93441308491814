import { Typography, Stack, useTheme } from "@mui/material";
import { formatDate } from "../../lib";
import { useMobile } from "../../themes";
import {
  QuestionIcon,
  WrenchIcon,
  CalendarIcon,
  ChatIcon,
  LockIcon,
} from "../icons";
import { ChipStyled } from "../styled";
import { PriorityType } from "../chips";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

interface Props {
  shouldDisplay: boolean;
  requestedByDate?: string;
}
export const QuickQuestionDisplay = ({
  shouldDisplay,
  internal_priority,
}: Props & { internal_priority: PriorityType<"internal"> }) => {
  const { palette } = useTheme();
  if (!shouldDisplay) return null;
  const highPriority = internal_priority === "high" ? " · High Priority" : "";
  return (
    <Typography color={palette.error.main} fontSize={14} fontWeight={500}>
      <QuestionIcon style={{ marginBottom: "-3px" }} size={"18px"} /> Quick
      Question{highPriority}
    </Typography>
  );
};

export const CustomWorkDisplay = ({
  shouldDisplay,
  requestedByDate,
}: Props) => {
  const { palette } = useTheme();
  if (!shouldDisplay) return null;
  const requestedBy = requestedByDate
    ? formatDate(requestedByDate, "M.dd")
    : ""; // TODO this is potentially null

  const neededByDate = requestedBy ? ` · Needed by ${requestedBy}` : "";
  return (
    <Typography fontSize={14} fontWeight={500} color={palette.grey[900]}>
      <WrenchIcon
        style={{ marginBottom: "-3px" }}
        color={palette.info.main}
        size={"20px"}
      />{" "}
      Custom Work{neededByDate}
    </Typography>
  );
};
export const InternalTaskDisplay = ({
  shouldDisplay,
  requestedByDate,
}: Props) => {
  const { palette } = useTheme();
  const isInternal = useSelector(authSelectors.isInternal);
  if (!shouldDisplay || !isInternal) return null;
  const requestedBy = requestedByDate
    ? formatDate(requestedByDate, "M.dd")
    : ""; // TODO this is potentially null

  const neededByDate = requestedBy ? ` · Needed by ${requestedBy}` : "";
  return (
    <Typography fontSize={14} fontWeight={500} color={palette.grey[900]}>
      <LockIcon
        style={{ marginBottom: "-3px" }}
        color={palette.warning.main}
        size={"20px"}
      />{" "}
      Internal {neededByDate}
    </Typography>
  );
};

export const SessionInfoDisplay = ({
  task: {
    sessions,
    session_title,
    pending_session_dates: psd,
    session_duration,
    session_end_time,
    session_start_time,
    has_active_session,
  } = {},
}: {
  task?: {
    sessions?:
      | {
          id?: number;
          title?: string;
          booking?: {
            id?: number;
            start_time?: string;
            duration?: number;
            end_time?: string;
          };
        }[]
      | null;
    pending_session_dates?: { value: string; inclusive: boolean }[];
    has_active_session?: boolean;
    session_title?: string;
    session_start_time?: string;
    session_duration?: number;
    session_end_time?: string;
  };
}) => {
  const { palette } = useTheme();
  const isMobile = useMobile();

  const session = sessions?.find((s) => s.booking?.start_time);
  const start_time = session_start_time || session?.booking?.start_time;
  if (!start_time && !has_active_session) return null;
  const end_time = session_end_time || session?.booking?.end_time;
  const duration = session_duration || session?.booking?.duration;
  const getDateStr = () => {
    if (!start_time) return;
    const start = new Date(start_time);
    const startStr = formatDate(
      start,
      isMobile ? "EEEE, MMMM do" : "EEEE, MMMM do · h:mm aa",
    );
    if (!end_time && !duration) return startStr;
    if (end_time) {
      return startStr + " - " + formatDate(new Date(end_time), "h:mm aa");
    }
    const end = new Date(start);
    end.setMinutes(end.getMinutes() + duration!);
    return startStr + " - " + formatDate(end, "h:mm aa");
  };

  const isPending =
    has_active_session && !session_start_time && psd?.length === 2;

  const dateString = `${session_title || "Consulting Session"} · ${
    !isPending
      ? getDateStr() ?? ""
      : isPending
      ? `Pending: ${formatDate(psd[0]?.value ?? "")} - ${formatDate(
          psd[1]?.value ?? "",
        )}`
      : ""
  }`;

  return (
    <Typography fontSize={14} fontWeight={500} color={palette.grey[900]}>
      <CalendarIcon
        style={{ marginBottom: "-3px" }}
        color={!isPending ? palette.success.main : palette.grey[700]}
        size={"18px"}
      />{" "}
      {isMobile ? "" : dateString}
    </Typography>
  );
};

export const NewCommentsDisplay = ({
  task,
}: {
  task?: {
    task_chats?: { notifications?: any[] }[];
    new_chats_count?: number;
  };
}) => {
  const unread =
    task?.new_chats_count ||
    task?.task_chats?.flatMap((tc) => tc.notifications).length;
  if (!unread) return null;
  return (
    <ChipStyled
      edge="badge"
      shade="tertiary"
      type="accent"
      label={
        <Typography
          fontSize={12}
          lineHeight={"13px"}
          textAlign={"center"}
          padding={0}
        >
          {unread} New
        </Typography>
      }
      size="small"
      sx={{ py: 0.3, height: "min-content", width: "min-content" }}
    />
  );
};

export const CommentsCountDisplay = ({
  task,
}: {
  task?: {
    task_chat_count?: number;
    task_chats?: { notifications?: any[] }[];
    new_chats_count?: number;
  };
}) => {
  const { palette } = useTheme();
  const count = task?.task_chat_count || task?.task_chats?.length;
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      <Typography fontSize={14} fontWeight={500} color={palette.grey[900]}>
        <ChatIcon
          style={{ marginBottom: "-3px" }}
          color={palette.primary.main}
          size={"18px"}
        />{" "}
        {`${count ?? 0} Comments`}
      </Typography>
      <NewCommentsDisplay task={task} />
    </Stack>
  );
};
