import { Button } from "@mui/material";
import { useState } from "react";
import { ActivityModal } from "../modal/ActivityModal";
import {
  useGetTaskActivitiesQuery,
  useGetOrgTaskActivitiesQuery,
} from "../../state/rtk-query/state/tasks";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useOrgId } from "../hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export function TaskActivityButton({ id }: { id?: number }) {
  if (!id) return null;
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const [open, setOpen] = useState(false);
  const { data: { activities } = {} } = isInternal
    ? useGetTaskActivitiesQuery(
        { id },
        {
          refetchOnMountOrArgChange: true,
        },
      )
    : useGetOrgTaskActivitiesQuery(orgId ? { id, orgId } : skipToken, {
        refetchOnMountOrArgChange: true,
      });
  if (!activities) return null;
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={!activities.length}
        sx={{ p: 0, fontSize: 10, height: "15px", fontWeight: 600 }}
        disableRipple
      >
        {activities.length ? "View activity" : "No activity"}
      </Button>
      <ActivityModal
        id={id}
        open={open}
        setOpen={setOpen}
        activities={activities}
        type="task"
      />
    </>
  );
}
