import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MainPages } from "../../pages";

interface MainContentStyledProps {
  shadeBackground: boolean;
}
// keep as a function so it checks on path change
const splitShadow = () => window.location.pathname === "/";
const isPrescreenPage = () =>
  window.location.pathname === MainPages(false).prescreen.path;
const isNestedPage = () =>
  window.location.pathname === MainPages(false).notifications.path ||
  window.location.pathname === MainPages(false).quicklinks.path;
export const MainContentStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "shadeBackground" && prop !== " path",
})<MainContentStyledProps>(({ shadeBackground, theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(shadeBackground
    ? {
        backgroundColor: theme.palette.grey[100],
        [theme.breakpoints.up("md")]: {
          width: `calc(100vw - ${splitShadow() ? 73 : 74}px)`,
        },
        [theme.breakpoints.up("sm")]: {
          borderStyle: "solid",
          borderColor: theme.palette.grey[500],
          borderWidth: `1px ${splitShadow() ? "1px" : 0} 0 1px`,
          borderRadius: `20px ${splitShadow() ? "20px" : 0} 0 0`,
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingLeft: isNestedPage() ? 0 : "16px",
          paddingRight: isNestedPage() ? 0 : "16px",
          backgroundColor: "#FFF",
        },
      }
    : {
        height: isPrescreenPage() ? "100vh" : "",
      }),
}));

export const MainPageStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "heightOffset",
})<{ heightOffset: number }>(({ theme, heightOffset }) => {
  return {
    height: `calc(100vh - ${heightOffset + 65}px)`, // subtract org select bar (if there is one) and title bar
    width: `calc(100vw - ${splitShadow() ? 73 : 74}px)`,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: `${splitShadow() ? 0 : "32px"} ${splitShadow() ? 0 : "32px"} 0 ${
      splitShadow() ? 0 : "32px"
    }`,

    [theme.breakpoints.down("sm")]: {
      // height: `calc(100vh - ${mobileAppBarHeight + mobileNavBarHeight}px)`, // subtract app bar height + mobile nav bar height
      padding: isNestedPage() ? 0 : "4px 4px 0 4px",
      marginLeft: isNestedPage() ? 0 : "16px",
      marginRight: isNestedPage() ? 0 : "16px",
      backgroundColor: "#FFF",
    },
  };
});
