import { useSelector } from "react-redux";
import { authSelectors, clientSelectors } from "../../state";

/**
 * This should be be used for any request that isn't requesting info about an org
 * We use /or/{org_id} in most routes for the sake of authorization so and to know which org is requesting data
 * So on a route like `getOrgLinksQuery` this allows us to know which org if the user has multiple
 * However, when getting specific org info the actual org we are trying to get should get passed in
 * @example DO NOT USE FOR - `useGetOrgByIdQuery`, `useGetOrgUsersByIdQuery`
 * @returns current users orgId (for multiple users this returns the one they are viewing for internal users return -1)
 */
export const useOrgId = (internalFallbackOrgId?: number) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const curOrg = useSelector(clientSelectors.currentOrg);
  const { id: orgId } = isInternal
    ? { id: internalFallbackOrgId ?? -1 }
    : curOrg ?? { id: 0 };
  return orgId;
};
