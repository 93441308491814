import {
  Typography,
  Stack,
  Button,
  Box,
  Tabs,
  Divider,
  useTheme,
  Tab,
  Link,
} from "@mui/material";
import {
  AddIcon,
  TimeEntryModal,
  TimerCard,
  TimerCardHeader,
} from "../../../components";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  addDays,
  differenceInSeconds,
  getDate,
  getDay,
  startOfWeek,
} from "date-fns";

import { WeekPicker } from "../../../components/inputs";
import { Navigation, RouterLink, useLocation } from "../../../lib";
import { useGetMyTimersQuery } from "../../../state/rtk-query/state/timer";
import { Emptiable, Loadable } from "../../../components/misc";
import { useMobile } from "../../../themes";
import { useMemoObj, useQuery } from "../../../components/hooks";
import { MainPages } from "..";

interface QueryParams {
  open: boolean;
  date?: string | Date;
  taskId?: number;
  timerId?: number;
  orgId?: number;
  mode?: "edit" | "new";
}
export const TimerPage = () => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const [query, setQuery] = useQuery<QueryParams>(
    "date",
    "open",
    "taskId",
    "timerId",
    "orgId",
  );
  const date = useMemo(() => query.date, [query.date]);
  const [expand, setExpand] = useState<number[]>([]);
  const [pickedDate, setPickedDate] = useState(
    date ? new Date(date) : new Date(),
  );
  const tz = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    [],
  );
  const { data, isFetching, isSuccess } = useGetMyTimersQuery(
    {
      date: pickedDate.toISOString(),
      tz,
    },
    { refetchOnMountOrArgChange: true },
  );

  const onOpen = useCallback(() => {
    setQuery({ open: true });
  }, [query]);
  const onClose = useCallback(() => {
    setQuery({
      open: undefined,
      taskId: undefined,
      timerId: undefined,
      mode: undefined,
      orgId: undefined,
    });
  }, [query]);

  const tabValues = useMemoObj([
    { id: 0, label: "Sun" },
    { id: 1, label: "Mon" },
    { id: 2, label: "Tues" },
    { id: 3, label: "Wed" },
    { id: 4, label: "Thurs" },
    { id: 5, label: "Fri" },
    { id: 6, label: "Sat" },
  ]);

  const dayOfWeek = useMemo(
    () => (date ? getDay(new Date(date)) : getDay(pickedDate)),
    [pickedDate, date],
  );

  const tabClicked = useCallback(
    (id: number) => {
      const date = addDays(startOfWeek(pickedDate), id);
      Navigation.replace(pathname, {
        query: {
          date: date.toLocaleDateString(),
        },
      });
      setPickedDate(date);
    },
    [pickedDate, date],
  );

  const groupingsMap = new Map<number, number>();

  data?.rows.forEach((r) => {
    if (r && groupingsMap.has(r?.id)) {
      return;
    } else {
      if (!r?.first_timer_of_group && r?.id) {
        // get the last entry in the map
        const findPrev = [...groupingsMap.values()]
          .reverse()
          .find((v) => v < r?.id);
        if (findPrev) groupingsMap.set(r?.id, findPrev);
      } else {
        if (r?.id && r?.first_timer_of_group) {
          groupingsMap.set(r?.id, r?.first_timer_of_group);
        }
      }
    }
  });

  useEffect(() => {
    if (data?.rows.length) {
      setExpand(data?.rows.map((r) => Number(r?.id ?? 0)));
    }
  }, [data?.rows, query.timerId]);

  useEffect(() => {
    if (!date) {
      setQuery({ date: new Date() });
    }
  }, []);
  const getTimerGroup = useCallback(
    (id: number) => {
      return (
        [...groupingsMap.entries()]
          .filter(([_k, v]) => v === id)
          .flatMap((t) => data?.rows.find((r) => r?.id === t[0]) ?? [])
          .filter(Boolean) ?? []
      );
    },
    [data?.rows],
  );
  const timeReviewPage = useMemo(() => MainPages(true).timerReview.path, []);
  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={isMobile ? 2 : 4}
        mt={isMobile ? 2 : 0}
        gap={isMobile ? 1 : 0}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography fontSize="20px" fontWeight="bold">
            Time Entries
          </Typography>
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          gap={2}
          alignItems={"center"}
        >
          <Link
            to={timeReviewPage}
            component={RouterLink}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              p: 0.75,
            }}
          >
            Review entries
          </Link>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{ fontWeight: "600" }}
          >
            <AddIcon style={{ fontSize: "1em" }} /> Manual Entry
          </Button>
        </Stack>
      </Stack>
      <Stack direction={"column"} width={"100%"} gap={2} mb={3}>
        <WeekPicker pickedDate={pickedDate} setPickedDate={setPickedDate} />

        <Box width={"100%"}>
          <Tabs value={dayOfWeek} allowScrollButtonsMobile variant="scrollable">
            {tabValues.map((tv) => (
              <Tab
                disableRipple
                key={tv.id}
                sx={{
                  width: "14.28%",
                  textAlign: "center",
                  color:
                    dayOfWeek === tv.id
                      ? palette.primary.main
                      : palette.grey[900],
                }}
                onClick={() => tabClicked(tv.id)}
                label={
                  <Stack
                    direction={"column"}
                    sx={{
                      color:
                        dayOfWeek === tv.id
                          ? palette.primary.main
                          : palette.grey[900],
                    }}
                  >
                    <Typography fontWeight={500} fontSize={14}>
                      {tv.label}
                    </Typography>
                    <Typography fontWeight={600} fontSize={18}>
                      {getDate(addDays(startOfWeek(pickedDate), tv.id))}
                    </Typography>
                  </Stack>
                }
              />
            ))}
          </Tabs>
          <Divider flexItem />
        </Box>
      </Stack>

      <Loadable isLoading={isFetching}>
        <Emptiable type="timers" isEmpty={isSuccess && !data?.rows?.length}>
          {data?.rows?.map((r, p, arr) => {
            const difGrouping =
              r?.id &&
              groupingsMap.get(r.id) !==
                groupingsMap.get(arr[p + 1]?.id as number);
            return (
              <Fragment key={r?.id}>
                <Box>
                  {r?.first_timer_of_group ? (
                    <TimerCardHeader
                      timer={r}
                      expand={expand}
                      setExpand={setExpand}
                      getTimerGroup={getTimerGroup}
                    />
                  ) : null}
                  {<TimerCard timer={r} next={arr[p + 1]} expand={expand} />}
                </Box>
                {difGrouping && <Box my={1} />}
                {arr[p + 1]?.id &&
                typeof arr[p + 1]?.start_time === "string" &&
                r?.end_time &&
                difGrouping &&
                Math.abs(
                  differenceInSeconds(
                    new Date(arr[p + 1]?.start_time as string),
                    new Date(r?.end_time),
                  ),
                ) > 0 ? (
                  <Divider>
                    <Typography variant="subtitle1">Timer off</Typography>
                  </Divider>
                ) : null}
                {difGrouping && <Box my={1} />}
              </Fragment>
            );
          })}
        </Emptiable>
      </Loadable>
      {!!query.open && <TimeEntryModal open={!!query.open} onClose={onClose} />}
    </>
  );
};
