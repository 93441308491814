import { ContentCopy, OpenInNew } from "@mui/icons-material";
import {
  Divider,
  Stack,
  Typography,
  Button,
  IconButton,
  Link,
} from "@mui/material";
import {
  NotificationContainerStyled,
  PopoverItemStyled,
} from "../../layouts/main/drawer";
import { useState, useCallback } from "react";
import { ArrayElement } from "../../global";
import { GetOrgLinksByIdApiResponse } from "../../state/rtk-query/state/organization";
import { useMobile } from "../../themes";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";

interface Props {
  link: ArrayElement<GetOrgLinksByIdApiResponse["rows"]>;
}
export const QuicklinksCard = ({ link }: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const [showLinks, setShowLinks] = useState(0);
  const onHover = useCallback(
    (id?: number) => setShowLinks(id ?? 0),
    [showLinks],
  );
  const copyToClipboard = useCallback((txt: string) => {
    navigator.clipboard.writeText(txt);
    dispatch(uiActions.showSuccess("link copied"));
  }, []);
  return (
    <NotificationContainerStyled
      key={link.id}
      onMouseOver={() => onHover(link.id)}
      onMouseOut={() => onHover()}
      changeBackground={showLinks === link.id}
      width={"100%"}
    >
      <Divider sx={{ width: "100%" }} />
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <PopoverItemStyled height={"50px"}>
          <Typography
            variant="subtitle1"
            fontSize={12}
            // width={200}
            textTransform="capitalize"
            whiteSpace="nowrap"
            textOverflow={"ellipsis"}
          >
            {link.label}
          </Typography>
        </PopoverItemStyled>
        {
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            width={"100%"}
            pr={4}
            visibility={
              showLinks === link.id || isMobile ? "visible" : "hidden"
            }
          >
            <Button component={Link} href={link.link} target="_blank">
              <OpenInNew
                fontSize="small"
                color="primary"
                style={{ fontSize: isMobile ? "1rem" : ".8rem" }}
              />
            </Button>
            <IconButton onClick={() => copyToClipboard(link.link)}>
              <ContentCopy
                fontSize="small"
                color="primary"
                style={{ fontSize: isMobile ? "1rem" : ".8rem" }}
              />
            </IconButton>
          </Stack>
        }
      </Stack>
    </NotificationContainerStyled>
  );
};
