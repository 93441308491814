import { BaseModal } from "../forms";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import {
  useLazyGetReportsQuery,
  useUpdateMyDashboardReportsMutation,
  GetMyDashboardReportsApiResponse,
} from "../../state/rtk-query/state/reports";
import { DraggableContainer, DraggableItem } from "../draggable";
import { useEffect, useState } from "react";
import { Button, FormControlLabel, Stack, Typography } from "@mui/material";
import { CustomCheckBox } from "../styled";
interface Props {
  open: boolean;
  onClose: () => void;
  tab?: GetMyDashboardReportsApiResponse["rows"][number]["tab"];
  myReports: GetMyDashboardReportsApiResponse["rows"];
}
export const DashReportsModal = ({
  open,
  onClose,
  myReports,
  tab = "tasks",
}: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const [getReports] = useLazyGetReportsQuery();
  const [updateReportOrder] = useUpdateMyDashboardReportsMutation();
  const [items, setItems] =
    useState<{ id: number; content: string; onDash: boolean }[]>();
  useEffect(() => {
    (async () => {
      const reports = await getReports({ includeDashReports: true }).unwrap();
      const values = reports?.rows?.map((r) => ({
        id: r.id,
        content: r.name,
        onDash: !!myReports?.find((mr) => mr.id === r.id),
      }));
      setItems(values);
    })();
    () => setItems(undefined);
  }, [tab, myReports]);

  const applyChanges = async () => {
    const values = items
      ?.filter((i) => i.onDash)
      .map((i, idx) => ({ id: i.id, order: idx }));
    await updateReportOrder({ body: { list: values ?? [], tab } });
    onClose();
  };
  const addToDash = (id: number, c: boolean) => {
    const newValues = items?.map((i) => {
      if (i.id === id) {
        return { ...i, onDash: c };
      }
      return i;
    });
    setItems(newValues);
  };
  return (
    <BaseModal
      open={open && isInternal}
      onClose={onClose}
      title="Customize reports"
    >
      <Stack width={"100%"} gap={2}>
        <DraggableContainer
          droppableId="reportsContainer"
          curOrder={items}
          setOrder={setItems as any}
          direction="vertical"
          sx={{
            maxHeight: "400px",
            overflow: "auto",
            // scrollbar on the outside
            width: "calc(100% + 10px)",
            paddingRight: "10px",
          }}
        >
          {items?.length
            ? items?.map((r, index) => {
                const checked = items
                  .filter((i) => i.onDash)
                  .find((i) => i.id === r.id)?.onDash;
                return (
                  <DraggableItem
                    key={r.id}
                    draggableId={`${r.id}`}
                    idx={index}
                    hideDrag={!checked}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <FormControlLabel
                        control={
                          <CustomCheckBox
                            onChange={(_, c) => addToDash(r.id, c)}
                            checked={!!checked}
                          />
                        }
                        label={``}
                      />
                      <Typography fontWeight={500}> {r.content}</Typography>
                    </Stack>
                  </DraggableItem>
                );
              })
            : null}
        </DraggableContainer>
        <Stack direction={"row"} justifyContent={"flex-end"} gap={1}>
          <Button variant="contained" color="white" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={applyChanges}>
            Apply changes
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};
