import { Statuses } from "../../state";
import { actions } from "../../state/rtk-query";
import { GetStatusesApiResponse } from "../../state/rtk-query/state/statuses";
const { useGetStatusesQuery } = actions;

type StatusType = GetStatusesApiResponse[number];

export const useStatuses = (type: StatusType["type"] = "all") => {
  const { data } = useGetStatusesQuery();

  const statuses = data;

  if (type === "all") {
    return statuses || [];
  }
  return (
    statuses?.filter(
      (c) =>
        (type === "session" ? c?.type === type : c.type !== "session") ||
        c?.type === "all",
    ) ?? []
  );
};

export const useGetStatus = (id?: number) => {
  const statuses = useStatuses("all");

  return statuses.find((ts) => ts?.id === id);
};

export const useGetStatusId = (
  internalName: Statuses,
  type?: StatusType["type"],
) => {
  const statuses = useStatuses(type ?? "all");
  return (
    statuses?.find(
      (s) => s?.internal_name.toLowerCase() === internalName.toLowerCase(),
    )?.id ?? 0
  );
};

export const useEditableStatues = (type: StatusType["type"]) => {
  const statuses = useStatuses(type);
  return statuses?.filter((ss) => !ss?.is_automated && ss?.color);
};

export const useDefaultTaskFilters = () => {
  const ignoreTaskStatus = [
    "resolved",
    "closed",
    "resolved_with_client",
    "complete",
    "on_hold_until_further_notice_from_client",
    "delayed",
    "on_hold",
    "converted",
  ];

  return [
    ...new Set(
      useStatuses("task")
        .filter((s) => !ignoreTaskStatus.includes(s.internal_name))
        .map((s) => s.internal_name),
    ),
  ];
};
