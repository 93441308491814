//Based on https://github.com/remix-run/react-router/issues/8264#issuecomment-973920319
import { Update } from "history";
import { useLayoutEffect, useReducer } from "react";
import { Router } from "react-router-dom";
import { history } from "./Navigation";

const reducer = (_: Update, action: Update) => action;

export const HistoryRouter = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(dispatch), []);

  return (
    <Router
      navigationType={state.action}
      location={state.location}
      navigator={history}
    >
      {children}
    </Router>
  );
};
