import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export function FormGridSpacingContainerStyled({
  children,
  ...props
}: {
  children: React.ReactNode;
  props: any;
}) {
  return (
    <Grid container spacing={{ xs: 1.5, sm: 2 }} {...props}>
      {children}
    </Grid>
  );
}

export function FormGridSpacingLargeContainerStyled({
  children,
  ...props
}: {
  children: React.ReactNode;
  props: any;
}) {
  return (
    <Grid container rowSpacing={3} columnSpacing={2} {...props}>
      {children}
    </Grid>
  );
}

export const FormFooterContainerStyled = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "end",
  padding: "32px 0",
  width: "100%",
}));
