import { useEffect } from "react";
import { useSelector } from "react-redux";
import { authSelectors, generatedApis, store } from "../../state";
import { useGetPushNotificationPublicQuery } from "../../state/rtk-query/state/notification";
import { __DEV__ } from "../../config";

export const useNotificationSW = () => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { currentData } = useGetPushNotificationPublicQuery();
  useEffect(() => {
    if (isLoggedIn && currentData?.PUBLIC_KEY) {
      registerServiceWorker(currentData.PUBLIC_KEY);
    }
  }, [isLoggedIn, currentData?.PUBLIC_KEY]);
};

function registerServiceWorker(publicKey: string) {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(function (swReg) {
        swReg.update();
        // eslint-disable-next-line no-console
        console.log("Service Worker is registered");
        return Notification.requestPermission();
      })
      .then(function (permission) {
        if (permission === "granted") {
          subscribeUserToPush(publicKey);
        } else {
          console.error("Notification permission denied.");
        }
      })
      .catch(function (error) {
        console.error("Service Worker Error", error);
      });
  }

  function subscribeUserToPush(publicKey: string) {
    navigator.serviceWorker.ready.then(function (registration) {
      const convertedVapidKey = urlBase64ToUint8Array(publicKey);
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        })
        .then(function (subscription) {
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log("User is subscribed:", subscription);
          }
          const subscriptionData = {
            endpoint: subscription.endpoint,
            auth_key: btoa(
              String.fromCharCode.apply(
                null,
                new Uint8Array(
                  subscription.getKey("auth")!,
                ) as unknown as number[],
              ),
            ),
            p256dh_key: btoa(
              String.fromCharCode.apply(
                null,
                new Uint8Array(
                  subscription.getKey("p256dh")!,
                ) as unknown as number[],
              ),
            ),
          };
          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log({ subscriptionData });
          }

          store.dispatch(
            generatedApis.notificationApi.actions.endpoints.registerPushNotificationSw.initiate(
              { body: subscriptionData },
            ),
          );
        })
        .catch(function (err) {
          console.warn("Failed to subscribe the user: ", err);
        });
    });
  }

  function urlBase64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
