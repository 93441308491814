import { GridFilterModel, GridState } from "@mui/x-data-grid-pro";
import { stringify } from "query-string";
import { ArrayElement } from "../global";
import { GetReportByIdApiResponse } from "../state/rtk-query/state/reports";
import { subDays, subMonths } from "date-fns";
import { isDate } from "./utils";
import { extractDigits } from "../components";

type SortingModel = GridState["sorting"]["sortModel"][0];

export interface QueryParams {
  sort?: string; // SortingModel["sort"];
  field?: SortingModel["field"];
  filter?: string;
  range?: number[];
  q?: string;
  linkType?: "and" | "or";
}

//in case 2 columns need to share a field use (org_id, org_id_, _org_id_, org__id, etc...) all will result in org_id
function parseDuplicateColNames(colName: string) {
  return colName.split("_").filter(Boolean).join("_");
}

export const buildParams = (state: GridState) => {
  const queryParams: QueryParams = {};
  if (state.sorting?.sortModel?.length) {
    queryParams.sort = state.sorting.sortModel[0].sort ?? "";
    const field = state.sorting.sortModel[0].field;
    const parsedField = parseDuplicateColNames(field);
    queryParams.field = parsedField;
  }
  if (state.filter.filterModel.items.length) {
    queryParams.filter = JSON.stringify(
      state.filter.filterModel.items.map(
        ({ field, operator: operatorValue, value }) => {
          const columnField = parseDuplicateColNames(field);
          return {
            columnField,
            operatorValue,
            value:
              typeof value === "string" &&
              extractDigits(value)?.length !== value?.length && // not string of just numbers
              isDate(new Date(value))
                ? new Date(value)
                : value,
          };
        },
      ),
    );
  }
  if (state.filter.filterModel.quickFilterValues?.length) {
    queryParams.q = state.filter.filterModel.quickFilterValues[0];
  }
  if (state.filter.filterModel.logicOperator) {
    queryParams.linkType = state.filter.filterModel.logicOperator;
  }
  const { page, pageSize } = state.pagination.paginationModel;
  queryParams.range = [page, pageSize];
  return {
    stringified: stringify(queryParams, { arrayFormat: "bracket" }),
    queryParams,
  };
};

export const rtkQuerySearch = (params?: QueryParams) => {
  return params
    ? {
        sort: params.sort,
        field: params.field,
        filter: params.filter,
        range: params.range,
        linkType: params.linkType,
        q: params.q,
      }
    : {};
};

export const buildFilterModel = (model: GridFilterModel) => {
  return JSON.stringify(
    model.items.map(({ field, operator: operatorValue, value }) => {
      const columnField = parseDuplicateColNames(field);
      return {
        columnField,
        operatorValue,
        value,
      };
    }),
  );
};
class MyMuiDate extends Date {
  constructor(d: any) {
    super(d);
  }
  toString(): string {
    return this.toISOString().slice(0, -5);
  }
}
export type ItemType = ArrayElement<
  NonNullable<GetReportByIdApiResponse["default_filter"]>["items"]
>;
export const convertToMuiDate = (item: ItemType, type: string) => {
  const time = parseInt(String(item?.value ?? 0));
  const today = new Date();
  return new MyMuiDate(
    type === "days" ? subDays(today, time) : subMonths(today, time),
  );
};
