import { Box, Button, Link, Typography } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect } from "react";
import {
  Form,
  Navigation,
  RouterLink,
  getQueryParam,
  getRoleInfoById,
  useFormik,
  yup,
} from "../../lib";
import { User, actions, authActions, useDispatch } from "../../state";
import { AuthState } from "../../state/auth/state";
import {
  CenterElementStyle,
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
} from "./Auth.styles";
const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginAfterResetPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const { useAuthLoginMutation } = actions;
    const [login, { isSuccess, data }] = useAuthLoginMutation();
    const dispatch = useDispatch();
    useEffect(() => {
      if (isSuccess && data) {
        const { user, token } = data;
        if (user && token) {
          const roles = user?.orgs[0].user_roles.map((ur) => ({
            id: ur.role_id,
            ...getRoleInfoById(ur.role_id),
          }));
          const authState: AuthState = {
            userId: user?.id,
            roles,
            token,
            userName: user.email,
            user: user as User,
            requiresMfa: !!user.mfa_required,
          };
          dispatch(authActions.setAuthState(authState));
          Navigation.replace(getQueryParam("after") || "/");
        }
      }
    }, [isSuccess]);
    const frm = useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        await login({ body: values });
        if (!isSuccess) {
          frm.setFieldValue("password", "");
        }
      },
    });

    return (
      <ContainerStyle>
        <Box>
          <Box>
            <Typography variant="h1" gutterBottom fontWeight={600}>
              Password Updated
            </Typography>
            <Typography variant="body1" fontWeight={500} fontSize={16}>
              Your password has been successfully updated. Sign in to continue.
            </Typography>
          </Box>
          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  autoFocus
                  fullWidth
                  name="username"
                  label="Email"
                />
              </TextFlexStyle>
              <TextFlexStyle>
                <Field
                  component={TextField}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                />
              </TextFlexStyle>
              <Button
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting}
                fullWidth
              >
                Sign In
              </Button>
              <CenterElementStyle>
                <Link
                  component={RouterLink}
                  to={`/auth/forgot-password?email=${encodeURIComponent(
                    frm.values.username,
                  )}`}
                >
                  Forgot Password?
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
