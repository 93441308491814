import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { uiSelectors } from "../../state";
import { useMobile } from "../../themes";

export const drawerWidth = 240;
export default function () {
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const isMobile = useMobile();
  useEffect(() => {
    if (menuOpen && isMobile) {
      setCurrentDrawerWidth(drawerWidth);
    } else {
      setCurrentDrawerWidth(73);
    }
  }, [menuOpen]);
  return currentDrawerWidth;
}
