import { api } from "../api";
export const addTagTypes = ["roles"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
        query: (queryArg) => ({
          url: `/roles`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["roles"],
      }),
      createRole: build.mutation<CreateRoleApiResponse, CreateRoleApiArg>({
        query: (queryArg) => ({
          url: `/roles`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["roles"],
      }),
      updateRole: build.mutation<UpdateRoleApiResponse, UpdateRoleApiArg>({
        query: (queryArg) => ({
          url: `/roles/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["roles"],
      }),
      deleteRole: build.mutation<DeleteRoleApiResponse, DeleteRoleApiArg>({
        query: (queryArg) => ({
          url: `/roles/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["roles"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetRolesApiResponse = /** status 200 all roles */ {
  count: number;
  rows: {
    id?: number;
    internal_name: string;
    name: string;
    type?: "internal" | "external";
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetRolesApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export type CreateRoleApiResponse = /** status 204 An empty response */ void;
export type CreateRoleApiArg = {
  /** role */
  body: {
    internal_name: string;
    name: string;
    type?: "internal" | "external";
  };
};
export type UpdateRoleApiResponse = /** status 204 An empty response */ void;
export type UpdateRoleApiArg = {
  /** record id */
  id: number;
  /** role */
  body: {
    internal_name: string;
    name: string;
    type?: "internal" | "external";
  };
};
export type DeleteRoleApiResponse = /** status 204 An empty response */ void;
export type DeleteRoleApiArg = {
  /** record id */
  id: number;
};
export const {
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = injectedRtkApi;
