import { announcementColumns } from "../../../columns";
import { useCallback, useMemo, useState } from "react";
import { ServerDataGrid } from "../../../components/tables/ServerDataGrid";
import { actions } from "../../../state/rtk-query";
import { AddIcon, CardStyled } from "../../../components";
import { Button, Stack, Typography } from "@mui/material";
import { AddAnnouncementForm, BaseModal } from "../../../components/forms";
import { GetAnnouncementsApiResponse } from "../../../state/rtk-query/state/announcements";
import { useMobile } from "../../../themes";
import { useRtkQueryParams } from "../../../components/hooks";
const { useGetAnnouncementsQuery } = actions;
export const AnnouncementsPage = () => {
  const [params, setParams] = useRtkQueryParams();
  const isMobile = useMobile();
  const {
    data: announcements,
    refetch,
    isFetching,
  } = useGetAnnouncementsQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const [editAnnouncement, setEditAnnouncement] =
    useState<GetAnnouncementsApiResponse["rows"][0]>();

  const columns = useMemo(() => announcementColumns(isMobile), [isMobile]);

  const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false);
  const onClose = useCallback(() => {
    setOpenAnnouncementModal(!openAnnouncementModal);
    if (refetch) {
      refetch();
    }
    setEditAnnouncement(undefined);
  }, [openAnnouncementModal, refetch, editAnnouncement]);

  const rowClick = useCallback(
    ({ row }: { row: GetAnnouncementsApiResponse["rows"][0] }) => {
      setEditAnnouncement(row);
      setOpenAnnouncementModal(true);
    },
    [editAnnouncement],
  );
  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography fontSize="20px" fontWeight="bold">
          Announcements
        </Typography>
        <Button variant="contained" onClick={onClose}>
          <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />{" "}
          Announcement
        </Button>
      </Stack>
      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <ServerDataGrid
          toolbarProps={{ hideToolbar: true }}
          isFetching={isFetching}
          rowCount={announcements?.count || 0}
          rows={announcements ? announcements.rows : []}
          columns={columns}
          setQueryParams={setParams}
          currentParams={params}
          props={{
            density: "standard",
            sx: {
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
              },
              ".MuiDataGrid-row": {
                cursor: "pointer",
              },
            },
            onRowClick: rowClick,
          }}
        />
      </CardStyled>
      <BaseModal
        open={openAnnouncementModal}
        onClose={onClose}
        title={editAnnouncement ? "Edit announcement" : "Add announcement"}
      >
        <AddAnnouncementForm
          onClose={onClose}
          editAnnouncement={editAnnouncement}
        />
      </BaseModal>
    </>
  );
};
