import { api } from "../api";
export const addTagTypes = ["positions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getPositions: build.query<GetPositionsApiResponse, GetPositionsApiArg>({
        query: () => ({ url: `/positions` }),
        providesTags: ["positions"],
      }),
      createPosition: build.mutation<
        CreatePositionApiResponse,
        CreatePositionApiArg
      >({
        query: (queryArg) => ({
          url: `/positions`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["positions"],
      }),
      updatePosition: build.mutation<
        UpdatePositionApiResponse,
        UpdatePositionApiArg
      >({
        query: (queryArg) => ({
          url: `/positions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["positions"],
      }),
      deletePosition: build.mutation<
        DeletePositionApiResponse,
        DeletePositionApiArg
      >({
        query: (queryArg) => ({
          url: `/positions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["positions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetPositionsApiResponse = /** status 200 all positions */ {
  count: number;
  rows: {
    id?: number;
    internal_name: string;
    name: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetPositionsApiArg = void;
export type CreatePositionApiResponse =
  /** status 204 An empty response */ void;
export type CreatePositionApiArg = {
  /** position */
  body: {
    internal_name: string;
    name: string;
  };
};
export type UpdatePositionApiResponse =
  /** status 204 An empty response */ void;
export type UpdatePositionApiArg = {
  /** record id */
  id: number;
  /** position */
  body: {
    internal_name: string;
    name: string;
  };
};
export type DeletePositionApiResponse =
  /** status 204 An empty response */ void;
export type DeletePositionApiArg = {
  /** record id */
  id: number;
};
export const {
  useGetPositionsQuery,
  useLazyGetPositionsQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useDeletePositionMutation,
} = injectedRtkApi;
