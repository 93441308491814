import { api } from "../api";
export const addTagTypes = ["microsoft", "media", "calendar"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSharePointFiles: build.query<
        GetSharePointFilesApiResponse,
        GetSharePointFilesApiArg
      >({
        query: () => ({ url: `/microsoft/share-point` }),
        providesTags: ["microsoft"],
      }),
      uploadSharePointFile: build.mutation<
        UploadSharePointFileApiResponse,
        UploadSharePointFileApiArg
      >({
        query: (queryArg) => ({
          url: `/microsoft/share-point`,
          method: "POST",
          body: queryArg.body.file,
          formData: true,
        }),
        invalidatesTags: ["media", "microsoft"],
      }),
      deleteSharePointFile: build.mutation<
        DeleteSharePointFileApiResponse,
        DeleteSharePointFileApiArg
      >({
        query: (queryArg) => ({
          url: `/microsoft/share-point/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["media", "microsoft"],
      }),
      getOutlookCalendarEvents: build.query<
        GetOutlookCalendarEventsApiResponse,
        GetOutlookCalendarEventsApiArg
      >({
        query: () => ({ url: `/microsoft/calendar-events` }),
        providesTags: ["microsoft", "calendar"],
      }),
      createOutlookCalendarEvent: build.mutation<
        CreateOutlookCalendarEventApiResponse,
        CreateOutlookCalendarEventApiArg
      >({
        query: (queryArg) => ({
          url: `/microsoft/calendar-events`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["microsoft"],
      }),
      editOutlookCalendarEvent: build.mutation<
        EditOutlookCalendarEventApiResponse,
        EditOutlookCalendarEventApiArg
      >({
        query: (queryArg) => ({
          url: `/microsoft/calendar-events/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["microsoft", "calendar"],
      }),
      deleteOutlookCalendarEvent: build.mutation<
        DeleteOutlookCalendarEventApiResponse,
        DeleteOutlookCalendarEventApiArg
      >({
        query: (queryArg) => ({
          url: `/microsoft/calendar-events/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["microsoft", "calendar"],
      }),
      getOutlookCalendarUrl: build.query<
        GetOutlookCalendarUrlApiResponse,
        GetOutlookCalendarUrlApiArg
      >({
        query: () => ({ url: `/microsoft/calendar-url` }),
        providesTags: ["microsoft"],
      }),
      getMicrosoftCredentials: build.query<
        GetMicrosoftCredentialsApiResponse,
        GetMicrosoftCredentialsApiArg
      >({
        query: () => ({ url: `/microsoft/sign-in-creds` }),
        providesTags: ["microsoft"],
        keepUnusedDataFor: 5,
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetSharePointFilesApiResponse =
  /** status 200 Sharepoint files response */ {
    "@odata.context"?: string;
    value?: {
      "@microsoft.graph.downloadUrl"?: string | null;
      eTag?: string | null;
      id?: string | null;
      createdDateTime?: string | null;
      lastModifiedDateTime?: string | null;
      name?: string | null;
      webUrl?: string | null;
      cTag?: string | null;
      size?: number | null;
      shared?: {
        scope?: string | null;
      } | null;
      fileSystemInfo?: {
        createdDateTime?: string;
        lastModifiedDateTime?: string;
      };
      file?: {
        mimeType?: string | null;
        hashes?: {
          quickXorHash?: string | null;
        } | null;
      } | null;
      parentReference?: {
        driveType?: string | null;
        driveId?: string | null;
        id?: string | null;
        path?: string | null;
        siteId?: string | null;
      } | null;
      createdBy?: {
        application?: {
          id?: string | null;
          displayName?: string | null;
        } | null;
        user?: {
          displayName?: string | null;
        } | null;
      } | null;
      lastModifiedBy?: {
        application?: {
          id?: string | null;
          displayName?: string | null;
        } | null;
        user?: {
          displayName?: string | null;
        } | null;
      } | null;
      [key: string]: any;
    }[];
  };
export type GetSharePointFilesApiArg = void;
export type UploadSharePointFileApiResponse =
  /** status 200 Generic response */ object;
export type UploadSharePointFileApiArg = {
  /** file */
  body: {
    name?: string | null;
    label?: string | null;
    file: FormData;
  };
};
export type DeleteSharePointFileApiResponse =
  /** status 204 An empty response */ undefined;
export type DeleteSharePointFileApiArg = {
  /** Microsoft string id */
  id: string;
};
export type GetOutlookCalendarEventsApiResponse =
  /** status 200 Outlook calendar events response */ {
    "@odata.context"?: string;
    value?: {
      "@odata.etag"?: string | null;
      id?: string | null;
      createdDateTime?: string | null;
      lastModifiedDateTime?: string | null;
      changeKey?: string | null;
      categories?: any | null;
      transactionId?: string | null;
      originalStart?: string | null;
      originalStartTimeZone?: string | null;
      originalEndTimeZone?: string;
      iCalUId?: string | null;
      reminderMinutesBeforeStart?: (number | null) | (string | null);
      isReminderOn?: boolean | null;
      hasAttachments?: boolean | null;
      subject?: string;
      bodyPreview?: string | null;
      importance?: string | null;
      sensitivity?: string | null;
      isAllDay?: boolean;
      isCancelled?: boolean | null;
      isOrganizer?: boolean | null;
      responseRequested?: boolean | null;
      seriesMasterId?: string | null;
      showAs?: string | null;
      type?: string | null;
      webLink?: string | null;
      onlineMeetingUrl?: object | null;
      isOnlineMeeting?: boolean | null;
      onlineMeetingProvider?: string | null;
      allowNewTimeProposals?: boolean | null;
      occurrenceId?: string | null;
      isDraft?: boolean | null;
      hideAttendees?: boolean | null;
      responseStatus?: object | null;
      body?: {
        contentType?: string;
        content?: string | null;
      } | null;
      start?: {
        dateTime?: string;
        timeZone?: string;
      };
      end?: {
        dateTime?: string | null;
        timeZone?: string | null;
      } | null;
      location?: {
        displayName?: string | null;
        locationType?: string | null;
        uniqueId?: string | null;
        uniqueIdType?: string | null;
      } | null;
      locations?:
        | ({
            displayName?: string | null;
            locationType?: string | null;
            uniqueIdType?: string | null;
          } | null)[]
        | null;
      recurrence?: object | null;
      attendees?:
        | ({
            type?: string | null;
            status?: {
              response?: string | null;
              time?: string | null;
            } | null;
            emailAddress?: {
              name?: string | null;
              address?: string | null;
            } | null;
          } | null)[]
        | null;
      organizer?: {
        emailAddress?: {
          name?: string | null;
          address?: string | null;
        } | null;
      } | null;
      onlineMeeting?: object | null;
      "calendar@odata.associationLink"?: string | null;
      "calendar@odata.navigationLink"?: string | null;
      [key: string]: any;
    }[];
  };
export type GetOutlookCalendarEventsApiArg = void;
export type CreateOutlookCalendarEventApiResponse =
  /** status 204 An empty response */ undefined;
export type CreateOutlookCalendarEventApiArg = {
  /** outlook calendar event */
  body: {
    "@odata.etag"?: string | null;
    id?: string | null;
    createdDateTime?: string | null;
    lastModifiedDateTime?: string | null;
    changeKey?: string | null;
    categories?: any | null;
    transactionId?: string | null;
    originalStart?: string | null;
    originalStartTimeZone?: string | null;
    originalEndTimeZone?: string;
    iCalUId?: string | null;
    reminderMinutesBeforeStart?: (number | null) | (string | null);
    isReminderOn?: boolean | null;
    hasAttachments?: boolean | null;
    subject?: string;
    bodyPreview?: string | null;
    importance?: string | null;
    sensitivity?: string | null;
    isAllDay?: boolean;
    isCancelled?: boolean | null;
    isOrganizer?: boolean | null;
    responseRequested?: boolean | null;
    seriesMasterId?: string | null;
    showAs?: string | null;
    type?: string | null;
    webLink?: string | null;
    onlineMeetingUrl?: object | null;
    isOnlineMeeting?: boolean | null;
    onlineMeetingProvider?: string | null;
    allowNewTimeProposals?: boolean | null;
    occurrenceId?: string | null;
    isDraft?: boolean | null;
    hideAttendees?: boolean | null;
    responseStatus?: object | null;
    body?: {
      contentType?: string;
      content?: string | null;
    } | null;
    start?: {
      dateTime?: string;
      timeZone?: string;
    };
    end?: {
      dateTime?: string | null;
      timeZone?: string | null;
    } | null;
    location?: {
      displayName?: string | null;
      locationType?: string | null;
      uniqueId?: string | null;
      uniqueIdType?: string | null;
    } | null;
    locations?:
      | ({
          displayName?: string | null;
          locationType?: string | null;
          uniqueIdType?: string | null;
        } | null)[]
      | null;
    recurrence?: object | null;
    attendees?:
      | ({
          type?: string | null;
          status?: {
            response?: string | null;
            time?: string | null;
          } | null;
          emailAddress?: {
            name?: string | null;
            address?: string | null;
          } | null;
        } | null)[]
      | null;
    organizer?: {
      emailAddress?: {
        name?: string | null;
        address?: string | null;
      } | null;
    } | null;
    onlineMeeting?: object | null;
    "calendar@odata.associationLink"?: string | null;
    "calendar@odata.navigationLink"?: string | null;
    [key: string]: any;
  };
};
export type EditOutlookCalendarEventApiResponse =
  /** status 204 An empty response */ undefined;
export type EditOutlookCalendarEventApiArg = {
  /** Microsoft string id */
  id: string;
  /** outlook calendar event */
  body: {
    "@odata.etag"?: string | null;
    id?: string | null;
    createdDateTime?: string | null;
    lastModifiedDateTime?: string | null;
    changeKey?: string | null;
    categories?: any | null;
    transactionId?: string | null;
    originalStart?: string | null;
    originalStartTimeZone?: string | null;
    originalEndTimeZone?: string;
    iCalUId?: string | null;
    reminderMinutesBeforeStart?: (number | null) | (string | null);
    isReminderOn?: boolean | null;
    hasAttachments?: boolean | null;
    subject?: string;
    bodyPreview?: string | null;
    importance?: string | null;
    sensitivity?: string | null;
    isAllDay?: boolean;
    isCancelled?: boolean | null;
    isOrganizer?: boolean | null;
    responseRequested?: boolean | null;
    seriesMasterId?: string | null;
    showAs?: string | null;
    type?: string | null;
    webLink?: string | null;
    onlineMeetingUrl?: object | null;
    isOnlineMeeting?: boolean | null;
    onlineMeetingProvider?: string | null;
    allowNewTimeProposals?: boolean | null;
    occurrenceId?: string | null;
    isDraft?: boolean | null;
    hideAttendees?: boolean | null;
    responseStatus?: object | null;
    body?: {
      contentType?: string;
      content?: string | null;
    } | null;
    start?: {
      dateTime?: string;
      timeZone?: string;
    };
    end?: {
      dateTime?: string | null;
      timeZone?: string | null;
    } | null;
    location?: {
      displayName?: string | null;
      locationType?: string | null;
      uniqueId?: string | null;
      uniqueIdType?: string | null;
    } | null;
    locations?:
      | ({
          displayName?: string | null;
          locationType?: string | null;
          uniqueIdType?: string | null;
        } | null)[]
      | null;
    recurrence?: object | null;
    attendees?:
      | ({
          type?: string | null;
          status?: {
            response?: string | null;
            time?: string | null;
          } | null;
          emailAddress?: {
            name?: string | null;
            address?: string | null;
          } | null;
        } | null)[]
      | null;
    organizer?: {
      emailAddress?: {
        name?: string | null;
        address?: string | null;
      } | null;
    } | null;
    onlineMeeting?: object | null;
    "calendar@odata.associationLink"?: string | null;
    "calendar@odata.navigationLink"?: string | null;
    [key: string]: any;
  };
};
export type DeleteOutlookCalendarEventApiResponse =
  /** status 204 An empty response */ undefined;
export type DeleteOutlookCalendarEventApiArg = {
  /** Microsoft string id */
  id: string;
};
export type GetOutlookCalendarUrlApiResponse =
  /** status 200 Oauth redirect url */ {
    oauthRedirectUrl: string;
  };
export type GetOutlookCalendarUrlApiArg = void;
export type GetMicrosoftCredentialsApiResponse =
  /** status 200 Microsoft creds */ {
    CLIENT_ID: string;
    TENANT_ID: string;
  };
export type GetMicrosoftCredentialsApiArg = void;
export const {
  useGetSharePointFilesQuery,
  useLazyGetSharePointFilesQuery,
  useUploadSharePointFileMutation,
  useDeleteSharePointFileMutation,
  useGetOutlookCalendarEventsQuery,
  useLazyGetOutlookCalendarEventsQuery,
  useCreateOutlookCalendarEventMutation,
  useEditOutlookCalendarEventMutation,
  useDeleteOutlookCalendarEventMutation,
  useGetOutlookCalendarUrlQuery,
  useLazyGetOutlookCalendarUrlQuery,
  useGetMicrosoftCredentialsQuery,
  useLazyGetMicrosoftCredentialsQuery,
} = injectedRtkApi;
