import { Button, Stack, Typography } from "@mui/material";
import noContent from "../../assets/img/NoContent.svg";
import { capitalizeSentence } from "../../lib";
import { AddIcon } from "../icons";
import { GetSupportModal, Modal, TimeEntryModal } from "../modal";
import { useCallback, useState } from "react";
import { AddTaskForm } from "../forms/AddTaskForm";
import { useMobile } from "../../themes";
import { InternalAddForm } from "../forms";

export interface EmptyStateProps {
  type: "sessions" | "tasks" | "timers" | "documents" | "cw_docs" | "reports";
  hasFilters?: boolean;
  orgId?: number;
  sessionId?: number;
  isArchived?: boolean;
}
export const EmptyState = ({
  type,
  orgId,
  hasFilters = false,
  isArchived = false,
  sessionId,
}: EmptyStateProps) => {
  const isMobile = useMobile();
  const [openTasks, setOpenTasks] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [openTimer, setOpenTimer] = useState(false);

  const openModal = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      switch (type) {
        case "sessions":
          setOpenSession(!openSession);
          break;
        case "tasks":
          setOpenTasks(!openTasks);
          break;
        case "timers":
          setOpenTimer(!openTimer);
          break;
        default:
          break;
      }
    },
    [openTasks, openSession, openTimer, type, orgId],
  );
  const docType = ["documents", "cw_docs"].includes(type);
  const hideButton = docType || type === "reports";
  return (
    <>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        gap={3}
      >
        <img src={noContent} alt="sidebar" />

        <Typography variant="h2">
          {hasFilters ? `No ${type} found` : "Nothing here yet"}
        </Typography>
        {
          <Typography variant="h3">
            {hasFilters
              ? "Try adjusting your filters to find what you're looking for."
              : isArchived
              ? `${capitalizeSentence(
                  type ?? "",
                )} cannot be added while page is archived`
              : !docType
              ? `${capitalizeSentence(type ?? "")} you add will show up here`
              : `${
                  type === "cw_docs" ? "Custom work documents" : "Documents"
                } added to tasks will show up here`}
          </Typography>
        }
        {!hasFilters && !isArchived && !hideButton ? (
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", fontWeight: 600 }}
            onClick={openModal}
          >
            <AddIcon style={{ fontSize: isMobile ? "1em" : "1.2em" }} />
            {type.slice(0, type.length - 1)}
          </Button>
        ) : null}
      </Stack>
      {!!orgId && (
        <Modal open={openTasks} onClose={openModal}>
          {sessionId ? (
            <AddTaskForm
              orgId={orgId}
              onClose={openModal}
              sessionId={sessionId}
            />
          ) : (
            <InternalAddForm onClose={openModal} orgId={orgId} removeSession />
          )}
        </Modal>
      )}

      <GetSupportModal
        open={openSession}
        onClose={openModal}
        defaultScreen="CreateSession"
        orgId={orgId}
      />
      <TimeEntryModal open={openTimer} onClose={openModal} />
    </>
  );
};
