import React from "react";
import {
  CardContentContainerStyled,
  CardContentItemStyled,
  CardHeaderStyled,
  CardStyled,
} from "../styled";
import { formatPhone } from "../../lib";
import { useCurOrg } from "../hooks";
import { ProfileField } from "../misc";

export const OrgProfileCard = React.memo(() => {
  const orgInfo = useCurOrg();

  const { account_manager, consultant, website, address, phone, it_info } =
    orgInfo ?? {};
  // const coordinators = admin_clients
  //   ?.map((ac) => `${ac?.first_name ?? ""} ${ac?.last_name ?? ""}`)
  //   .join(", ");

  return (
    <CardStyled sx={{ marginBottom: 3 }}>
      <CardHeaderStyled>PROFILE</CardHeaderStyled>

      <CardContentContainerStyled container sx={{ px: 2 }}>
        <CardContentItemStyled item>
          <ProfileField isOrgProfile field="Address" value={address || ""} />
          <ProfileField
            isOrgProfile
            field="Phone"
            value={formatPhone(phone ?? "") ?? ""}
          />
          <ProfileField isOrgProfile field="Website" value={website} />
        </CardContentItemStyled>
        <CardContentItemStyled item>
          <ProfileField
            isOrgProfile
            field="Account Manager"
            value={`${account_manager?.first_name ?? ""} ${
              account_manager?.last_name ?? ""
            }`}
          />
          <ProfileField
            isOrgProfile
            field="Consultant"
            value={`${consultant?.first_name ?? ""} ${
              consultant?.last_name ?? ""
            }`}
          />
          <ProfileField isOrgProfile field="IT Info" value={it_info ?? ""} />
          {/* <DescriptionText field="Admire Coordinator" value={coordinators} /> */}
        </CardContentItemStyled>
      </CardContentContainerStyled>
    </CardStyled>
  );
});
