import { useEffect, useState } from "react";
interface Props {
  direction: "up" | "down";
  start: boolean;
  defaultCount?: number;
}
export const useClock = ({ direction = "up", start, defaultCount }: Props) => {
  const [time, setTime] = useState<number>(defaultCount ?? 0);
  let intervalId: NodeJS.Timer;
  useEffect(() => {
    if (start) {
      intervalId = setInterval(() => {
        setTime((old) => (direction === "up" ? old + 1 : old - 1));
      }, 1000);
    }
    return () => {
      clearInterval(intervalId as unknown as number);
    };
  }, [start]);

  const stopClock = () => {
    if (intervalId) {
      clearInterval(intervalId as unknown as number);
    }
  };
  return { time, setTime, stopClock };
};
