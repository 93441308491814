import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import {
  BuildingIcon,
  CalendarIcon,
  ChatIcon,
  ClipboardIcon,
  EllipsisTypographyStyled,
  HtmlWrapper,
} from "../../components";
import { GlobalSearchApiResponse } from "../../state/rtk-query/state/global_search";
import { formatDate, useLocation } from "../../lib";
import { ArrayElement } from "../../global";
import { Link as RouterLink } from "react-router-dom";
import { CallMissedOutgoing } from "@mui/icons-material";

interface SearchResultProp {
  sr: ArrayElement<GlobalSearchApiResponse["rows"]>;
  onSearchPage?: boolean;
}
export default ({ sr, onSearchPage = false }: SearchResultProp) => {
  const IconList = {
    session: CalendarIcon,
    task_title: ClipboardIcon,
    task_code: ClipboardIcon,
    task_description: ClipboardIcon,
    comment: ChatIcon,
    topic: ChatIcon,
    org: BuildingIcon,
    no_category: CallMissedOutgoing,
  };
  const { palette } = useTheme();
  const { query } = useLocation();

  const {
    category,
    task_description,
    task_title,
    top_description,
    org_id,
    org_name,
    session_title,
    tc_comment,
    session_id,
    task_id,
    booking_start_time,
    session_created_at,
    tc_created_at,
    task_created_at,
  } = sr;
  const Icon =
    category && Object.keys(IconList).includes(category) && IconList[category];

  let resultText: string | null | undefined;
  let resultDate: string | null | undefined;

  switch (category) {
    case "session":
      resultText = session_title;
      resultDate = booking_start_time ?? session_created_at;
      break;
    case "comment":
      resultText = tc_comment;
      resultDate = tc_created_at;
      break;
    case "topic":
      resultText = top_description;
      resultDate = task_created_at;
      break;
    case "org":
      resultText = org_name;
      resultDate = "";
      break;
    default:
      resultText = "";
      resultDate = "";
  }
  const taskParams = task_id
    ? `?${new URLSearchParams({
        ...query,
        task: String(task_id),
      }).toString()}`
    : "";

  return (
    <>
      {category && category !== "no_category" ? (
        <Link
          component={RouterLink}
          to={
            category === "session"
              ? `/sessions/${session_id}`
              : [
                  "task_code",
                  "task_description",
                  "task_title",
                  "comment",
                ].includes(category)
              ? taskParams
              : category === "org"
              ? `/organizations/${org_id}`
              : ""
          }
          sx={{ color: "#383C43" }}
        >
          <Stack
            direction={"row"}
            p={2}
            alignItems={onSearchPage ? "flex-start" : "initial"}
          >
            <Box pr={1}>
              {!!Icon && (
                <Icon
                  color={palette.grey[700] as any}
                  width={onSearchPage ? 24 : 20}
                />
              )}
            </Box>
            <Stack
              direction={"column"}
              width={"100%"}
              overflow={"hidden"}
              justifyContent={"center"}
            >
              {["task_code", "task_description", "task_title"].includes(
                category,
              ) ? (
                <Box width={"100%"}>
                  <Stack
                    direction={"row"}
                    justifyContent={
                      task_created_at ? "space-between" : "flex-start"
                    }
                  >
                    <EllipsisTypographyStyled fontSize={16}>
                      <HtmlWrapper
                        message={task_title ?? ""}
                        ellipsis
                        ignoreLists
                      />
                    </EllipsisTypographyStyled>
                    {task_created_at ? (
                      <Typography
                        fontSize={12}
                        color={palette.grey[900]}
                        fontWeight={500}
                      >
                        {formatDate(new Date(task_created_at))}
                      </Typography>
                    ) : null}
                  </Stack>
                  <EllipsisTypographyStyled>
                    <HtmlWrapper
                      message={task_description ?? ""}
                      ellipsis
                      ignoreLists
                    />
                  </EllipsisTypographyStyled>
                </Box>
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent={resultDate ? "space-between" : "flex-start"}
                >
                  <EllipsisTypographyStyled
                    fontSize={16}
                    sx={{ lineHeight: "28px" }}
                  >
                    <HtmlWrapper
                      message={resultText ?? ""}
                      ellipsis
                      ignoreLists
                    />{" "}
                  </EllipsisTypographyStyled>
                  {resultDate ? (
                    <Typography
                      fontSize={12}
                      color={palette.grey[900]}
                      fontWeight={500}
                    >
                      {formatDate(new Date(resultDate))}
                    </Typography>
                  ) : null}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Link>
      ) : null}
    </>
  );
};
