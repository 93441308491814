import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo } from "react";
// local
import { Navigation, filterBoolean } from "../../../lib";
import {
  actions,
  authSelectors,
  timerSelectors,
  useSelector,
} from "../../../state";
import {
  DrawerSubSection,
  DrawerSubSectionHeaderStyled,
} from "../drawer.styles";
import {
  SocketMessage,
  useRefetchSocket,
} from "../../providers/SocketProvider";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { GetSignedUrlByMediaIdApiResponse } from "../../../state/rtk-query/state/media";
import {
  DrawerSubsectionHeader,
  Chat,
  ChatEditor,
  TimerBanner,
  // MultipleSessionsBanner,
} from "..";
import {
  CloseIcon,
  AvatarGroup,
  UploadButton,
  AttendeeAndAssigneeForm,
  LinkIconOrig,
  TaskHeader,
  TaskDescription,
} from "../..";
import {
  TaskDetails,
  TaskMenu,
  AttachmentsWithIcons,
  ValidateTaskWrapper,
  TaskValidationProps,
  TaskSectionType,
} from "../../task";
import { useTimerSetter } from "../../hooks";
import { useMobile } from "../../../themes";
import { BaseModal } from "../../forms/BaseForm";
import { useActivityActionsList, useStatusesList } from "../../hooks/useLists";
import { Loadable } from "../../misc";
import { useCreateUserTimerActivityMutation } from "../../../state/rtk-query/state/timer";
import { useHeightOffset } from "../../hooks/useHeightOffset";

const { useMarkTaskChatAsReadMutation } = actions;

const TaskDrawer = ({
  taskData,
  isFetching,
  id,
  orgId,
  closeTask,
  collapse,
  setCollapse,
  taskChats,
  taskWatchers,
}: TaskValidationProps) => {
  const {
    priority,
    title,
    assigned_to_user,
    client_assignee_user,
    type,
    status,
    description,
    linkedTasks,
    topic,
    deleted_at,
  } = taskData || {};
  const { palette } = useTheme();
  const isMobile = useMobile();
  const [openModal, setOpenModal] = useState(false);

  const [createTimerActivity] = useCreateUserTimerActivityMutation();
  const isInternal = useSelector(authSelectors.isInternal);
  const { state: timerState } = useSelector(timerSelectors.currentTimer) ?? {};

  const { data: taskChat, refetch, isFetching: isFetchingTaskChat } = taskChats;
  const [updateRead] = useMarkTaskChatAsReadMutation();
  const { getActionId } = useActivityActionsList();
  const { getStatusByPhase } = useStatusesList(type, !type);
  const completeStatuses = getStatusByPhase("done");
  const isComplete = completeStatuses.includes(status?.id || 0);
  const isArchived = Boolean(deleted_at);
  const isCompleteOrArchived = isComplete || isArchived;

  const [lightBoxSlides, setLightBoxSlides] = useState<
    GetSignedUrlByMediaIdApiResponse[] | undefined
  >();
  const [lightBoxIndex, setLightBoxIndex] = useState(0);

  let timeout: any;
  const interval = useCallback(
    (action: "start" | "clear") => {
      if (action === "start" && !timeout && id && orgId) {
        timeout = setTimeout(async () => {
          await updateRead({
            id,
            orgId,
            body: { is_read: true },
          });
        }, 5000);
      } else {
        if (timeout && id) clearTimeout(timeout);
      }
    },
    [id, orgId, timeout],
  );
  useEffect(() => {
    if (id && id > 0) {
      interval("start");
    }
    return () => interval("clear");
  }, [id, orgId]);
  const openAction = getActionId("task_opened");

  useEffect(() => {
    if (openAction) {
      createTimerActivity({
        body: { entity: "task", entity_id: id, action_id: openAction },
      });
    }
  }, [openAction, id]);

  useTimerSetter(taskData?.org_id);

  // for receiver
  const shouldRefetch = async (d: SocketMessage) => {
    if (
      d.tag === "tasks_chat" &&
      d.message === "refresh" &&
      id === d.entity_id
    ) {
      refetch();
      interval("start");
    }
  };
  useRefetchSocket({ tag: "tasks_chat", cb: shouldRefetch });

  const isCol = (section: TaskSectionType) => collapse.includes(section);

  const collapseSection = (section: TaskSectionType) => {
    setCollapse((old) => {
      if (!old.includes(section)) {
        return [...old, section];
      } else {
        return old.filter((s) => s !== section);
      }
    });
  };

  const closeAction = getActionId("task_closed");
  const onClose = () => {
    if (closeAction) {
      createTimerActivity({
        body: { entity: "task", entity_id: id, action_id: closeAction },
      });
    }
    closeTask();
  };

  const usersRelatedToTask = useMemo(
    () =>
      filterBoolean([
        assigned_to_user && {
          ...assigned_to_user,
          label: "Assignee",
        },
        client_assignee_user && {
          ...client_assignee_user,
          label: "Client Assignee",
        },
        ...(taskWatchers?.rows ?? []),
      ]),
    [assigned_to_user, client_assignee_user, taskWatchers?.rows],
  );

  const modalClick = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const displayMedia =
    taskData?.media?.filter((m) => !m?.task_media?.is_custom_work_manual) ?? [];

  const moveDrawerDown = timerState === "active" || timerState === "switching";
  const heightOffset = useHeightOffset();
  return (
    <>
      <Drawer
        open={id > 0}
        onClose={onClose}
        anchor="right"
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 2, // over the org select bar under timer
          height: `calc(100vh + ${moveDrawerDown ? heightOffset : 0}px)`,
        })}
        PaperProps={{
          sx: {
            width: isMobile ? "100%" : "40%",
            minWidth: isMobile ? "100%" : "min-content",
            maxWidth: isMobile ? "100%" : "750px",
            marginTop: moveDrawerDown ? `${heightOffset}px` : 0,
          },
        }}
      >
        {isInternal && (
          <TimerBanner
            orgId={taskData?.org_id}
            type={type}
            taskId={id}
            taskCode={taskData?.code ?? ""}
            taskTitle={title ?? ""}
            taskTimeSpent={taskData?.time_spent}
          />
        )}
        <TaskHeader
          taskData={taskData}
          id={id}
          orgId={orgId}
          isComplete={isCompleteOrArchived}
          AvatarAndMenu={
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <AvatarGroup
                users={usersRelatedToTask}
                maxBeforeGrouping={2}
                setOpenModal={
                  !isCompleteOrArchived && type !== "internal_task"
                    ? setOpenModal
                    : undefined
                }
              />
              <TaskMenu
                isComplete={isComplete}
                task={taskData}
                manageAttendeeClick={modalClick}
                isArchived={isArchived}
              />
            </Stack>
          }
          CloseButton={
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <TaskDescription
          collapsed={isCol("description")}
          toggleCollapsed={() => collapseSection("description")}
          id={id}
          orgId={orgId}
          description={description}
          isEditable={!isCompleteOrArchived}
          isLoading={isFetching}
          priority={priority}
          type={type}
          title={title}
          topicId={topic?.id}
          topicName={topic?.name}
          code={taskData?.code || ""}
        />
        <Divider />
        {/* TODO: needs discussion  */}
        {/* <MultipleSessionsBanner sessions={taskData?.sessions} /> */}
        {!!linkedTasks &&
          linkedTasks.map((lt, i) => (
            <Stack
              bgcolor={palette.info[100]}
              direction={"row"}
              sx={{ fontSize: 12, whiteSpace: "nowrap" }}
              alignItems="center"
              px={4}
              py={0.5}
              height={"32px"}
              width="100%"
              key={i}
            >
              <Stack alignItems="center">
                <LinkIconOrig color={palette.info[800]} size={13} />
              </Stack>
              <Typography width={"auto"} fontSize={12}>
                &nbsp;Task linked to&nbsp;
              </Typography>
              <Typography component="div" noWrap>
                <Link
                  component={RouterLink}
                  to={Navigation.appendToUrl({
                    task: lt.linkedTaskId,
                  })}
                >
                  <Typography
                    fontSize={12}
                    color={palette.info[800]}
                    fontWeight={600}
                    overflow={"hidden"}
                    textOverflow="ellipsis"
                  >
                    {lt.linkedTask.title || ""}
                  </Typography>
                </Link>
              </Typography>
            </Stack>
          ))}
        <DrawerSubSection>
          {taskData ? <TaskDetails details={taskData} /> : null}
        </DrawerSubSection>
        <Divider />
        <DrawerSubSection>
          <DrawerSubsectionHeader
            title={`Attachments
              ${displayMedia?.length ? ` \u00b7 ${displayMedia.length}` : ""}`}
            onClick={() => collapseSection("attachments")}
            rotate={isCol("attachments")}
          />
          <Loadable isLoading={isFetching} useProgressBar>
            {!isCol("attachments") ? (
              <AttachmentsWithIcons
                taskId={id}
                setLightBoxSlides={setLightBoxSlides}
                setLightBoxIndex={setLightBoxIndex}
                allMedia={displayMedia}
                isComplete={isCompleteOrArchived}
              />
            ) : (
              <></>
            )}
          </Loadable>

          {!isCompleteOrArchived && <UploadButton taskId={id} />}
        </DrawerSubSection>
        <Divider />
        <DrawerSubSection>
          <DrawerSubSectionHeaderStyled my={1}>
            <Typography variant="h4">
              Comments {taskChat?.count ? ` \u00b7 ${taskChat.count}` : ""}
            </Typography>
          </DrawerSubSectionHeaderStyled>

          <Loadable isLoading={isFetchingTaskChat && !taskChat} useProgressBar>
            <ChatEditor
              taskId={id}
              orgId={taskData?.org_id ?? 0}
              isInternalTask={taskData?.type === "internal_task"}
              currentActionPending={taskData?.action_pending}
              assigned_to_user={assigned_to_user}
              client_assignee_user={client_assignee_user}
            />
          </Loadable>

          <Loadable isLoading={isFetchingTaskChat && !taskChat} useProgressBar>
            <Box
              sx={{
                maxHeight: `calc(400px + ${isInternal ? "48px" : 0})`,
                mb: 8,
                minWidth: "100%",
              }}
              overflow={"auto"}
            >
              {taskChat?.rows.map((c, idx) => (
                <Chat
                  key={c.id}
                  chatDetails={c}
                  previousChat={taskChat.rows[idx > 0 ? idx - 1 : 0]}
                  isFirst={idx === 0}
                  orgId={taskData?.org_id ?? 0}
                  isComplete={isCompleteOrArchived}
                  assigned_to_user={assigned_to_user}
                  client_assignee_user={client_assignee_user}
                />
              ))}
            </Box>
          </Loadable>
        </DrawerSubSection>
      </Drawer>

      <Lightbox
        open={Boolean(lightBoxSlides)}
        close={() => setLightBoxSlides(undefined)}
        index={lightBoxIndex}
        slides={lightBoxSlides?.map((s) => {
          return { src: s?.signedUrl };
        })}
      />
      <BaseModal open={openModal} onClose={modalClick} title="Manage people">
        <AttendeeAndAssigneeForm onClose={modalClick} task={taskData} />
      </BaseModal>
    </>
  );
};
const ValidateTaskDrawer = () => (
  <ValidateTaskWrapper ReturnNode={TaskDrawer} />
);
export { ValidateTaskDrawer as TaskDrawer };
