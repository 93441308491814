import { useState } from "react";
import { ConfirmDeleteDialog, ConfirmDialogProps } from "../tables";
import { SxProps } from "@mui/material";

interface DeReactivateButtonProps {
  isActive: boolean;
  entity: string;
  buttonText?: string;
  color?: ConfirmDialogProps["buttonColor"];
  handleClick: (type: "re" | "de") => void;
  sx?: SxProps;
  buttonSx?: SxProps;
}

export function DeReactivateButton({
  isActive,
  entity,
  buttonText,
  color,
  handleClick,
  sx,
}: DeReactivateButtonProps) {
  const [reactivating, setReactivating] = useState(false);
  const type = (isActive ? "De" : "Re") + "activate";
  if (reactivating) {
    return null;
  }
  return (
    <ConfirmDeleteDialog
      titleText={type + " " + entity}
      subTitleText={
        isActive
          ? `Are you sure you want to deactivate this ${entity}?`
          : `Please review and save the form to reactivate ${entity}.`
      }
      buttonText={buttonText || type}
      buttonColor={color || "primary"}
      modalProps={
        isActive
          ? { confirmActionButtonText: "Deactivate" }
          : {
              confirmActionButtonText: "OK",
              confirmActionButtonColor: "primary",
              pcWidth: "28%",
            }
      }
      handleConfirm={() => {
        if (!isActive) {
          setReactivating(true);
        }
        handleClick(isActive ? "de" : "re");
      }}
      sx={sx}
    />
  );
}
