// import { ContactsViewState } from "./contacts/state";
// import { contactActions, contactSelectors } from "./states";
// import { useAction, useSelector } from "./utils";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
export function useTrue() {
  return [true];
}

/** Creates a callback function to dispatch the given action. */
function useAction(action: any) {
  const dispatch = useDispatch();
  return useCallback(
    (...args: any[]) => dispatch(action(...args)),
    [dispatch, action],
  );
}

export { useAction };
// export function useContactBool(
//   name: keyof ContactsViewState,
// ): [boolean, () => void, (value?: any) => void] {
//   const value = useSelector(contactSelectors.viewBool(name));
//   const setTrue = useAction(contactActions.showView(name));
//   const setFalse = useAction(contactActions.hideView(name));
//   return [value, setTrue, setFalse];
// }
