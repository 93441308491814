import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { CardStyled, useDatagridProps, useQuery } from "../../../components";
import { StyledDataGrid } from "../../../components/tables/DataGrid.styles";
import {
  useGetMigrationQueueQuery,
  useAddToQueueMutation,
  useRemoveFromQueueMutation,
  useUpdateCodeMutation,
} from "../../../state/rtk-query/state/migration_queue";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";
import { useState } from "react";
import {
  GridRenderEditCellParams,
  GridValidRowModel,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { formatDate } from "../../../lib";

const pageSizeOptions = [10, 25, 50, 100, 250, 500];

function RenderEditCode(
  props: GridRenderEditCellParams<
    GridValidRowModel,
    any,
    any,
    GridTreeNodeWithRender
  >,
) {
  const getCode = (code?: string | null) =>
    code ? code.toUpperCase().trim().slice(0, 4) : "";
  const [input, setInput] = useState(getCode(props.value));
  const [updateCode] = useUpdateCodeMutation();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 3,
        px: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <TextField
        placeholder="ABCD"
        focused
        fullWidth
        value={input}
        onChange={(e) => setInput(getCode(e.target.value))}
        onBlur={() =>
          updateCode({
            id: props.row.id,
            body: { code: getCode(input) || null },
          })
        }
        onKeyDown={(e) =>
          e.key === "Enter" &&
          updateCode({
            id: props.row.id,
            body: { code: getCode(input) || null },
          })
        }
      />
    </Box>
  );
}

export function MigrationPage() {
  const [{ field = "client_name", sort = "asc" }, setParams] = useQuery<{
    field?: string;
    sort?: "asc" | "desc" | null;
  }>("field", "sort");
  const { palette } = useTheme();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { count, rows } = useGetMigrationQueueQuery(undefined, {
    skip: !isAdmin,
    selectFromResult: (r) => ({
      count: r.currentData?.count || 0,
      rows: r.currentData?.rows || [],
    }),
  });
  const { slotProps, autosizeOptions } = useDatagridProps();
  const [addToQueue, { isLoading: isAdding }] = useAddToQueueMutation();
  const [removeFromQueue, { isLoading: isRemoving }] =
    useRemoveFromQueueMutation();
  return (
    <CardStyled sx={{ height: "100%", mb: 2 }}>
      <StyledDataGrid
        rowCount={count}
        rows={rows}
        columns={[
          { field: "client_name", headerName: "Client", flex: 1 },
          {
            field: "code",
            headerName: "Org code",
            width: 200,
            editable: true,
            renderEditCell: (params) => {
              return <RenderEditCode {...params} />;
            },
          },
          {
            field: "status",
            headerName: "Status",
            width: 200,
            valueFormatter: (r) => r.value?.split("_").join(" ").toUpperCase(),
          },
          {
            field: "created_at",
            headerName: "Completed on",
            width: 200,
            type: "date",
            valueGetter: (v) => {
              if (v.row.status !== "completed") {
                return null;
              }
              return new Date(v.row.created_at);
            },
            renderCell: (p) => {
              const created = p.row?.created_at;
              const date = created && new Date(created);
              if (p.row.status !== "completed") {
                return null;
              }
              return (
                <>
                  {date ? (
                    <Typography
                      variant="subtitle2"
                      fontSize={14}
                      fontWeight={500}
                    >
                      {formatDate(date, "M.d.yy")} &middot;{" "}
                      {formatDate(date, "h:mm a")}
                    </Typography>
                  ) : null}
                </>
              );
            },
          },
          {
            field: "action",
            headerName: "",
            type: "actions",
            getActions: (r) => [
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  r.row.status === "waiting"
                    ? removeFromQueue({ id: r.row.id })
                    : addToQueue({ id: r.row.id })
                }
                disabled={
                  !r.row.code ||
                  !(
                    !r.row.status ||
                    r.row.status === "failed" ||
                    r.row.status === "waiting"
                  ) ||
                  isRemoving ||
                  isAdding
                }
                sx={{ fontSize: 12 }}
              >
                {r.row.status === "waiting"
                  ? "Remove from queue"
                  : "Add to queue"}
              </Button>,
            ],
            width: 200,
          },
        ]}
        sortModel={[{ field, sort }]}
        onSortModelChange={(s) => {
          setParams({
            field: s[0]?.field,
            sort: s[0]?.sort,
          });
        }}
        pagination
        pageSizeOptions={pageSizeOptions}
        autosizeOnMount={true}
        slotProps={slotProps}
        autosizeOptions={autosizeOptions}
        sx={{
          "&.MuiDataGrid-root": {
            border: `1px solid ${palette.grey[300]}`,
            borderRadius: "6px",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            textTransform: "uppercase",
          },
          ".MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
      />
    </CardStyled>
  );
}
