import { Button } from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { useState } from "react";
import { ModalJSONReportView } from "../modal/ModalJSONReportView";

export const JSONPopup = (
  props: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
) => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setShowDialog(true)}
      >
        Show Data
      </Button>
      <ModalJSONReportView
        props={props}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </>
  );
};
