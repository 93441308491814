import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown } from "../icons";
import { useState } from "react";

interface ToggleDropdownProps {
  option1: string;
  option2: string;
  value: string;
  setValue: (val: string) => void;
  buttonPadding?: number;
}

export function ToggleDropdown({
  option1,
  option2,
  value,
  setValue,
  buttonPadding,
}: ToggleDropdownProps) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const anchorOrigin = { vertical: "bottom", horizontal: "left" } as const;
  const transformOrigin = {
    vertical: "top",
    horizontal: "left",
  } as const;
  return (
    <Stack width={"auto"}>
      <Button
        onClick={handleClick}
        color="black"
        aria-expanded={open ? "true" : undefined}
        sx={{
          width: "fit-content",
          height: "max-content",
          ...(buttonPadding !== undefined && { p: buttonPadding }),
        }}
      >
        <Typography
          fontSize={"1em"}
          width={"100%"}
          fontWeight={500}
          color="primary"
          display={"flex"}
          alignItems={"center"}
          noWrap
        >
          <Box component={"span"}>
            {value
              .split("_")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </Box>
          <ChevronDown color={palette.primary.main} />
        </Typography>
      </Button>
      <Menu
        aria-labelledby="sortable-button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{ maxHeight: "100%" }}
      >
        <MenuItem
          key={option1}
          value={option1}
          sx={{
            ":hover": { bgcolor: palette.accent[50] },
            bgcolor: value === option1 ? palette.accent[50] : undefined,
          }}
          onClick={() => {
            setValue(option1);
            handleClose();
          }}
        >
          {option1
            .split("_")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")}
        </MenuItem>
        <MenuItem
          key={option2}
          value={option2}
          sx={{
            ":hover": { bgcolor: palette.accent[50] },
            bgcolor: value === option2 ? palette.accent[50] : undefined,
          }}
          onClick={() => {
            setValue(option2);
            handleClose();
          }}
        >
          {option2
            .split("_")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
