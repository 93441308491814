import { useMediaQuery, createTheme, PaletteColorOptions } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  // custom palette colors
  interface Palette {
    accent: PaletteColor | "main";
    white: "contrastText" | "main";
    black: PaletteColor | "main";
    blue: PaletteColor | "main";
    orange: PaletteColor | "main";
  }
  interface PaletteOptions {
    accent: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    blue: PaletteColorOptions;
    orange: PaletteColorOptions;
  }
  // custom typography styles
  interface TypographyVariants {
    labelSmall: React.CSSProperties;
    labelLarge: React.CSSProperties;
    overlineXS: React.CSSProperties;
    overlineMedium: React.CSSProperties;
    overlineLarge: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    labelSmall?: React.CSSProperties;
    labelLarge?: React.CSSProperties;
    overlineXS?: React.CSSProperties;
    overlineMedium?: React.CSSProperties;
    overlineLarge?: React.CSSProperties;
    fontWeightSemiBold?: React.CSSProperties["fontWeight"];
  }
  interface PaletteColor extends ColorPartial {}
}

// custom typography variants
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    labelSmall: true;
    labelLarge: true;
    overlineXS: true;
    overlineMedium: true;
    overlineLarge: true;
  }
}

// custom button variants
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
    white: true;
    accent: true;
  }
}

// custom icon button variants
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

// color definitions
const primaryMain = "#555FE6";
const primary100 = "#E7E8FA";
const primary200 = "#CCCEF5";
const primary700 = "#4149B2";
const primary800 = "#353C91";

const secondaryMain = "#00C2FF";
const secondary600 = "#00ADE4";

const errorMain = "#F45370";
const error300 = "#F8ADB7";
const error700 = "#BD4056";
const error600 = "#DA4A64";
const error800 = "#9A3446";

const successMain = "#1EC28B";
const success100 = "#E4F4EC";
const success800 = "#127A57";

const infoMain = "#A95BFF";
const info600 = "#9751E4";

const warningMain = "#FCCB5E";
const warning100 = "#FEF5E7";
const warning600 = "#E1B554";

const greyMain = "#DFE1EB";
const grey50 = "#FBFCFD";
const grey100 = "#F8F9FB";
const grey200 = "#F2F3F7";
const grey300 = "#DFE1EB";
const grey400 = "#E5E7EF";
const grey600 = "#C7C9D2";
const grey700 = "#ACAEB6";
const grey800 = "#8D8E94";
const grey900 = "#636469";

const accentMain = "#6E66FF";
const accent700 = "#554FC5";

const blueMain = "#1D1D89";

const blackMain = "#353847";
const black900 = "#17191F";

const white = "#FFFFFF";

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */

export const defaultTheme = createTheme({
  palette: {
    // color palette: https://www.figma.com/file/52e0I4WSvhS8rimFFkge4s/Admire-Design-System?node-id=43%3A6026&t=LZJGzBbaIA4wz3ck-0
    primary: {
      50: "#F3F3FC",
      100: primary100,
      200: primary200,
      300: "#AEB1F0",
      400: "#898EEB",
      500: primaryMain,
      600: "#4C54CD",
      700: primary700,
      800: primary800,
      900: "#262A66",
      main: primaryMain,
    },
    secondary: {
      50: "#F1F9FF",
      100: "#E4F4FF",
      200: "#C5E8FF",
      300: "#A1DCFF",
      400: "#72CFFF",
      500: secondaryMain,
      600: secondary600,
      700: "#0096C5",
      800: "#007AA1",
      900: "#005672",
      main: secondaryMain,
    },
    error: {
      50: "#FDF3F4",
      100: "#FCE7E9",
      200: "#FACCD1",
      300: error300,
      400: "#F68897",
      500: errorMain,
      600: error600,
      700: error700,
      800: error800,
      900: "#6D2532",
      main: errorMain,
    },
    success: {
      50: "#F2F9F5",
      100: success100,
      200: "#C6E8D8",
      300: "#A2DCC1",
      400: "#75CFA8",
      500: successMain,
      600: "#1AAD7C",
      700: "#17966B",
      800: success800,
      900: "#0D563E",
      main: successMain,
    },
    // Figma: Tertiary
    info: {
      50: "#F7F3FF",
      100: "#F0E7FF",
      200: "#E0CDFF",
      300: "#CFB0FF",
      400: "#BD8CFF",
      500: infoMain,
      600: info600,
      700: "#8246C5",
      800: "#6A39A1",
      900: "#4B2872",
      main: infoMain,
    },
    warning: {
      50: "#FEFAF3",
      100: warning100,
      200: "#FDEBCE",
      300: "#FDE1B0",
      400: "#FCD68D",
      500: warningMain,
      600: warning600,
      700: "#C39D48",
      800: "#9F803B",
      900: "#705A2A",
      main: warningMain,
    },
    grey: {
      50: grey50,
      100: grey100,
      200: grey200,
      300: grey300,
      400: grey400,
      500: greyMain,
      600: grey600,
      700: grey700,
      800: grey800,
      900: grey900,
    },
    accent: {
      50: "#F4F4FF",
      100: "#E9E8FF",
      200: "#D1CFFF",
      300: "#B6B3FF",
      400: "#9692FF",
      500: accentMain,
      600: "#625BE4",
      700: accent700,
      800: "#4540A1",
      900: "#312D72",
      main: accentMain,
    },
    blue: {
      50: "#F2F2F5",
      100: "#E4E4EC",
      200: "#C6C6D7",
      300: "#A2A2C1",
      400: "#7474A7",
      500: blueMain,
      600: "#19197A",
      700: "#16166A",
      800: "#121256",
      900: "#0C0C3D",
      main: blueMain,
    },
    orange: {
      100: "#FCEADE",
      500: "#FCA05E",
      600: "#E69245",
      main: "#FCA05E",
    },
    black: {
      500: blackMain,
      900: black900,
      main: blackMain,
    },
    white: {
      contrastText: grey700,
      main: white,
    },
    text: {
      primary: blackMain,
      secondary: grey700,
    },
    background: {
      default: white,
    },
  },

  typography: {
    fontFamily: "Gilroy, Arial",
    // font weight defintions
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    // variants
    h1: {
      fontSize: "1.5em !important",
      lineHeight: "33px",
      letterSpacing: "0.02em",
      fontWeight: "600",
    },
    h2: {
      fontSize: "1.25em !important",
      lineHeight: "32px",
      letterSpacing: "0.02em",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1em !important",
      lineHeight: "17px",
      letterSpacing: "0.02em",
      fontWeight: "600",
    },
    h4: {
      fontSize: ".9em !important",
      lineHeight: "17px",
      letterSpacing: "0.02em",
      fontWeight: "600",
    },
    h5: {
      fontSize: "25px",
      lineHeight: "30px",
      letterSpacing: "0.02em",
    },
    h6: {
      fontSize: "22px",
      lineHeight: "26px",
      letterSpacing: "0.02em",
    },
    labelLarge: {
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.02em",
    },
    labelSmall: {
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.02em",
    },
    // Figma: Body/Default
    body1: {
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.01em",
    },
    // Figma: Body/Small
    body2: {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.02em",
    },
    subtitle1: {
      fontSize: 13,
      fontWeight: 500,
      color: "#000000",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 500,
      color: "#767676",
      letterSpacing: 0.15,
    },
    // Figma: Overline/Small
    overline: {
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.06em",
      textTransform: "uppercase",
    },
    overlineXS: {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      textTransform: "uppercase",
    },
    overlineMedium: {
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
    },
    overlineLarge: {
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.10em",
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.02em",
    },
    button: { textTransform: "none" },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "600",
        },
        sizeSmall: {
          height: "24px",
          padding: "10px 0px",
          fontSize: "14px",
          lineHeight: "16px",
          "& .MuiChip-icon": {
            height: "12.8px",
            width: "12.8px",
          },
        },
        sizeMedium: {
          height: "32px",
          padding: "10px 12px",
          fontSize: "16px",
          lineHeight: "20px",
          "& .MuiChip-icon": {
            height: "16px",
            width: "16px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: ".5rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        //button sizes
        sizeSmall: {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: "500",
          padding: "6px 12px",
          height: "32px",
        },
        sizeMedium: {
          fontSize: "14px",
          lineHeight: "19px",
          padding: "6px 16px",
          height: "38px",
        },
        sizeLarge: {
          fontSize: "15px",
          lineHeight: "20px",
          padding: "8px 22px",
          height: "44px",
        },
        // button variants:
        // contained variant
        contained: {
          color: white,
          borderRadius: "6px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:disabled": {
            backgroundColor: `${grey200} !important`,
            border: `1px solid ${grey600}`,
            color: `${grey600} !important`,
          },
          // custom button colors:
          // accent
          "&.MuiButton-containedAccent": {
            backgroundColor: accentMain,
            "&:hover": {
              backgroundColor: accent700,
            },
          },
          // white
          "&.MuiButton-containedWhite": {
            backgroundColor: white,
            border: `1px solid ${greyMain}`,
            color: grey900,
            "&:hover": {
              backgroundColor: grey100,
              borderColor: "transparent",
            },
            "&:disabled": { border: "none" },
          },
          // black
          "&.MuiButton-containedBlack": {
            backgroundColor: blackMain,
            "&:hover": {
              backgroundColor: black900,
            },
            "&:disabled": { border: "none" },
          },
        },
        // outlined variant
        outlined: {
          borderWidth: "2px",
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: "initial",
            borderWidth: "2px",
          },
          "&:disabled": {
            backgroundColor: `${grey200} !important`,
            border: `1px solid ${grey600}`,
            color: `${grey600} !important`,
          },
          // custom button colors:
          // accent
          "&.MuiButton-outlinedAccent": {
            borderColor: accentMain,
            "&:hover": {
              borderColor: accent700,
              color: accent700,
            },
          },
          // white
          "&.MuiButton-outlinedWhite": {
            backgroundColor: white,
            borderColor: greyMain,
            color: grey900,
            "&:hover": {
              backgroundColor: grey100,
            },
          },
          // black
          "&.MuiButton-outlinedBlack": {
            borderColor: blackMain,
            color: blackMain,
            "&:hover": {
              borderColor: black900,
              color: black900,
            },
          },
        },
        outlinedPrimary: {
          borderColor: primaryMain,
          "&:hover": {
            borderColor: primary700,
            color: primary700,
          },
        },
        outlinedSecondary: {
          borderColor: secondaryMain,
          "&:hover": {
            borderColor: secondary600,
            color: secondary600,
          },
        },
        outlinedInfo: {
          borderColor: infoMain,
          "&:hover": {
            borderColor: info600,
            color: info600,
          },
        },
        outlinedError: {
          borderColor: errorMain,
          "&:hover": {
            borderColor: error800,
            color: error800,
          },
        },
        // text variant
        text: {
          "&:hover": {
            background: "initial",
          },
          "&:disabled": {
            color: `${grey700} !important`,
          },
          // custom button colors:
          // accent
          "&.MuiButton-textAccent": {
            color: accentMain,
            "&:hover": {
              color: accent700,
            },
          },
          // white
          "&.MuiButton-textWhite": {
            color: white,
            "&:hover": {
              color: grey400,
            },
          },
          // black
          "&.MuiButton-textBlack": {
            color: blackMain,
            "&:hover": {
              color: black900,
            },
          },
        },
        textPrimary: {
          color: primaryMain,
          "&:hover": {
            color: primary700,
          },
        },
        textSecondary: {
          color: secondaryMain,
          "&:hover": {
            color: secondary600,
          },
        },
        textInfo: {
          color: infoMain,
          "&:hover": {
            color: info600,
          },
        },
        textError: {
          color: errorMain,
          "&:hover": {
            color: error800,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          //error
          "&.MuiIconButton-colorError": {
            "&:hover": {
              color: error700,
            },
          },
          //info
          "&.MuiIconButton-colorInfo": {
            "&:hover": {
              color: info600,
            },
          },
          // white
          "&.MuiIconButton-colorWhite": {
            backgroundColor: white,
            borderColor: greyMain,
            color: grey900,
            "&:hover": {
              backgroundColor: grey100,
            },
          },
        },
        colorPrimary: {
          "&:hover": {
            color: primary700,
          },
        },
        colorSecondary: {
          "&:hover": {
            color: info600,
          },
        },
        sizeSmall: {
          height: "32px",
          width: "32px",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          borderRadius: "6px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: ".5rem",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primaryMain,
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "19px",
          textDecoration: "none",
          ":hover": {
            opacity: 0.8,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          justifyContent: "center",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "&&": { marginBottom: "0px" },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          color: grey900,
          "&.MuiToggleButton-primary.Mui-selected": {
            backgroundColor: primaryMain,
            color: white,
          },
        },
        sizeMedium: {
          padding: "8px, 9px, 8px, 9px",
          height: "38px",
          fontSize: "18px",
        },
        sizeSmall: {
          height: "32px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:not(.MuiInputBase-multiline)": {
            height: "40px",
          },
          "& svg": {
            color: grey900,
          },
          // padding adjustments to accomodate input height override
          "& input, & textarea": {
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
        multiline: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
        sizeSmall: {
          "&:not(.MuiInputBase-multiline)": {
            height: "32px",
          },
          // padding adjustments to accomodate input height override
          "& input": {
            paddingTop: "4px",
            paddingBottom: "4px",
          },
        },
        input: {
          "&::placeholder": {
            color: grey600,
            opacity: "100%",
          },
          "&:focus": {
            backgroundColor: white,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          "&:hover fieldset": {
            borderColor: `${grey600} !important`,
          },
          "&.Mui-focused fieldset": {
            border: `2px solid ${primary200} !important`,
          },
          "&.Mui-error fieldset": {
            border: `2px solid ${error300} !important`,
          },
          "&.Mui-disabled": {
            backgroundColor: grey200,
            "& fieldset": {
              border: "none",
            },
          },
        },
        notchedOutline: {
          borderColor: greyMain,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: blackMain,
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "16px",
          marginBottom: "6px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grey800,
          fontSize: "14px",
          marginLeft: "0px",
          marginTop: "4px",
          "&.Mui-error": {
            color: error600,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          // input padding adjustments to accomodate input height overrides
          paddingTop: "8px",
          paddingBottom: "8px",
          "&.MuiInputBase-inputSizeSmall": {
            paddingTop: "4px",
            paddingBottom: "4px",
          },
        },
        icon: {
          // top: "calc(50% - 11px)",
          color: `${primaryMain} !important`,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          // eliminate top/bottom padding to accomodate input height overrides
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
        //override padding for items with divider
        option: {
          "&.MuiMenuItem-divider": {
            padding: "14px 12px 15px 12px !important",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: "1.5em",
          },
          // disabled checkbox style
          "&.Mui-disabled": {
            "&.Mui-checked span": {
              backgroundColor: `${grey400} !important`,
            },
            "&:not(.Mui-checked) svg": {
              fill: `${grey400} !important`,
              "& rect": {
                stroke: `${grey400} !important`,
              },
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fontSize: "1.5em",
          },

          // disabled radio style
          "&.Mui-disabled svg": {
            fill: `${grey400} !important`,
            "& rect": {
              fill: `${grey400} !important`,
              stroke: `${grey400} !important`,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // disabled form control label style
        label: {
          "&.Mui-disabled": {
            color: grey700,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          maxHeight: "100vh",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          display: "flex",
          alignItems: "center",
          whiteSpace: "pre-wrap",
        },
        outlined: {
          backgroundColor: white,
        },
        outlinedError: {
          borderColor: error600,
          "& .MuiAlert-icon svg": { color: error600 },
        },
        outlinedSuccess: {
          borderColor: primaryMain,
          "& .MuiAlert-icon svg": { color: primaryMain },
        },
        filledError: {
          backgroundColor: error600,
          "& .MuiButton-outlined:hover": { backgroundColor: white },
        },
        filledSuccess: {
          backgroundColor: primaryMain,
          color: white,
          "& .MuiButton-outlined:hover": { backgroundColor: white },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          rounded: {
            borderRadius: "12px",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: grey900,
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "16px",
          textTransform: "uppercase",
        },
        root: {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: "500",
          verticalAlign: "top",
        },
        sizeMedium: {
          padding: "14px 12px",
          "&.MuiTableCell-head": {
            padding: "16px 12px",
          },
        },
        sizeSmall: {
          padding: "8px 12px",
          "&.MuiTableCell-head": {
            padding: "10px 12px",
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          position: "relative",
        },
        dot: {
          height: "8px",
          width: "16px",
          borderRadius: "4px",
        },
        dotActive: {
          width: "24px",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1025,
      xl: 1536,
    },
  },
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "(max-width:599px)") {
  return useMediaQuery(size);
}

export function useTablet(size = "(max-width:1024px)") {
  return useMediaQuery(size);
}

export const isHoverableDevice = matchMedia(
  "(hover: hover), (pointer: fine)",
).matches;
