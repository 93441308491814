import { memo, useCallback, useEffect } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  AppThunkDispatch,
  adminActions,
  adminSelectors,
  uiActions,
} from "../../../state";
import { actions } from "../../../state/rtk-query";
const { useGetGoogleAuthStatusQuery, useLazyGetGoogleOauthRedirectQuery } =
  actions;
export const CalendarPage = memo(function () {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const googleCalendars = useSelector(adminSelectors.googleCalendars);
  const { currentData } = useGetGoogleAuthStatusQuery();
  const [getRedirect] = useLazyGetGoogleOauthRedirectQuery();
  const hasOAuthAccessToken = currentData ? currentData.hasAccessToken : false;
  useEffect(() => {
    if (searchParams.get("code")) {
      dispatch(adminActions.getOAuthTokens({ code: searchParams.get("code") }));
      setSearchParams({});
    }
  }, [searchParams]);

  useEffect(() => {
    if (hasOAuthAccessToken) {
      dispatch(adminActions.getGoogleCalendars());
    } else {
      dispatch(uiActions.showError("failed to getGoogleCalendars"));
    }
  }, [hasOAuthAccessToken]);

  const redirect = useCallback(async () => {
    const { data, isSuccess } = await getRedirect();
    if (isSuccess && data && data?.oauthRedirectUrl) {
      window.location.href = data.oauthRedirectUrl;
      dispatch(uiActions.showSuccess("Hang tight"));
    } else {
      dispatch(uiActions.showError("failed to authenticate"));
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Typography variant="h1">Your Calendars</Typography>
        {!hasOAuthAccessToken && (
          <Button onClick={redirect}>Sign in with Google 🚀</Button>
        )}
      </Box>
      <Box>
        <Grid container spacing={2}>
          {googleCalendars.map((c: any, idx) => (
            <Grid key={idx} item xs={12} md={3}>
              <Card
                sx={{
                  p: 4,
                  width: "100%",
                  height: 100,
                  backgroundColor: c.backgroundColor,
                }}
              >
                <Typography>{c.summary} - Google Calendar</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
});
