import {
  ActiveElement,
  Chart,
  ChartEvent,
  ChartOptions,
  TooltipItem,
} from "chart.js";
import { defaultBarOptions } from "../ChartConstants";
// import { castZero } from "../../../lib";

export const ADMIN_BAR_CHART_THICKNESS = 12;
// amount of rows before we display the top sticky axis
export const ADMIN_DISPLAY_TOP_AXIS = 25;
export const adminChartOptions: ChartOptions<"bar"> = {
  ...defaultBarOptions,
  indexAxis: "y",
  maintainAspectRatio: false,
  // helps with performance by removing animations/extra lines and gaps
  hover: {
    animationDuration: 0,
  },
  showLine: false,
  spanGaps: true,
  animation: false,
  responsiveAnimationDuration: 0,
  normalized: true,
  plugins: {
    legend: { display: false },

    tooltip: {
      enabled: false,
      mode: "y",
      yAlign: "center",
      axis: "y",

      callbacks: {
        title: function (context: TooltipItem<"bar">[]) {
          return context[0].label;
        },
        label: function (context: TooltipItem<"bar">) {
          return `${context.dataIndex}`;
        },
      },
    },
    elements: {
      point: {
        radius: 0, // default to disabled in all datasets
      },
      line: {
        tension: 0, // disables bezier curves
      },
    },
  },

  onHover: (event: ChartEvent, chartElement: ActiveElement[], chart: Chart) => {
    const chartCanvas = event?.native?.target as any;

    if (chartElement.length && chartCanvas) {
      chartCanvas.style.cursor = "pointer";
    } else {
      chartCanvas.style.cursor = "default";
    }
    if (chartElement.length) {
      const index = chartElement[0].index;
      const yScale = chart.scales.y;

      yScale.ticks.forEach((_tick: any, tickIndex: number) => {
        if (tickIndex === index) {
          (yScale.options as any).ticks.font = (context: any) =>
            context.index === index ? { weight: "bold" } : { weight: "normal" };
        }
      });
    } else {
      const yScale = chart.scales.y;
      (yScale.options as any).ticks.font = () => ({
        weight: "normal",
      });
    }
    chart.update();
  },

  scales: {
    // x2: {
    //   ...defaultBarOptions.scales?.x,
    //   position: "top",
    //   ticks: {
    //     callback(value) {
    //       const yScale = this.chart.scales.y;

    //       const isDecimal = this.ticks[this.ticks.length - 2].value;
    //       return (yScale as any)?._valueRange < ADMIN_DISPLAY_TOP_AXIS
    //         ? ""
    //         : +value === 0
    //         ? "0"
    //         : isDecimal < 1
    //         ? castZero(+value).toFixed(1) //Math.round(castZero(+value) * 10) / 10
    //         : value;
    //     },
    //   },
    // },
    x: {
      ...defaultBarOptions.scales?.x,
      beginAtZero: true,
      min: 0,
      position: "bottom",
    },

    y: {
      ...defaultBarOptions.scales?.y,

      grid: {
        ...defaultBarOptions.scales?.y?.grid,
      },
      afterFit(scale) {
        scale.width = 200;
        const xScale = scale.chart.scales.x;

        scale.chart.scales.x2 = xScale;

        (scale.chart.scales.x2 as any).id = "x2";
      },

      ticks: {
        minRotation: 0,
        maxRotation: 0,
        callback: function (value) {
          const lbl = this.getLabelForValue(value as number);
          // 27 seems to be a good length for the label to fit in 200px width set above
          // the reason we need a width is because on hover we make the label bold which causes the whole chart to shift
          if (typeof lbl === "string" && lbl.length > 25) {
            return `${lbl.substring(0, 25)}...`;
          }

          return lbl;
        },
        font: {
          weight: "normal",
        },
      },
      stacked: true,
    },
  },
} as ChartOptions<"bar">;
