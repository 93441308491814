import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BaseForm, BaseModal } from "../forms";
import {
  TopLabel,
  SelectField,
  InputField,
  AutoCompleteField,
} from "../forms/FormFields";
import { useOrgsList, useActivityTypesList } from "../hooks/useLists";
import {
  Stack,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ClockIcon } from "../icons";
import { isDate, useLocation, yup } from "../../lib";
import { format, isAfter, isBefore } from "date-fns";
import {
  CreateTimerApiArg,
  useCreateTimerMutation,
  useDeleteTimerMutation,
  useGetTimerQuery,
  useUpdateTimerMutation,
} from "../../state/rtk-query/state/timer";
import { FormikProps } from "formik";
import { useMobile } from "../../themes";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { SingleDateTimeField } from "../inputs";
import { ConfirmDeleteDialog } from "../tables";
import { useGetCwTasksQuery } from "../../state/rtk-query/state/tasks";
import { skipToken } from "@reduxjs/toolkit/query";
interface Props {
  open: boolean;
  onClose: () => void;
}

type EntryType = "time" | "duration";
interface FormValues {
  org_id: number;
  duration?: number;
  start_time: Date | undefined;
  end_time: Date | undefined;
  duration_date: Date | undefined;
  activity_type_id?: number;
  task_id?: number;
  notes?: string;
}

export const TimeEntryModal = ({ open, onClose }: Props) => {
  const { query } = useLocation();
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const { orgs } = useOrgsList(!isInternal);
  const ref = useRef<FormikProps<any>>(null);
  const isMobile = useMobile();
  const { activityTypes } = useActivityTypesList();
  const [entryType, setEntryType] = useState<EntryType>("time");
  const [openDuration, setOpenDuration] = useState(false);

  const [createTimer] = useCreateTimerMutation();
  const [updateTimer] = useUpdateTimerMutation();
  const [deleteTimer] = useDeleteTimerMutation();
  const { currentData: CWTasks } = useGetCwTasksQuery(undefined, {
    skip: !isInternal,
  });
  const { currentData: timer } = useGetTimerQuery(
    query?.timerId
      ? {
          id: query?.timerId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const showParamDate =
    query.date && isDate(new Date(query.date))
      ? new Date(query.date)
      : new Date();
  const cwBillable = activityTypes.find(
    (a) => a.internal_name === "custom_work",
  )?.id;
  const _initialValues = useMemo(
    () => ({
      org_id: Number(query?.orgId) ?? ("" as any as number),
      duration: "" as any as number,
      start_time: showParamDate,
      end_time: undefined,
      duration_date: showParamDate,
      activity_type_id: "" as any as number,
      task_id: query.taskId ?? ("" as any as number),
      notes: "",
    }),
    [query.date, query?.orgId, query.taskId, cwBillable],
  );
  const [initialValues, setInitialValues] =
    useState<FormValues>(_initialValues);

  useEffect(() => {
    if ((isCW || query.taskId) && query?.mode !== "edit" && cwBillable) {
      setInitialValues({
        ...initialValues,
        activity_type_id: Number(cwBillable),
      });
    }
  }, [cwBillable, isCW]);

  const isCWActivity = (activity_type_id?: number) => {
    return activityTypes
      .find((c) => c.id === activity_type_id)
      ?.internal_name.includes("custom_work");
  };

  useEffect(() => {
    if (query?.timerId && timer && query?.mode === "edit") {
      const duration = Number(timer?.duration ?? 0) > 0;
      setEntryType(duration ? "duration" : "time");
      setInitialValues({
        org_id: timer?.org?.id ?? query.orgId ?? ("" as any as number),
        duration: timer?.duration ?? ("" as any as number),
        start_time: duration
          ? undefined
          : timer?.start_time && isDate(new Date(timer?.start_time))
          ? new Date(timer?.start_time)
          : showParamDate,
        end_time: duration
          ? undefined
          : timer?.end_time && isDate(new Date(timer?.end_time))
          ? new Date(timer?.end_time)
          : undefined,
        duration_date:
          timer?.duration_date && isDate(new Date(timer?.duration_date))
            ? new Date(timer?.duration_date.replace(/-/g, "/")) // date not respected with dashes https://stackoverflow.com/a/31338499
            : showParamDate,

        activity_type_id:
          timer?.activity_type_id ??
          timer?.activity_type?.id ??
          (isCW && cwBillable)
            ? Number(cwBillable)
            : ("" as any as number),
        task_id: query.taskId ?? timer?.task_id ?? ("" as any as number),
        notes: timer?.notes || "",
      });
    }
  }, [query.timerId, timer?.id]);

  const radioClick = useCallback(
    (type: EntryType) => {
      const form = ref.current;
      // if user adds a duration then switches back to a start time we clear duration field
      if (type === "time" && form) {
        form.setFieldValue("duration", "");
        form.setFieldValue("duration_date", "");
      }
      setEntryType(type);
    },
    [entryType, open, ref.current],
  );

  const validationSchema = yup.object({
    org_id: yup.number().required("Organization is required"),
    start_time: yup.date().when([], {
      is: () => entryType === "time",
      then: yup
        .date()
        .test("start_time_test", (value, { createError, parent, path }) => {
          if (value) {
            const { end_time } = parent;
            if (isAfter(new Date(value), new Date(end_time))) {
              return createError({
                path,
                message: "Start time must be before the end time",
              });
            }
          }
          return true;
        })
        .required("Start time is required"),
      otherwise: yup.date().nullable().optional(),
    }),
    end_time: yup.date().when([], {
      is: () => entryType === "time",
      then: yup
        .date()
        .test("start_time_test", (value, { createError, path, parent }) => {
          if (value) {
            const { start_time } = parent;
            if (isBefore(new Date(value), new Date(start_time))) {
              return createError({
                path,
                message: "End time must be later than start time",
              });
            }
          }
          return true;
        })
        .required("End time is required"),
      otherwise: yup.date().nullable().optional(),
    }),
    duration: yup.number().when([], {
      is: () => entryType === "duration",
      then: yup.number().min(0).required("Duration is required"),
      otherwise: yup.number().nullable().optional(),
    }),
    duration_date: yup.date().when([], {
      is: () => entryType === "duration",
      then: yup.date().required("Date for duration is required"),
      otherwise: yup.date().nullable().optional(),
    }),
    activity_type_id: yup.number().required("Type is required"),
    task_id: yup.number().when(["activity_type_id"], {
      is: (activity_type_id?: number) => !!isCWActivity(activity_type_id),
      then: yup.number().min(0).required("Task is required"),
      otherwise: yup.number().nullable().optional(),
    }),
    notes: yup.string().nullable().optional(),
  });
  const openTimePicker = useCallback(() => {
    setOpenDuration(!openDuration);
  }, [open, openDuration]);

  const onSubmit = async (values: typeof initialValues) => {
    const vals: CreateTimerApiArg["body"] = {
      ...values,
      start_time:
        entryType === "duration" ? undefined : values.start_time?.toISOString(),
      end_time:
        entryType === "duration" ? undefined : values.end_time?.toISOString(),
      is_manual: true,
      duration_date:
        entryType === "time"
          ? undefined
          : values?.duration_date
          ? format(new Date(values?.duration_date), "yyyy-MM-dd") //https://stackoverflow.com/a/49379235
          : "",
    };
    if (isCWActivity(values?.activity_type_id) && !values.task_id) {
      return;
    }
    if (query.timerId) {
      await updateTimer({
        id: query.timerId,
        body: vals,
      });
    } else {
      await createTimer({
        body: vals,
      });
    }
    onClose();
  };
  const disableEditing = useMemo(
    () => timer?.status && ["old", "billed", "billing"].includes(timer?.status),
    [timer?.status, query?.timerId],
  );

  return (
    <BaseModal
      open={open}
      title={`${query?.mode === "edit" ? "Edit" : "Add"} manual entry`}
      onClose={onClose}
    >
      <BaseForm
        onClose={onClose}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        innerRef={ref}
        enableReinitialize
        disableEditing={!!disableEditing}
        disableEditingReason={"This timer has already been billed"}
        LeftSideButton={
          query.timerId && !disableEditing ? (
            <ConfirmDeleteDialog
              titleText="Delete"
              buttonText="Delete"
              buttonColor="primary"
              handleConfirm={() => {
                deleteTimer({ id: query.timerId });
                onClose();
              }}
            />
          ) : null
        }
      >
        {({ setFieldValue, values, errors, touched }) => {
          const cwTasksFiltered = useMemo(() => {
            return (CWTasks?.rows ?? [])
              .filter((c) => c.org_id === Number(values.org_id))
              ?.map((c) => ({
                id: c.id,
                name: c?.name,
              }));
          }, [CWTasks, values.org_id, values.activity_type_id]);

          return (
            <Stack direction="column" gap={1} mb={1} width={"100%"}>
              <FormControl disabled={disableEditing}>
                <RadioGroup
                  defaultValue={"time"}
                  value={entryType}
                  name="entry_type"
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="time"
                    control={<Radio onClick={() => radioClick("time")} />}
                    label="Start and end times"
                  />

                  <FormControlLabel
                    value="duration"
                    control={<Radio onClick={() => radioClick("duration")} />}
                    label="Duration"
                  />
                </RadioGroup>
              </FormControl>
              <Stack direction={isMobile ? "column" : "row"} gap={2}>
                <TopLabel label="Organization">
                  <SelectField
                    name="org_id"
                    items={orgs.map((c) => ({
                      id: c.id,
                      name: `${c.name}`,
                    }))}
                    disabled={disableEditing}
                  />
                </TopLabel>
                <TopLabel label="Type">
                  <SelectField
                    name="activity_type_id"
                    items={activityTypes.map((c) => ({
                      id: c.id,
                      name: c.name,
                    }))}
                    disabled={disableEditing}
                  />
                </TopLabel>
              </Stack>
              {entryType === "time" ? (
                <Stack direction={isMobile ? "column" : "row"} gap={2}>
                  <TopLabel label="Start time">
                    <SingleDateTimeField
                      value={values?.start_time}
                      name="start_time"
                      disabled={disableEditing}
                    />
                  </TopLabel>
                  <TopLabel label="End time">
                    <SingleDateTimeField
                      value={values?.end_time}
                      name="end_time"
                      disabled={disableEditing}
                    />
                  </TopLabel>
                </Stack>
              ) : (
                <Stack>
                  <TopLabel label="Duration minutes">
                    <InputField
                      type="number"
                      name="duration"
                      disabled={disableEditing}
                    />
                  </TopLabel>
                  <TopLabel label="Duration date">
                    <DatePicker
                      disabled={disableEditing}
                      value={values?.duration_date}
                      open={openDuration}
                      onClose={openTimePicker}
                      slots={{
                        textField: TextField,
                      }}
                      slotProps={{
                        textField: {
                          placeholder: "",
                          onClick: openTimePicker,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <ClockIcon width={14} height={14} />
                              </InputAdornment>
                            ),
                          },
                          error:
                            touched.duration_date && !!errors.duration_date,
                          helperText: touched && errors?.duration_date,
                        },
                      }}
                      onChange={(v, _c) => setFieldValue("duration_date", v)}
                    />
                  </TopLabel>
                </Stack>
              )}
              {!!isCWActivity(values?.activity_type_id) && (
                <Stack direction={"row"} gap={2} mb={1}>
                  <AutoCompleteField
                    label={"Task"}
                    fieldName={"task_id"}
                    options={cwTasksFiltered}
                    disabled={disableEditing}
                  />
                </Stack>
              )}
              <Stack direction={"row"} gap={2} mb={1}>
                <TopLabel label="Notes">
                  <InputField name="notes" disabled={disableEditing} />
                </TopLabel>
              </Stack>
            </Stack>
          );
        }}
      </BaseForm>
    </BaseModal>
  );
};
