import { Button } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { ActivityModal } from "../modal/ActivityModal";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
// import { useOrgId } from "../hooks";
import { useGetUserActivityQuery } from "../../state/rtk-query/state/user";

export const UserActivityButton = memo(({ id }: { id?: number }) => {
  if (!id) return null;
  // const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const [open, setOpen] = useState(false);
  const { data: { activities } = {} } = useGetUserActivityQuery(
    { id },
    { skip: !isInternal, refetchOnMountOrArgChange: true },
  );
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
    },
    [open],
  );

  if (!activities) return null;
  return (
    <>
      <Button
        onClick={onClick}
        disabled={!activities.length}
        sx={{ p: 0, fontWeight: 600 }}
        disableRipple
      >
        {activities.length ? "View activity" : "No activity"}
      </Button>
      <ActivityModal
        id={id}
        open={open}
        setOpen={setOpen}
        activities={activities}
        type="user"
      />
    </>
  );
});
