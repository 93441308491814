import { Stack, IconButton, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { FileIcon, CloseIcon } from "../icons";
import { EllipsisTypographyStyled } from "../styled";

interface Props {
  attachments: File[];
  setAttachments: Dispatch<SetStateAction<File[]>>;
}
export const InlineAttachmentsList = ({
  attachments,
  setAttachments,
}: Props) => {
  const { palette } = useTheme();
  const removeAttachment = useCallback(
    (filename: string) => {
      if (attachments.length) {
        const removed = [...attachments].filter((b) => b.name !== filename);
        setAttachments(removed);
      }
    },
    [attachments],
  );
  return (
    <>
      {attachments.length ? (
        <Stack width={"70%"}>
          {[...attachments]?.map((mf) => {
            return (
              <Stack
                direction={"row"}
                alignItems={"center"}
                p={1}
                borderRadius={"4px"}
                key={mf.name}
              >
                <FileIcon
                  width={15}
                  height={22}
                  style={{ minWidth: 15 }}
                  color={palette.primary[500]}
                  opacity={1}
                />
                <EllipsisTypographyStyled ml={1} color={"primary"}>
                  {mf.name}
                </EllipsisTypographyStyled>

                <IconButton onClick={() => removeAttachment(mf.name)}>
                  <CloseIcon width={10} height={10} />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      ) : null}
    </>
  );
};
