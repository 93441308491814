import { useLocation } from "../../../lib";
import { AnnouncementsPage } from "./AnnouncementsPage";
import { PageTabs } from "../../../components";
import { useMobile, useTablet } from "../../../themes";

export const InternalSettingsPage = () => {
  const loc = useLocation();
  const { tab = "announcements" } = loc.query;
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  return (
    <>
      <PageTabs
        tabValues={[{ id: "announcements", label: "Announcements" }]}
        defaultTab={tab}
        mt={isSmallerThanPc ? 1 : -2}
      />
      {tab === "announcements" && <AnnouncementsPage />}
    </>
  );
};
