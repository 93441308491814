import { Alert, useTheme } from "@mui/material";
import { useOrgId } from "../../../components/hooks";
import {
  UpdateMyPreferencesApiArg,
  useGetMyPreferencesQuery,
} from "../../../state/rtk-query/state/preferences";
import {
  PreferenceCardsContainer,
  PreferenceCard,
  ProfileCard,
} from "../../../components";
import { useSelector } from "react-redux";
import { authSelectors, clientSelectors } from "../../../state";
import { useGetUserProfileQuery } from "../../../state/rtk-query/state/user";

type PreferenceName = UpdateMyPreferencesApiArg["preference"];
const ClientOnlyPreferences: PreferenceName[] = [
  "inactive_reminder",
  "task_auto_closed",
  "announcement_global",
  "announcement_org",
];
const NotificationPreferences: PreferenceName[] = ["all_task_activity"];
const UserPreferences: PreferenceName[] = ["view_task_as_modal"];

export const ProfilePage = () => {
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const { palette } = useTheme();
  const { currentData } = useGetMyPreferencesQuery({ orgId });
  const { id: _id, ...prefs } = currentData ?? {};
  const org = useSelector(clientSelectors.currentOrg);
  const isExternalAdmin = useSelector(authSelectors.isExternalAdmin);

  const { currentData: userProfile } = useGetUserProfileQuery();
  const { orgs = [] } = userProfile || {};

  return (
    <>
      <ProfileCard data={userProfile} />

      {orgs?.length > 1 && !isInternal ? (
        <Alert
          severity="warning"
          sx={{ fontWeight: 500, border: `1px solid ${palette.warning[500]}` }}
        >
          {`Please note, changes below will only apply to ${
            org?.name ?? "your currently logged in org"
          }`}
        </Alert>
      ) : null}

      <PreferenceCardsContainer title="Email Preferences">
        {Object.entries(prefs).map(([key, value]) => {
          if (
            (isInternal &&
              ClientOnlyPreferences.includes(key as PreferenceName)) ||
            NotificationPreferences.includes(key as PreferenceName) ||
            UserPreferences.includes(key as PreferenceName)
          ) {
            return null;
          }

          return (
            <PreferenceCard
              key={key}
              name={key as keyof typeof currentData}
              active={!!value}
            />
          );
        })}
      </PreferenceCardsContainer>

      <PreferenceCardsContainer title="User Preferences">
        {Object.entries(prefs).map(([key, value]) => {
          if ((key as PreferenceName) !== "view_task_as_modal") {
            return null;
          }

          return (
            <PreferenceCard
              key={key}
              name={key as keyof typeof currentData}
              active={!!value}
            />
          );
        })}
      </PreferenceCardsContainer>

      {isExternalAdmin && !isInternal && (
        <PreferenceCardsContainer title={"Notification Preferences"}>
          <PreferenceCard
            name={"all_task_activity"}
            active={!!currentData?.all_task_activity}
          />
        </PreferenceCardsContainer>
      )}
    </>
  );
};
