import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { MenuItemStyled, PopoverItemStyled } from "./Drawer.styles";
import {
  BellIcon,
  CloseIcon,
  LinkIcon,
  NotificationCard,
  QuicklinksCard,
  SocketMessage,
  Tooltip,
  useRefetchSocket,
} from "../../../components";
import { useSelector } from "react-redux";
import { actions, authSelectors } from "../../../state";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrgId, useTaskDrawerIsOpen } from "../../../components/hooks";
import { useDeleteMyNotificationsMutation } from "../../../state/rtk-query/state/notification";
import { useParams } from "react-router-dom";
import { PopoverButton } from "../../../components/buttons/PopoverButton";
const {
  useGetMyNotificationsQuery,
  useGetOrgLinksByIdQuery,
  useUpdateNotificationMutation,
  useGetMyUnreadNotificationCountQuery,
} = actions;
interface Props<T> {
  togglePopper: Dispatch<SetStateAction<T>>;
  whichMenu: T;
}
export const NestedMenu = ({
  whichMenu,
  togglePopper,
}: Props<"notifications" | "quickLinks" | undefined>) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateToRead] = useUpdateNotificationMutation();
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const drawerIsOpen = useTaskDrawerIsOpen();

  const [clearNotifications] = useDeleteMyNotificationsMutation();
  const {
    currentData,
    refetch: notiFetch,
    isUninitialized: notiUninitialized,
  } = useGetMyNotificationsQuery(orgId ? { orgId } : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const {
    currentData: unreadNotifications,
    refetch: countRefetch,
    isUninitialized: countUninitialized,
  } = useGetMyUnreadNotificationCountQuery(orgId ? { orgId } : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const { currentData: links, refetch: linkFetch } = useGetOrgLinksByIdQuery(
    orgId > 0 ? { orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const fetch = {
    notifications: notiFetch,
    quickLinks: linkFetch,
  };

  let timeout: any;
  const interval = useCallback(
    (action: "start" | "clear") => {
      if (action === "start") {
        if (currentData?.rows && !timeout) {
          timeout = setTimeout(async () => {
            for await (const r of currentData.rows) {
              updateToRead({
                id: r.id,
                orgId,
                body: { is_read: true },
              });
            }
            notiFetch();
          }, 5000);
        }
      } else {
        if (timeout) clearTimeout(timeout);
      }
    },
    [orgId, whichMenu, timeout],
  );
  const handleClick = useCallback(
    (event?: any, type?: typeof whichMenu) => {
      if (event && type) {
        const hasFetchedOnce = type === "notifications" ? currentData : links;
        if (orgId && hasFetchedOnce) {
          fetch[type]();
        }
        togglePopper(type);
        setAnchorEl(event.currentTarget);
        if (type === "notifications") {
          interval("start");
        }
      } else {
        interval("clear");
        setAnchorEl(() => null);
        togglePopper(() => undefined);
      }
    },
    [orgId, whichMenu],
  );

  const shouldRefetch = async (d: SocketMessage) => {
    if (d.tag === "notification" && d.message === "refresh" && !drawerIsOpen) {
      if (!notiUninitialized) notiFetch();
      if (!countUninitialized) countRefetch();
    }
  };
  useRefetchSocket({ tag: "notification", cb: shouldRefetch });

  const clearAllNotifications = () => {
    interval("clear");
    clearNotifications({ orgId });
  };
  return (
    <>
      {!isInternal && (
        <Tooltip title={"Quick Links"}>
          <MenuItemStyled
            aria-controls="menu"
            aria-haspopup="true"
            onClick={(e) => handleClick(e, "quickLinks")}
            selected={whichMenu === "quickLinks"}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
          </MenuItemStyled>
        </Tooltip>
      )}
      <Tooltip title={"Notifications"}>
        <MenuItemStyled
          aria-controls="menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e, "notifications")}
          selected={whichMenu === "notifications"}
          sx={{ my: 2 }}
        >
          <Badge
            badgeContent={unreadNotifications?.count}
            color="error"
            invisible={unreadNotifications?.count === 0}
          >
            <ListItemIcon>
              <BellIcon />
            </ListItemIcon>
          </Badge>
        </MenuItemStyled>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClick()}
        onClick={() => handleClick()} //close popper if notification is clicked
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 4,
            mt: 1,
            minWidth: "25%",
            maxWidth: "33%",
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transitionDuration={{ exit: 0 }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={currentData?.count ? 2 : 4}
          py={2}
        >
          <Typography fontWeight={600}>
            {whichMenu === "quickLinks"
              ? "QUICK LINKS"
              : whichMenu?.toUpperCase()}
          </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            {whichMenu === "notifications" && currentData?.rows?.length ? (
              <Button onClick={clearAllNotifications}>Clear all</Button>
            ) : null}
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={handleClick}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        {whichMenu === "notifications" ? (
          currentData?.count ? (
            currentData?.rows.map((r) => {
              return (
                <Fragment key={r.id}>
                  <NotificationCard notification={r} />
                </Fragment>
              );
            })
          ) : (
            <PopoverItemStyled sx={{ px: 4 }}>
              <Typography fontWeight={600}>
                You have no new notifications at this time
              </Typography>
            </PopoverItemStyled>
          )
        ) : whichMenu === "quickLinks" ? (
          links?.count ? (
            links?.rows.map((l) => {
              return (
                <Fragment key={l.id}>
                  <QuicklinksCard link={l} />
                </Fragment>
              );
            })
          ) : (
            <PopoverItemStyled>
              Quicklinks have not been set up
            </PopoverItemStyled>
          )
        ) : null}
      </Popover>
    </>
  );
};

export function QuickLinksButton() {
  const id = Number(useParams().id);

  const { currentData: links } = useGetOrgLinksByIdQuery(
    id > 0 ? { orgId: id } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  return (
    <PopoverButton
      button={
        <Tooltip title={"Quick Links"}>
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Tooltip>
      }
      title={"Quick Links"}
      stackProps={{ px: links?.count ? 2 : 4 }}
    >
      {links?.count ? (
        links.rows.map((l) => {
          return (
            <Fragment key={l.id}>
              <QuicklinksCard link={l} />
            </Fragment>
          );
        })
      ) : (
        <PopoverItemStyled>Quicklinks have not been set up</PopoverItemStyled>
      )}
    </PopoverButton>
  );
}
