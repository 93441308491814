import { Box, Link, useTheme } from "@mui/material";
import { useOpenTaskDrawer } from "../hooks";

export const ReportLink = ({
  href,
  value,
  taskId,
}: {
  href?: string;
  taskId?: number;
  value: string;
}) => {
  const { palette } = useTheme();
  const openTask = useOpenTaskDrawer();
  const openDrawer = () => {
    if (taskId) {
      openTask(taskId);
    }
  };
  return (
    <>
      {taskId ? (
        <Box
          onClick={openDrawer}
          sx={{ fontSize: 14, fontWeight: 500, color: palette.primary.main }}
        >
          {value}
        </Box>
      ) : (
        <Link
          target={"_blank"}
          href={href}
          rel="noreferrer"
          sx={{ fontSize: 14, fontWeight: 500 }}
        >
          {value}
        </Link>
      )}
    </>
  );
};
