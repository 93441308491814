import { Button } from "@mui/material";
import { useCloseTaskMutation } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { ConfirmDeleteDialog } from "../tables";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export function CompleteTaskButton({
  id,
  orgId,
  isFromPaymo,
}: {
  id: number;
  orgId: number;
  isFromPaymo?: boolean;
}) {
  const isMobile = useMobile();
  const [closeTask] = useCloseTaskMutation();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const canCloseSilently = isAdmin || (isInternal && isFromPaymo);
  return (
    <ConfirmDeleteDialog
      handleConfirm={() => closeTask({ id, orgId, body: {} })}
      buttonText="Complete Task"
      buttonColor="primary"
      sx={{
        width: 100,
        textWrap: "nowrap",
        height: isMobile ? "fit-content" : "100%",
        py: isMobile ? 0 : "auto",
      }}
      titleText="Are you sure you want to close this task?"
      subTitleText="Selecting 'Yes' will mark this task as complete and close it out. Selecting 'No' will keep the task open."
      modalProps={{
        confirmActionButtonText: "Yes",
        skipActionButtonText: "No",
      }}
      additionalActions={
        canCloseSilently && (
          <Button
            onClick={() => closeTask({ id, orgId, body: { silent: true } })}
          >
            Close Silently
          </Button>
        )
      }
    />
  );
}
