import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { CardStyled } from "../styled";
import { GetSupportButton } from "../buttons";
import { ForumIcon, PresentationIcon } from "../icons";
import { Icon } from "@iconscout/react-unicons/dist/icon";
import { useMobile } from "../../themes";
interface Props {
  link: string;
  text: string;
  Icon: Icon;
}
const AdmireHelpButton = ({ link, text, Icon }: Props) => {
  return (
    <Link
      target="_blank"
      href={link}
      sx={{
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        border: "1px solid #FFF",
        backgroundColor: "transparent",
        borderRadius: "6px",
        px: 0.5,
        py: 0.5,
        color: "#FFF",
      }}
    >
      <Icon />
      <Box component={"span"} ml={1}>
        {text}
      </Box>
    </Link>
  );
};

export const GetSupportStyledCard = () => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  return (
    <CardStyled
      sx={{
        px: 4,
        py: 2,
        color: "#FFF",
        backgroundImage: "linear-gradient(to right, #AA5CFF 0%, #8766FF 100%)",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h2" fontWeight={600}>
        Need Support?
      </Typography>
      <Typography
        variant="h3"
        fontWeight={500}
        fontSize={isMobile ? 16 : ".6rem !important"}
      >
        Set up a consulting session or submit a task
      </Typography>
      <GetSupportButton
        sx={{
          width: "100%",
          backgroundColor: "#FFF",
          color: palette.primary.main,
          m: 0,
          "&:hover": {
            backgroundColor: "#FFF",
            color: palette.primary.main,
            opacity: 0.95,
            boxShadow: "0 1px 12px 2px rgba(99,100,105,0.4)",
          },
        }}
      />
      <Typography fontWeight={500} fontSize={14}>
        Or find answers in
      </Typography>
      <Stack direction={"row"} gap={2}>
        <AdmireHelpButton
          Icon={PresentationIcon}
          text="Admire Academy"
          link="https://admire.document360.io/"
        />
        <AdmireHelpButton
          Icon={ForumIcon}
          link="https://community.admirepro.com/login"
          text="Admire Community"
        />
      </Stack>
    </CardStyled>
  );
};
