import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import { memo } from "react";
import { PreviewFileExtensions } from "../../lib";
import { GetSignedUrlByMediaIdApiResponse } from "../../state/rtk-query/state/media";
import { PictureAsPdfIcon, ChartIcon, FileIcon, Tooltip } from "../icons";
import { EllipsisTypographyStyled } from "../styled";
type ImageInfo = GetSignedUrlByMediaIdApiResponse & { filename: string };
interface Props {
  images?: ImageInfo[];
  fetchingImage: boolean;
  onClick: (id: number) => void;
}
export const DisplayMediaImages = memo(
  ({ images, fetchingImage, onClick }: Props) => {
    const { palette } = useTheme();
    return images?.map((d) => {
      const ogExt = d?.originalExt?.toLowerCase();
      const canPreview = PreviewFileExtensions.find((e) => e === ogExt);

      return fetchingImage ? (
        <Skeleton
          variant="rectangular"
          width={150}
          height={150}
          key={d.mediaId}
        />
      ) : (
        <Box
          height={150}
          width={150}
          key={d.mediaId}
          sx={{
            borderRadius: "6px",
            border: `1px solid ${palette.grey[300]}`,
            cursor: "pointer",
            "&:hover": { boxShadow: "0 1px 12px 2px  rgba(99,100,105,0.2)" },
            bgcolor: "#FFF",
          }}
          onClick={() => onClick(d.mediaId)}
        >
          {canPreview && d.mediaId ? (
            <img
              src={d.signedUrl}
              height={150}
              width={150}
              style={{
                borderRadius: "6px",
              }}
              alt={d.filename}
            />
          ) : (
            <Tooltip title={`Preview unavailable`}>
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"150px"}
                height={"150px"}
                gap={1}
              >
                {ogExt === ".pdf" ? (
                  <PictureAsPdfIcon />
                ) : [".csv", ".xls", ".xlsx"].includes(ogExt) ? (
                  <ChartIcon size={60} color={palette.success[700]} />
                ) : (
                  <FileIcon size={60} />
                )}
                <EllipsisTypographyStyled variant="subtitle2" width={"100px"}>
                  {d.filename}
                </EllipsisTypographyStyled>
              </Stack>
            </Tooltip>
          )}
        </Box>
      );
    });
  },
);
