import { capitalizeSentence } from "../../lib";
import { ActivitiesModal, ActivitiesViewProps } from "../views";

export function ActivityModal({
  id,
  onClose,
  open,
  setOpen,
  activities,
  type,
}: {
  id?: number;
  onClose?: () => void;
  open: boolean;
  setOpen: (o: boolean) => void;
  activities?: ActivitiesViewProps["activities"];
  type: "session" | "task" | "user";
}) {
  if (!id) return null;

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  if (!activities) return null;
  return (
    <ActivitiesModal
      activities={activities}
      open={open}
      onClose={handleOnClose}
      title={`${capitalizeSentence(type)} Activity`}
      useArrow={type === "task"}
    />
  );
}
