/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { authActions, persistor, store } from "./state";
import { initStateWithPrevTab } from "redux-state-sync";

// Example to use service worker / progressive web app (PWA):
// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import { defaultTheme } from "./themes";

// import "./assets/css/styles.css";

import { App } from "./App";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { VITE_MUI_LICENSE } from "./config";
import { PersistGate } from "redux-persist/es/integration/react";
import "./assets/fonts/stylesheet.css"; //TODO: Gilroy is a paid font downloaded from https://fontshub.pro/font/gilroy-download - Purchase the font before deploying to production
import { SocketProvider } from "./components/providers/SocketProvider";
// ^  was taken from admire core
// NOTE: The `store` already comes preloaded slices that where persisted.

const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
});
/**
 * Startup function.
 */
LicenseInfo.setLicenseKey(VITE_MUI_LICENSE!);

function main() {
  const dispatch = store.dispatch;
  dispatch(authActions.load({}));
  render();
  registerServiceWorker();
  initStateWithPrevTab(store);
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 * @see useNotificationSW hook
 */
function registerServiceWorker() {
  //
}

function render() {
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);

  root.render(
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <CssBaseline />
            <SocketProvider>
              <App />
            </SocketProvider>
          </PersistGate>
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>,
  );
}

main();
