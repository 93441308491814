import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, Form } from "formik";
import { FileExtensions, yup } from "../../lib";
import { CloseIcon, PaperDropzone } from "../";
import { actions } from "../../state/rtk-query";
import { useOrgId } from "../hooks";
const { useSaveMediaMutation, useCreateTaskMediaMutation } = actions;
interface UploadFormProps {
  taskId: number;
  onClose: () => void;
}
export const UploadCWManualForm = ({ taskId, onClose }: UploadFormProps) => {
  const { palette } = useTheme();
  const orgId = useOrgId();
  const singleInitialValues = {
    files: "",
  };

  const validationSchema = yup.object({
    files: yup.mixed().required("file is required."),
  });

  const [saveMedia] = useSaveMediaMutation();

  const [createTaskMedia] = useCreateTaskMediaMutation();
  const handleSubmit = async (values: any) => {
    if (!values.files) {
      return;
    }
    if (values.files.length) {
      [...values.files].map(async (file: any) => {
        const { name } = file ?? {};
        const formData = new FormData();
        formData.append("file", file);

        const missingLabel = name.split(".")[0];

        const retFile = await saveMedia({
          body: { file: formData },
          label: missingLabel,
          name: name || missingLabel,
        }).unwrap();

        if (taskId && retFile.id && orgId === -1) {
          await createTaskMedia({
            id: taskId,
            orgId,
            body: {
              media_id: retFile.id,
              is_internal: false,
              is_custom_work_manual: true,
            },
          });
        }
      });
    }
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={singleInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <Grid alignItems={"center"} container px={4}>
            <Grid
              item
              xs={12}
              md={12}
              display={"flex"}
              flexDirection={"column"}
            >
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h1">Upload custom work manual</Typography>
                <IconButton onClick={onClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Stack>

              <PaperDropzone
                filesCB={(files: File[]) => setFieldValue("files", files)}
                fileType={FileExtensions.join(", ")}
                sx={{
                  height: "100%",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px dashed ${palette.grey[700]}`,
                  my: 2,
                }}
                innerText={
                  <Box component={"span"}>
                    Drag and drop or{" "}
                    <Box
                      component={"span"}
                      color={palette.primary.main}
                      fontWeight={600}
                      sx={{ cursor: "pointer" }}
                    >
                      Browse files
                    </Box>
                  </Box>
                }
              />
            </Grid>
          </Grid>
          <Divider
            sx={{ width: "100%", my: 2, position: "absolute", left: 0 }}
          />

          <Stack direction={"row"} justifyContent={"flex-end"} mt={4} px={4}>
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
