import { api } from "../api";
export const addTagTypes = ["notifications", "jobs"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyNotifications: build.query<
        GetMyNotificationsApiResponse,
        GetMyNotificationsApiArg
      >({
        query: (queryArg) => ({ url: `/notifications/${queryArg.orgId}/mine` }),
        providesTags: ["notifications"],
      }),
      deleteMyNotifications: build.mutation<
        DeleteMyNotificationsApiResponse,
        DeleteMyNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/${queryArg.orgId}/mine`,
          method: "DELETE",
        }),
        invalidatesTags: ["notifications"],
      }),
      getMyUnreadNotificationCount: build.query<
        GetMyUnreadNotificationCountApiResponse,
        GetMyUnreadNotificationCountApiArg
      >({
        query: (queryArg) => ({
          url: `/notification/${queryArg.orgId}/count/mine`,
        }),
        providesTags: ["notifications"],
      }),
      getNotification: build.query<
        GetNotificationApiResponse,
        GetNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/notifications/${queryArg.id}`,
        }),
        providesTags: ["notifications"],
      }),
      updateNotification: build.mutation<
        UpdateNotificationApiResponse,
        UpdateNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/notifications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["notifications"],
      }),
      deleteNotification: build.mutation<
        DeleteNotificationApiResponse,
        DeleteNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/notifications/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["notifications"],
      }),
      getNotificationCount: build.query<
        GetNotificationCountApiResponse,
        GetNotificationCountApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/notification/${queryArg.id}/count`,
          params: { entity: queryArg.entity },
        }),
        providesTags: ["notifications"],
      }),
      updateNotificationByTask: build.mutation<
        UpdateNotificationByTaskApiResponse,
        UpdateNotificationByTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/notifications/task/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["notifications"],
      }),
      getPushNotificationPublic: build.query<
        GetPushNotificationPublicApiResponse,
        GetPushNotificationPublicApiArg
      >({
        query: () => ({ url: `/notifications/push/creds` }),
        providesTags: ["notifications"],
      }),
      registerPushNotificationSw: build.mutation<
        RegisterPushNotificationSwApiResponse,
        RegisterPushNotificationSwApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/push/creds`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["notifications"],
      }),
      sendSamplePushNotification: build.mutation<
        SendSamplePushNotificationApiResponse,
        SendSamplePushNotificationApiArg
      >({
        query: () => ({ url: `/notifications/push/sample`, method: "POST" }),
        invalidatesTags: ["jobs"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetMyNotificationsApiResponse = /** status 200 Notifications */ {
  count: number;
  rows: {
    id: number;
    org_id?: number;
    message: string;
    user_id: number;
    is_read: boolean;
    entity?: string | null;
    entity_id: number;
    notification_type?: string | null;
    context?: string | null;
    link?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetMyNotificationsApiArg = {
  /** organization id */
  orgId: number;
};
export type DeleteMyNotificationsApiResponse =
  /** status 204 An empty response */ void;
export type DeleteMyNotificationsApiArg = {
  /** organization id */
  orgId: number;
};
export type GetMyUnreadNotificationCountApiResponse = /** status 200 count */ {
  count: number;
};
export type GetMyUnreadNotificationCountApiArg = {
  /** organization id */
  orgId: number;
};
export type GetNotificationApiResponse =
  /** status 200 Show single Notification */ {
    id: number;
    org_id?: number;
    message: string;
    user_id: number;
    is_read: boolean;
    entity?: string | null;
    entity_id: number;
    notification_type?: string | null;
    context?: string | null;
    link?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
export type GetNotificationApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateNotificationApiResponse =
  /** status 204 An empty response */ void;
export type UpdateNotificationApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** notifications */
  body: {
    is_read?: boolean;
  };
};
export type DeleteNotificationApiResponse =
  /** status 204 An empty response */ void;
export type DeleteNotificationApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type GetNotificationCountApiResponse = /** status 200 count */ {
  count: number;
};
export type GetNotificationCountApiArg = {
  /** record id */
  id: number;
  /** entity type */
  entity: "task" | "session" | "booking" | "comment";
  /** organization id */
  orgId: number;
};
export type UpdateNotificationByTaskApiResponse =
  /** status 204 An empty response */ void;
export type UpdateNotificationByTaskApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** notifications */
  body: {
    is_read?: boolean;
  };
};
export type GetPushNotificationPublicApiResponse =
  /** status 200 Push notification creds */ {
    PUBLIC_KEY: string;
  };
export type GetPushNotificationPublicApiArg = void;
export type RegisterPushNotificationSwApiResponse =
  /** status 204 An empty response */ void;
export type RegisterPushNotificationSwApiArg = {
  /** user activity body */
  body: {
    endpoint: string;
    auth_key: string;
    p256dh_key: string;
  };
};
export type SendSamplePushNotificationApiResponse =
  /** status 204 An empty response */ void;
export type SendSamplePushNotificationApiArg = void;
export const {
  useGetMyNotificationsQuery,
  useLazyGetMyNotificationsQuery,
  useDeleteMyNotificationsMutation,
  useGetMyUnreadNotificationCountQuery,
  useLazyGetMyUnreadNotificationCountQuery,
  useGetNotificationQuery,
  useLazyGetNotificationQuery,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useGetNotificationCountQuery,
  useLazyGetNotificationCountQuery,
  useUpdateNotificationByTaskMutation,
  useGetPushNotificationPublicQuery,
  useLazyGetPushNotificationPublicQuery,
  useRegisterPushNotificationSwMutation,
  useSendSamplePushNotificationMutation,
} = injectedRtkApi;
