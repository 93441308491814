import {
  GridCallbackDetails,
  GridFilterModel,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useDatagridProps, useQuery } from "../hooks";
import { StyledDataGridPremium } from "./DataGrid.styles";
import { useMobile } from "../../themes";
import { useAdminTimesColumns } from "../../columns";
import {
  GetTimeSpentOnAllOrgsApiArg,
  useGetDetailedTimeSpentOnAllOrgsQuery,
} from "../../state/rtk-query/state/timer";
import { CardStyled } from "../styled";
import { useCallback, useMemo, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import { capitalizeSentence, filterBoolean } from "../../lib";
import { Stack } from "@mui/material";
import { lastDayOfMonth } from "date-fns";
type FilterType = GetTimeSpentOnAllOrgsApiArg["filterTime"];

interface QueryProps {
  dateRange?: DateRange<any> | undefined;
  time_filter?: FilterType;
  month?: string;
  org_name?: string;
  consultant?: string;
}

export const AdminTimeTable = () => {
  const apiRef = useGridApiRef();
  const isMobile = useMobile();
  const [query, setQuery] = useQuery<QueryProps>(
    "dateRange",
    "time_filter",
    "org_name",
    "month",
    "consultant",
  );
  const columns = useAdminTimesColumns(isMobile);
  const { slotProps, autosizeOptions } = useDatagridProps();
  const { time_filter, dateRange, month, org_name, consultant } = query;

  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );

  const dateFilter = useMemo(() => {
    return month && month !== ""
      ? [
          {
            field: "start_time",
            operator: "onOrAfter",
            value: month,
          },
          {
            field: "start_time",
            operator: "onOrBefore",
            value: lastDayOfMonth(new Date(month ?? "")),
          },
        ]
      : [];
  }, [month]);

  const orgFilter = useMemo(() => {
    return org_name
      ? [
          {
            field: "org_name",
            operator: "contains",
            value: org_name?.toLowerCase()?.replace(/_/g, " "),
          },
        ]
      : [];
  }, [org_name]);

  const consultantFilter = useMemo(() => {
    return consultant
      ? [
          {
            field: "consultant",
            operator: "contains",
            value: consultant?.toLowerCase()?.replace(/_/g, " "),
          },
        ]
      : [];
  }, [consultant]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      filter: {
        filterModel: {
          items: [...orgFilter, ...consultantFilter, ...dateFilter],
        },
      },
      rowGrouping: {
        model: ["org_name"] as GridRowGroupingModel,
      },
      aggregation: {
        model: {
          time_spent: "sum",
        },
      },
    },
  });

  const formatGroupByName = useCallback((models?: GridRowGroupingModel) => {
    return capitalizeSentence(
      (models ?? [])
        .map((m) => (m === "org_name" ? "Organization" : m.replace("_", " ")))
        .join(", "),
    );
  }, []);

  const [groupByName, setGroupByName] = useState(
    formatGroupByName(initialState?.rowGrouping?.model) ?? "",
  );

  const { currentData } = useGetDetailedTimeSpentOnAllOrgsQuery({
    dateRange: hasDateRange ? dateRange : undefined,
    filterTime: time_filter,
  });
  const changeName = useCallback(
    (models: GridRowGroupingModel) => {
      setGroupByName(capitalizeSentence(formatGroupByName(models)));
    },
    [groupByName],
  );
  const updateQuery = useCallback(
    (name: keyof QueryProps, value: QueryProps[keyof QueryProps]) => {
      setQuery({ ...query, [name]: value });
    },
    [query],
  );
  const onFilterModelChange = (
    model: GridFilterModel,
    _details: GridCallbackDetails<"filter">,
  ) => {
    const { items } = model;
    items.forEach((i) => {
      const val = i.value;
      switch (i.field) {
        case "org_name":
          updateQuery("org_name", val);
          break;
        case "start_time":
          updateQuery("month", new Date(val).toLocaleDateString());
          break;
        case "consultant":
          updateQuery("consultant", val);
          break;
        default:
          break;
      }
    });
  };

  const Table = useMemo(() => {
    return (
      <StyledDataGridPremium
        rows={currentData ?? []}
        apiRef={apiRef}
        columns={columns}
        disableRowSelectionOnClick
        initialState={initialState}
        autosizeOnMount={true}
        autosizeOptions={autosizeOptions}
        slotProps={slotProps}
        groupingColDef={{
          flex: isMobile ? undefined : 1,
          headerName: `Group by "${groupByName}"`,
        }}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            height: "100%",
          },
        }}
        onRowGroupingModelChange={changeName}
        pagination
        onFilterModelChange={onFilterModelChange}
      />
    );
  }, [currentData, columns, apiRef, groupByName, initialState]);

  return (
    <CardStyled
      sx={{
        height: "100%",
        mb: 2,
      }}
    >
      <Stack height={"100%"} overflow={"hidden"}>
        {Table}
      </Stack>
    </CardStyled>
  );
};
