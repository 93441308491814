import { api } from "../api";
export const addTagTypes = ["sessions", "time_entry"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTimeEntries: build.query<
        GetTimeEntriesApiResponse,
        GetTimeEntriesApiArg
      >({
        query: (queryArg) => ({ url: `/sessions/${queryArg.id}/time-entries` }),
        providesTags: ["sessions", "time_entry"],
      }),
      createTimeEntry: build.mutation<
        CreateTimeEntryApiResponse,
        CreateTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/${queryArg.id}/time-entries`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
      getTimeEntry: build.query<GetTimeEntryApiResponse, GetTimeEntryApiArg>({
        query: (queryArg) => ({ url: `/sessions/time-entry/${queryArg.id}` }),
        providesTags: ["sessions", "time_entry"],
      }),
      updateTimeEntry: build.mutation<
        UpdateTimeEntryApiResponse,
        UpdateTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/time-entry/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
      deleteTimeEntry: build.mutation<
        DeleteTimeEntryApiResponse,
        DeleteTimeEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/sessions/time-entry/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sessions", "time_entry"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetTimeEntriesApiResponse = /** status 200 SessionTimeEntries */ {
  count: number;
  rows: {
    id: number;
    session_id: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTimeEntriesApiArg = {
  /** record id */
  id: number;
};
export type CreateTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type CreateTimeEntryApiArg = {
  /** record id */
  id: number;
  /** session_time_entry */
  body: {
    id?: number;
    session_id?: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked?: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type GetTimeEntryApiResponse =
  /** status 200 Show single SessionTimeEntry */ {
    id: number;
    session_id: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
export type GetTimeEntryApiArg = {
  /** record id */
  id: number;
};
export type UpdateTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTimeEntryApiArg = {
  /** record id */
  id: number;
  /** session_time_entry */
  body: {
    id?: number;
    session_id?: number;
    start_time: string;
    end_time?: string | null;
    minutes_worked?: number;
    note?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
};
export type DeleteTimeEntryApiResponse =
  /** status 204 An empty response */ void;
export type DeleteTimeEntryApiArg = {
  /** record id */
  id: number;
};
export const {
  useGetTimeEntriesQuery,
  useLazyGetTimeEntriesQuery,
  useCreateTimeEntryMutation,
  useGetTimeEntryQuery,
  useLazyGetTimeEntryQuery,
  useUpdateTimeEntryMutation,
  useDeleteTimeEntryMutation,
} = injectedRtkApi;
