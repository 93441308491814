import {
  Stack,
  Typography,
  FormControlLabel,
  useTheme,
  Button,
} from "@mui/material";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Navigation, capitalizeSentence, generatePath } from "../../lib";
import { CustomCheckBox, SortableButton } from "..";
import { ServerDataGrid } from "./ServerDataGrid";
import { useSessionColumns } from "../../columns";
import {
  GetSessionsApiArg,
  GetSessionsApiResponse,
} from "../../state/rtk-query/state/session";
import { useQuery, useRtkQueryParams, useStatuses } from "../hooks";
import { DateRange } from "@mui/x-date-pickers-pro";
import { useSelector } from "react-redux";
import { MainPages } from "../../pages";
import { authSelectors } from "../../state";
import { useMobile } from "../../themes";
import { actions } from "../../state/rtk-query";
import { GridSortModel } from "@mui/x-data-grid";

const { useGetAdmireUsersListQuery, useGetSessionsQuery, useGetOrgsListQuery } =
  actions;

interface SessionQueryFilters {
  sessionStatuses: number[];
  sessionConsultant: number[];
  sessionReporter: number[];
  sortByOpenTasks: boolean;
  sessionDateRange: DateRange<any> | undefined;
  sessionOrgs: number[];
  sessionFilterMyOrgs: boolean;
}
export const SessionsTableInternal = ({ height }: { height: string }) => {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const isInternal = useSelector(authSelectors.isInternal);
  const statuses = useStatuses("session");
  const pages = MainPages(isInternal);
  const [sessionParams, setSessionParams] = useRtkQueryParams();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "booking.start_time",
      sort: "asc",
    },
    {
      field: "status_id",
      sort: "asc",
    },
  ]);
  // NOTE: If there are ever more filters make sure the names don't overlap tasks query's
  const [query, setQuery] = useQuery<SessionQueryFilters>(
    "sessionStatuses",
    "sessionConsultant",
    "sessionReporter",
    "sortByOpenTasks",
    "sessionDateRange",
    "sessionOrgs",
    "sessionFilterMyOrgs",
  );

  const { currentData: admireUsers } = useGetAdmireUsersListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { currentData: orgList } = useGetOrgsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    sessionConsultant,
    sessionReporter,
    sessionStatuses,
    sortByOpenTasks,
    sessionDateRange,
    sessionOrgs,
    sessionFilterMyOrgs,
    // sessionArchived,
  } = query;

  const sessionRowClick = useCallback(
    async ({ row }: { row: GetSessionsApiResponse["rows"][number] }) => {
      if (row.id) {
        Navigation.go(
          generatePath(pages.session.path, {
            id: String(row.id),
          }),
        );
      }
    },
    [],
  );

  const page = sessionParams.range ? sessionParams?.range[0] : 1;

  const sessionsQuery: GetSessionsApiArg = useMemo(() => {
    return {
      custom: JSON.stringify({
        status: sessionStatuses,
        dateRange: sessionDateRange,
        consultants: sessionConsultant,
        reporters: sessionReporter,
        sortOpenTasks: sortByOpenTasks,
        sortByMyOrg: sessionFilterMyOrgs,
        orgs: sessionOrgs,
      }),
      // archived: !!sessionArchived,
      range: [page, sessionParams.range ? sessionParams.range[1] : 25],
      ...sessionParams,
    };
  }, [
    sessionStatuses,
    sessionConsultant,
    sessionReporter,
    sortByOpenTasks,
    sessionFilterMyOrgs,
    sessionDateRange,
    sessionOrgs,
    sessionParams,
    // sessionArchived,
  ]);

  const { currentData: sessions, isFetching: sessionsFetch } =
    useGetSessionsQuery(sessionsQuery, {
      refetchOnMountOrArgChange: true,
    });
  const sessionCols = useSessionColumns();

  const sortButton = (
    f: number,
    c: boolean,
    sortable: keyof Omit<
      SessionQueryFilters,
      | "sortByOpenTasks"
      | "sessionDateRange"
      | "sessionFilterMyOrgs"
      | "sessionArchived"
    >,
  ) => {
    const values = query[sortable];
    setQuery({
      [sortable]: c
        ? [...(values || []), f]
        : (values || []).filter((p: number) => p !== f),
    });
  };

  const clearAllFilters = useCallback(() => {
    setQuery(
      {},
      "sessionConsultant",
      "sessionReporter",
      "sessionDateRange",
      "sessionOrgs",
      "sessionStatuses",
      "sortByOpenTasks",
    );
    setQuery({
      sessionFilterMyOrgs: false,
      // sessionArchived: false,
      sessionStatuses: [],
      sessionDateRange: undefined,
    });
  }, [query]);

  return (
    <Stack
      sx={{
        height,
        mb: 2,
        borderRadius: "6px",
        boxShadow: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: palette.grey[500],
        backgroundColor: "#FFF",
      }}
    >
      <Stack direction={"column"}>
        <Stack direction={"row"} pt={2} alignItems={"center"}>
          <Typography variant="h3" pl={2}>
            SESSIONS
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          width={"100%"}
          py={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={"row"}
            overflow={"auto"}
            width={"100%"}
            sx={{
              "::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <SortableButton
              title="Status"
              onClick={(f, c) => sortButton(f, c, "sessionStatuses")}
              isDropDown
              displayItems={statuses?.map((s) => {
                return {
                  label: capitalizeSentence(
                    s.internal_name.toLowerCase().replace("_", " "),
                  ),
                  id: s.id,
                };
              })}
              currentSort={sessionStatuses}
            />
            <SortableButton
              title="Consultant"
              onClick={(f, c) => sortButton(f, c, "sessionConsultant")}
              isDropDown
              displayItems={
                admireUsers?.data.slice(0, 100)?.map((u) => {
                  return {
                    label: `${u.first_name} ${u.last_name}`,
                    id: Number(u.id),
                  };
                }) ?? []
              }
              currentSort={sessionConsultant}
            />
            {/*  TODO: Should all clients be included in the dropdown filter???
            <SortableButton
              title="Created By"
              onClick={(f, c) => sortButton(f, c, "sessionReporter")}
              isDropDown
              displayItems={
                admireUsers?.data.slice(0, 100)?.map((u) => {
                  return {
                    label: `${u.first_name} ${u.last_name}`,
                    id: Number(u.id),
                  };
                }) ?? []
              }
              currentSort={sessionReporter}
            /> */}
            <SortableButton
              title="Session Date"
              onChange={(range) => {
                setQuery({
                  sessionDateRange: range[0] && range[1] ? range : undefined,
                });
              }}
              isDropDown
              isDate
              currentSort={sessionDateRange as any as Date[]}
            />
            <SortableButton
              title="Orgs"
              onClick={(f, c) => sortButton(f, c, "sessionOrgs")}
              isDropDown
              displayItems={
                orgList?.map((o) => {
                  return {
                    label: o.name,
                    id: o.id,
                  };
                }) ?? []
              }
              currentSort={sessionOrgs}
            />
            <FormControlLabel
              sx={{
                height: "min-content",
                display: "flex",
                alignItems: "center",
                "& > span:last-child": {
                  fontSize: isMobile ? 14 : 16,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                },
              }}
              labelPlacement="end"
              control={
                <CustomCheckBox
                  onChange={(
                    _e: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => setQuery({ sortByOpenTasks: checked })}
                  checked={sortByOpenTasks}
                />
              }
              label="Open tasks"
            />
            <FormControlLabel
              sx={{
                height: "min-content",
                display: "flex",
                alignItems: "center",
                "& > span:last-child": {
                  fontSize: isMobile ? 14 : 16,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                },
              }}
              labelPlacement="end"
              control={
                <CustomCheckBox
                  props={{
                    checked:
                      sessionFilterMyOrgs !== undefined
                        ? sessionFilterMyOrgs
                        : true,
                  }}
                  onChange={(
                    _e: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => setQuery({ sessionFilterMyOrgs: checked })}
                />
              }
              label="My orgs"
            />
          </Stack>
          {!!Object.values(query).filter(Boolean).length && (
            <Button
              variant="text"
              color="info"
              sx={{ minWidth: "fit-content" }}
              size="small"
              onClick={clearAllFilters}
            >
              <Typography fontSize={16} fontWeight={"500"} pr={1}>
                Reset filters
              </Typography>
            </Button>
          )}
        </Stack>
      </Stack>
      <ServerDataGrid
        rowCount={sessions?.count || 0}
        rows={sessions?.rows ?? []}
        columns={sessionCols}
        setQueryParams={setSessionParams}
        isFetching={sessionsFetch}
        toolbarProps={{ hideToolbar: true }}
        props={{
          onRowClick: sessionRowClick,
          sortModel,
          onSortModelChange: (model) => setSortModel(model),
          density: "compact",
          sx: {
            "& .MuiDataGrid-columnHeaders": {
              borderTop: `0.5px solid ${palette.grey["300"]}`,
            },
            "& .MuiBadge-badge ": {
              display: "none",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
            },
            ".MuiDataGrid-row": {
              cursor: "pointer",
            },
          },
        }}
        currentParams={sessionParams}
      />
    </Stack>
  );
};
