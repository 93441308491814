import { styled } from "@mui/material/styles";
import {
  Paper,
  IconButton,
  PaperProps,
  Button,
  Link,
  useTheme,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  useDispatch,
  uiActions,
  useSelector,
  authSelectors,
} from "../../../state";
import { drawerWidth } from "./Drawer.styles";
import { GetSupportButton, SearchIcon } from "../../../components";

interface AppBarProps extends PaperProps {
  open?: boolean;
}

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 999,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MobileTopBar() {
  const dispatch = useDispatch();
  const isInternal = useSelector(authSelectors.isInternal);
  const { palette } = useTheme();
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <PaperBar
      sx={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        boxShadow: "none",
        borderRadius: 0,
        borderBottom: `1px solid ${palette.grey[600]}`,
        position: "sticky",
        top: 0,
        zIndex: 20,
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
      </IconButton>
      <Stack direction={"row"}>
        <Button
          component={Link}
          href="/search"
          sx={{ color: palette.grey[700] }}
        >
          <SearchIcon style={{ fontSize: "1em" }} />
        </Button>

        {isInternal ? null : (
          <GetSupportButton sx={{ mx: 1, width: "100%", display: "flex" }} />
        )}
      </Stack>
    </PaperBar>
  );
}
