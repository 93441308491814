import {
  Box,
  Typography,
  Tab,
  Tabs,
  BoxProps,
  Divider,
  Link,
  Stack,
} from "@mui/material";
import { Navigation, useLocation } from "../lib";
import { ReactNode, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useMobile } from "../themes";
export type PageTabsProps = {
  tabValues: { id: string; label: string }[];
  defaultTab: string;
  tabKey?: string;
  maintainQuery?: boolean;
  EndButton?: ReactNode;
} & BoxProps;

export function PageTabs({
  tabValues,
  defaultTab,
  tabKey = "tab",
  maintainQuery,
  EndButton,
  ...props
}: PageTabsProps) {
  const location = useLocation();
  const { query, pathname } = location;
  const { [tabKey]: tab = defaultTab } = query;
  const isMobile = useMobile();
  const TabItems = useMemo(
    () =>
      tabValues.map((t) => (
        <Tab
          disableRipple
          key={t.id}
          label={
            <Link
              component={RouterLink}
              to={
                maintainQuery
                  ? Navigation.appendToUrl({ [tabKey]: t.id })
                  : pathname + "?" + tabKey + "=" + t.id
              }
              color="inherit"
            >
              <Box
                sx={{
                  p: 1,
                  px: 2,
                  minWidth: "fit-content",
                }}
              >
                <Typography
                  fontWeight={600}
                  fontSize={isMobile ? "16px" : ".65rem"}
                >
                  {t.label}
                </Typography>
              </Box>
            </Link>
          }
          value={t.id}
          sx={{
            minWidth: "fit-content",
            p: 0,
          }}
        />
      )),
    [tabValues, pathname, tabKey, isMobile, query],
  );

  return (
    <Box width={"100%"} mb={3} {...props}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignContent={"flex-end"}
        width={"100%"}
      >
        <Tabs value={tab} allowScrollButtonsMobile variant="scrollable">
          {TabItems}
        </Tabs>
        {EndButton ? EndButton : null}
      </Stack>
      <Divider flexItem sx={{ width: "100%" }} />
    </Box>
  );
}
