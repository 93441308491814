import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { admin, AdminState } from "./admin/state";
import { client, ClientState } from "./client/state";
import { microsoft, MicrosoftState } from "./microsoft/state";
import { AppStateInfo } from "./types";
import { timer, TimerState } from "./timer/state";

// Export Actions, Selectors, Types

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./admin/actions";
export * from "./admin/selectors";

export * from "./client/actions";
export * from "./client/selectors";

export * from "./timer/actions";
export * from "./timer/selectors";

export * from "./microsoft/actions";
export * from "./rtk-query";
export interface AppState {
  auth: AuthState;
  ui: UIState;
  admin: AdminState;
  client: ClientState;
  timer: TimerState;
  microsoft: MicrosoftState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  admin,
  client,
  timer,
  microsoft,
];
export default states;
