import { api } from "../api";
export const addTagTypes = ["statuses"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getStatuses: build.query<GetStatusesApiResponse, GetStatusesApiArg>({
        query: () => ({ url: `/statuses` }),
        providesTags: ["statuses"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetStatusesApiResponse = /** status 200 Statuses */ {
  id: number;
  internal_name: string;
  name: string;
  description?: string;
  type:
    | (
        | "all"
        | "session"
        | "all_tasks"
        | "task"
        | "internal_task"
        | "custom_work"
        | "quick_question"
        | null
      )
    | null;
  is_automated?: boolean | null;
  transition_phase:
    | "todo"
    | "in_progress"
    | "action_required"
    | "done"
    | "blocked";
  color:
    | "green"
    | "red"
    | "yellow"
    | "grey"
    | "primary"
    | "tertiary"
    | "secondary"
    | "lightGrey";
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
}[];
export type GetStatusesApiArg = void;
export const { useGetStatusesQuery, useLazyGetStatusesQuery } = injectedRtkApi;
