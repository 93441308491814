import { api } from "../api";
export const addTagTypes = ["search"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      globalSearch: build.query<GlobalSearchApiResponse, GlobalSearchApiArg>({
        query: (queryArg) => ({
          url: `/search/${queryArg.orgId}`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            fuzzy_search: queryArg.fuzzySearch,
            max_words_in_search: queryArg.maxWordsInSearch,
            orgs: queryArg.orgs,
          },
        }),
        providesTags: ["search"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GlobalSearchApiResponse = /** status 200 GlobalSearchRes */ {
  count: number;
  rows: {
    task_id?: number;
    org_id?: number | null;
    task_status?: number;
    task_title?: string | null;
    task_description?: string | null;
    task_code?: string | null;
    code_id?: number | null;
    session_id?: number | null;
    session_title?: string | null;
    topic_id?: number | null;
    top_description?: string | null;
    tc_comment?: string | null;
    org_name?: string | null;
    comment_id?: number | null;
    booking_id?: number | null;
    task_created_at?: string | null;
    booking_start_time?: string | null;
    session_created_at?: string | null;
    tc_created_at?: string | null;
    category?:
      | "session"
      | "task_title"
      | "task_description"
      | "task_code"
      | "topic"
      | "comment"
      | "org"
      | "no_category";
    matching_id?: number | null;
  }[];
};
export type GlobalSearchApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** Extra results from fuzzy matching may take more time */
  fuzzySearch: boolean;
  /** The max amount of words to display before a guaranteed found search term */
  maxWordsInSearch?: number;
  /** orgs to filter by */
  orgs?: number[];
  /** organization id */
  orgId: number;
};
export const { useGlobalSearchQuery, useLazyGlobalSearchQuery } =
  injectedRtkApi;
