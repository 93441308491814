import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { IconButton } from "@mui/material";

import Menu from "./Menu";
import {
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import TopBar from "./TopBar";

import logo from "../../../assets/img/logo.svg";
import { MenuIcon } from "../../../components";
import { Link } from "react-router-dom";
import { drawerWidth } from "./Drawer.styles";
import { useMobile } from "../../../themes";
import { useHeightOffset } from "../../../components/hooks/useHeightOffset";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": { ...closedMixin(theme), borderRight: "none" },
  }),
}));

export const NavDrawer = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  // const handleDrawerClose = () => {
  //   dispatch(uiActions.setMenuOpen(false));
  // };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };
  const heightOffset = useHeightOffset();
  return (
    <>
      <TopBar />
      <Drawer variant="permanent">
        <DrawerHeader
          sx={{
            ml: 1,
            mb: 2,
            display: "flex",
            justifyContent: !menuOpen ? "center" : "space-between",
            mt: `${heightOffset}px`,
          }}
        >
          {menuOpen || !isMobile ? (
            <Link to="/">
              <img src={logo} alt="logo" />
              {/* <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton> */}
            </Link>
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Menu />
      </Drawer>
    </>
  );
};
