import { useMobile, useTablet } from "../../themes";
import { Typography, useTheme, Link, Stack } from "@mui/material";
import {
  CardContentContainerStyled,
  CardContentItemStyled,
  CardStyled,
} from "../styled";
import { memo } from "react";
import { Icon } from "@iconscout/react-unicons/dist/icon";

interface InfoCardProps {
  Icon: Icon;
  title: string;
  blurb: string;
  link?: string;
  onClick?: () => void;
}
export const InfoCard = memo(
  ({ Icon, title, blurb, link, onClick }: InfoCardProps) => {
    const isMobile = useMobile();
    const isTablet = useTablet();
    const { palette } = useTheme();
    return (
      <Link
        href={link}
        target="_blank"
        width={isMobile ? "inherit" : isTablet ? "100%" : 275}
        onClick={onClick}
      >
        <CardStyled
          sx={{
            height: isMobile ? "100%" : 220,
            minWidth: isMobile || isTablet ? 288 : 275,
            p: isMobile ? 1 : 0,
            ":hover": {
              boxShadow: "0 1px 12px 2px  rgba(99,100,105,0.2)",
            },
          }}
        >
          <CardContentContainerStyled
            container
            height={"100%"}
            sx={{
              justifyContent: isMobile || isTablet ? "center" : "flex-start",
            }}
          >
            <CardContentItemStyled
              item
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon color={palette.primary.main} height={40} width={40} />
              <Typography variant="h4" fontSize={18} fontWeight={600}>
                {title}
              </Typography>
              <Stack flexWrap={"wrap"} direction={"row"} width={"100%"}>
                <Typography
                  fontSize={14}
                  align={"center"}
                  lineHeight={"20px"}
                  p={isMobile ? 1 : 3}
                  pt={1}
                  height={isMobile ? "100%" : 80}
                  sx={{ width: isMobile ? 300 : "auto" }}
                >
                  {blurb}
                </Typography>
              </Stack>
            </CardContentItemStyled>
          </CardContentContainerStyled>
        </CardStyled>
      </Link>
    );
  },
);
