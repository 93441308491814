import { Box, MenuItem, SxProps, Theme } from "@mui/material";
import {
  CriticalIcon,
  CriticalIconOutlined,
  DoubleDownIcon,
  DoubleUpIcon,
  MinusCircleIcon,
  MinusIcon,
  Tooltip,
} from "../icons";
import { ChipStyled } from "../styled";
import {
  GetTaskApiResponse,
  useUpdateTaskPriorityMutation,
} from "../../state/rtk-query/state/tasks";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { DropDownButton } from "../buttons/DropDownButton";

export type PriorityType<T extends "internal" | "external"> =
  T extends "internal"
    ? NonNullable<GetTaskApiResponse[`${T}_priority`]>
    : GetTaskApiResponse["priority"];

type InternalPriority = {
  type: "internal";
  priority: PriorityType<"internal">;
};

type ExternalPriority = {
  type: "external";
  priority: PriorityType<"external">;
};

type DropDownProps = {
  dropDown: true;
  taskId: number;
  orgId: number;
};

type BaseProps = {
  edge?: "badge" | "pill";
  sx?: SxProps<Theme>;
  showPriorityName?: boolean;
} & (DropDownProps | { dropDown?: false; taskId?: number; orgId?: number });

export type PriorityChipProps = BaseProps &
  (InternalPriority | ExternalPriority);

function priorityIsNotUndefined(
  props:
    | (BaseProps &
        (
          | {
              type: "internal";
              priority: PriorityType<"internal"> | undefined;
            }
          | {
              type: "external";
              priority: PriorityType<"external"> | undefined;
            }
        ))
    | PriorityChipProps,
): props is PriorityChipProps {
  if (props.priority) return true;
  return false;
}

function _PriorityChip(
  props:
    | (BaseProps &
        (
          | {
              type: "internal";
              priority: PriorityType<"internal"> | undefined;
            }
          | {
              type: "external";
              priority: PriorityType<"external"> | undefined;
            }
        ))
    | PriorityChipProps,
) {
  if (!priorityIsNotUndefined(props)) return null;
  props;
  if (props.type === "internal") {
    return <InternalPriorityCip {...props} />;
  }
  if (props.dropDown) {
    return <PriorityDropDown {...props} />;
  }
  return <PriorityChip {...props} />;
}

export { _PriorityChip as PriorityChip };

function InternalPriorityCip(props: PriorityChipProps) {
  const isInternal = useSelector(authSelectors.isInternal);
  if (!isInternal) {
    return null;
  }
  if (props.dropDown) {
    return <PriorityDropDown {...props} />;
  }
  return <PriorityChip {...props} />;
}

const priorityIconsAndColors = {
  internal: {
    high: {
      Icon: () => <DoubleUpIcon />,
      color: "red",
    },
    medium: {
      Icon: () => <MinusIcon />,
      color: "yellow",
    },
    low: {
      Icon: () => <DoubleDownIcon />,
      color: "green",
    },
  },
  external: {
    normal: {
      Icon: () => <MinusCircleIcon size={20} />,
      color: "dark-green",
    },
    high: {
      Icon: () => <CriticalIconOutlined size={20} />,
      color: "dark-yellow",
      size: 20,
    },
    critical: {
      Icon: () => <CriticalIcon size={20} />,
      color: "dark-red",
    },
  },
} as const;

function PriorityDropDown(props: PriorityChipProps & DropDownProps) {
  const { taskId, orgId, dropDown: _, ...rest } = props;
  const [updatePriority] = useUpdateTaskPriorityMutation();
  const priorityOptions = (
    props.type === "external"
      ? ["normal", "high", "critical"]
      : ["low", "medium", "high"]
  ) as (keyof (typeof priorityIconsAndColors)[typeof props.type])[];

  return (
    <DropDownButton
      items={priorityOptions}
      renderItems={(item, _, close) => {
        return (
          <MenuItem
            key={item}
            value={item}
            onClick={() => {
              if (item !== props.priority) {
                updatePriority({
                  id: taskId,
                  orgId,
                  body: { ...rest, priority: item },
                });
              }
              close();
            }}
            sx={{ width: "100%" }}
          >
            <PriorityChip {...props} priority={item} />
          </MenuItem>
        );
      }}
    >
      <PriorityChip {...props} />
    </DropDownButton>
  );
}

function PriorityChip(props: PriorityChipProps) {
  const { edge = "badge", sx, priority, showPriorityName, dropDown } = props;
  const { Icon, color } =
    props.type === "internal"
      ? priorityIconsAndColors[props.type][props.priority]
      : priorityIconsAndColors[props.type][props.priority];

  return (
    <ChipStyled
      label={
        <Tooltip
          title={
            <Box component={"span"} sx={{ textTransform: "capitalize" }}>
              {priority}
              {props.type === "internal" ? " Internal" : ""} Priority
            </Box>
          }
          props={{ placement: "top" }}
        >
          <Box
            component={"span"}
            display={"flex"}
            alignItems={"center"}
            {...(showPriorityName
              ? { height: "100%", width: "100% !important" }
              : {})}
          >
            <Icon />
            {!!showPriorityName && (
              <Box
                component={"span"}
                fontWeight={600}
                pl={0.5}
                alignSelf={"center"}
                width={"100% !important"}
              >
                {priority}
              </Box>
            )}
          </Box>
        </Tooltip>
      }
      onClick={dropDown ? () => {} : undefined}
      edge={edge}
      shade={color}
      type={"accent"}
      sx={{
        borderRadius: edge === "badge" ? "4px" : undefined,
        textTransform: "uppercase",
        height: 24,
        fontSize: 12,
        ...(showPriorityName
          ? {
              width: "100% !important",
              "& > .MuiChip-label": {
                width: "95px !important",
              },
            }
          : {}),
        ...sx,
      }}
      size="small"
    />
  );
}
