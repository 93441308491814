import * as authApi from "./auth";
import * as calendlyApi from "./calendly";
import * as globalSearchApi from "./global_search";
import * as googleApi from "./google";
import * as mediaApi from "./media";
import * as microsoftApi from "./microsoft";
import * as notificationApi from "./notification";
import * as organizationApi from "./organization";
import * as rolesApi from "./roles";
import * as sessionApi from "./session";
import * as slackApi from "./slack";
import * as statusesApi from "./statuses";
import * as tasksApi from "./tasks";
import * as timerApi from "./timer";
import * as topicsApi from "./topics";
import * as timeEntryApi from "./time_entry";
import * as userApi from "./user";
import * as positionApi from "./positions";
import * as preferencesApi from "./preferences";
import * as announcementsApi from "./announcements";
import * as reportsApi from "./reports";
import * as termsApi from "./terms";

export const actions = {
  ...authApi,
  ...calendlyApi,
  ...globalSearchApi,
  ...googleApi,
  ...mediaApi,
  ...microsoftApi,
  ...notificationApi,
  ...organizationApi,
  ...rolesApi,
  ...sessionApi,
  ...slackApi,
  ...statusesApi,
  ...tasksApi,
  ...topicsApi,
  ...timeEntryApi,
  ...timerApi,
  ...userApi,
  ...positionApi,
  ...preferencesApi,
  ...announcementsApi,
  ...reportsApi,
  ...termsApi,
};
export const generatedApis = {
  authApi: authApi,
  calendlyApi: calendlyApi,
  globalSearchApi: globalSearchApi,
  googleApi: googleApi,
  mediaApi: mediaApi,
  microsoftApi: microsoftApi,
  notificationApi: notificationApi,
  organizationApi: organizationApi,
  rolesApi: rolesApi,
  sessionApi: sessionApi,
  slackApi: slackApi,
  statusesApi: statusesApi,
  tasksApi: tasksApi,
  topicsApi: topicsApi,
  timeEntryApi: timeEntryApi,
  timerApi: timerApi,
  userApi: userApi,
  positionApi: positionApi,
  preferencesApi: preferencesApi,
  announcementsApi: announcementsApi,
  reportsApi: reportsApi,
  termsApi: termsApi,
};

export type apis = keyof typeof generatedApis;

// type TagType<T extends apis> = typeof generatedApis[T]["addTagTypes"][number];
// export const useInvalidateTags = <T extends apis>(
//   apiName: T,
//   tags: Readonly<TagType<T> | TagType<T>[]>,
// ) => {
//   const dispatch = useDispatch();
//   const api = generatedApis[apiName];
//   useEffect(() => {
//     dispatch(api.actions.util.invalidateTags(tags as any));
//   }, []);
//   return;
// };
