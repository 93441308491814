import { api } from "../api";
export const addTagTypes = ["preferences"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyPreferences: build.query<
        GetMyPreferencesApiResponse,
        GetMyPreferencesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/preferences/mine`,
        }),
        providesTags: ["preferences"],
      }),
      updateMyPreferences: build.mutation<
        UpdateMyPreferencesApiResponse,
        UpdateMyPreferencesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/preferences/mine`,
          method: "PUT",
          params: { preference: queryArg.preference },
        }),
        invalidatesTags: ["preferences"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetMyPreferencesApiResponse = /** status 200 Show Preferences */ {
  id: number;
  comment_added: boolean;
  session_invite: boolean;
  upcoming_session_day: boolean;
  upcoming_session_hour: boolean;
  late_to_session: boolean;
  inactive_reminder: boolean;
  task_auto_closed: boolean;
  announcement_global: boolean;
  announcement_org: boolean;
  all_task_activity: boolean;
  view_task_as_modal: boolean;
  task_close: boolean;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type GetMyPreferencesApiArg = {
  /** organization id */
  orgId: number;
};
export type UpdateMyPreferencesApiResponse =
  /** status 204 An empty response */ void;
export type UpdateMyPreferencesApiArg = {
  /** preference type */
  preference:
    | "comment_added"
    | "session_invite"
    | "upcoming_session_day"
    | "upcoming_session_hour"
    | "late_to_session"
    | "inactive_reminder"
    | "task_auto_closed"
    | "announcement_global"
    | "announcement_org"
    | "all_task_activity"
    | "view_task_as_modal"
    | "task_close";
  /** organization id */
  orgId: number;
};
export const {
  useGetMyPreferencesQuery,
  useLazyGetMyPreferencesQuery,
  useUpdateMyPreferencesMutation,
} = injectedRtkApi;
