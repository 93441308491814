import { Typography, Stack, Button } from "@mui/material";
import {
  AddIcon,
  AddOrEditOrgModal,
  CardStyled,
  ConfirmDeleteDialog,
  ServerDataGrid,
} from "../../../components";
import { useCallback, useEffect, useState } from "react";
import { actions, authSelectors, useSelector } from "../../../state";
import { useOrgColumns } from "../../../columns";
import {
  GetOrgsApiResponse,
  useBulkDeleteOrgsSubConsultantMutation,
  useUpdateOrgsConsultantMutation,
} from "../../../state/rtk-query/state/organization";
import { BaseForm, BaseModal } from "../../../components/forms";
import { TopLabel, SelectField } from "../../../components/forms/FormFields";
import { useQueryParam, useRtkQueryParams } from "../../../components/hooks";
import { useConsultantsList } from "../../../components/hooks/useLists";
import { ToggleDropdown } from "../../../components/buttons/ToggleDropdown";
import RowsSelectedBanner from "../../../components/cards/RowsSelectedBanner";
import { useMobile } from "../../../themes";
import { useRowSelectionModel } from "../../../components/hooks/useRowSelectionModel";
const { useGetOrgsQuery } = actions;

export const OrganizationsPage = () => {
  const [params, setParams] = useRtkQueryParams();
  const isMobile = useMobile();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [inactive, setInactive] = useQueryParam<boolean | undefined>(
    "inactive",
  );
  const {
    data: orgs,
    refetch,
    isFetching,
  } = useGetOrgsQuery(
    { ...params, inactive },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    onCellClick,
    onColumnHeaderClick,
    selectedRows,
    setSelectedRows,
    onRowClick,
  } = useRowSelectionModel(orgs?.rows);

  const [editOrganization, setEditOrganization] =
    useState<GetOrgsApiResponse["rows"][number]>();
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
  const onClose = useCallback(() => {
    setOpenOrganizationModal(!openOrganizationModal);
    if (refetch) {
      refetch();
    }
    setEditOrganization(undefined);
  }, [openOrganizationModal, refetch, editOrganization]);

  const onEditClick = useCallback(
    (row: GetOrgsApiResponse["rows"][number]) => {
      setEditOrganization(row);
      setOpenOrganizationModal(true);
    },
    [editOrganization],
  );

  const columns = useOrgColumns(onEditClick);
  const [openConsultantModal, setOpenConsultantModal] = useState(false);

  const [removeSubConsultants, { isSuccess }] =
    useBulkDeleteOrgsSubConsultantMutation();

  useEffect(() => {
    if (isSuccess) {
      setSelectedRows([]);
    }
  }, [isSuccess]);

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={"wrap"}
        mb={3}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography variant="h2" fontWeight="bold">
            Organizations
          </Typography>
          <ToggleDropdown
            option1="active"
            option2="inactive"
            setValue={(val) => setInactive(val === "inactive" || undefined)}
            value={inactive ? "inactive" : "active"}
          />
        </Stack>
        <Button variant="contained" onClick={onClose}>
          <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />{" "}
          Organization
        </Button>
      </Stack>
      <RowsSelectedBanner
        selectedRowsCt={selectedRows.length}
        onClear={() => setSelectedRows([])}
        onSelectAll={() => setSelectedRows(orgs?.rows?.map((r) => r.id) || [])}
      >
        {isAdmin && (
          <Button
            color="info"
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => setOpenConsultantModal(!openConsultantModal)}
          >
            Edit Consultant
          </Button>
        )}
        {isAdmin && (
          <ConfirmDeleteDialog
            buttonText="Remove Sub-Consultants"
            buttonColor="info"
            handleConfirm={() =>
              removeSubConsultants({ body: { orgs: selectedRows } })
            }
            titleText="Remove Sub-Consultants"
            subTitleText="Are you sure you want to remove these sub-consultants?"
          />
        )}
      </RowsSelectedBanner>

      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <ServerDataGrid
          toolbarProps={{ hideToolbar: true }}
          rowCount={orgs?.count || 0}
          isFetching={isFetching}
          rows={orgs ? orgs.rows : []}
          columns={columns}
          setQueryParams={setParams}
          hideColumns={{ sub_consultant_id: false }}
          currentParams={params}
          props={{
            density: "standard",
            sx: {
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
              },
              ".MuiDataGrid-row": {
                cursor: "pointer",
              },
            },
            onRowClick,
            checkboxSelection: isAdmin && !inactive,
            onCellClick,
            onColumnHeaderClick,
            rowSelectionModel: selectedRows,
          }}
        />
      </CardStyled>

      <AddOrEditOrgModal
        open={openOrganizationModal}
        onClose={onClose}
        editOrg={editOrganization}
      />
      <BaseModal
        open={openConsultantModal}
        title="Edit Consultant"
        onClose={() => {
          setOpenConsultantModal(!openConsultantModal);
        }}
      >
        <EditConsultantForm
          selectedRows={selectedRows}
          onClose={() => {
            setOpenConsultantModal(!openConsultantModal);
            setSelectedRows([]);
          }}
        />
      </BaseModal>
    </>
  );
};

type EditConsultantFormProps = {
  selectedRows: number[];
  onClose: () => void;
};
function EditConsultantForm({
  selectedRows,
  onClose,
}: EditConsultantFormProps) {
  const { consultants } = useConsultantsList();
  const initialValues = {
    consultant_id: "" as any as number,
    sub_consultant_id: "" as any as number,
  };
  const [updateOrgsConsultant] = useUpdateOrgsConsultantMutation();
  const onSubmit = ({
    consultant_id,
    sub_consultant_id,
  }: typeof initialValues) => {
    if (consultant_id || sub_consultant_id) {
      updateOrgsConsultant({
        body: {
          orgs: selectedRows,
          consultant_id: consultant_id || undefined,
          sub_consultant_id: sub_consultant_id || undefined,
        },
      });
    }
    onClose();
  };
  return (
    <BaseForm
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <TopLabel label="Consultant" fullWidth sx={{ my: 1 }}>
            <SelectField
              name="consultant_id"
              items={consultants.map((c) => ({
                id: c.id,
                name: `${c.first_name} ${c.last_name}`,
              }))}
              fullWidth
            />
          </TopLabel>
          <TopLabel label="Sub Consultant" fullWidth sx={{ my: 1 }}>
            <SelectField
              name="sub_consultant_id"
              items={consultants.map((c) => ({
                id: c.id,
                name: `${c.first_name} ${c.last_name}`,
              }))}
              fullWidth
            />
          </TopLabel>
        </>
      )}
    </BaseForm>
  );
}
