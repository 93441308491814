import { AdminPanelSettingsOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, styled } from "@mui/material";
import { ReactNode } from "react";
import { Popover } from "../icons/Popover";

interface Props {
  isSender: boolean;
  isMedia: boolean;
}

interface MessageProps extends Props {
  sx?: any;
  children: ReactNode;
  isInternal: Boolean;
  prevMessage?: {
    isSender: boolean;
    name?: string;
  };
}
export const ChatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowY: "auto",
  padding: theme.spacing(2),
  borderRadius: 12,
  display: "flex",
  flexDirection: "column-reverse",
  maxHeight: "500px",
  minHeight: "200px",
}));

const SenderName = styled("span")(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[900],
  whiteSpace: "nowrap",
}));

const MessageWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

const Message = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSender" && prop !== "isMedia",
})<Props>(({ theme, isSender, isMedia }) => ({
  color: isSender ? "#fff" : "#000",
  backgroundColor:
    isSender && !isMedia
      ? theme.palette.primary.main
      : isSender && isMedia
      ? theme.palette.primary.main
      : isMedia
      ? theme.palette.grey[200]
      : "#e0e0e0",
  marginLeft: isSender ? "auto" : "",
  maxWidth: "75%",
  width: "fit-content",
  padding: theme.spacing(1),
  borderRadius: 12,
  lineHeight: "1.4",
  whiteSpace: "pre-line",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root, & .MuiTypography-root ": {
    color: isSender ? theme.palette.grey[200] : theme.palette.primary.main,
  },
}));

export function ChatMessage({
  isSender,
  isMedia = false,
  isInternal = false,
  children,
  prevMessage,
}: MessageProps) {
  return (
    <MessageWrapper>
      <Stack
        direction={"row"}
        justifyContent={isSender ? "flex-end" : "flex-start"}
        spacing={1}
        alignItems={"flex-end"}
      >
        {!isSender && <SenderName>{prevMessage?.name}</SenderName>}

        {isInternal ? (
          <Box fontSize={14}>
            <Popover title="This is an internal message">
              <AdminPanelSettingsOutlined fontSize="inherit" />
            </Popover>
          </Box>
        ) : null}
      </Stack>
      <Message isSender={isSender} isMedia={isMedia} my={0.5}>
        {children}
      </Message>
    </MessageWrapper>
  );
}
