import { Stack, LinearProgress } from "@mui/material";
import { ReactNode } from "react";
import loadAnimation from "../../assets/img/AdmireLoader.gif";

export interface LoadableProps {
  isLoading?: boolean;
  children: ReactNode;
  useProgressBar?: boolean;
}
export function Loadable(props: LoadableProps) {
  if (props.isLoading) {
    if (props.useProgressBar) {
      return <LinearProgress />;
    }
    return (
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        sx={{
          "& > img:hover": {
            transition: "all .5s",
            transform: "rotate(360deg)",
          },
        }}
      >
        <img
          style={{
            width: 100,
            height: 100,
          }}
          src={loadAnimation}
          alt="sidebar"
        />
      </Stack>
    );
  }
  return <>{props.children}</>;
}
