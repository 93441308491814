import { styled } from "@mui/material/styles";

export const PageRootStyled = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));

export const PageContainerStyled = styled(`div`)(({ theme }) => ({
  backgroundColor: `${theme.palette.grey[50]}`,
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: "20px",
  width: "100%",
  height: "fit-content",
  padding: "32px !important",
}));
