import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSessionActivitiesQuery,
  useGetSessionsByIdQuery,
} from "../../../state/rtk-query/state/session";
import { useGetOrgSessionActivitiesQuery } from "../../../state/rtk-query/state/session";
import {
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  CardStyled,
  CloseIcon,
  LeftArrowIcon,
  Modal,
  PageTabs,
  SessionPageHeader,
  SocketMessage,
  TaskCard,
  useRefetchSocket,
} from "../../../components";
import { useLocation } from "../../../lib";
import { useCallback, useEffect, useState } from "react";
import { useOrgId } from "../../../components/hooks";
import { ActivitiesView } from "../../../components/views";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Loadable } from "../../../components/misc";
import { EmptyState } from "../../../components/misc";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, clientActions, clientSelectors } from "../../../state";
import { AddTaskForm } from "../../../components/forms/AddTaskForm";

export const SessionPage = () => {
  const id = Number(useParams().id);
  const { palette } = useTheme();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const { currentData, isError, isFetching, isSuccess, refetch } =
    useGetSessionsByIdQuery(id && orgId ? { id, orgId } : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  const [openAddTaskForm, setOpenAddTaskForm] = useState(false);
  const shouldRefetch = async (d: SocketMessage) => {
    if (d.tag === "session" && d.message === "refresh" && d?.entity_id === id) {
      refetch();
    }
  };
  useRefetchSocket({ tag: "session", cb: shouldRefetch });
  if (isError) {
    nav("/");
  }

  const { tab = "tasks" } = useLocation().query;
  const to = isInternal
    ? currentData?.org_id
      ? `/organizations/${currentData.org_id}?tab=sessions`
      : ""
    : "/sessions";
  const { screen, sessionId } = useSelector(clientSelectors.currentSupportStep);

  // fixes internal users creating a session and modal closing
  useEffect(() => {
    if (
      currentData?.org_id &&
      sessionId === (id || 0) &&
      screen === "AddTask"
    ) {
      setOpenAddTaskForm(true);
    }
  }, [currentData?.org_id]);

  const closeModal = useCallback(() => {
    dispatch(clientActions.setSupportScreenAction("TypeSelectionInternal"));
    dispatch(clientActions.setSupportSessionId(undefined));
    setOpenAddTaskForm(false);
  }, [screen, sessionId]);

  return (
    <>
      {currentData?.org_id && openAddTaskForm && (
        <Modal
          open={openAddTaskForm}
          onClose={closeModal}
          sx={{
            width: "40%",
            height: "max-content",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h1">{"Add Task"}</Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <AddTaskForm orgId={currentData.org_id} onClose={closeModal} />
        </Modal>
      )}
      <Link
        component={RouterLink}
        to={to}
        color={"inherit"}
        width={"min-content"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <LeftArrowIcon color={palette.primary.main} />
          <Typography fontWeight={500} noWrap>
            All sessions
          </Typography>
        </Stack>
      </Link>

      <Box mt={4}>
        <SessionPageHeader session={currentData} />
      </Box>

      <PageTabs
        defaultTab={tab}
        tabValues={[
          { id: "tasks", label: "Tasks" },
          { id: "activity", label: "Activity" },
        ]}
      />
      <Loadable isLoading={isFetching}>
        {tab === "tasks" && (
          <>
            {isSuccess && !currentData?.tasks?.length ? (
              <EmptyState
                type="tasks"
                orgId={isInternal ? currentData?.org_id : orgId}
                sessionId={id}
                isArchived={!!currentData?.deleted_at}
              />
            ) : (
              <Stack direction={"column"} gap={1}>
                {currentData?.tasks?.map((t) => {
                  return (
                    <CardStyled
                      sx={{
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      key={t.id}
                    >
                      <TaskCard task={t} hideMenuButtons />
                    </CardStyled>
                  );
                })}
              </Stack>
            )}
          </>
        )}
      </Loadable>
      {tab === "activity" && <SessionActivitiesView id={id} orgId={orgId} />}
    </>
  );
};

function SessionActivitiesView({ id, orgId }: { id?: number; orgId?: number }) {
  if (!id || !orgId) return null;
  const { data: { activities } = {} } =
    orgId === -1
      ? useGetSessionActivitiesQuery(
          { id },
          { refetchOnMountOrArgChange: true },
        )
      : useGetOrgSessionActivitiesQuery(
          { id, orgId },
          { refetchOnMountOrArgChange: true },
        );
  if (!activities) return null;
  return <ActivitiesView activities={activities} />;
}
