import { api } from "../api";
export const addTagTypes = ["migration_queue"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMigrationQueue: build.query<
        GetMigrationQueueApiResponse,
        GetMigrationQueueApiArg
      >({
        query: () => ({ url: `/migration_queue` }),
        providesTags: ["migration_queue"],
      }),
      updateCode: build.mutation<UpdateCodeApiResponse, UpdateCodeApiArg>({
        query: (queryArg) => ({
          url: `/migration_queue/${queryArg.id}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["migration_queue"],
      }),
      addToQueue: build.mutation<AddToQueueApiResponse, AddToQueueApiArg>({
        query: (queryArg) => ({
          url: `/migration_queue/${queryArg.id}`,
          method: "PUT",
        }),
        invalidatesTags: ["migration_queue"],
      }),
      removeFromQueue: build.mutation<
        RemoveFromQueueApiResponse,
        RemoveFromQueueApiArg
      >({
        query: (queryArg) => ({
          url: `/migration_queue/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["migration_queue"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetMigrationQueueApiResponse =
  /** status 200 get migration queue */ {
    count: number;
    rows: {
      id: number;
      client_id: number;
      client_name: string;
      code?: string | null;
      status?:
        | ("waiting" | "in_progress" | "completed" | "failed" | null)
        | null;
      metadata?: object | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetMigrationQueueApiArg = void;
export type UpdateCodeApiResponse = /** status 204 An empty response */ void;
export type UpdateCodeApiArg = {
  /** record id */
  id: number;
  body: {
    code: string | null;
  };
};
export type AddToQueueApiResponse = /** status 204 An empty response */ void;
export type AddToQueueApiArg = {
  /** record id */
  id: number;
};
export type RemoveFromQueueApiResponse =
  /** status 204 An empty response */ void;
export type RemoveFromQueueApiArg = {
  /** record id */
  id: number;
};
export const {
  useGetMigrationQueueQuery,
  useLazyGetMigrationQueueQuery,
  useUpdateCodeMutation,
  useAddToQueueMutation,
  useRemoveFromQueueMutation,
} = injectedRtkApi;
