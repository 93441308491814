import { ChevronRight } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { PickersDayProps, DateCalendar } from "@mui/x-date-pickers";
import { isSameWeek, addDays, endOfWeek, startOfWeek, subDays } from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { Navigation, formatDate, useLocation } from "../../lib";
import { ChevronLeftIcon } from "../icons";
import { Modal } from "../modal";
import { CustomPickersDay } from ".";
import { useMobile } from "../../themes";

const isInSameWeek = (dayA: Date, dayB: Date | null | undefined) => {
  if (dayB == null) {
    return false;
  }
  return isSameWeek(dayA, dayB);
};

const Day = (
  props: PickersDayProps<Date> & {
    selectedDay?: Date | null;
    hoveredDay?: Date | null;
  },
) => {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
};

interface Props {
  pickedDate: Date;
  setPickedDate: Dispatch<SetStateAction<Date>>;
}
export const WeekPicker = ({ pickedDate, setPickedDate }: Props) => {
  const { pathname } = useLocation();
  const isMobile = useMobile();
  const [openCalModal, setOpenCalModal] = useState(false);
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState(startOfWeek(pickedDate));

  const setDateAndUrl = (date: Date) => {
    setStartDate(date);
    setPickedDate(date);
    Navigation.replace(pathname, {
      query: { date: date.toLocaleDateString() },
    });
  };
  const handlePrevWeek = () => {
    const prevWeekStart = startOfWeek(subDays(startDate, 7));
    setDateAndUrl(prevWeekStart);
  };

  const handleNextWeek = () => {
    const nextWeekStart = startOfWeek(addDays(startDate, 7));
    setDateAndUrl(nextWeekStart);
  };

  const onCalendarChange = (val: Date | null) => {
    if (val) {
      setDateAndUrl(startOfWeek(val));
      setOpenCalModal(false);
    }
  };

  const endDate = endOfWeek(startDate);

  const openModalClick = () => setOpenCalModal((c) => !c);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} width={"100%"}>
        <IconButton onClick={handlePrevWeek}>
          <ChevronLeftIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
        </IconButton>

        <Button
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            lineHeight: "32px",
            textAlign: "center",
            width: isMobile ? "100%" : "fit-content",
          }}
          color="black"
          onClick={openModalClick}
        >
          {formatDate(startDate, "LLLL do")} - {formatDate(endDate, "do")}
        </Button>

        <IconButton onClick={handleNextWeek}>
          <ChevronRight style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
        </IconButton>
      </Stack>
      {openCalModal && (
        <Modal
          open={openCalModal}
          onClose={openModalClick}
          sx={{ width: "fit-content" }}
        >
          <DateCalendar
            value={startDate}
            onChange={onCalendarChange}
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
              day: (ownerState) => ({
                selectedDay: startDate,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              }),
            }}
          />
        </Modal>
      )}
    </>
  );
};
