import {
  Box,
  Divider,
  IconButton,
  Link,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useCallback, useEffect, useState, useMemo, MouseEvent } from "react";
// local
import { Navigation, filterBoolean } from "../../lib";
import { actions } from "../../state";

import { SocketMessage, useRefetchSocket } from "../providers/SocketProvider";
import "yet-another-react-lightbox/styles.css";

import { Chat, ChatEditor } from "../drawer/task";
import { AvatarGroup, FileIcon, LinkIconOrig } from "../icons";

import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useMobile } from "../../themes";
import { DrawerSubSection, DrawerSubSectionHeaderStyled } from "../drawer";
import { BaseModal, AttendeeAndAssigneeForm } from "../forms";
import { useTimerSetter } from "../hooks";
import { useActivityActionsList, useStatusesList } from "../hooks/useLists";
import { Loadable } from "../misc";
import { Modal } from "./Modal";
import {
  TaskDescription,
  TaskMenu,
  ValidateTaskWrapper,
  TaskValidationProps,
  TaskSectionType,
  ModalTimerBanner,
  ModalTaskHeader,
  AttachmentsWithIcons,
} from "../task";
import { GetSignedUrlByMediaIdApiResponse } from "../../state/rtk-query/state/media";
import { CompleteTaskButton } from "../task/CompleteTaskButton";

const { useMarkTaskChatAsReadMutation } = actions;

const TaskModal = ({
  taskData,
  isFetching,
  id,
  orgId,
  closeTask,
  collapse,
  setCollapse,
  taskChats,
  taskWatchers,
}: TaskValidationProps) => {
  const {
    priority,
    title,
    assigned_to_user,
    client_assignee_user,
    type,
    status,
    description,
    linkedTasks,
    topic,
    deleted_at,
  } = taskData || {};
  const { palette } = useTheme();
  const isMobile = useMobile();
  const biggerModal = useMobile("(max-width:1300px)");
  const [openModal, setOpenModal] = useState(false);

  const [openAttachments, setOpenAttachments] =
    useState<HTMLButtonElement | null>(null);
  const [lightBoxSlides, setLightBoxSlides] = useState<
    GetSignedUrlByMediaIdApiResponse[] | undefined
  >();
  const [lightBoxIndex, setLightBoxIndex] = useState(0);
  const [createTimerActivity] = useCreateUserTimerActivityMutation();

  const { data: taskChat, refetch, isFetching: isFetchingTaskChat } = taskChats;
  const [updateRead] = useMarkTaskChatAsReadMutation();
  const { getActionId } = useActivityActionsList();
  const { getStatusByPhase } = useStatusesList(type, !type);
  const completeStatuses = getStatusByPhase("done");
  const isComplete = completeStatuses.includes(status?.id || 0);
  const isArchived = Boolean(deleted_at);
  const isCompleteOrArchived = isComplete || isArchived;

  let timeout: any;
  const interval = useCallback(
    (action: "start" | "clear") => {
      if (action === "start" && !timeout && id && orgId) {
        timeout = setTimeout(async () => {
          await updateRead({
            id,
            orgId,
            body: { is_read: true },
          });
        }, 5000);
      } else {
        if (timeout && id) clearTimeout(timeout);
      }
    },
    [id, orgId, timeout],
  );
  useEffect(() => {
    if (id && id > 0) {
      interval("start");
    }
    return () => interval("clear");
  }, [id, orgId]);
  const openAction = getActionId("task_opened");

  useEffect(() => {
    if (openAction) {
      createTimerActivity({
        body: { entity: "task", entity_id: id, action_id: openAction },
      });
    }
  }, [openAction, id]);

  useTimerSetter(taskData?.org_id);

  // for receiver
  const shouldRefetch = async (d: SocketMessage) => {
    if (
      d.tag === "tasks_chat" &&
      d.message === "refresh" &&
      id === d.entity_id
    ) {
      refetch();
      interval("start");
    }
  };
  useRefetchSocket({ tag: "tasks_chat", cb: shouldRefetch });

  const isCol = (section: TaskSectionType) => collapse.includes(section);

  const collapseSection = (section: TaskSectionType) => {
    setCollapse((old) => {
      if (!old.includes(section)) {
        return [...old, section];
      } else {
        return old.filter((s) => s !== section);
      }
    });
  };

  const closeAction = getActionId("task_closed");
  const onClose = () => {
    if (closeAction) {
      createTimerActivity({
        body: { entity: "task", entity_id: id, action_id: closeAction },
      });
    }
    closeTask();
  };

  const usersRelatedToTask = useMemo(
    () =>
      filterBoolean([
        assigned_to_user && {
          ...assigned_to_user,
          label: "Assignee",
        },
        client_assignee_user && {
          ...client_assignee_user,
          label: "Client Assignee",
        },
        ...(taskWatchers?.rows ?? []),
      ]),
    [assigned_to_user, client_assignee_user, taskWatchers?.rows],
  );

  const modalClick = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const displayMedia =
    taskData?.media?.filter((m) => !m?.task_media?.is_custom_work_manual) ?? [];

  const openAttachmentModalClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!openAttachments) {
      setOpenAttachments(e.currentTarget);
    } else {
      setOpenAttachments(null);
    }
  };

  return (
    <>
      <Modal
        open={id > 0}
        onClose={onClose}
        sx={{
          mt: 2,
          height: "90%",
          width: isMobile ? "100%" : biggerModal ? "90%" : "60%",
          overflow: "auto",
          p: "0px !important",
        }}
      >
        <Stack
          direction={"column"}
          overflow={"hidden"}
          position={isMobile ? "initial" : "sticky"}
          top={0}
          zIndex={20}
          bgcolor={"#FFF"}
        >
          <ModalTimerBanner
            orgId={taskData?.org_id}
            type={type}
            taskId={id}
            taskCode={taskData?.code ?? ""}
            taskTitle={title ?? ""}
            createdAt={new Date(taskData?.created_at ?? "")}
            createdBy={`${taskData?.reported_by_user?.first_name ?? ""} ${
              taskData?.reported_by_user?.last_name ?? ""
            }`}
            taskTimeSpent={taskData?.time_spent}
          />
          <ModalTaskHeader
            taskData={taskData}
            id={id}
            orgId={orgId}
            isComplete={isCompleteOrArchived}
            onClose={onClose}
            AvatarAndMenu={
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <AvatarGroup
                  users={usersRelatedToTask}
                  maxBeforeGrouping={2}
                  setOpenModal={
                    !isCompleteOrArchived && type !== "internal_task"
                      ? setOpenModal
                      : undefined
                  }
                />
                <TaskMenu
                  isComplete={isComplete}
                  task={taskData}
                  manageAttendeeClick={modalClick}
                  isArchived={isArchived}
                />
              </Stack>
            }
            FileIcon={
              !!displayMedia?.length && (
                <IconButton onClick={openAttachmentModalClick}>
                  <FileIcon
                    style={{
                      color: openAttachments ? "#FFF" : palette.primary.main,
                      backgroundColor: openAttachments
                        ? palette.primary.main
                        : "#FFF",
                      border: `1px solid ${palette.primary[200]}`,
                      padding: 3,
                      borderRadius: "6px",
                    }}
                  />
                </IconButton>
              )
            }
            CompleteTaskButton={
              id && orgId && <CompleteTaskButton id={id} orgId={orgId} />
            }
          />{" "}
          <Divider />
          <TaskDescription
            collapsed={isCol("description")}
            toggleCollapsed={() => collapseSection("description")}
            id={id}
            orgId={orgId}
            description={description}
            isEditable={!isCompleteOrArchived}
            isLoading={isFetching}
            priority={priority}
            type={type}
            title={title}
            topicId={topic?.id}
            topicName={topic?.name}
            code={taskData?.code || ""}
            condensed
          />
          <Divider />
          {!!linkedTasks &&
            linkedTasks.map((lt, i) => (
              <Stack
                bgcolor={palette.info[100]}
                direction={"row"}
                sx={{ fontSize: 12, whiteSpace: "nowrap" }}
                alignItems="center"
                px={4}
                py={0.5}
                height={"32px"}
                width="100%"
                key={i}
              >
                <Stack alignItems="center">
                  <LinkIconOrig color={palette.info[800]} size={13} />
                </Stack>
                <Typography width={"auto"} fontSize={12}>
                  &nbsp;Task linked to&nbsp;
                </Typography>
                <Typography component="div" noWrap>
                  <Link
                    component={RouterLink}
                    to={Navigation.appendToUrl({
                      task: lt.linkedTaskId,
                    })}
                  >
                    <Typography
                      fontSize={12}
                      color={palette.info[800]}
                      fontWeight={600}
                      overflow={"hidden"}
                      textOverflow="ellipsis"
                    >
                      {lt.linkedTask.title || ""}
                    </Typography>
                  </Link>
                </Typography>
              </Stack>
            ))}
          <Divider />
        </Stack>

        <DrawerSubSection sx={{ height: "fit-content", overflow: "auto" }}>
          <DrawerSubSectionHeaderStyled sx={{ py: 1 }}>
            <Typography
              color={palette.grey[700]}
              fontWeight={600}
              fontSize={12}
            >
              COMMENTS {taskChat?.count ? ` \u00b7 ${taskChat.count}` : ""}
            </Typography>
          </DrawerSubSectionHeaderStyled>

          <Loadable isLoading={isFetchingTaskChat && !taskChat} useProgressBar>
            <ChatEditor
              taskId={id}
              orgId={taskData?.org_id ?? 0}
              isInternalTask={taskData?.type === "internal_task"}
              currentActionPending={taskData?.action_pending}
              assigned_to_user={assigned_to_user}
              client_assignee_user={client_assignee_user}
            />
          </Loadable>

          <Loadable isLoading={isFetchingTaskChat && !taskChat} useProgressBar>
            <Box
              sx={{
                minWidth: "100%",
                overflow: "auto",
                height: "100%",
              }}
            >
              {taskChat?.rows.map((c, idx) => (
                <Chat
                  key={c.id}
                  chatDetails={c}
                  previousChat={taskChat.rows[idx > 0 ? idx - 1 : 0]}
                  isFirst={idx === 0}
                  orgId={taskData?.org_id ?? 0}
                  isComplete={isCompleteOrArchived}
                  assigned_to_user={assigned_to_user}
                  client_assignee_user={client_assignee_user}
                />
              ))}
            </Box>
          </Loadable>
        </DrawerSubSection>
      </Modal>

      <BaseModal open={openModal} onClose={modalClick} title="Manage people">
        <AttendeeAndAssigneeForm onClose={modalClick} task={taskData} />
      </BaseModal>
      <Popover
        open={!!openAttachments}
        anchorEl={openAttachments}
        onClose={openAttachmentModalClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0 1px 4px 1px rgba(99,100,105,0.2) !important",
            },
          },
        }}
      >
        <AttachmentsWithIcons
          taskId={id}
          setLightBoxSlides={setLightBoxSlides}
          setLightBoxIndex={setLightBoxIndex}
          allMedia={displayMedia}
          isComplete={isCompleteOrArchived}
          attachmentSx={{
            px: 2,
          }}
          condensed
        />
      </Popover>
      <Lightbox
        open={Boolean(lightBoxSlides)}
        close={() => setLightBoxSlides(undefined)}
        index={lightBoxIndex}
        slides={lightBoxSlides?.map((s) => {
          return { src: s?.signedUrl };
        })}
      />
    </>
  );
};

const ValidateTaskModal = () => <ValidateTaskWrapper ReturnNode={TaskModal} />;

export { ValidateTaskModal as TaskModal };
