import { Typography, IconButton, Stack } from "@mui/material";
import { KeyboardArrowDown } from "../icons";
import { DrawerSubSectionHeaderStyled } from "./drawer.styles";
import { useMobile } from "../../themes";
import { ReactNode } from "react";

interface Props {
  title: string;
  onClick?: () => void;
  rotate?: boolean;
  children?: ReactNode;
}
export const DrawerSubsectionHeader = ({
  title,
  onClick,
  rotate,
  children,
}: Props) => {
  const isMobile = useMobile();
  return (
    <DrawerSubSectionHeaderStyled>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        <Typography variant="h4">{title}</Typography>
        {children}
      </Stack>
      {onClick ? (
        <IconButton onClick={onClick} size="small">
          <KeyboardArrowDown
            sx={{
              rotate: rotate ? "180deg" : 0,
              fontSize: isMobile ? "1rem" : ".8rem",
            }}
          />
        </IconButton>
      ) : null}
    </DrawerSubSectionHeaderStyled>
  );
};
