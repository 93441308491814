import { Stack, Typography, useTheme, Divider } from "@mui/material";

import { CardStyled } from "../styled";
import { useQuery } from "../hooks";
import { capitalizeFirstLetter, castZero, filterBoolean } from "../../lib";
import {
  useGetTimeSpentOnAllOrgsSummaryQuery,
  GetTimeSpentOnAllOrgsApiArg,
} from "../../state/rtk-query/state/timer";
import { useMemo } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
type TimeFilter = GetTimeSpentOnAllOrgsApiArg["filterTime"];

interface QueryProps {
  time_filter: TimeFilter;
  dateRange: DateRange<any> | undefined;
  view: "table" | "chart";
}

export const AdminTimeFiltersCard = () => {
  const { palette } = useTheme();
  const [query, setQuery] = useQuery<QueryProps>(
    "dateRange",
    "time_filter",
    "view",
  );
  const { view, time_filter, dateRange } = query;
  const cardClick = (r?: TimeFilter) => {
    setQuery({ time_filter: r });
  };
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );
  const { currentData } = useGetTimeSpentOnAllOrgsSummaryQuery(
    {
      dateRange: hasDateRange ? dateRange : undefined,
    },
    { refetchOnMountOrArgChange: true },
  );

  return (
    <Stack width={"100%"}>
      <CardStyled
        sx={{
          p: 1,
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
        >
          {currentData?.map((r) => {
            const selected = time_filter === r.label;
            if (!r || (view === "chart" && r.label === "all_orgs")) return null;
            return (
              <Stack
                direction={"row"}
                justifyContent={"center"}
                p={2}
                key={r.label}
                borderRadius={selected ? "5px" : ""}
                bgcolor={selected ? palette.primary[50] : "#FFF"}
                color={palette.grey[900]}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => cardClick(r?.label as TimeFilter)}
                width={"100%"}
              >
                <Stack
                  direction={"column"}
                  alignItems={"flex-start"}
                  width={"100%"}
                >
                  <Typography variant="h2">{castZero(r.count)}</Typography>
                  <Typography fontSize={14} fontWeight={500}>
                    {capitalizeFirstLetter(
                      r.label.replace("_", " ").replace("avg", "average"),
                    )}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </CardStyled>
    </Stack>
  );
};
