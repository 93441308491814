import { useEffect, useState } from "react";

const useOs = () => {
  const [os, setOs] = useState("");

  useEffect(() => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes("win")) setOs("Windows");
    else if (platform.includes("mac")) setOs("MacOS");
    else if (platform.includes("linux")) setOs("Linux");
    else if (
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        platform,
      )
    ) {
      setOs("Mobile");
    } else setOs("Unknown");
  }, [window.navigator.platform]);

  return os;
};

export default useOs;
