import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useLocation } from "../../lib";
import { useGetUserProfileQuery } from "../../state/rtk-query/state/user";

export function useHeightOffset() {
  const { currentData: userInfo } = useGetUserProfileQuery();
  const isInternal = useSelector(authSelectors.isInternal);
  const inImpersonation = useSelector(authSelectors.inImpersonation);
  const { pathname } = useLocation();
  const onPrescreen = pathname === "/select-org";
  const hasOrgSelectBar =
    !isInternal && userInfo?.orgs && userInfo.orgs.length > 1 && !onPrescreen;
  const hasTimerBar =
    isInternal && userInfo?.timer_is_active && !inImpersonation;
  if (hasOrgSelectBar) {
    return 60;
  }
  if (hasTimerBar) {
    return 48;
  }
  return 0;
}
