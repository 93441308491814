import { styled } from "@mui/material";
import { PickersDayProps, PickersDay } from "@mui/x-date-pickers";
import { isSunday, isSaturday } from "date-fns";
import { ComponentType } from "react";

interface CustomPickerDayProps extends PickersDayProps<Date> {
  isSelected: boolean;
  isHovered: boolean;
}
//https://mui.com/x/react-date-pickers/date-calendar/#week-picker
export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered &&
    !isSelected && {
      backgroundColor: theme.palette.primary[200],
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[200],
      },
    }),
  ...(isSunday(day) && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isSaturday(day) && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as ComponentType<CustomPickerDayProps>;
