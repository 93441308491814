import { ChartOptions } from "chart.js";
import { defaultBarOptions } from "../ChartConstants";

export const defaultHorizontalChartOptions: ChartOptions<"bar"> = {
  ...defaultBarOptions,
  indexAxis: "y",
  plugins: {
    ...defaultBarOptions.plugins,
    tooltip: {
      callbacks: {
        label: (context) => {
          const { dataset, raw } = context;
          return `${dataset.label}: ${
            typeof raw === "number" ? raw.toFixed(2) : raw
          }`;
        },
      },
    },
  },
  layout: {
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
  },
} as ChartOptions<"bar">;
