import { Stack, Typography } from "@mui/material";
import { useCallback, useState, memo } from "react";
import { useMobile, useTablet } from "../../themes";
import { InfoCard } from "../cards";
import { ForumIcon, PresentationIcon, CalendarIcon } from "../icons";
import { GetSupportModal } from "../modal";

export const HelpSection = memo(() => {
  const isMobile = useMobile();
  const isTablet = useTablet();

  const [openModal, setOpenModel] = useState(false);
  const onClose = useCallback(() => {
    setOpenModel(() => !openModal);
  }, [openModal]);
  return (
    <>
      <Stack
        direction={"column"}
        width={"100%"}
        px={isTablet && !isMobile ? 4 : "auto"}
      >
        <Typography
          variant="h2"
          fontWeight={600}
          fontSize={22}
          marginTop={isMobile ? 2 : 0}
        >
          How can we help you today?
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          marginY={4}
          gap={1}
          minWidth={isMobile ? 288 : "100%"}
          width={"100%"}
        >
          <InfoCard
            Icon={ForumIcon}
            title="Admire Community"
            blurb="Find answers in our Exclusive Community for Questions, Insights, and Friendly Advice for Admire Users!"
            link="https://community.admirepro.com/login"
          />
          <InfoCard
            Icon={PresentationIcon}
            title="Admire Academy"
            blurb="Find support articles, setup guides, troubleshooting, FAQs, and more."
            link="https://admire.document360.io/"
          />
          <InfoCard
            Icon={CalendarIcon}
            title="Get support"
            blurb="Set up a consulting session or submit a task with your personal consultant."
            onClick={onClose}
          />
        </Stack>
      </Stack>
      <GetSupportModal open={openModal} onClose={onClose} />
    </>
  );
});
