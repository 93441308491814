import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import {
  //
  Button,
  Link,
  Typography,
  Stack,
} from "@mui/material";
import { Form, Navigation, RouterLink, useFormik, yup } from "../../lib";
import {
  User,
  authActions,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
} from "./Auth.styles";
import { GoogleIdentityAuth } from "../../components";
import { actions } from "../../state/rtk-query";
import AuthPages from ".";
const useResize = (myRef: any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);
  }, [myRef]);

  return { width, height };
};

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginPage = React.memo(function LoginPage() {
  const targetRef = useRef<HTMLHeadingElement>(null);
  const { width } = useResize(targetRef);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const { useAuthLoginMutation } = actions;
  const [login, { isSuccess, data }] = useAuthLoginMutation();
  // const { currentData } = useGetMicrosoftCredentialsQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess && data) {
      const { user, token } = data;
      if (user && token) {
        dispatch(
          authActions.load({
            user: user as User,
            token,
          }),
        );
        // let authLayout handle redirect
      }
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isLoggedIn) {
      Navigation.go(AuthPages.logout.path);
    }
  }, [isLoggedIn]);
  const frm = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values) {
      await login({ body: values });
      if (!isSuccess) {
        frm.setFieldValue("password", "");
      }
    },
  });

  return (
    <ContainerStyle>
      <Typography variant="h1" gutterBottom fontWeight={600}>
        Welcome back
      </Typography>
      <Typography variant="body1" gutterBottom fontWeight={500}>
        Sign in to your account
      </Typography>

      <FormStyle>
        <Form form={frm}>
          <TextFlexStyle>
            <Field
              component={TextField}
              autoFocus
              fullWidth
              name="username"
              label="Email"
            />
          </TextFlexStyle>
          <TextFlexStyle>
            <Field
              component={TextField}
              fullWidth
              name="password"
              label="Password"
              type="password"
            />
          </TextFlexStyle>
          <div ref={targetRef}>
            <Button
              variant="contained"
              type="submit"
              disabled={frm.isSubmitting}
              fullWidth
            >
              Sign In
            </Button>
          </div>

          <CenterElementStyle>
            <Link
              component={RouterLink}
              to={`/auth/forgot-password?email=${encodeURIComponent(
                frm.values.username,
              )}`}
            >
              Forgot Password?
            </Link>
          </CenterElementStyle>
          <Stack direction={"column"} gap={2} mt={2}>
            {!isLoggedIn && <GoogleIdentityAuth width={width} />}
            {/* {!!currentData?.CLIENT_ID && !!currentData?.TENANT_ID && (
                <MSALSignIn width={width} />
              )} */}
          </Stack>
        </Form>
      </FormStyle>
    </ContainerStyle>
  );
});
