import {
  ListItemIcon,
  Box,
  List,
  Divider,
  ListItemText,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { validateRoles } from "../../../lib";
import { MainPages, AuthPages } from "../../../pages";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../../themes";
import {
  uiActions,
  useDispatch,
  authSelectors,
  useSelector,
} from "../../../state";
import {
  CalendarIcon,
  DashIcon,
  ClipboardIcon,
  SignOutIcon,
  SettingsIcon,
  LinkIcon,
  BellIcon,
  Tooltip,
  GetSupportButton,
  BuildingIcon,
  MultiUsersIcon,
  FolderIcon,
  UserIcon,
  FileIcon,
  BillingIcon,
  ClockIcon,
  ExchangeIcon,
} from "../../../components";
import {
  MenuContainerStyled,
  MenuItemStyled,
  MenuListStyled,
} from "./Drawer.styles";
import { Fragment, memo, useState } from "react";
import { NestedMenu } from "./NestedMenu";
import { useGetUserProfileQuery } from "../../../state/rtk-query/state/user";

type MenuTypes = "notifications" | "quickLinks" | undefined;
interface TopMenuType {
  page: ReturnType<typeof MainPages>["home"];
  Icon: typeof DashIcon;
  showOnMobile: boolean;
}
const Menu = memo(() => {
  const rolesNames = useSelector(authSelectors.rolesNames);
  const location = useLocation();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isCW = useSelector(authSelectors.isCustomWorkUser);

  const pages = MainPages(isInternal, isAdmin);

  const displayMedia = isInternal
    ? {}
    : {
        page: pages.media,
        Icon: FileIcon,
        showOnMobile: false,
      };
  const displayQuickLinks = isInternal
    ? {}
    : {
        page: pages.quicklinks,
        Icon: LinkIcon,
        showOnMobile: !isInternal,
      };
  const displaySessions = isCW
    ? {}
    : {
        page: pages.sessions,
        Icon: CalendarIcon,
        showOnMobile: false,
      };
  const displaySettings =
    isInternal && !isMobile
      ? {
          page: pages.settings,
          Icon: SettingsIcon,
          showOnMobile: false,
        }
      : {};

  const TOP_MENU: TopMenuType[] = [
    {
      page: pages.home,
      Icon: DashIcon,
      showOnMobile: false,
    },
    displaySessions,
    {
      page: pages.tasks,
      Icon: ClipboardIcon,
      showOnMobile: false,
    },
    {
      page: pages.organizations,
      Icon: BuildingIcon,
      showOnMobile: false,
    },
    {
      page: pages.users,
      Icon: MultiUsersIcon,
      showOnMobile: false,
    },
    {
      page: pages.reports,
      Icon: FolderIcon,
      showOnMobile: false,
    },
    { page: pages.billing, Icon: BillingIcon, showOnMobile: false },
    { page: pages.adminTimer, Icon: ClockIcon, showOnMobile: false },
    { page: pages.migrations, Icon: ExchangeIcon, showOnMobile: false },
    displayMedia,
    displaySettings,
    displayQuickLinks,
    {
      page: pages.notifications,
      Icon: BellIcon,
      showOnMobile: true,
    },
    {
      page: pages.profile,
      Icon: UserIcon,
      showOnMobile: true,
    },
    {
      page: pages.settings,
      Icon: SettingsIcon,
      showOnMobile: true,
    },
    {
      page: AuthPages.logout,
      Icon: SignOutIcon,
      showOnMobile: true,
    },
  ].filter((menu): menu is TopMenuType => !!menu.page);

  const { currentData: userInfo } = useGetUserProfileQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const { orgs = [] } = userInfo || {};

  const itemButtonClick = () => {
    if (isMobile) {
      dispatch(uiActions.setMenuOpen(false));
    }
  };
  const [showSideMenu, setShowSideMenu] = useState<MenuTypes>();

  const menuItemsToDisplay = TOP_MENU.filter(({ page: { roles: roleGroup } }) =>
    validateRoles(rolesNames, roleGroup),
  );

  return (
    <>
      <MenuContainerStyled>
        <MenuListStyled>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              my: orgs && orgs?.length > 1 ? "8px" : 0,
            }}
          >
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: menuItemsToDisplay.length > 13 ? "1.3vw" : "1.5vw",
                height: "100%",
              }}
            >
              {menuItemsToDisplay.map(({ page, Icon, showOnMobile }, idx) => {
                return (
                  <Fragment key={idx}>
                    {!showOnMobile || (showOnMobile && isMobile) ? (
                      <Tooltip title={page?.title ?? ""}>
                        <Link to={page.path} component={RouterLink}>
                          <MenuItemStyled
                            onClick={itemButtonClick}
                            selected={
                              // Highlight sub pages icon (i.e. reports icon will stay highlighted if user is on `/reports` or `/reports/:id`
                              (location.pathname === "/" &&
                                page.path === "/") ||
                              (location.pathname.includes(page.path) &&
                                page.path !== "/")
                            }
                          >
                            <ListItemIcon>
                              <Icon />
                            </ListItemIcon>
                            {isMobile && <ListItemText primary={page?.title} />}
                          </MenuItemStyled>
                        </Link>
                      </Tooltip>
                    ) : null}
                  </Fragment>
                );
              })}

              {isMobile ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "115px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Divider sx={{ width: "100%" }} />
                  {isInternal ? null : (
                    <GetSupportButton sx={{ m: 2, width: "88%" }} />
                  )}
                </Box>
              ) : null}
            </List>

            <List
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                gap: ".75vw",
              }}
            >
              {!isMobile ? (
                <>
                  <NestedMenu
                    whichMenu={showSideMenu}
                    togglePopper={setShowSideMenu}
                  />
                  <ProfileMenu />
                </>
              ) : null}
            </List>
          </Box>
        </MenuListStyled>
      </MenuContainerStyled>
    </>
  );
});

export default Menu;
