import {
  Box,
  BoxProps,
  IconButton,
  Popover,
  PopoverProps,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { CloseIcon } from "../icons";

export namespace PopoverButton {
  export interface Props {
    children: ReactNode;
    button: ReactNode;
    title: ReactNode;
    boxProps?: BoxProps;
    popoverProps?: PopoverProps;
    stackProps?: StackProps;
  }
}

export function PopoverButton(props: PopoverButton.Props) {
  const { children, button, title, boxProps, popoverProps, stackProps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        width={"min-content"}
        height={"min-content"}
        onClick={(e) => {
          if (!anchorEl) {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }
        }}
        {...boxProps}
        sx={{
          cursor: "pointer",
          ...boxProps?.sx,
        }}
      >
        {button}
      </Box>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={close}
        onClick={close} //close popper if notification is clicked
        {...popoverProps}
        slotProps={{
          ...popoverProps?.slotProps,
          paper: {
            elevation: 0,
            ...popoverProps?.slotProps?.paper,
            sx: {
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              ml: 4,
              mt: 1,
              minWidth: "25%",
              ...(popoverProps?.slotProps?.paper as any)?.sx,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
          ...popoverProps?.transformOrigin,
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
          ...popoverProps?.anchorOrigin,
        }}
        transitionDuration={popoverProps?.transitionDuration ?? { exit: 0 }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          py={2}
          {...stackProps}
        >
          <Typography fontWeight={600}>{title}</Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={close}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        {children}
      </Popover>
    </>
  );
}
