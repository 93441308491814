import {
  Field,
  FieldArray,
  FieldArrayConfig,
  FormikValues,
  useFormikContext,
  FieldConfig,
  FieldProps,
  FieldArrayRenderProps,
} from "formik";
import { TextField, CheckboxWithLabel, RadioGroup, Select } from "formik-mui";
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  InputAdornment,
  MenuItem,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  Grid,
  Select as MUISelect,
  TextField as MUITextField,
  Box,
  IconButton,
  Autocomplete,
  AutocompleteProps,
} from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import {
  AddIcon,
  CalendarBlankIcon,
  ChevronDown,
  CloseIcon,
  DeleteIcon,
} from "../../components";
import { CountryCodes } from "../../lib/CountryCodes";
import useOs from "../../components/hooks/useOs";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import { useMobile } from "../../themes";
import { castZero } from "../../lib";

type Props<C extends (...props: any[]) => any> = Parameters<C>[0];

export function FormContainer({ ...props }: Props<typeof Grid>) {
  return <Grid alignItems={"center"} container gap={2} {...props} />;
}

export function FormItem(props: Props<typeof Grid>) {
  return (
    <Grid
      item
      xs={12}
      md={12}
      gap={1.5}
      display={"flex"}
      alignItems="flex-start"
      {...props}
    />
  );
}

export type TopLabelProps = {
  label: string;
  typographyProps?: Props<typeof Typography>;
} & Props<typeof FormControl>;

export function TopLabel({
  label,
  typographyProps = {},
  children,
  ...props
}: TopLabelProps) {
  return (
    <FormControl fullWidth {...props}>
      <Typography
        fontSize={12}
        lineHeight={0}
        my={2}
        sx={{ fontWeight: 500, ...typographyProps.sx }}
        {...typographyProps}
      >
        {label}
      </Typography>
      {children}
    </FormControl>
  );
}

function RemoveArrayItem({
  index,
  remove,
  minDeleteIndex,
  disabled,
}: {
  index: number;
  remove: (index: number) => void;
  minDeleteIndex?: number;
  disabled?: boolean;
}) {
  const isMobile = useMobile();
  if (minDeleteIndex && index < minDeleteIndex) return null;
  return (
    <IconButton
      sx={{ pr: 0, pl: 0, alignItems: "flex-start" }}
      color="error"
      onClick={() => remove(index)}
      disableRipple
      disabled={disabled}
    >
      <DeleteIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
    </IconButton>
  );
}

export type ArrayFieldProps = Omit<FieldArrayConfig, "children"> & {
  itemInitialValue: any;
  buttonName: string;
  minLength?: number;
  maxLength?: number;
  children?: (
    props: FieldArrayRenderProps & {
      DeleteItem: (props: { index: number }) => JSX.Element;
      items: any[];
    },
  ) => ReactNode | ReactNode[];
  label?: string;
};

export function ArrayField({
  buttonName,
  itemInitialValue,
  children,
  label,
  minLength,
  maxLength,
  ...props
}: ArrayFieldProps) {
  const isMobile = useMobile();
  return (
    <FieldArray {...props}>
      {(renderProps) => {
        function DeleteItem({ index }: { index: number }) {
          return (
            <RemoveArrayItem
              remove={renderProps.remove}
              index={index}
              minDeleteIndex={minLength}
              disabled={renderProps.form.status === "disabled"}
            />
          );
        }
        const field = renderProps.form.getFieldMeta(props.name);
        return (
          <Grid container rowSpacing={1.5} columnSpacing={1.5} item>
            {!!label && (
              <Grid item xs={12}>
                <Typography fontWeight={600} color="#ACAEB6" mt={1}>
                  {label}
                </Typography>
              </Grid>
            )}
            {!!children &&
              children({
                ...renderProps,
                DeleteItem,
                items: renderProps.form.values[props.name],
              })}
            <Grid item xs={12}>
              <Button
                sx={{
                  py: 2,
                  px: 0,
                  height: 0,
                }}
                onClick={() => renderProps.push(itemInitialValue)}
                disableRipple
                disabled={
                  renderProps.form.status === "disabled" ||
                  !!(maxLength && (field?.value as any)?.length >= maxLength)
                }
              >
                <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
                <Typography marginLeft={0.8} fontWeight={500}>
                  {buttonName}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
}

function FormField<C extends (...props: any[]) => JSX.Element>(
  props: Omit<FieldConfig, "component"> & {
    component: C;
  } & Omit<Props<C>, "field" | "meta" | "form">,
) {
  return <Field {...props} />;
}

function CustomTextField(props: Props<typeof TextField>) {
  const {
    form: { status },
  } = props;

  return <TextField disabled={status === "disabled"} {...props} />;
}

export function InputField(
  props: Omit<Props<typeof FormField<typeof TextField>>, "component">,
) {
  return <FormField component={CustomTextField} fullWidth {...props} />;
}

function SmallDownIcon(props: any) {
  return <ChevronDown {...props} color="#636469" />;
}
function SmallCloseIcon(props: any) {
  return <CloseIcon {...props} color="#636469" width={18} height={18} />;
}
function onClose() {}
const MenuSx = {
  sx: { maxHeight: "50%", width: "100%" },
};
function CustomSelect(props: Props<typeof Select>) {
  const {
    form: { setFieldValue, status },
    field: { name },
  } = props;
  const handleChange = useCallback(
    (e: any) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue],
  );
  return (
    <Select
      disabled={status === "disabled"}
      onChange={handleChange}
      onClose={onClose}
      MenuProps={MenuSx}
      {...props}
    />
  );
}
type MenuItemOptionType = { id: number | string; name: string };
export function SelectField({
  items,
  allowNoSelection,
  dynamicValue,
  ...props
}: Omit<Props<typeof FormField<typeof Select>>, "component" | "children"> & {
  items: string[] | MenuItemOptionType[];
  dynamicValue?: MenuItemOptionType;
  allowNoSelection?: boolean;
}) {
  const MenuItems = useMemo(
    () =>
      dynamicValue?.id ? (
        <MenuItem
          key={dynamicValue?.id}
          value={dynamicValue?.id}
          children={dynamicValue?.name}
        />
      ) : (
        items.map((item) => {
          const menuProps =
            typeof item === "string"
              ? { key: item, value: item, children: item }
              : { key: item.id, value: item.id, children: item.name };
          const { key, ...rest } = menuProps;
          return <MenuItem {...rest} key={key} />;
        })
      ),
    [items, dynamicValue?.id],
  );
  const EmptySelection = useMemo(() => {
    if (!allowNoSelection) return null;
    return (
      <MenuItem key="" value="">
        {""}
      </MenuItem>
    );
  }, [allowNoSelection]);

  return (
    <FormField
      component={CustomSelect}
      IconComponent={SmallDownIcon}
      {...props}
    >
      {EmptySelection}
      {MenuItems}
    </FormField>
  );
}

function CustomCheckboxWithLabel(props: Props<typeof CheckboxWithLabel>) {
  const {
    form: { status },
  } = props;

  return <CheckboxWithLabel disabled={status === "disabled"} {...props} />;
}

export function CheckboxWithLabelField({
  label,
  ...props
}: Omit<
  Props<typeof FormField<typeof CheckboxWithLabel>>,
  "component" | "Label" | "type"
> & { label: string }) {
  return (
    <FormField
      component={CustomCheckboxWithLabel}
      Label={{ label }}
      disableRipple
      type="checkbox"
      sx={{ alignItems: "flex-start" }}
      {...props}
    />
  );
}

export function RadioGroupField({
  items,
  spacing,
  ...props
}: Omit<
  Props<typeof FormField<typeof RadioGroup>>,
  "component" | "children"
> & {
  items: string[] | { id: number | string; name: string }[];
  spacing?: number;
}) {
  const RadioItems = useMemo(() => {
    const _items = items.map((item) => {
      const radioProps =
        typeof item === "string"
          ? { label: item, value: item, key: item }
          : { label: item.name, value: item.id, key: item.id };
      const { key, ...rest } = radioProps;
      return (
        <FormControlLabel
          {...rest}
          key={key}
          control={<Radio disableRipple checkedIcon={<TripOriginIcon />} />}
        />
      );
    });
    if (spacing) {
      const [first, ...rest] = _items;
      const m = props.row ? { mx: spacing / 2 } : { my: spacing / 2 };
      return [
        first,
        rest.map((item, i) => [<Box key={i + "space"} {...m} />, item]).flat(),
      ];
    }
    return _items;
  }, [items, spacing]);
  return (
    <FormField component={RadioGroup} {...props}>
      {RadioItems}
    </FormField>
  );
}

export function OptionalRadioGroupField(props: Props<typeof RadioGroupField>) {
  const { name } = props;
  const { setFieldValue, getFieldMeta } = useFormikContext<FormikValues>();
  const onClick = useCallback(
    (e: any) => {
      const value = String(getFieldMeta(name)?.value);
      if (value === (e as any).target?.value) {
        setFieldValue(name, "");
      }
    },
    [name, setFieldValue, getFieldMeta],
  );
  return <RadioGroupField onClick={onClick} {...props} />;
}

export function PhoneWithCountryCodeField({
  name,
  countryCodeName,
}: {
  name: string;
  countryCodeName: string;
}) {
  const { setFieldValue, getFieldMeta, status } =
    useFormikContext<FormikValues>();
  const countryCode = getFieldMeta(countryCodeName);
  const os = useOs();
  const [countryCodeIndex, setCountryCodeIndex] = useState(0);

  useEffect(() => {
    const findIndex = CountryCodes.findIndex(
      (cc) => cc.country_code === countryCode.value,
    );
    setCountryCodeIndex(findIndex);
  }, []);

  const renderValue = useCallback(
    (value: unknown) => {
      const typProps =
        os === "Windows"
          ? { variant: "body1" as "body1", marginTop: 0.3 }
          : { variant: "h1" as "h1", marginTop: 0.7 };
      return (
        <Typography {...typProps}>
          {os === "Windows"
            ? CountryCodes[value as number]?.country
            : CountryCodes[value as number]?.flag}
        </Typography>
      );
    },
    [os],
  );

  const onChange = useCallback(
    (e: any) => {
      setCountryCodeIndex(e.target.value);
      setFieldValue(countryCodeName, CountryCodes[e.target.value].country_code);
    },
    [countryCodeName],
  );

  const ccMenuItems = useMemo(
    () =>
      CountryCodes.map((cc, i) => (
        <MenuItem key={i} value={i}>
          <Typography align="left" overflow="hidden" variant="labelSmall">
            {os === "Windows"
              ? cc.label + "  +" + cc.country_code
              : cc.flag + " " + cc.label}
          </Typography>
        </MenuItem>
      )),
    [os],
  );
  const InputProps = useMemo(() => {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <MUISelect
            value={countryCodeIndex}
            IconComponent={SmallDownIcon}
            onChange={onChange}
            renderValue={renderValue}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            disabled={status === "disabled"}
          >
            {ccMenuItems}
          </MUISelect>
        </InputAdornment>
      ),
      sx: { p: 0 },
    };
  }, [countryCodeIndex, status === "disabled"]);

  return <InputField name={name} InputProps={InputProps} fullWidth />;
}

export function isValidDate(date: any | Date): date is Date {
  if (!(date instanceof Date)) return false;
  return !isNaN(new Date(date).getTime());
}

function DatePicker({
  field: { name, value },
  form: {
    setFieldValue,
    setFieldTouched,
    errors: { [name]: error },
    touched: { [name]: touched },
    status,
  },
  ...props
}: Parameters<typeof DateRangePicker>[0] & FieldProps) {
  const handleDateChange = useCallback(
    (newValue: DateRange<unknown>) => {
      if (isValidDate(newValue[1])) {
        const end_date = new Date(newValue[1]);
        end_date.setHours(23, 59, 59, 999);
        setFieldValue(name, [newValue[0], end_date], true);
      } else {
        setFieldValue(name, newValue, true);
      }
    },
    [name],
  );

  const slots = useMemo(() => {
    return {
      field: SingleInputDateRangeField,
    };
  }, [error]);
  const slotProps = useMemo(() => {
    return {
      textField: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <CalendarBlankIcon />
            </InputAdornment>
          ),
        },
        error: touched && !!error,
        helperText: touched && error,
      },
    } as Props<typeof DateRangePicker>["slotProps"];
  }, [error, touched, value]);
  const onClose = () => {
    setTimeout(() => {
      setFieldTouched(name, true);
    }, 100);
  };
  return (
    <DateRangePicker
      slots={slots}
      slotProps={slotProps}
      value={value}
      onClose={onClose}
      format="M.d.yy"
      disabled={status === "disabled"}
      {...props}
      onChange={handleDateChange}
    />
  );
}

export function DatePickerField({
  name,
  disablePast,
}: {
  name: string;
  disablePast?: boolean;
}) {
  return (
    <FormField component={DatePicker} name={name} disablePast={disablePast} />
  );
}

type OurAutocompleteProps = {
  label: string;
  options: MenuItemOptionType[];
  fieldName: string;
} & Partial<AutocompleteProps<MenuItemOptionType, any, any, any>>;

export const AutoCompleteField = ({
  options,
  fieldName,
  label,
  ...props
}: OurAutocompleteProps) => {
  const { setFieldValue, errors, touched, values } =
    useFormikContext<FormikValues>();

  const handleSelectChange = (
    _e: SyntheticEvent<Element, Event>,
    value: any,
  ) => {
    if (!value) {
      setFieldValue(fieldName, "");
    } else {
      if (value && value?.id) {
        setFieldValue(fieldName, value?.id);
      }
    }
  };
  const val = useMemo(
    () => options.find((r) => r?.id === castZero(values[fieldName])),
    [options, castZero(values[fieldName])],
  );

  return (
    <Autocomplete
      options={options}
      value={val ?? ""}
      isOptionEqualToValue={(option, value) =>
        option?.id === value?.id || String(value?.id) === ""
      }
      sx={{ width: "100%" }}
      clearIcon={<SmallCloseIcon />}
      popupIcon={<SmallDownIcon />}
      onChange={handleSelectChange}
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.name ?? val?.name ?? ""
      }
      getOptionKey={(option) =>
        typeof option === "string" ? option : option?.id
      }
      // inputValue={inputValue}
      renderInput={(params) => (
        <TopLabel label={label}>
          <MUITextField
            {...params}
            error={touched[fieldName] && Boolean(errors[fieldName])}
            helperText={touched[fieldName] && (errors[fieldName] as any)}
            sx={{
              width: "100%",
            }}
          />
        </TopLabel>
      )}
      {...props}
    />
  );
};
