import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { Box, SxProps, Theme } from "@mui/material";
type ItemType = { id: number; content: string; onDash: boolean }[];
interface Props {
  children: ReactNode;
  droppableId: string;
  setOrder?: Dispatch<SetStateAction<ItemType>>;
  curOrder?: ItemType;
  direction?: "horizontal" | "vertical";
  sx?: SxProps<Theme>;
}
const reorder = (list: ItemType, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const DraggableContainer = ({
  children,
  droppableId,
  setOrder,
  curOrder,
  direction = "horizontal",
  sx,
}: Props) => {
  let currentOrder: ItemType = [];
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || !curOrder) {
      return;
    }
    currentOrder = reorder(
      curOrder,
      result.source.index,
      result.destination.index,
    );
    if (setOrder) setOrder(currentOrder);
    return currentOrder;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} direction={direction}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 1,
              alignItems: "center",
              ...sx,
            }}
          >
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
