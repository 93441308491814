import { Stack, Typography, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  CardStyled,
  ServerDataGrid,
  ToggleDropdown,
} from "../../../components";
import { useReviewTimerColumns } from "../../../columns";
import {
  useGetMyReviewTimersQuery,
  useUpdateMyReviewedTimersMutation,
} from "../../../state/rtk-query/state/timer";
import { useMobile } from "../../../themes";
import { useRtkQueryParams, useQueryParam } from "../../../components/hooks";
import RowsSelectedBanner from "../../../components/cards/RowsSelectedBanner";
export const TimerReviewPage = () => {
  const isMobile = useMobile();
  const [params, setParams] = useRtkQueryParams();

  const columns = useReviewTimerColumns(isMobile);
  const [markTimersAsReviewed] = useUpdateMyReviewedTimersMutation();
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const [pending, setPending] = useQueryParam<boolean | undefined>("pending");
  const { data, isFetching } = useGetMyReviewTimersQuery(
    { ...params, pending },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const markAsReviewed = useCallback(
    async (reviewed: boolean) => {
      await markTimersAsReviewed({ body: { timers: selectedRows, reviewed } });
      setSelectedRows([]);
    },
    [selectedRows, pending, params],
  );

  useEffect(() => {
    setPending(true);
  }, []);
  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography fontSize="20px" fontWeight="bold">
            Timer review
          </Typography>
          <ToggleDropdown
            option1="pending"
            option2="all"
            setValue={(val) => setPending(val === "pending" || undefined)}
            value={pending ? "pending" : "all"}
          />
        </Stack>
      </Stack>
      <RowsSelectedBanner
        onClear={() => setSelectedRows([])}
        onSelectAll={() => setSelectedRows(data?.rows?.map((r) => r?.id) || [])}
        selectedRowsCt={selectedRows.length}
      >
        <Button
          color="info"
          sx={{ whiteSpace: "nowrap" }}
          onClick={async () => await markAsReviewed(true)}
        >
          Mark as reviewed
        </Button>
        {" | "}
        <Button
          color="info"
          sx={{ whiteSpace: "nowrap" }}
          onClick={async () => await markAsReviewed(false)}
        >
          Mark as un-reviewed
        </Button>
      </RowsSelectedBanner>
      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <ServerDataGrid
          toolbarProps={{ hideToolbar: true }}
          rowCount={data?.count || 0}
          isFetching={isFetching}
          rows={data?.rows ?? []}
          columns={columns}
          setQueryParams={setParams}
          hideColumns={{ sub_consultant_id: false }}
          currentParams={params}
          props={{
            density: "standard",
            sx: {
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
              },
              ".MuiDataGrid-row": {
                cursor: "pointer",
              },
            },

            checkboxSelection: true,
            onCellClick: (c, e: any) => {
              if (c.field === "__check__") {
                if (e.target.checked) {
                  setSelectedRows((prev) => [...prev, c.id]);
                } else {
                  setSelectedRows((prev) => prev.filter((id) => id !== c.id));
                }
              }
            },
            onColumnHeaderClick: (c, e: any) => {
              if (c.field === "__check__") {
                if (e.target.checked) {
                  setSelectedRows(data?.rows?.map((r) => r?.id) || []);
                } else {
                  setSelectedRows([]);
                }
              }
            },
            rowSelectionModel: selectedRows,
          }}
        />
      </CardStyled>
    </>
  );
};
