import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";

export const ListActionsContainerStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "18px",
  paddingBottom: "24px",
  "& .MuiFormControl-root": {
    maxWidth: "400px",
  },
}));

export const ListActionsExpandedStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: "24px",
  "& .MuiFormControl-root": {
    width: "360px",
  },
  "& .MuiFormControlLabel-root": {
    whiteSpace: "nowrap",
  },
}));

export const StyledDataGrid = styled(DataGridPro)`
  &.MuiDataGrid-root .MuiDataGrid-cell{
    align-items:start;
    padding-top:14px;
    padding-bottom:14px;
  },
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer' {
    visibility: visible !important,
    width: 0 !important
  },
 
`;
