import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CodeMirror, { lineNumbers, EditorView } from "@uiw/react-codemirror";
import { PostgreSQL, sql, keywordCompletionSource } from "@codemirror/lang-sql";
import { materialLight } from "@uiw/codemirror-theme-material";
import {
  Completion,
  autocompletion,
  completeFromList,
} from "@codemirror/autocomplete";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import {
  GetTablesAndColumnsApiResponse,
  useGetTablesAndColumnsQuery,
} from "../../state/rtk-query/state/reports";
import { Box } from "@mui/material";

import { linter, Diagnostic } from "@codemirror/lint";
type Tables = GetTablesAndColumnsApiResponse[number] | undefined;
interface Props {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  setLinterErrors: Dispatch<SetStateAction<Diagnostic[]>>;
}
export const SQLEditor = ({ value, setValue, setLinterErrors }: Props) => {
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [completions, setCompletions] = useState<Completion[]>([]);
  const { currentData } = useGetTablesAndColumnsQuery(
    isAdmin ? undefined : skipToken,
  );

  const tableHints: Record<string, any> =
    (currentData?.length
      ? currentData?.reduce(
          (acc: Record<string, any> | undefined, cur: Tables) => {
            if (!acc || !cur?.table_name) return;
            acc[`${cur.table_name}`] = cur.columns;
            return acc;
          },
          {},
        )
      : {}) ?? {};

  const findAllTablesWithCol = (col: string) =>
    Object.entries(tableHints)
      .filter(([_table, columns]) => columns.includes(col))
      .map((t) => t[0]);

  const allHints: Completion[] = Object.entries(tableHints).flatMap(
    ([table, columns]) => {
      const cols = columns.map((column: string) => ({
        label: column,
        type: "keyword",
        info: `This column belongs to the following tables:\n${findAllTablesWithCol(
          column,
        ).join(", ")}`,
        detail: `column`,
      }));
      return [
        {
          label: table,
          type: "keyword",
          detail: `table`,
        },
        ...cols,
      ];
    },
  );

  const words = value.trim().split(/\r?\n|\r|\n|\s/g);

  const lastWord = words[words.length - 1];

  useEffect(() => {
    setCompletions(allHints);
  }, [currentData?.length]);

  // Basic SQL parser to extract table and column references
  // If theres a need to deal with aliases again in the future see https://chatgpt.com/share/d4c94da6-8922-49bc-af4c-2f18f0de4e27
  const parseSQL = (sql: string) => {
    const tables: { [alias: string]: string } = {};
    const sqlKeywords = [
      "ON",
      "USING",
      "WHERE",
      "INNER",
      "LEFT",
      "RIGHT",
      "FULL",
      "JOIN",
      "GROUP",
      "ORDER",
      "LIMIT",
      "OFFSET",
      "HAVING",
    ];

    const tableRegex = /(?:FROM|JOIN)\s+(\w+)(?:\s+AS\s+(\w+)|\s+(\w+))?/gim;
    let match;
    while ((match = tableRegex.exec(sql))) {
      const tableName = match[1];
      const alias =
        match[2] ||
        (match[3] && !sqlKeywords.includes(match[3].toUpperCase())
          ? match[3]
          : tableName);
      tables[alias] = tableName; // Use alias if provided, otherwise the table name
    }

    const columns: { column: string; tableAlias: string }[] = [];
    const columnRegex = /\b(\w+)\.(\w+)\b/g;
    while ((match = columnRegex.exec(sql))) {
      columns.push({ tableAlias: match[1], column: match[2] });
    }

    return { tables, columns };
  };

  const badSqlEndings = [
    "select",
    "*",
    "from",
    "where",
    "group by",
    "having",
    "on",
    "join",
    "limit",
    "offset",
  ];

  const postgresLinter = linter((view) => {
    const diagnostics: Diagnostic[] = [];
    const doc = view.state.doc.toString();

    if (!doc.length) return diagnostics;

    if (!doc.toLowerCase().includes("select")) {
      diagnostics.push({
        from: 0,
        to: doc.length,
        severity: "warning",
        message: "No SELECT statement found in the query",
      });
    }

    if (
      badSqlEndings.includes(lastWord.toLowerCase()) ||
      lastWord.endsWith(".")
    ) {
      diagnostics.push({
        from: doc.length - 1,
        to: doc.length,
        severity: "error",
        message: "Query has invalid ending",
      });
    }

    const { tables, columns } = parseSQL(doc);

    Object.values(tables).forEach((table) => {
      if (lastWord.endsWith(".")) {
        const findTable = currentData?.find((t) => t.table_name === table);
        if (!findTable) return;
        setCompletions(() =>
          findTable.columns.flatMap((column: string) => ({
            label: column,
            type: "keyword",
            info: `This column belongs to the following tables:\n${findAllTablesWithCol(
              column,
            ).join(", ")}`,
            detail: `column`,
          })),
        );
      } else {
        setCompletions(() => allHints);
      }
    });

    // Check if column belong to the given
    columns.forEach(({ tableAlias, column }) => {
      const tableName = tables[tableAlias];
      if (!tableName) return;

      if (!tableHints[tableName] || !tableHints[tableName].includes(column)) {
        const columnPos = doc.indexOf(`${tableAlias}.${column}`);
        diagnostics.push({
          from: columnPos,
          to: columnPos + `${tableAlias}.${column}`.length,
          severity: "error",
          message: `Column "${column}" does not belong to table "${
            tableName || tableAlias
          }"`,
        });
      }
    });
    setLinterErrors(diagnostics);
    return diagnostics;
  });

  const formatNumber = useCallback(
    (n: number) => n.toString().padStart(2, "\u00A0"),
    [],
  );

  const autoCompleteExt = useMemo(
    () =>
      autocompletion({
        override: [
          keywordCompletionSource(PostgreSQL),
          completeFromList(completions),
        ],
        compareCompletions: (a, b) =>
          String(b?.detail)?.localeCompare(String(a.detail)), //sort by tables on top
      }),
    [completions?.length],
  );

  const onChange = useCallback(
    (val: string) => {
      if (!lastWord.endsWith(".")) {
        setCompletions(allHints);
      }
      setValue(val);
    },
    [value, allHints, lastWord],
  );

  return useMemo(
    () => (
      <Box
        sx={{
          "& .cm-editor": {
            outline: "none",
            borderRadius: "6px",
          },
          ".cm-gutters": {
            backgroundColor: "#045",
            color: "#ddd",
            border: "none",
            borderRadius: "4px",
          },

          ".cm-tooltip": {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "18px",
          },
          ".cm-tooltip-hover": {
            border: "none",
            boxShadow: "0 1px 12px 2px rgba(99,100,105,0.4)",
          },
          ".cm-tooltip-autocomplete > *": {
            boxShadow: "0px 1px 4px rgba(99, 100, 105, 0.2);",
            marginLeft: 0.5,
            borderRadius: "3px",
          },
        }}
      >
        <CodeMirror
          value={value}
          height={"500px"}
          width={"100%"}
          extensions={[
            sql({
              dialect: PostgreSQL,
            }),
            autoCompleteExt,
            postgresLinter,
            lineNumbers({
              formatNumber,
            }),
            EditorView.lineWrapping,
          ]}
          theme={materialLight}
          basicSetup={{
            autocompletion: true,
            highlightActiveLine: true,
            searchKeymap: true,
            syntaxHighlighting: true,
            foldGutter: false,
            defaultKeymap: true,
          }}
          onChange={onChange}
          placeholder={"SELECT * FROM..."}
        />
      </Box>
    ),
    [value, completions?.length],
  );
};
