import { useSelector } from "react-redux";
import { useTemplatesList } from "../hooks";
import { authSelectors } from "../../state";
import { useParams } from "react-router-dom";
import { BaseForm } from "./BaseForm";
import { SelectField, TopLabel } from "./FormFields";
import { useApplyTemplateMutation } from "../../state/rtk-query/state/organization";
import { useLocation } from "../../lib";

export function ApplyTemplateToOrg({ onClose }: { onClose: () => void }) {
  const { pathname } = useLocation();
  const orgId = Number(useParams().id);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { templates } = useTemplatesList(!isAdmin);
  const [applyTemplate] = useApplyTemplateMutation();
  return (
    <BaseForm
      initialValues={{ templateId: "" as "" | number }}
      onClose={onClose}
      onSubmit={(values) => {
        values.templateId &&
          orgId &&
          pathname === "/organizations/" + orgId &&
          applyTemplate({ orgId, body: { template_id: values.templateId } });
        onClose();
      }}
      submitButtonName="Apply"
    >
      {() => {
        return (
          <TopLabel label="Template">
            <SelectField name="templateId" items={templates} />
          </TopLabel>
        );
      }}
    </BaseForm>
  );
}
