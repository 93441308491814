import { useCallback, useMemo, useState } from "react";
import { ServerDataGrid } from "../../../components/tables/ServerDataGrid";
import { reportsColumns } from "../../../columns";
import { actions } from "../../../state/rtk-query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";
import { Button, Stack, useTheme } from "@mui/material";
import { Navigation, generatePath } from "../../../lib";
import { MainPages } from "../..";
import { GetReportsApiResponse } from "../../../state/rtk-query/state/reports";
import { ArrayElement } from "../../../global";
import {
  AddIcon,
  EditReportForm,
  Modal,
  ToggleDropdown,
  useQueryParam,
} from "../../../components";
import { useMobile } from "../../../themes";

const { useGetReportsQuery } = actions;

export const ReportsPage = () => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const userRoles = useSelector(authSelectors.roles);
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const pages = MainPages(isInternal);
  const [openModal, setOpenModal] = useState(false);
  const [includeDash, setIncludeDash] = useQueryParam<boolean | undefined>(
    "include_dash",
  );

  const { data: reports, isFetching } = useGetReportsQuery(
    { includeDashReports: isAdmin ? !!includeDash : false },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const rowClick = useCallback(
    async ({ row }: { row: ArrayElement<GetReportsApiResponse["rows"]> }) => {
      const disable =
        !row.report_permissions?.find((rp) =>
          userRoles.map((r) => r.id).includes(Number(rp?.role_id)),
        ) && !isAdmin;
      if (disable) return;
      if (isInternal && row.id) {
        Navigation.go(generatePath(pages.report.path, { id: row.id }));
      }
    },
    [isInternal],
  );

  const rows = useMemo(() => {
    if (isAdmin) return reports?.rows;
    return reports?.rows.filter((r) =>
      r.report_permissions?.find((rp) =>
        userRoles.map((r) => r.id).includes(Number(rp?.role_id)),
      ),
    );
  }, [reports, isAdmin]);

  const onClose = () => setOpenModal((o) => !o);

  return (
    <Stack gap={1} direction={"column"}>
      {isAdmin && (
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <ToggleDropdown
            option1="reports"
            option2="include dash reports"
            setValue={(val) =>
              setIncludeDash(val === "include dash reports" || undefined)
            }
            value={includeDash ? "include dash reports" : "reports"}
          />
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ width: "fit-content" }}
          >
            <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} /> Report
          </Button>
        </Stack>
      )}
      <ServerDataGrid
        toolbarProps={{ hideToolbar: true }}
        rowCount={reports?.count || 0}
        rows={rows ?? []}
        columns={reportsColumns}
        isFetching={isFetching}
        props={{
          sx: {
            "&.MuiDataGrid-root": {
              border: `1px solid ${palette.grey[300]}`,
              borderRadius: "6px",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
            },
            ".MuiDataGrid-row": {
              cursor: "pointer",
            },
          },
          onRowClick: rowClick,
        }}
        currentParams={undefined}
      />

      <Modal
        open={openModal}
        onClose={onClose}
        pcWidth={"80% !important"}
        sx={{ height: "90%", overflow: "auto" }}
      >
        <EditReportForm onClose={onClose} />
      </Modal>
    </Stack>
  );
};
