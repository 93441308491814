import { Stack, Typography, useTheme, Box } from "@mui/material";
import { formatDate, formatDateToHuman } from "../../lib";
import { ClockIcon } from "../icons";
import { AvatarGroup } from "../icons/Avatar";
import { ArrayElement } from "../../global";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { CardStyled, AddTaskButton } from "..";
import { SessionTimeRange } from "../misc";

type SessionType = ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;

interface SessionCardProp {
  session: SessionType;
}
const PADDING_LEFT = 18;
const PADDING_Y = 18;

export const ChooseSessionCard = ({ session }: SessionCardProp) => {
  const { palette } = useTheme();

  const {
    tasks,
    booking,
    title,
    session_invitees,
    pending_session_dates: psd,
  } = session;

  const taskTopics = tasks?.map((t) => t.topic?.name);
  const users = (session_invitees || [])
    .map((u) => u.user)
    .flat()
    .filter(Boolean)
    .map((u) => u) as { first_name: string; last_name: string }[];

  const defaultTitle = (
    <>
      {booking?.start_time ? (
        <>
          {" "}
          {formatDateToHuman(new Date(booking?.start_time ?? ""))} &middot;{" "}
          {title || "Consulting Session"}
        </>
      ) : psd?.length ? (
        `Pending: ${formatDate(psd[0]?.value ?? "")} -  ${formatDate(
          psd[1]?.value ?? "",
        )}`
      ) : (
        title || "Consulting Session"
      )}
    </>
  );

  return (
    <CardStyled
      sx={{
        pl: `${PADDING_LEFT}px`,
        py: `${PADDING_Y}px`,
        borderWidth: "1px",
      }}
    >
      <Stack direction="column" justifyContent={"space-between"} gap={2}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography fontWeight={600} fontSize={18}>
            {defaultTitle}
          </Typography>
          <Box pr={2}>
            <AvatarGroup
              users={users}
              defaultLabel={{
                externalLabel: "Client assignee",
                internalLabel: "Admire assignee",
              }}
            />
          </Box>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
          <Stack direction={"row"} alignItems={"center"}>
            <ClockIcon color={palette.primary.main} width={18} height={18} />
            {booking ? (
              <Typography variant="subtitle2" fontSize={14} ml={1}>
                <SessionTimeRange
                  startTime={booking.start_time}
                  endTime={booking.end_time}
                />
              </Typography>
            ) : null}
          </Stack>
          <AddTaskButton
            session={session}
            removeAddButton={true}
            containerWidth={"100px"}
          />
        </Stack>
        {taskTopics?.length ? (
          <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
            {taskTopics.slice(0, 5).map((t, idx) => (
              <Box
                sx={{ bgcolor: palette.grey[200], color: palette.grey[900] }}
                height={20}
                width={"max-content"}
                px={1}
                borderRadius={"2px"}
                key={idx}
              >
                <Typography fontSize={12} textAlign={"center"}>
                  {t}
                </Typography>
              </Box>
            ))}
            {taskTopics?.length > 5 && (
              <Typography fontSize={12} textAlign={"center"}>
                + {Number(taskTopics?.length ?? 0) - 5}
              </Typography>
            )}
          </Stack>
        ) : null}
      </Stack>
    </CardStyled>
  );
};
