import { useCurOrg } from "../hooks";
import { BriefCaseIcon, HeadphonesIcon } from "../icons";
import { StatusCard, StatusContent } from "./StatusCard";
import { useTheme } from "@mui/material";
export const SupportCard = () => {
  const { palette } = useTheme();
  const org = useCurOrg();

  const { account_manager, consultant } = org ?? {};
  return (
    <StatusCard header={"SUPPORT TEAM"}>
      <StatusContent
        Icon={BriefCaseIcon}
        value={`${account_manager?.first_name ?? ""}
        ${account_manager?.last_name ?? ""}`}
        caption="Account Manager"
        removeClick
        color={palette.primary}
      />
      <StatusContent
        Icon={HeadphonesIcon}
        value={`${consultant?.first_name ?? ""}
        ${consultant?.last_name ?? ""}`}
        caption="Consultant"
        removeClick
        color={palette.primary}
      />
    </StatusCard>
  );
};
