import {
  Typography,
  Box,
  useTheme,
  IconButton,
  Stack,
  Theme,
  SxProps,
  TypographyProps,
} from "@mui/material";
import { useFormikContext, Formik, Form, FormikConfig } from "formik";
import { useState, useEffect, MouseEvent, FocusEvent } from "react";
import {
  DrawerHeader,
  DrawerSubSectionHeaderStyled,
} from "../drawer/drawer.styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { useUpdateTaskMutation } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { useCustomEditor } from "../hooks";
import { useTopicsList } from "../hooks/useLists";
import { InlineSelectField, InlineTextField, CustomEditor } from "../inputs";
import { Loadable } from "../misc";
import { HtmlWrapper } from "../text";
import { EditIcon } from "../icons";

export interface TaskDescriptionProps extends DescriptionFields {
  isEditable?: boolean;
  isLoading?: boolean;
  collapsed: boolean;
  condensed?: boolean;
  toggleCollapsed: () => void;
  topicName?: string;
  description?: string | null | undefined;
}

interface DescriptionFields {
  id: number;
  orgId: number;
  topicId?: number;
  title?: string;
  type?: "task" | "internal_task" | "custom_work" | "quick_question";
  priority?: "normal" | "high" | "critical";
  code: string;
}

export function TaskDescription(props: TaskDescriptionProps) {
  const {
    type,
    topicId,
    priority,
    title,
    id,
    orgId,
    description,
    code,
    condensed = false,
  } = props;
  const isEditable =
    props.isEditable &&
    id &&
    orgId &&
    type &&
    typeof topicId === "number" &&
    priority &&
    typeof title === "string";

  if (!isEditable) {
    return <TaskDescriptionNoForm {...props} />;
  }
  return (
    <TaskDescriptionForm
      {...props}
      code={code}
      type={type}
      topicId={topicId}
      id={id}
      orgId={orgId}
      description={description}
      title={title}
      priority={priority}
      condensed={condensed}
    />
  );
}

function TaskDescriptionNoForm({
  description,
  isLoading,
  title,
  collapsed,
  toggleCollapsed,
  topicName,
  condensed,
  code,
}: TaskDescriptionProps) {
  const { palette } = useTheme();
  const isMobile = useMobile();

  return (
    <DrawerHeader sx={{ py: condensed ? "12px !important" : "auto" }}>
      <DrawerSubSectionHeaderStyled>
        <Typography
          fontSize={14}
          color={palette.grey[900]}
          fontWeight={600}
          py={1}
          lineHeight={"24px"}
          letterSpacing="inherit"
        >
          {topicName}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            color={palette.grey[900]}
            whiteSpace={"nowrap"}
          >{`[${code}]`}</Typography>
          <IconButton onClick={toggleCollapsed} size="small">
            <KeyboardArrowDown
              sx={{
                rotate: collapsed ? "180deg" : 0,
                fontSize: isMobile ? "1em" : ".8rem",
              }}
            />
          </IconButton>
        </Stack>
      </DrawerSubSectionHeaderStyled>
      {!collapsed && (
        <Loadable isLoading={isLoading} useProgressBar>
          <Typography
            fontSize={18}
            fontWeight={700}
            color={"#383C43"}
            py={1}
            lineHeight={"23px"}
            letterSpacing="inherit"
          >
            {title}
          </Typography>
        </Loadable>
      )}
      {!collapsed && (
        <Loadable isLoading={isLoading} useProgressBar>
          {description && (
            <Typography variant="subtitle1" fontSize={14} height={"100%"}>
              <HtmlWrapper message={description} sx={{ cursor: "text" }} />
            </Typography>
          )}
        </Loadable>
      )}
    </DrawerHeader>
  );
}

function TaskDescriptionForm({
  description,
  isLoading,
  title,
  topicId,
  collapsed,
  type,
  priority,
  id,
  orgId,
  code,
  condensed,
  toggleCollapsed,
}: TaskDescriptionProps & Required<DescriptionFields>) {
  const [updateTask] = useUpdateTaskMutation();
  const isMobile = useMobile();
  const { palette } = useTheme();
  const { topics } = useTopicsList();
  const topicOptions = topics.map((t) => ({
    id: Number(t.id),
    label: t.name,
  }));
  const initialValues = {
    topic_id: topicId,
    title,
    description,
    type,
    priority,
  };
  const handleSubmit: FormikConfig<typeof initialValues>["onSubmit"] = (
    body,
  ) => {
    updateTask({
      id,
      orgId,
      body,
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form>
        <DrawerHeader sx={{ py: condensed ? "12px !important" : "auto" }}>
          <DrawerSubSectionHeaderStyled>
            <InlineFormSelectField name="topic_id" options={topicOptions} />
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                color={palette.grey[900]}
              >{`[${code}]`}</Typography>
              <IconButton onClick={toggleCollapsed} size="small">
                <KeyboardArrowDown
                  sx={{
                    rotate: collapsed ? "180deg" : 0,
                    fontSize: isMobile ? "1em" : ".8rem",
                  }}
                />
              </IconButton>
            </Stack>
          </DrawerSubSectionHeaderStyled>
          {!collapsed && (
            <Loadable isLoading={isLoading} useProgressBar>
              <InlineFormTextField name="title" />
            </Loadable>
          )}
          {!collapsed && (
            <Loadable isLoading={isLoading} useProgressBar>
              <HtmlEditorField
                name="description"
                sx={{ maxHeight: "250px", overflow: "auto" }}
              />
            </Loadable>
          )}
        </DrawerHeader>
      </Form>
    </Formik>
  );
}

function InlineFormSelectField({
  name,
  options,
}: {
  name: string;
  options: { id: number; label: string }[];
}) {
  const { values, handleSubmit, setFieldValue, initialValues } =
    useFormikContext<any>();
  const value = values[name];
  const initialValue = initialValues[name];
  const changed = value !== initialValue;
  useEffect(() => {
    if (changed) {
      handleSubmit();
    }
  }, [changed]);
  return (
    <InlineSelectField
      name="topic"
      onChange={(v) => {
        setFieldValue(name, v.target.value);
      }}
      value={value}
      options={options}
    />
  );
}

function InlineFormTextField({ name }: { name: string }) {
  const dispatch = useDispatch();
  const { values, handleSubmit, setFieldValue, initialValues } =
    useFormikContext<any>();
  const value = values[name];
  const initialValue = initialValues[name];
  const changed = value !== initialValue;
  const [blurred, setBlurred] = useState(false);
  useEffect(() => {
    if (blurred && changed) {
      if (!value) {
        setTimeout(
          () => dispatch(uiActions.showError("Task title cannot be empty")),
          100,
        );
        setFieldValue(name, initialValue);
      } else {
        handleSubmit();
      }
      setBlurred(false);
    }
  }, [blurred && changed]);

  const [editMode, setEditMode] = useState(false);
  if (!editMode) {
    return (
      <TypographyWithEditButton
        fontSize={18}
        fontWeight={700}
        color={"#383C43"}
        py={1}
        lineHeight={"23px"}
        letterSpacing="inherit"
        onIconClick={() => setEditMode(true)}
      >
        {value}
      </TypographyWithEditButton>
    );
  }
  return (
    <InlineTextField
      value={value}
      onBlur={() => {
        setBlurred(true);
        setEditMode(false);
      }}
      onChange={(e) => setFieldValue(name, e.target.value)}
      style={{ fontSize: 18, fontWeight: 700, color: "#383C43" }}
      autoFocus
    />
  );
}

function HtmlEditorField({ name, sx }: { name: string; sx?: SxProps<Theme> }) {
  const { palette } = useTheme();
  const {
    values,
    handleSubmit,
    setFieldValue,
    initialValues,
    touched,
    setFieldTouched,
  } = useFormikContext<any>();
  const value = values[name];
  const initialValue = initialValues[name];

  const [editMode, setEditMode] = useState(false);
  const editor = useCustomEditor({
    defaultText: value,
  });
  const toggleEditMode = (
    e: FocusEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    // if any part of the header gets click we shouldn't toggle the editor
    if (
      editMode &&
      ((e.target as Element)?.classList?.contains("editorHeader") ||
        (e.relatedTarget as Element)?.classList?.contains("editorHeader"))
    ) {
      return;
    }
    setEditMode((prev) => {
      if (!prev) {
        editor?.commands.selectTextblockEnd();
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (touched[name] && !editMode && value !== initialValue) {
      const v = editor?.getText();
      if (!(!v && !initialValue)) handleSubmit();
    }
  }, [value]);

  useEffect(() => {
    if (!editMode) {
      setFieldValue(name, editor?.getHTML() || value);
    } else {
      setFieldTouched(name, true);
    }
  }, [editMode]);

  if (!editMode) {
    const isNotEmpty = !editor?.isEmpty && value && value?.trim() !== "";
    return (
      <TypographyWithEditButton
        variant="subtitle1"
        fontSize={14}
        height={"100%"}
        sx={sx}
        onIconClick={() => setEditMode(true)}
      >
        {isNotEmpty ? (
          <HtmlWrapper message={value} sx={{ cursor: "text" }} />
        ) : (
          <Box
            component="span"
            onClick={toggleEditMode}
            color={palette.grey[700]}
            fontSize={14}
            fontWeight={500}
            sx={{ cursor: "text" }}
          >
            The more details we have, the better we can help you.{" "}
            <Box component="span" color={palette.primary.main}>
              Add description
            </Box>
          </Box>
        )}
      </TypographyWithEditButton>
    );
  }
  return <CustomEditor editor={editor} onBlur={toggleEditMode} />;
}

function TypographyWithEditButton({
  onIconClick,
  sx,
  children,
  ...props
}: {
  onIconClick?: () => void;
} & TypographyProps) {
  return (
    <Typography
      minHeight={34}
      {...props}
      sx={{
        ":hover > .MuiBox-root": { display: "block" },
        position: "relative",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 0,
          display: "none",
          color: "grey",
          zIndex: 1,
        }}
        height={"100%"}
      >
        <IconButton onClick={onIconClick}>
          <EditIcon sx={{ fontSize: 18 }} />
        </IconButton>
      </Box>
      {children}
    </Typography>
  );
}
