import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Stack,
  SxProps,
  Theme,
  useTheme,
  styled,
  AvatarGroupProps,
} from "@mui/material";
import { addOpacityToHex } from "../../lib";
import admireLogo from "../../assets/img/logo.svg";
import uniqolor from "uniqolor";
import { Tooltip } from "./Popover";
import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { useAdmireUsersList } from "../hooks/useLists";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { UserIcon } from ".";

const generateBorder = `linear-gradient(#13BFFB 0%, #555FE6 51.26%, #A95BFF 100%)`;

const SmallAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== "groupItem" && prop !== "isGroup",
})<{ groupItem: number; isGroup: boolean }>(
  ({ theme: _theme, groupItem, isGroup }) => ({
    //https://stackoverflow.com/a/62051110 <- the only way I found to get a round border on mui avatar component
    width: isGroup ? 12 : 18,
    height: isGroup ? 12 : 18,
    border: "1px solid transparent",
    borderRadius: 4,
    backgroundImage: `linear-gradient(#FFF,#FFF), ${generateBorder}`,
    backgroundClip: "content-box, border-box",
    marginLeft: `${isGroup ? -2 : -24}px !important`,
    zIndex: `${groupItem + 2} !important`,
    top: 1,
    "& > .MuiAvatar-img": {
      width: isGroup ? 8 : 12,
      height: isGroup ? 8 : 12,
    },
  }),
);

interface TooltipTitleProps {
  showTooltip?: boolean;
  label?: string;
  content?: string | string[];
}

function TooltipTitle({ showTooltip, label, content }: TooltipTitleProps) {
  const show = showTooltip && (label || content);
  if (!show) return null;
  const contents = typeof content === "string" ? [content] : content;
  return (
    <Stack
      direction={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      maxHeight={"40vh"}
      overflow={"auto"}
    >
      {(label || "") + ":"}
      {!!contents && <br />}
      {!!contents &&
        contents.map((content, i) => (
          <Box component={"span"} textAlign={"center"} key={i}>
            {content}
          </Box>
        ))}
    </Stack>
  );
}

interface CustomAvatarProp extends TooltipTitleProps {
  value: string | ReactNode;
  background?: string;
  textProps?: SxProps<Theme>;
  props?: AvatarGroupProps;
  whiteBorder?: boolean;
}

export const CustomAvatar = ({
  value,
  background,
  showTooltip,
  textProps,
  props,
  label,
  whiteBorder,
  content,
}: CustomAvatarProp) => {
  return (
    <Tooltip
      title={
        showTooltip && (
          <TooltipTitle
            showTooltip={showTooltip}
            label={label}
            content={content}
          />
        )
      }
      props={{
        placement: "bottom",
        leaveDelay: 600000,
        leaveTouchDelay: 600000,
        open: true,
      }}
    >
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
          height: "100%",
          border: whiteBorder ? `1px solid #FFF` : "",
          borderRadius: "100%",
        }}
      >
        <Box
          component={"span"}
          fontWeight={"bold"}
          fontSize={18}
          sx={{
            ...textProps,
            borderRadius: "100%",
            bgcolor: background,
            cursor: "pointer",
          }}
          width="100%"
          height="100%"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          {...props}
          // sx={{ textShadow: isAdmin ? "" : "-1px 1px 2px rgba(1, 1, 1, 0.6)" }} potentially add shadow if colors start blending
        >
          {value}
        </Box>
      </Stack>
    </Tooltip>
  );
};

interface AdmireLogoBadgeProps {
  groupItem?: number;
  isGroup?: boolean;
  badgeStyles?: SxProps<Theme>;
  isInternal?: boolean;
}

function AdmireLogoBadge({
  groupItem = 0,
  badgeStyles,
  isGroup,
  isInternal,
}: AdmireLogoBadgeProps) {
  if (!isInternal) return null;
  return (
    <SmallAvatar
      alt="admire-logo"
      src={admireLogo}
      groupItem={groupItem}
      isGroup={!!isGroup}
      sx={{ ...badgeStyles, cursor: "pointer" }}
    />
  );
}

interface AvatarProps extends CustomAvatarProp {
  sx?: SxProps<Theme>;
  onClick?: () => void;
  badgeContent?: ReactNode;
  groupItem?: number;
  hasBorder?: boolean;
  dimensions?: number | string;
}

export function Avatar({
  sx,
  groupItem = 0,
  textProps,
  showTooltip = false,
  label,
  content,
  value,
  background,
  badgeContent,
  hasBorder,
  dimensions = 24,
  onClick,
}: AvatarProps) {
  const border = hasBorder ? generateBorder : background;
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={badgeContent}
      sx={{ zIndex: groupItem }}
    >
      <Stack
        onClick={onClick}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        color="#FFF"
        sx={{
          width: dimensions,
          height: dimensions,
          fontSize: 14,
          border: hasBorder ? `1px solid transparent` : ``,
          backgroundClip: "padding-box",
          bgcolor: background,
          position: "relative",
          borderRadius: "100%",
          "&:after": {
            position: "absolute",
            top: "-2px",
            left: "-2px",
            right: "-2px",
            bottom: "-2px",
            border: hasBorder ? "" : "1px solid #FFF",
            background: border,
            content: '""',
            zIndex: -1,
            borderRadius: "100%",
          },
          ...sx,
        }}
      >
        <CustomAvatar
          value={value}
          background={background}
          textProps={textProps}
          showTooltip={showTooltip}
          label={label}
          content={content}
          whiteBorder={!!hasBorder}
        />
      </Stack>
    </Badge>
  );
}

type UserAvatarProps = Omit<
  AvatarProps,
  "value" | "content" | "background" | "badgeContent" | "whiteBorder"
> &
  AdmireLogoBadgeProps & {
    first_name?: string;
    last_name?: string;
    color?: string;
    id?: number;
    defaultLabel?: string | { internalLabel: string; externalLabel: string };
  };

export function UserAvatar({
  color: _color,
  first_name = "",
  last_name = "",
  isInternal: isInternal_,
  badgeStyles,
  groupItem,
  isGroup,
  id,
  label,
  defaultLabel,
  ...rest
}: UserAvatarProps) {
  const { palette } = useTheme();
  const { getAdmireUser } = useAdmireUsersList();
  const isInternal = isInternal_ ?? (!!id && !!getAdmireUser(id));
  const name = [first_name, last_name].filter(Boolean);
  const fullName = name.join(" ");
  const isUnassignedUser = fullName === "Unassigned User";
  const isAdmireBot = fullName === "Admire Bot";
  const content = isUnassignedUser ? "Unassigned" : fullName;

  const value = isUnassignedUser ? (
    <UserIcon height={"1.2em"} />
  ) : isAdmireBot ? (
    <img alt="admire-bot-logo" src={admireLogo} width={24} height={24} />
  ) : (
    name
      .map((n) => n.charAt(0))
      .filter(Boolean)
      .map((i) => i.toUpperCase())
      .join("")
  );
  const color = isUnassignedUser
    ? palette.grey[600]
    : _color && !_color.startsWith("#")
    ? `#${_color}`
    : _color
    ? _color
    : uniqolor((id && id * 10) || first_name + last_name).color;
  const background = isAdmireBot
    ? "#FFF"
    : !isInternal
    ? `repeating-linear-gradient(-50deg, ${color}, ${color} 4%, ${addOpacityToHex(
        color,
        0.7,
      )} 8%
        );`
    : color;
  const label_ =
    label ||
    (typeof defaultLabel === "string"
      ? defaultLabel
      : !defaultLabel
      ? undefined
      : isInternal
      ? defaultLabel.internalLabel
      : defaultLabel.externalLabel);

  return (
    <Avatar
      value={value}
      content={content}
      background={background}
      hasBorder={(isInternal && !isUnassignedUser) || isAdmireBot}
      label={label_}
      badgeContent={
        <AdmireLogoBadge
          isInternal={isInternal && !isUnassignedUser}
          groupItem={groupItem}
          isGroup={isGroup}
          badgeStyles={badgeStyles}
        />
      }
      {...rest}
    />
  );
}

interface AvatarGroupProp {
  users: {
    id?: number;
    first_name?: string;
    last_name?: string;
    label?: string;
    isInternal?: boolean;
  }[];
  dimensions?: string | number;
  maxBeforeGrouping?: number;
  defaultLabel?: string | { internalLabel: string; externalLabel: string };
  labelForRemaining?: string;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
  spacing?: number;
}
export const AvatarGroup = ({
  users,
  dimensions = 24,
  maxBeforeGrouping = 4,
  defaultLabel = "Watcher",
  labelForRemaining = "Watchers",
  setOpenModal,
  spacing = 0.5,
}: AvatarGroupProp) => {
  const { palette } = useTheme();
  const { getAdmireUser } = useAdmireUsersList();

  const users_ = useMemo(() => {
    const ids = new Set();
    return users?.filter((u) => {
      if (!u.id) return true;
      const exists = ids.has(u.id);
      ids.add(u.id);
      return !exists;
    });
  }, [users, getAdmireUser]);

  const usersLimited = users_.slice(0, maxBeforeGrouping);
  const numberToDisplay = users_.length - maxBeforeGrouping;
  const openModalClick = useCallback(
    (e?: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (setOpenModal) setOpenModal((cur) => !cur);
    },
    [setOpenModal],
  );

  return (
    <Stack direction={"row"} spacing={spacing} alignItems={"center"}>
      {!!usersLimited?.length &&
        usersLimited.map((i, idx) => {
          const { first_name, last_name, isInternal, label, id } = i;

          return (
            <Fragment key={idx}>
              <UserAvatar
                onClick={openModalClick}
                sx={{
                  fontWeight: 600,
                  width: dimensions,
                  height: dimensions,
                  cursor: "pointer",
                }}
                textProps={{
                  fontSize: 11,
                }}
                first_name={first_name}
                last_name={last_name}
                id={id}
                isGroup
                groupItem={idx + 1}
                showTooltip
                label={label}
                defaultLabel={defaultLabel}
                isInternal={isInternal}
              />
            </Fragment>
          );
        })}
      {numberToDisplay > 0 && (
        <Avatar
          background={palette.grey[600]}
          onClick={openModalClick}
          label={
            labelForRemaining ||
            (typeof defaultLabel === "string"
              ? defaultLabel
              : defaultLabel?.externalLabel)
          }
          content={users_
            .slice(maxBeforeGrouping)
            .map((u) => [u.first_name, u.last_name].filter(Boolean).join(" "))}
          showTooltip
          groupItem={0}
          value={`+${numberToDisplay}`}
          dimensions={dimensions}
          textProps={{
            width: 27,
            height: 27,
            fontSize: 10,
            zIndex: 0,
            color: "#000",
            fontWeight: 600,
            textAlign: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        />
      )}
    </Stack>
  );
};

interface UserProfileAvatarProps {
  sx?: SxProps<Theme>;
  textProps?: SxProps<Theme>;
  badgeProps?: SxProps<Theme>;
}
export const UserProfileAvatar = ({
  sx,
  textProps,
  badgeProps,
}: UserProfileAvatarProps) => {
  const user = useSelector(authSelectors.userInfo);
  const isInternal = useSelector(authSelectors.isInternal);
  return (
    <UserAvatar
      id={"id" in user ? user.id : undefined}
      first_name={user.first_name}
      last_name={user.last_name}
      sx={{ height: 24, width: 24, fontSize: 10, ...sx }}
      textProps={{ fontSize: 10, ...textProps }}
      isInternal={isInternal}
      badgeStyles={{ right: -12, ...badgeProps }}
    />
  );
};
