import { IconButton, Button } from "@mui/material";
import { memo } from "react";
import { useMobile } from "../../themes";
import { useMemoObj } from "../hooks";
import { StopIcon } from "../icons";
import { PropsOf } from "@emotion/react";

interface TimerButtonProps {
  onClick: () => void;
  Icon?: typeof StopIcon;
  text: string;
  color?: PropsOf<typeof Button>["color"];
}
export const TimerButton = memo(
  ({ onClick, Icon, text, color }: TimerButtonProps) => {
    const isMobile = useMobile();

    const styles = useMemoObj({
      backgroundColor: "transparent !important",
      color: color ?? "#FFF !important",
    });
    const iconProps = useMemoObj({
      size: isMobile ? 16 : 18,
      style: { marginRight: isMobile ? 0 : "4px" },
    });

    return (
      <>
        {isMobile && Icon ? (
          <IconButton
            onClick={onClick}
            color={color ? "primary" : "white"}
            sx={{ ...styles }}
          >
            <Icon {...iconProps} />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            color={color || "white"}
            sx={{
              ...styles,
              fontSize: 16,
              fontWeight: 600,
              p: 1,
              height: "32px",
            }}
            onClick={onClick}
          >
            {Icon ? <Icon {...iconProps} /> : null}
            {text}
          </Button>
        )}
      </>
    );
  },
);
