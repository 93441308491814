import { Box, Menu } from "@mui/material";
import { ReactNode, useState } from "react";

export interface DropDownButtonProps<Item = any> {
  children: ReactNode;
  items: Item[];
  renderItems: (item: Item, index: number, close: () => void) => ReactNode;
  menuToLeft?: boolean;
}

export function DropDownButton<Item = any>({
  children,
  items,
  renderItems,
  menuToLeft,
}: DropDownButtonProps<Item>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        width={"min-content"}
        height={"min-content"}
        onClick={(e) => {
          if (!anchorEl) {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        {children}
      </Box>
      <Menu
        anchorEl={anchorEl}
        sx={{
          fontWeight: 500,
          fontSize: 14,
          display: "flex",
          alignItems: "baseline",
          mt: 1,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuToLeft ? "right" : "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuToLeft ? "right" : "center",
        }}
        open={Boolean(anchorEl)}
        onClose={close}
      >
        {items.map((item, i) => renderItems(item, i, close))}
      </Menu>
    </>
  );
}
