import { formatTimeToHuman } from "../../lib";

interface Props {
  startTime?: Date | string;
  endTime?: Date | string | null;
}
export const SessionTimeRange = ({ startTime, endTime }: Props) => {
  if (!startTime) return null;
  return (
    <>
      {formatTimeToHuman(new Date(startTime))} -{" "}
      {
        endTime ? formatTimeToHuman(new Date(endTime)) : "" //TODO should end_time be required in backend?
      }
    </>
  );
};
