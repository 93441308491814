import { GridColDef, GridFilterModel } from "@mui/x-data-grid";
import {
  useCalculateBillingsQuery,
  CalculateBillingsApiArg,
} from "../../../state/rtk-query/state/terms";
import {
  CardStyled,
  QuickbooksLoginModal,
  SortableButton,
  ToggleDropdown,
} from "../../../components";
import { StyledDataGrid } from "../../../components/tables/DataGrid.styles";
import RowsSelectedBanner from "../../../components/cards/RowsSelectedBanner";
import { Button, Stack } from "@mui/material";
import { useDatagridProps, useQuery } from "../../../components/hooks";
import { useRowSelectionModel } from "../../../components/hooks/useRowSelectionModel";
import { useState } from "react";
import { useInvoiceClientsMutation } from "../../../state/rtk-query/state/quickbooks";

const columns: GridColDef[] = [
  { field: "name", headerName: "Organization", flex: 1.5 },
  {
    field: "total_hours",
    headerName: "CW Hours",
    type: "number",
    valueFormatter: (v) => v.value.toFixed(2),
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "billable_hours",
    headerName: "CW Billable Hours",
    type: "number",
    valueFormatter: (v) => v.value.toFixed(2),
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "cost",
    headerName: "CW Cost",
    type: "number",
    valueFormatter: (v) => v.value.toFixed(2),
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "quickbooks_customer_id",
    headerName: "Linked to quickbooks",
    width: 250,
    align: "center",
    headerAlign: "center",
    type: "boolean",
  },
];

const pageSizeOptions = [10, 25, 50, 100, 250, 500];
export function BillingPage() {
  const [params, setParams] = useQuery<Partial<CalculateBillingsApiArg>>(
    "dateRange",
    "billed",
    "activityType",
  );

  const { slotProps, autosizeOptions } = useDatagridProps();
  const { dateRange, billed, activityType } = params;
  const { data } = useCalculateBillingsQuery({
    billed,
    dateRange,
    activityType: activityType || "custom_work",
  });

  const haveQbIds = data?.rows?.filter((r) => r.quickbooks_customer_id);

  const {
    onCellClick,
    onColumnHeaderClick,
    onRowClick,
    selectedRows,
    setSelectedRows,
  } = useRowSelectionModel(haveQbIds);

  const [openQb, setOpenQb] = useState(false);

  const [invoiceClients] = useInvoiceClientsMutation();

  const [filterModel, onFilterModelChange] = useState({
    items: [{ field: "billable_hours", operator: ">", value: 0 }],
  } as GridFilterModel);

  return (
    <>
      <Stack direction={"row"} width={"100%"} overflow={"auto"} gap={3} pb={3}>
        <SortableButton
          title="Date Range"
          onChange={(range) => {
            setParams({
              dateRange: range[0] && range[1] ? range : undefined,
            });
          }}
          isDropDown
          isDate
          currentSort={dateRange as any as Date[]}
          buttonPadding={0}
        />
        <SortableButton
          title="Activity"
          onClick={(val, checked) => {
            setParams({
              activityType: (checked
                ? val.toLowerCase().split(" ").join("_")
                : undefined) as typeof activityType,
            });
          }}
          items={[
            "Custom Work",
            "Standardized Custom Work",
            "Complimentary Custom Work",
          ]}
          isDropDown
          isSingleSelect
          singleSelectSort={
            activityType
              ? activityType
                  .split("_")
                  .map((s) => s[0].toUpperCase() + s.slice(1))
                  .join(" ")
              : "Custom Work"
          }
          buttonPadding={0}
        />
        <ToggleDropdown
          option1="billed"
          option2="unbilled"
          setValue={(val) =>
            setParams({ billed: val === "billed" || undefined })
          }
          value={billed ? "billed" : "unbilled"}
          buttonPadding={0}
        />
      </Stack>
      {!billed && (
        <RowsSelectedBanner
          selectedRowsCt={selectedRows.length}
          onClear={() => setSelectedRows([])}
          onSelectAll={() => setSelectedRows(haveQbIds?.map((r) => r.id) || [])}
        >
          <Button
            color="info"
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => {
              setOpenQb(true);
            }}
            disabled={activityType && activityType !== "custom_work"}
          >
            Invoice client
          </Button>
        </RowsSelectedBanner>
      )}
      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <StyledDataGrid
          key={billed ? "billed" : "unbilled"}
          columns={columns}
          rows={data?.rows ?? []}
          rowCount={data?.count}
          pagination
          pageSizeOptions={pageSizeOptions}
          autosizeOnMount={true}
          slotProps={slotProps}
          autosizeOptions={autosizeOptions}
          checkboxSelection={!billed}
          rowSelectionModel={selectedRows}
          onCellClick={onCellClick}
          onColumnHeaderClick={onColumnHeaderClick}
          onRowClick={onRowClick}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
            },
            ".MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
          isRowSelectable={(p) => !!haveQbIds?.some((r) => r.id === p.row.id)}
          onFilterModelChange={onFilterModelChange}
          filterModel={filterModel}
        />
      </CardStyled>
      <QuickbooksLoginModal
        open={openQb}
        apiCall={() =>
          invoiceClients({ body: { orgs: selectedRows, dateRange } })
        }
        handleClose={() => {
          setOpenQb(false);
          setSelectedRows([]);
        }}
      />
    </>
  );
}
