import { Editor, EditorContent } from "@tiptap/react";
import { Stack, useTheme } from "@mui/material";
interface Props {
  editor: Editor | null;
  placeholder?: string;
  isComplete?: boolean;
  editorSectionFocus?: boolean;
}

export const CustomEditorBody = ({
  editor,
  placeholder = "",
  isComplete = false,
  editorSectionFocus,
}: Props) => {
  const { palette } = useTheme();

  if (!editor) return null;
  const beforeStyles = {
    content: `"${placeholder}"`,
    fontWeight: 500,
    float: "left",
    color: isComplete ? palette.grey[800] : palette.grey[700],
    pointerEvents: "none",
    height: 0,
  };

  return (
    <Stack
      sx={{
        backgroundColor: isComplete ? palette.grey[300] : "#FFF",
        "& .ProseMirror": {
          mx: 2,
          height: "100%",
          minHeight: editorSectionFocus ? "60px" : "0px",
          outline: "none",
        },
        "& .ProseMirror > p.is-editor-empty::before": beforeStyles,
        "& .ProseMirror > p::before": isComplete ? beforeStyles : {},
        "& .ProseMirror  span.mention": {
          color: palette.primary.main,
          backgroundColor: palette.primary[100],
          borderRadius: "4px",
          fontWeight: 500,
          px: "4px",
          py: "1px",
        },
      }}
    >
      <EditorContent editor={editor} />
    </Stack>
  );
};
