import { useCallback, useEffect, useMemo, useState } from "react";
import { AddIcon, AddUserForm } from "../../components";
import { ServerDataGrid } from "../../components/tables/ServerDataGrid";
import { useUsersColumns } from "../../columns";
import { actions } from "../../state/rtk-query";
import { GetUserApiResponse } from "../../state/rtk-query/state/user";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { Button } from "@mui/material";
import { useLocation } from "../../lib";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BaseModal } from "../forms/BaseForm";
import { useOrgId, useRtkQueryParams } from "../hooks";
import { useMobile } from "../../themes";

const { useGetUsersQuery, useGetOrgUsersByIdQuery } = actions;

type Mode = "admin" | "client";

interface UsersTableProps<M extends Mode> {
  mode: M;
  orgId?: number;
  children: (props: {
    AddUserModel?: JSX.Element;
    UsersTable: JSX.Element;
    AddUserButton?: JSX.Element;
  }) => JSX.Element;
}

export const UsersTable = <M extends Mode>({
  mode,
  children,
  orgId: _orgId,
}: UsersTableProps<M>) => {
  const location = useLocation();
  const isMobile = useMobile();
  const {
    userType = "client",
    inactive,
    action,
  } = location.query as {
    userType: "admire" | "client";
    inactive?: boolean;
    action?: "add_user";
  };

  const orgId =
    mode === "client"
      ? _orgId !== undefined
        ? _orgId
        : useOrgId()
      : undefined;
  const [params, setParams] = useRtkQueryParams(
    mode === "admin" ? { userType, inactive } : { orgId, inactive },
    orgId === undefined ? undefined : { linkType: "and", range: [0, 25] },
  );

  const { data, refetch, isFetching } =
    mode === "admin"
      ? useGetUsersQuery(params, { refetchOnMountOrArgChange: true })
      : orgId
      ? useGetOrgUsersByIdQuery(
          params && orgId ? { ...params, orgId } : skipToken,
          {
            refetchOnMountOrArgChange: true,
          },
        )
      : { data: null, refetch: null, isFetching: false };

  const [editUser, setEditUser] = useState<GetUserApiResponse>();
  const [openUserModal, setOpenUserModal] = useState(false);
  const onClose = useCallback(() => {
    setOpenUserModal(!openUserModal);
    if (refetch) {
      refetch();
    }
    setEditUser(undefined);
  }, [openUserModal, refetch, editUser]);

  const isAnAdmin = useSelector(
    mode === "admin" ? authSelectors.isAdmin : authSelectors.isExternalAdmin,
  );
  const isInternal = useSelector(authSelectors.isInternal);
  const hasPermission =
    isAnAdmin || (mode === "client" && (isAnAdmin || isInternal));
  const rowClick = useCallback(
    ({ row }: { row: GetUserApiResponse }) => {
      if ((mode === "admin" && isAnAdmin) || mode !== "admin") {
        setEditUser(row);
        setOpenUserModal(true);
      }
    },
    [editUser, isAnAdmin],
  );

  const cols = useUsersColumns(mode, userType)?.filter(Boolean);

  useEffect(() => {
    if (action === "add_user") {
      setOpenUserModal(() => true);
    }
  }, []);
  const AddUserModel = useMemo(
    () =>
      hasPermission ? (
        <BaseModal
          open={openUserModal}
          title={editUser?.id ? "Edit user" : "Add user"}
          onClose={onClose}
        >
          <AddUserForm
            mode={mode}
            onClose={onClose}
            editUser={editUser}
            userType={
              userType as M extends "admin" ? "admire" | "client" : never
            }
            orgId={orgId}
          />
        </BaseModal>
      ) : undefined,
    [hasPermission, openUserModal, editUser?.id, userType, onClose, action],
  );
  const UsersTable = useMemo(
    () => (
      <ServerDataGrid
        isFetching={isFetching}
        key={userType}
        toolbarProps={{ hideToolbar: true }}
        rowCount={data?.count || 0}
        rows={data ? data.rows : []}
        columns={cols}
        hideColumns={{
          created_at: false,
          calendly_link: false,
          activity: false,
        }}
        setQueryParams={setParams}
        currentParams={params}
        props={{
          density: mode === "client" ? "compact" : "standard",
          sx: {
            ".MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
            },
            ".MuiDataGrid-row": {
              cursor: "pointer",
            },
          },
          onRowClick: rowClick,
          getRowHeight: ({ model }) => {
            if (model.phones.length > 1) return "auto";
          },
        }}
      />
    ),
    [userType, data, cols, params, setParams, rowClick],
  );

  const AddUserButton = useMemo(
    () =>
      hasPermission ? (
        <Button variant="contained" onClick={onClose}>
          <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} /> User
        </Button>
      ) : undefined,
    [isAnAdmin, onClose],
  );

  return children({
    AddUserButton,
    AddUserModel,
    UsersTable,
  });
};
