import { Box, styled } from "@mui/material";
const baseStyles = {
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
} as const;
export const RootStyle = styled("div")(({ theme: _theme }) => ({
  ...baseStyles,
}));
export const PreScreenRootStyle = styled("div")(({ theme: _theme }) => ({
  ...baseStyles,
  minHeight: "80vh",
}));

export const SelectOrgBoxContainer = styled(Box)(({ theme: _theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: 12,
  height: "100%",
}));

export const SelectOrgBox = styled(Box)(({ theme }) => ({
  display: "flex",
  border: `1px solid ${theme.palette.grey[500]}`,
  backgroundColor: "#FFF",
  width: 500,
  height: 96,
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 24px",
  borderRadius: 6,
  marginTop: 24,
  "&:last-child": {
    marginBottom: 2,
  },
  ":hover": {
    boxShadow: "0 1px 12px 2px  rgba(99,100,105,0.2)",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "16px",
    width: 288,
  },
}));

export const HomepageContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fullScreen",
})<{ fullScreen?: boolean }>(({ theme, fullScreen }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.up("sm")]: {
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderWidth: `1px 1px 0 1px`,
    borderRadius: `20px 20px 0 0`,
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    width: fullScreen ? "100%" : "calc(100% - 350px)",
  },
  [theme.breakpoints.up("xl")]: {
    width: fullScreen ? "100%" : "calc(100% - 450px)",
  },
}));

export const HomepageSectionStyled = styled(Box)(({ theme }) => ({
  // height: `calc(100vh - ${appBarHeight + 1}px)`, // subtract app bar height + 1px for MainContentStyled border
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  padding: "32px  32px 0  32px",
  [theme.breakpoints.down("sm")]: {
    // height: `calc(100vh - ${mobileAppBarHeight + mobileNavBarHeight}px)`, // subtract app bar height + mobile nav bar height
    padding: "16px 4px 0 4px",
    // marginLeft: "16px",
    // marginRight: "16px",
  },
}));
