import { styled, Theme } from "@mui/material/styles";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { DataGridPro } from "@mui/x-data-grid-pro";

const baseStyles = (theme: Theme) => ({
  "&.MuiDataGrid-root": {
    border: "none",
    fontWeight: 500,
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.grey["100"],
    borderBottom: "0.5px solid",
    borderColor: theme.palette.grey["300"],
    color: theme.palette.grey["900"],
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "14px",
      letterSpacing: "0.15px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    "& .MuiIconButton-root, .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    "& .MuiCheckbox-root": {
      "& .MuiSvgIcon-root": {
        fontSize: "1.5em",
      },
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    borderColor: theme.palette.grey["300"],
    color: theme.palette.grey["900"],
  },
  "& .MuiTablePagination-toolbar": {
    [theme.breakpoints.up("sm")]: {
      fontSize: ".5rem",
      "& .MuiSvgIcon-root": {
        fontSize: ".8rem",
      },
    },
  },
  "& .MuiCheckbox-root": {
    "& .MuiSvgIcon-root": {
      fontSize: "1.5em",
    },
    // transform: "scale(.75)",
    transformOrigin: "center center",
  },
});
export const StyledDataGrid = styled(DataGridPro)(({ theme }) =>
  baseStyles(theme),
);

export const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) =>
  baseStyles(theme),
);
