import { IconButtonProps, IconButton, styled } from "@mui/material";

interface IconButtonBorderedStyledProps extends IconButtonProps {
  borderThickness?: string;
}

export const IconButtonBorderedStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "borderThickness",
})<IconButtonBorderedStyledProps>(({ borderThickness }) => ({
  border: `${borderThickness || "1px"} solid`,
  borderRadius: "6px",
}));

export const HoverIconButtonStyled = styled(IconButton)(() => ({
  svg: {
    display: "none",
  },
  ":hover": {
    svg: {
      display: "block",
    },
  },
}));
