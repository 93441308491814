import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  ParamsSerializerOptions,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { VITE_API_URL, VITE_PERSIST_KEY } from "../../config";
import { auth, AuthState } from "./state";
import {
  getQueryParam,
  getRoleInfoById,
  INTERNAL,
  InternalName,
  Navigation,
  timeoutAsync,
  validateRoles,
} from "../../lib";
import { AppThunk, User, Role } from "../types";
import { uiActions } from "../ui/actions";
import { api, clientActions, generatedApis, store } from "../../state";
import { AuthLoginApiResponse } from "../rtk-query/state/auth";

const { actions } = auth;

// interface AuthResponse {
//   user?: {
//     id: number;
//     user_roles: UserRole[];
//     email: string;
//     mfa_required?: boolean;
//   } & Partial<User>;
//   token?: string;
//   id?: number;
// }
type AuthResponse = AuthLoginApiResponse;

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.delete(url, config));
  },
  download(_url: string, _config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) => apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.post<T>(url, data, config),
    );
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse((apiClient) =>
      apiClient.put<T>(url, data, config),
    );
  },
};

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: Partial<AuthResponse>): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        const roles = user?.orgs?.[0]?.user_roles.map((ur) => ({
          id: ur.role_id,
          ...getRoleInfoById(ur.role_id),
        }));
        authStateFromResponse = {
          userId: user.id,
          roles,
          token,
          userName: user.email,
          user,
          requiresMfa: !!user.mfa_required,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  impersonate(authResponseData: AuthResponse, returnUrl?: string): AppThunk {
    let authStateFromResponse: AuthState & { returnUrl?: string };
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        const roles = user?.orgs?.[0]?.user_roles.map((ur) => ({
          id: ur.role_id,
          ...getRoleInfoById(ur.role_id),
        }));
        authStateFromResponse = {
          userId: user.id,
          roles,
          token,
          userName: user.email,
          user: user as User,
          requiresMfa: !!user.mfa_required,
          returnUrl,
        };
      }
    }
    return async (dispatch) => {
      dispatch(actions.enterImpersonation(authStateFromResponse));
    };
  },
  loadUserValues(user?: User, roles?: Role[]): AppThunk {
    return async (dispatch) => {
      if (user) {
        const authState: AuthState = {
          roles,
          user,
        };
        dispatch(actions.setAuthState(authState));
      }
    };
  },
  socialLogin(values: {
    credential: string;
    sign_in_type: "google" | "microsoft";
  }): AppThunk<Promise<boolean>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { user, token },
        } = await axios.post(`/auth/social-login`, values, {
          baseURL: VITE_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        Navigation.replace(getQueryParam("after") || "/");
        dispatch(uiActions.setLoading(false));
        dispatch(authActions.load({ user, token }));
        return true;
      } catch (e) {
        dispatch(uiActions.showError());
        dispatch(uiActions.setLoading(false));
        return false;
      }
    };
  },
  multiFactorAuth(values: { mfaCode: string; rememberMe: boolean }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(
        `/auth/multi-factor-auth`,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (status !== 200) {
        const { message, code } = data;
        dispatch(uiActions.showError(message));
        if (status === 403 && code === 406) {
          await logout();
        }
      } else {
        dispatch(authActions.load(data));
      }
    };
  },
  resendMfaCode(type = "sms"): AppThunk {
    return async () => {
      const method = type === "email" ? "?type=email" : "";
      await authClient.get(`/auth/resend-mfa-code${method}`);
    };
  },
};

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: VITE_API_URL,
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}
let refreshWait = 100;
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: (_apiClient: AxiosInstance) => Promise<AxiosResponse<T>>,
  retrying = false,
): Promise<AxiosResponse<T>> {
  //whats this doing?
  let error: AxiosError<T> | undefined;
  const promiseWithCatch = promise(apiClient).catch((err) => {
    return err.response;
  });
  const res = await promiseWithCatch;
  if (error) {
    console.error({ error, res });
  }
  if (res.status && res.status === 401) {
    const dispatch: any = store.dispatch;
    if (!retrying) {
      refreshWait += refreshWait + 200;
      console.warn("Calling refresh token", refreshWait);
      if (refreshWait > 30000) {
        console.error("refreshWait too high");
        return res;
      }
      const response = await authClient.get("/auth/refresh-token");
      if (response.status === 200) {
        await dispatch(authActions.load(response.data));
        await timeoutAsync(refreshWait);
        return handleAuthResponse(promise, true);
      } else {
        console.warn("Refresh token also expired - logging out");
        // if refresh token is also expired
        await logout();
      }
    } else {
      console.warn("failed to refresh token");
      await logout();
    }
  }
  return res;
}

export function redirectToLogin() {
  if (!window.location.search.includes("after=")) {
    window.location.href =
      "/auth/login?after=" +
      encodeURIComponent(window.location.pathname + window.location.search);
  }
}

export async function mutex_logout() {
  localStorage.removeItem(`${KEY_PREFIX}${VITE_PERSIST_KEY}`);
  store.dispatch(api.util.resetApiState());
  await timeoutAsync(500);
  store.dispatch(actions.setAuthState(undefined));
  store.dispatch(clientActions.clearCurrentOrg());
}

export async function logout() {
  localStorage.removeItem(`${KEY_PREFIX}${VITE_PERSIST_KEY}`);
  const roles: InternalName[] =
    ((store.getState() as any)?.auth?.roles ?? [])?.map(
      (r: { internal_name?: InternalName }) => r?.internal_name,
    ) ?? [];
  const isInternal = validateRoles(roles, INTERNAL);

  if (isInternal) {
    await store.dispatch(
      generatedApis.timerApi.actions.endpoints.stopActiveTimer.initiate(),
    );
  }
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  Object.values(generatedApis).forEach((api) => {
    return store.dispatch(api.actions.util.resetApiState());
  });
  store.dispatch(api.util.resetApiState());
  await timeoutAsync(500);
  await authClient
    .get("/auth/logout")
    .catch((err) => console.error("/auth/logout failed", err));
  store.dispatch(actions.setAuthState(undefined));
  store.dispatch(clientActions.clearCurrentOrg());
  await timeoutAsync(700);
  redirectToLogin();
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
const serializeParams: ParamsSerializerOptions = {
  encode: (params: { [x: string]: any; toString?: any }) => {
    if (params instanceof URLSearchParams) {
      return params.toString();
    }
    const formattedParams: Record<string, any> = {};
    const keys = Object.keys(params);
    const { length } = keys;
    for (let i = 0; i < length; i++) {
      const key = keys[i];
      let value = params[key];
      if (value === null || value === undefined) {
        continue;
      }
      if (Object.prototype.toString.call(value) === "[object Date]") {
        // Format Dates...
        value = value.toISOString();
      } else if (value !== null && typeof value === "object") {
        // Format objects and arrays...
        value = JSON.stringify(value);
      }
      formattedParams[key] = value;
    }
    // URLSearchParams does not handle arrays...
    // return new URLSearchParams(formattedParams).toString();
    return qs.stringify(formattedParams);
  },
};
axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
export type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
