import { Button, useTheme } from "@mui/material";
import { differenceInHours } from "date-fns";
import { BookingType } from "../../global";
import { useCallback } from "react";
import { useActivityActionsList } from "../hooks/useLists";
import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
export const JoinZoomMeetingButton = ({
  booking,
}: {
  booking?: BookingType;
}) => {
  const { palette } = useTheme();

  const { getActionId } = useActivityActionsList();
  const [createTimerActivity] = useCreateUserTimerActivityMutation();

  const { start_time, join_url, session_id } = booking ?? {};
  const isJoinable = start_time
    ? [0, 1].includes(differenceInHours(new Date(start_time), new Date()))
    : false;
  const sessionStart = getActionId("start_session");
  const stopDefault = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (join_url) {
        if (sessionStart && session_id) {
          createTimerActivity({
            body: {
              entity: "session",
              entity_id: session_id,
              action_id: sessionStart,
            },
          });
        }
        window.open(join_url);
      }
    },
    [join_url, isJoinable, sessionStart, session_id],
  );

  return (
    <>
      {start_time && join_url ? (
        <Button
          onClick={stopDefault}
          sx={{
            pointerEvents: !isJoinable ? "none" : "all",
            color: isJoinable ? palette.primary.main : palette.grey[600],
            alignSelf: "flex-start",
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Join
        </Button>
      ) : null}
    </>
  );
};
