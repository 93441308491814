import React, { useCallback, useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress, SxProps, Theme } from "@mui/material";

interface BackdropLoaderProps {
  loading: boolean;
  size?: number;
  sx?: SxProps<Theme>;
}

export const BackdropLoader = React.memo(
  /**
   *
   */
  function BackdropLoader({ loading, size = 128, sx }: BackdropLoaderProps) {
    // track component mounted to avoid setting state in timeout after component has unmounted
    const isMountedRef = useRef(false);
    useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
      };
    });

    const [open, setOpen] = useState(loading);
    const [mountLoader, setMountLoader] = useState(loading);

    // unmounting loader 2 seconds after loading has been set to false to avoid constant rendering of the circular progress component in the background
    const unmountLoaderTimerRef = useRef<any>();

    const setUnmountLoaderTimer = useCallback(() => {
      unmountLoaderTimerRef.current = setTimeout(() => {
        isMountedRef.current && setMountLoader(false);
      }, 2000);
    }, []);

    const clearUnmountLoaderTimer = useCallback(() => {
      unmountLoaderTimerRef.current &&
        clearTimeout(unmountLoaderTimerRef.current);
    }, []);

    useEffect(() => {
      if (loading) {
        setMountLoader(true);
        clearUnmountLoaderTimer();
      } else {
        setUnmountLoaderTimer();
      }

      setOpen(loading);
    }, [clearUnmountLoaderTimer, loading, setUnmountLoaderTimer]);

    return (
      <Backdrop open={open} sx={sx}>
        {mountLoader && <CircularProgress size={size} />}
      </Backdrop>
    );
  },
);
