import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Switch, TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { RootStyled, GridStyled } from "./UserPage.styles";
import { actions } from "../../../state/rtk-query";
const { useGetUserProfileQuery, useUpdateUserProfileMutation } = actions;
const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ProfilePage = () => {
  const { currentData: userInfo } = useGetUserProfileQuery();
  const [initialValues, setInitialValues] = useState<
    Exclude<typeof userInfo, undefined>
  >({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    mfa_required: false,
    phones: [],
    orgs: [],
  });

  useEffect(() => {
    if (userInfo) {
      setInitialValues((pre) => {
        const { first_name, last_name, email, mfa_required } = userInfo;
        return { ...pre, first_name, last_name, email, mfa_required };
      });
    }
  }, [userInfo]);
  //not currently used
  const [_updateProfile] = useUpdateUserProfileMutation();

  const handleSubmit = async (_values: typeof initialValues) => {
    // return await updateProfile({
    //   body: {
    //     ...values,
    //     mfa_required: values.mfa_required ?? false,
    //     roles: [],
    //   },
    // });
  };

  return (
    <RootStyled>
      <Card>
        <CardContent sx={{ pt: 6 }}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched, setFieldValue, values }) => (
              <Form>
                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      name="last_name"
                      label="Last Name"
                      required
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </GridStyled>
                </Grid>

                <Grid container>
                  <GridStyled item>
                    <Field
                      component={TextField}
                      fullWidth
                      type="email"
                      name="email"
                      label="Email address"
                      disabled={true}
                    />
                  </GridStyled>
                  <GridStyled item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            type="checkbox"
                            name="mfa_required"
                            checked={values?.mfa_required}
                            onChange={() =>
                              setFieldValue(
                                "mfa_required",
                                !values.mfa_required,
                              )
                            }
                            error={
                              touched.mfa_required &&
                              Boolean(errors.mfa_required)
                                ? true
                                : undefined
                            }
                          />
                        }
                        label="Mfa Required"
                      />
                    </FormGroup>
                  </GridStyled>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <GridStyled
                    item
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </GridStyled>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </RootStyled>
  );
};

export default ProfilePage;
