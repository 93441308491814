import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";
import {
  EXTERNAL_ADMINS,
  INTERNAL,
  INTERNAL_ADMINS,
  InternalName,
  ROLES,
  validateRoles,
} from "../../lib";

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) => state.auth.roles ?? [];
const _rolesNames = createSelector(_roles, (roles) =>
  roles.map(({ internal_name }) => internal_name),
);
const _isAdmin = createSelector(_rolesNames, (roles) =>
  validateRoles(roles, INTERNAL_ADMINS),
);
const _isInternal = createSelector(_rolesNames, (roles) =>
  validateRoles(roles, INTERNAL),
);
const _isExternalAdmin = createSelector(_rolesNames, (roles) =>
  validateRoles(roles, EXTERNAL_ADMINS),
);
const _isCustomWork = createSelector(
  _rolesNames,
  (roles) => !!roles.find((r) => r === "professional_services"),
);
const _isSuperAdmin = createSelector(
  _rolesNames,
  (roles) => !!roles.find((r) => r === "super_admin"),
);

const _isConsultant = createSelector(
  _rolesNames,
  (roles) => !!roles.find((r) => r === "consultant"),
);

const _isAccountManager = createSelector(
  _rolesNames,
  (roles) => !!roles.find((r) => r === "account_manager"),
);
const _isIT = createSelector(
  _rolesNames,
  (roles) => !!roles.find((r) => r === "it"),
);

const _inImpersonation = createSelector(_state, (state) => !!state.oldAuth);
const _impersonationReturnUrl = createSelector(
  _state,
  (state) => state.oldAuth?.returnUrl,
);

const _isLoggedIn = createSelector(_state, (state) => !!state.userId);

const _userId = createSelector(_state, (state) => state.userId ?? 0);
const _userOrgs = createSelector(_state, (state) =>
  state.user?.orgs.map((o) => o.id),
);
const _userName = createSelector(_state, (state) => state.userName ?? "");
const _userInfo = (state: AppState) =>
  state.auth.user || { first_name: "", last_name: "", email: "" };
const _authToken = (state: AppState) => state.auth.token;

export const authSelectors = {
  isAdmin: _isAdmin,
  isSuperAdmin: _isSuperAdmin,
  isInternal: _isInternal,
  isExternalAdmin: _isExternalAdmin,
  rolesNames: _rolesNames,
  isLoggedIn: _isLoggedIn,
  isCustomWorkUser: _isCustomWork,
  isIT: _isIT,
  isConsultant: _isConsultant,
  isAccountManager: _isAccountManager,
  inImpersonation: _inImpersonation,
  impersonationReturnUrl: _impersonationReturnUrl,
  requiresMfa: (state: AppState) => state.auth.requiresMfa,
  roles: _roles,
  /** Selector used for routing */
  route: createSelector(_isLoggedIn, _roles, (isLoggedIn, roles) => ({
    isAdmin: roles
      .map((r: { internal_name?: InternalName }) => r.internal_name ?? "")
      .includes(ROLES.SUPER_ADMIN),
    isLoggedIn,
    roles,
  })),
  authToken: _authToken,
  /** Selector used for getting the entire auth state. */
  state: createSelector(
    _isAdmin,
    _isLoggedIn,
    _isCustomWork,
    _isIT,
    _roles,
    _userId,
    _userName,
    (isAdmin, isLoggedIn, roles, userId, userName) => ({
      isAdmin,
      isLoggedIn,
      roles,
      userId,
      userName,
    }),
  ),
  userId: _userId,
  userName: _userName,
  userInfo: _userInfo,
  userOrgs: _userOrgs,
};

export const AuthSelectors = authSelectors;
