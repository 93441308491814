import { Fragment, useEffect } from "react";
import { useGetOrgLinksByIdQuery } from "../../../state/rtk-query/state/organization";
import { QuicklinksCard } from "../../../components";
import { Stack } from "@mui/material";
import { useOrgId } from "../../../components/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const QuickLinksPage = () => {
  const orgId = useOrgId();
  const { currentData, refetch: linkFetch } = useGetOrgLinksByIdQuery(
    orgId
      ? {
          orgId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  useEffect(() => {
    linkFetch();
  }, [orgId]);
  return (
    <Stack direction={"column"} height={"100%"}>
      <>
        {currentData?.rows
          ? currentData?.rows.map((r) => {
              return (
                <Fragment key={r.id}>
                  <QuicklinksCard link={r} />
                </Fragment>
              );
            })
          : null}
      </>
    </Stack>
  );
};
