import { ReactNode } from "react";
import { EmptyState, EmptyStateProps } from "./EmptyState";

export interface EmptiableProps extends EmptyStateProps {
  isEmpty?: boolean;
  children: ReactNode;
  orgId?: number;
  sessionId?: number;
}
export function Emptiable(props: EmptiableProps) {
  if (props.isEmpty) {
    return (
      <EmptyState
        type={props.type}
        hasFilters={props.hasFilters}
        orgId={props.orgId}
        sessionId={props.sessionId}
      />
    );
  }
  return <>{props.children}</>;
}
