import { Box, SxProps, Theme, useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import { MouseEvent } from "react";
import { stripHtml } from "string-strip-html";

interface Props {
  message: string;
  ellipsis?: boolean;
  ignoreLists?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  sx?: SxProps<Theme>;
}
export const HtmlWrapper = ({
  message,
  ellipsis = false,
  ignoreLists = false,
  onClick,
  sx,
}: Props) => {
  const { palette } = useTheme();

  const element = document.createElement("div");
  element.innerHTML = ignoreLists
    ? stripHtml(message, {
        onlyStripTags: ["ul", "ol", "li"],
      }).result.replace("</p> <p>", " ")
    : message;

  return (
    <Box
      onClick={onClick}
      component={"span"}
      fontWeight={500}
      sx={{
        "& .mention": {
          bgcolor: palette.primary[100],
          color: palette.primary.main,
          borderRadius: "4px",
          fontWeight: 500,
          px: "4px",
          py: "1px",
        },
        height: "min-content",
        wordBreak: ellipsis ? "normal" : "break-word",
        "& > p , & > li , &:first-of-type": {
          textOverflow: ellipsis ? "ellipsis" : "initial",
          whiteSpace: ellipsis ? "nowrap" : "normal",
          overflow: ellipsis ? "hidden" : "auto",
          m: ellipsis ? 0 : "initial",
          lineClamp: ellipsis ? 1 : "none",
          lineHeight: ellipsis ? "1.8em" : "auto",
          maxHeight: ellipsis ? "1.8em" : "auto",
        },
        "& > b": {
          fontWeight: 700,
        },
        ...sx,
      }}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(element?.innerHTML ?? message ?? "<></>", {
          ALLOWED_ATTR: ["class", "href", "target"],
        }),
      }}
    />
  );
};
