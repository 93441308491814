import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { useLocation, RouterLink } from "../../lib";
import { ContainerStyle, CenterElementStyle } from "./Auth.styles";
import { actions } from "../../state/rtk-query";
import { useMobile } from "../../themes";
export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const location = useLocation();
    const isMobile = useMobile();
    const [showResend, setShowReset] = useState(false);
    const { useAuthForgotPasswordMutation } = actions;
    const [recoverPassword] = useAuthForgotPasswordMutation();
    const resendEmail = useCallback((_e: any) => {
      setShowReset(false);
      recoverPassword({ body: { email: location.query.email } });
    }, []);
    useEffect(() => {
      if (showResend === true) return;

      const timer = setTimeout(() => {
        setShowReset(true);
      }, 15000);
      return () => {
        clearTimeout(timer);
      };
    }, [showResend]);
    return (
      <ContainerStyle>
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            fontWeight={600}
            fontSize={isMobile ? 24 : 28}
          >
            Email sent
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            fontWeight={500}
            fontSize={16}
          >
            If {location.query.email || "email provided"} is a valid user's
            email, an email has been sent to reset your password.
          </Typography>
          <div>
            <Button
              variant="contained"
              type="button"
              fullWidth
              onClick={resendEmail}
              disabled={!showResend}
              sx={{
                mt: 5,
                mb: 2,
              }}
            >
              Resend email
            </Button>
          </div>
          <Typography variant="body2">
            If it doesn't arrive soon check your spam folder
          </Typography>
          <CenterElementStyle>
            <Link component={RouterLink} to={`/auth/login`}>
              <Typography variant="body2"> Back to login </Typography>
            </Link>
          </CenterElementStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
