import {
  Box,
  Button,
  FormControlLabel,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { CustomCheckBox, SessionCard, SortableButton } from "..";
import { actions, authSelectors, useSelector } from "../../state";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useMobile } from "../../themes";
import { DateRange } from "@mui/x-date-pickers-pro";
import { capitalizeSentence, filterBoolean, useLocation } from "../../lib";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetStatusId,
  useQuery,
  useRtkQueryParams,
  useStatuses,
} from "../hooks";
import { Emptiable, Loadable } from "../misc";
const {
  useGetSessionsByOrgIdQuery,
  useGetAdmireUsersQuery,
  useGetOrgUsersByIdQuery,
} = actions;
type QueryProps = {
  dateRange?: DateRange<any> | undefined;
  status?: number[];
  assignee?: number[];
  sortOpenTasks?: boolean;
  pageNumber?: number;
};

export const SessionsView = ({
  orgId,
  limit,
  mode,
}: {
  orgId?: number;
  limit: number;
  mode?: string;
}) => {
  const isMobile = useMobile();
  const { query: globalQuery } = useLocation();
  const isInternal = useSelector(authSelectors.isInternal);
  const userId = useSelector(authSelectors.userId);
  const [expandAll, setExpandAll] = useState<number[]>([]);
  const [params, _setparams] = useRtkQueryParams();
  const statuses = useStatuses("session");
  const upcomingStatus = useGetStatusId("upcoming");
  const unscheduledStatus = useGetStatusId("unscheduled");

  // NOTE: If there are ever more filters make sure the names don't overlap tasks query's
  const [query, setQuery, replaceQuery] = useQuery<QueryProps>(
    "dateRange",
    "status",
    "sortOpenTasks",
    "pageNumber",
    "assignee",
  );
  const { dateRange, status, sortOpenTasks, pageNumber, assignee } = query;
  const page = pageNumber ?? 1;

  useEffect(() => {
    // possible that 0 gets loaded before upcoming status is known
    const curStatus = (globalQuery?.status ?? []).filter((s?: string) =>
      Boolean(Number(s)),
    );
    if (!curStatus?.length) {
      replaceQuery({
        status: [upcomingStatus, unscheduledStatus],
        pageNumber: 1,
      });
    }
  }, [upcomingStatus, unscheduledStatus]);

  const sessionsQuery = useMemo(() => {
    const { ...rest } = params;
    return {
      custom: JSON.stringify({
        status,
        assignee,
        dateRange: dateRange?.filter((r) => r !== null),
        sortOpenTasks,
      }),
      range: [page - 1, limit ?? 25],
      ...rest,
    };
  }, [query, params, orgId]);
  const hasFilter =
    filterBoolean([...(status ?? []), ...(assignee ?? [])]).length ||
    sortOpenTasks ||
    (Array.isArray(dateRange) &&
      filterBoolean(dateRange?.filter((r) => r !== null)).length);

  const {
    currentData: sessionData,
    isFetching: loadOrgSessions,
    isSuccess,
  } = useGetSessionsByOrgIdQuery(
    orgId ? { orgId, ...sessionsQuery } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const sortButton = (
    f: number,
    c: boolean,
    sortable: keyof Omit<
      QueryProps,
      "sortOpenTasks" | "dateRange" | "pageNumber"
    >,
    clearFilters?: keyof QueryProps,
  ) => {
    const values = query[sortable];
    if (clearFilters) {
      setQuery({}, clearFilters);
    }
    setQuery({
      [sortable]: c
        ? [...(values || []), f]
        : (values || []).filter((p: number) => p !== f),
      pageNumber: 1,
    });
  };

  const { currentData: orgUsers } = isInternal
    ? useGetAdmireUsersQuery(undefined, {
        skip: !isInternal,
        refetchOnMountOrArgChange: true,
      })
    : useGetOrgUsersByIdQuery(orgId ? { orgId } : skipToken, {
        refetchOnMountOrArgChange: true,
      });

  const expandAllClick = () => {
    if (sessionData?.rows?.length) {
      setExpandAll(
        expandAll?.length === 0
          ? sessionData?.rows?.map((s) => Number(s?.id)) ?? []
          : [],
      );
    }
  };
  useEffect(() => {
    if (expandAll.length && isSuccess) {
      setExpandAll(sessionData?.rows?.map((s) => Number(s?.id)) ?? []);
    }
  }, [loadOrgSessions]);
  const viewMoreClick = (
    _event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setQuery({ pageNumber: newPage });
  };

  useEffect(() => {
    if (
      (assignee && assignee[0] === userId && assignee.length === 1) ||
      assignee?.length === 0
    ) {
      setQuery({ assignee: assignee });
    }
  }, [assignee]);

  return (
    <Stack height={"100%"} justifyContent={"space-between"}>
      {/* <SessionTaskHeader /> */}
      {/* add mt={4} if header gets added back  */}
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        mb={isMobile ? 2 : 3}
        gap={isMobile ? 1 : 0}
        height={"auto"}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          overflow={"auto"}
          sx={{
            "::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          {/* Waiting on what goes in filter  */}
          <SortableButton
            title="Status"
            onClick={(f, c) => sortButton(f, c, "status")}
            isDropDown
            displayItems={statuses.map((s) => {
              return {
                label: capitalizeSentence(
                  s.internal_name.toLowerCase().replace("_", " "),
                ),
                id: s.id,
              };
            })}
            currentSort={status}
          />
          <SortableButton
            title="Session Date"
            onChange={(range) => {
              setQuery({
                dateRange: range[0] && range[1] ? range : undefined,
              });
            }}
            isDropDown
            isDate
            currentSort={dateRange}
          />
          {isInternal ? (
            <SortableButton
              title="Assignee"
              onClick={(f, c) => sortButton(f, c, "assignee")}
              isDropDown
              displayItems={
                orgUsers?.rows?.map((u) => {
                  return {
                    label: `${u.first_name} ${u.last_name}`,
                    id: Number(u.id),
                  };
                }) ?? []
              }
              currentSort={assignee}
            />
          ) : null}
          <Stack
            height={"min-content"}
            alignContent={"flex-start"}
            direction={"row"}
          >
            <FormControlLabel
              sx={{
                height: "min-content",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                "& > span:last-child": {
                  fontSize: isMobile ? 14 : 16,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                },
              }}
              labelPlacement="end"
              control={
                <CustomCheckBox
                  onChange={(
                    _e: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => setQuery({ sortOpenTasks: checked }, "pageNumber")}
                  checked={sortOpenTasks}
                />
              }
              label="Open tasks"
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} whiteSpace={"nowrap"}>
          <>
            {hasFilter && (
              <Button
                variant="text"
                color="info"
                sx={{ minWidth: "fit-content" }}
                size="small"
                onClick={() => {
                  setQuery(
                    {},
                    "dateRange",
                    "status",
                    "sortOpenTasks",
                    "pageNumber",
                    "assignee",
                  );
                }}
              >
                <Typography fontSize={16} fontWeight={"500"}>
                  Reset filters
                </Typography>
              </Button>
            )}
            {sessionData?.rows?.length && !isMobile ? (
              <>
                {/* <SortableHeader title=" Sort by" /> */}
                <Button
                  sx={{ fontSize: 16, fontWeight: 600 }}
                  onClick={expandAllClick}
                  fullWidth
                >
                  {expandAll?.length === 0 ? "Expand all" : "Collapse all"}
                </Button>
              </>
            ) : null}
          </>
        </Stack>
      </Stack>
      <Loadable isLoading={loadOrgSessions}>
        <Emptiable
          isEmpty={isSuccess && !sessionData?.rows?.length}
          type="sessions"
          hasFilters={Boolean(hasFilter)}
          orgId={orgId}
        >
          <Stack
            flexGrow={1}
            overflow={"auto"}
            justifyContent={"space-between"}
            sx={{
              "::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Stack gap={2}>
              {sessionData?.rows?.length
                ? sessionData?.rows.map((s) => {
                    return (
                      <Box key={s?.id}>
                        <SessionCard
                          session={s}
                          expand={expandAll}
                          setExpandAll={setExpandAll}
                          viewer={
                            mode === "individualOrg" ? "internal" : "external"
                          }
                        />
                      </Box>
                    );
                  })
                : null}
            </Stack>
            {sessionData && sessionData?.count > limit ? (
              <Stack
                direction={"row"}
                justifyContent={isMobile ? "center" : "flex-end"}
                py={isMobile ? 2 : 4}
              >
                <Pagination
                  count={Math.ceil(Number(sessionData?.count) / limit)}
                  onChange={viewMoreClick}
                  page={Number(page) ?? 1}
                  color="primary"
                  shape="rounded"
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      fontSize: "1em",
                      "& .MuiSvgIcon-root": {
                        fontSize: "1em",
                      },
                    },
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
        </Emptiable>
      </Loadable>
    </Stack>
  );
};
