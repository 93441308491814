import {
  AppBarProps,
  Box,
  Button,
  IconButton,
  Paper,
  Select,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { drawerWidth } from "./Drawer.styles";

export const appBarHeight = 72;
export const mobileAppBarHeight = 56;
export const mobileNavBarHeight = 72;

interface TopBarStyledProps extends AppBarProps {
  open?: boolean;
  shadeBackgroundMobile?: boolean;
}

export const TopBarStyled = styled(Paper, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "shadeBackgroundMobile",
})<TopBarStyledProps>(({ theme, open, shadeBackgroundMobile }) => ({
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  top: "0px",
  zIndex: theme.zIndex.appBar,
  borderRadius: "0px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(shadeBackgroundMobile
    ? {
        [theme.breakpoints.down("sm")]: {
          backgroundColor: theme.palette.grey[50],
        },
      }
    : {}),
}));

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    height: appBarHeight,
    padding: "20px 32px",
  },
  [theme.breakpoints.down("sm")]: {
    height: mobileAppBarHeight,
    padding: "8px",
  },
}));
export const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<TopBarStyledProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
export const ToolbarTitleStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "24px",
  overflow: "hidden",
  "& > *": {
    marginRight: "11px",
  },
  "& > .MuiTypography-root": {
    fontSize: "18px",
    lineHeight: "22px",
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.text.primary,
      fontWeight: "500",
    },
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.grey[900],
      fontWeight: "700",
    },
    textDecoration: "none",
    marginRight: "11px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& > svg": {
    color: theme.palette.grey[500],
    marginRight: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    "& > .MuiTypography-root": {
      fontWeight: "700",
    },
  },
}));

export const ToolbarContentStyled = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flex: 1,
}));

export const SearchContainerStyled = styled(Box)(({ theme }) => ({
  marginRight: "20px",
  "& .MuiAutocomplete-root": {
    width: "220px",
  },
  "& input": {
    fontSize: "14px",
    height: "14px",
  },
  "& svg": {
    height: "16px",
    width: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& .MuiAutocomplete-root.Mui-focused": {
      width: "100%",
      transition: "width .2s",
    },
  },
}));

export const HelpDialogContainerStyled = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  padding: 40,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  " > *": {
    maxWidth: 296,
  },
  [theme.breakpoints.up("sm")]: {
    height: "552px",
    width: "442px",
  },
}));

export const MobileNavBarStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: mobileNavBarHeight,
  width: "100%",
  position: "fixed",
  bottom: "0px",
  zIndex: theme.zIndex.appBar,
}));

export const MobileNavBarButtonStyled = styled(Button)(({ theme }) => ({
  flexDirection: "column",
  height: "fit-content",
  textAlign: "center",
  color: theme.palette.grey[700],
  "& .MuiTypography-root": {
    fontSize: "14px",
    marginTop: "4px",
  },
}));

export const MobileSearchButtonStyled = styled(IconButton)(({ theme }) => ({
  margin: "0px 52px",
  background: "linear-gradient(40deg, #AA5CFF 46.45%, #8766FF 85.49%)",
  color: theme.palette.background.default,
}));

export const HomeIconButtonStyled = styled(IconButton)(({ theme }) => ({
  background: "linear-gradient(40deg, #AA5CFF 46.45%, #8766FF 85.49%)",
  color: theme.palette.background.default,
  height: "32px",
  width: "32px",
  padding: "8px",
  "& img": {
    height: "16px",
  },
}));

export const SelectOrgBarStyled = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary[800],
  color: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 60,
  [theme.breakpoints.down("sm")]: { zIndex: 20 },
}));
export const SelectOrgFieldStyled = styled(Select)(({ theme: _theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "transparent !important",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: 20,
    fontWeight: 500,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
  "& > svg": {
    color: "#FFF !important",
  },
}));
