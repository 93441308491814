import { isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { uiActions } from "../states";
import { NODE_ENV } from "../../config";

/**
 * At this point it seems easier to to declare which endpoints should not display a toast
 * and the rest will get by default...
 */
const SKIP_TOAST_ENDPOINTS = [
  "updateNotification",
  "getNotificationCount",
  "updateNotificationByTask",
  "createTaskChat",
  "createTask",
  "globalSearch",
  "dismissAnnouncement",
  "markTaskChatAsRead",
  "toggleTaskActionPending",
  "updateTimer",
  "deleteTimer",
  "updateOpenTabs",
  "createUserTimerActivity",
];

export const rtkQueryUIMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    const endpoint: string = action?.meta?.arg?.endpointName;
    const pathname = location.pathname;

    if (isRejectedWithValue(action)) {
      if (NODE_ENV !== "production") {
        console.warn("We got a rejected action!", action);
      }
      if (pathname === "/auth/login" && !["authLogin"].includes(endpoint)) {
        return next(action);
      }
      // A race condition can happen when a user switches orgs => closes the tab => reopens a new tab
      // since we first try and do an update for the active org but if that update doesn't happen quick enough it's possible
      // that we create a new timer. I throw a 409 in the timers "beforeCreate" hook in the BE if there is an active timer already
      if (endpoint === "createTimer" && action.payload?.status === 409) {
        return next(action);
      }
      api?.dispatch(
        uiActions?.showError(
          action?.payload?.data?.message || "Oops! Something went wrong!",
        ),
      );
    } else if (isFulfilled(action)) {
      const capitalizeFirstLetter = (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1);
      if (
        endpoint &&
        !endpoint.startsWith("get") &&
        !endpoint.startsWith("auth") &&
        !SKIP_TOAST_ENDPOINTS.includes(endpoint)
      ) {
        if (
          action.payload?.code === 214 &&
          typeof action.payload?.message === "string"
        ) {
          api?.dispatch(uiActions?.showWarning(action.payload.message));
        }
        let msg = "";
        switch (endpoint) {
          case "stopActiveTimer":
            msg = "Timer stopped";
            break;
          case "createTimer":
            msg = "Timer started";
            break;
          default:
            msg = `${capitalizeFirstLetter(
              endpoint.replace(/([A-Z])/g, " $1"),
            )} was successful`;
        }
        // eslint-disable-next-line no-console
        console.log(msg);
        // api?.dispatch(uiActions?.showSuccess(msg));
      }
    }
    return next(action);
  };
