import { api } from "../api";
export const addTagTypes = ["google", "calendar"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getGoogleOauthRedirect: build.query<
        GetGoogleOauthRedirectApiResponse,
        GetGoogleOauthRedirectApiArg
      >({
        query: () => ({ url: `/google/oauth-redirect` }),
        providesTags: ["google"],
      }),
      createGoogleAccessToken: build.mutation<
        CreateGoogleAccessTokenApiResponse,
        CreateGoogleAccessTokenApiArg
      >({
        query: () => ({ url: `/google/oauth-code`, method: "POST" }),
        invalidatesTags: ["google"],
      }),
      getGoogleCalendars: build.query<
        GetGoogleCalendarsApiResponse,
        GetGoogleCalendarsApiArg
      >({
        query: () => ({ url: `/google/calendars` }),
        providesTags: ["google", "calendar"],
      }),
      getGoogleAuthStatus: build.query<
        GetGoogleAuthStatusApiResponse,
        GetGoogleAuthStatusApiArg
      >({
        query: () => ({ url: `/google/oauth-status` }),
        providesTags: ["google", "calendar"],
      }),
      getGoogleCredentials: build.query<
        GetGoogleCredentialsApiResponse,
        GetGoogleCredentialsApiArg
      >({
        query: () => ({ url: `/google/sign-in-creds` }),
        providesTags: ["google", "calendar"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetGoogleOauthRedirectApiResponse =
  /** status 200 Oauth redirect url */ {
    oauthRedirectUrl: string;
  };
export type GetGoogleOauthRedirectApiArg = void;
export type CreateGoogleAccessTokenApiResponse =
  /** status 204 An empty response */ void;
export type CreateGoogleAccessTokenApiArg = void;
export type GetGoogleCalendarsApiResponse =
  /** status 200 Generic response */ object;
export type GetGoogleCalendarsApiArg = void;
export type GetGoogleAuthStatusApiResponse =
  /** status 200 Oath Status check */ {
    hasAccessToken: boolean;
  };
export type GetGoogleAuthStatusApiArg = void;
export type GetGoogleCredentialsApiResponse = /** status 200 Google creds */ {
  client_id: string;
};
export type GetGoogleCredentialsApiArg = void;
export const {
  useGetGoogleOauthRedirectQuery,
  useLazyGetGoogleOauthRedirectQuery,
  useCreateGoogleAccessTokenMutation,
  useGetGoogleCalendarsQuery,
  useLazyGetGoogleCalendarsQuery,
  useGetGoogleAuthStatusQuery,
  useLazyGetGoogleAuthStatusQuery,
  useGetGoogleCredentialsQuery,
  useLazyGetGoogleCredentialsQuery,
} = injectedRtkApi;
