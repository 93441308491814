import { useEffect } from "react";
import { Typography } from "@mui/material";
import { Navigation } from "../../lib";
import { logout, authSelectors } from "../../state";
import { useSelector } from "react-redux";
import { AuthPages } from "./";

export const LogoutPage = () => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        await logout();
      }
      Navigation.reload(AuthPages.login);
    })();
  }, [logout, isLoggedIn]);

  return (
    <Typography variant="h1" fontWeight={600}>
      Logging out...
    </Typography>
  );
};
