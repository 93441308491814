import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

const initialState: UIState = {
  loading: false,
  notification: undefined, //{message: "hello", variant: "success"},
  menuOpen: false,
  view: {},
  dynamicTitle: null,
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 6000;
        }
        state.notification = payload;
      },
      setMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.menuOpen = payload;
      },
      setDynamicTitle(state, { payload }: PayloadAction<DynamicTitle>) {
        state.dynamicTitle = payload;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: ReactNode | string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

interface DynamicTitle {
  path: string;
  value: ReactNode | "string";
}

export interface UIState {
  loading: boolean;
  notification?: UINotification;
  view: UIViewState;
  menuOpen: boolean;
  dynamicTitle?: DynamicTitle | null;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // vehicleSpecsDialog?: UIViewType;
}
