import { PropsOf } from "@emotion/react";
import { Icon } from "@iconscout/react-unicons/dist/icon";
import { Stack, Button, Typography } from "@mui/material";
interface Props {
  onClick: () => void;
  Icon: Icon;
  text: string;
  buttonColor?: PropsOf<typeof Button>["color"];
}
export const TextButtonWithIcon = ({
  onClick,
  Icon,
  text,
  buttonColor,
}: Props) => {
  return (
    <Stack direction={"row"} justifyContent={"center"} minWidth={"100px"}>
      <Button
        onClick={onClick}
        sx={{ display: "flex", alignItems: "flex-end" }}
        color={buttonColor || "primary"}
      >
        <Icon />
        <Typography ml={0.65} fontWeight={600} fontSize={14}>
          {text}
        </Typography>
      </Button>
    </Stack>
  );
};
