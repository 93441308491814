import { Stack } from "@mui/material";

import { SessionsTableInternal, TasksTableInternal } from "../../../components";
import { useEffect } from "react";
import { useGetStatusId, useQuery } from "../../../components/hooks";
import { useLocation } from "../../../lib";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";

const BASE_HEIGHT = "550px";

export const SessionAndTaskPage = () => {
  const { query: globalQuery } = useLocation();
  const upcomingStatus = useGetStatusId("upcoming");
  const unscheduledStatus = useGetStatusId("unscheduled");
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);

  const [_q, setQuery] = useQuery(
    "sessionFilterMyOrgs",
    "taskFilterMyOrgs",
    "sessionStatuses",
    "taskStatuses",
    "taskType",
  );

  // possible that 0 gets loaded before getStatuses are known
  const filterZeros = (arr?: string[]) =>
    (arr ?? []).filter((s) => Boolean(Number(s)));

  useEffect(() => {
    const curSessionStatus = filterZeros(globalQuery?.sessionStatuses);
    setQuery({
      sessionFilterMyOrgs: !!globalQuery?.sessionFilterMyOrgs,
      sessionStatuses: curSessionStatus?.length
        ? curSessionStatus.map(Number)
        : [upcomingStatus, unscheduledStatus],
    });
  }, [upcomingStatus, isCustomWorkUser]);

  return (
    <Stack gap={1} direction={"column"}>
      <SessionsTableInternal height={BASE_HEIGHT} />
      <TasksTableInternal height={BASE_HEIGHT} />
    </Stack>
  );
};
