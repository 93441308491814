import { IconButton, Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { ToggleActionPendingButton } from "../buttons/ToggleActionPendingButton";
import { PriorityChip, StatusChip } from "../chips";
import { DrawerHeader } from "../drawer";
import { CloseIcon, MoveToSessionIcon, TaskTypeIcon } from "../icons";
import { useGetSessionsByIdQuery } from "../../state/rtk-query/state/session";
import { skipToken } from "@reduxjs/toolkit/query";
import { StartCwTimerButton } from "../buttons";
import { castZero } from "../../lib";
interface ModalTaskHeaderProps {
  taskData: GetTaskApiResponse | undefined;
  id: number;
  orgId: number;
  isComplete: boolean;
  CompleteTaskButton: ReactNode;
  onClose: () => void;
  AvatarAndMenu: ReactNode;
  FileIcon: ReactNode;
}

export const ModalTaskHeader = ({
  taskData,
  id,
  orgId,
  isComplete,
  CompleteTaskButton,
  AvatarAndMenu,
  FileIcon,
  onClose,
}: ModalTaskHeaderProps) => {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const {
    priority,
    action_pending,
    type,
    internal_priority,
    time_spent,
    code,
    title: taskTitle,
    org_id: taskOrgId,
  } = taskData || {};
  const activeSession = taskData?.sessions?.find(
    (s) => s?.session_tasks?.is_active,
  );
  const { data: sessionInfo } = useGetSessionsByIdQuery(
    activeSession?.id && orgId ? { id: activeSession.id, orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const psd = activeSession?.pending_session_dates;

  const { booking, status_id, title } = sessionInfo ?? {};
  if (isMobile) {
    return (
      <DrawerHeader width={"100%"} gap={1} p={"16px !important"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          gap={1}
        >
          <Stack direction={"row"} justifyContent={"flex-start"} gap={1}>
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="internal"
              priority={internal_priority}
            />
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="external"
              priority={priority}
              showPriorityName
            />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {AvatarAndMenu}
            <Stack
              direction={"column"}
              height={"100%"}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
        >
          {type !== "internal_task" && !isComplete && (
            <ToggleActionPendingButton
              orgId={orgId > 0 ? orgId : taskData?.org_id}
              taskId={id}
              action_pending={action_pending}
            />
          )}
          {FileIcon}
          <TaskTypeIcon
            type={type}
            psd={psd}
            start_time={booking?.start_time}
            end_time={booking?.end_time}
            status_id={status_id}
            session_id={booking?.session_id ?? activeSession?.id}
            session_title={title}
            show_session_details
          />
          <MoveToSessionIcon taskId={id} orgId={orgId} type={type} />
          <StartCwTimerButton
            type={type}
            taskTimeSpent={time_spent}
            orgId={castZero(taskOrgId)}
            taskId={id}
            taskCode={code ?? ""}
            taskTitle={taskTitle ?? ""}
          />
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={2}
        >
          <StatusChip
            statusId={taskData?.status?.id}
            type={taskData?.type}
            dropDown
            id={id}
            orgId={orgId}
          />
          {!isComplete && CompleteTaskButton}
        </Stack>
      </DrawerHeader>
    );
  }
  return (
    <Stack minWidth={"604px"} gap={2} bgcolor={palette.grey[50]} px={4} py={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"auto"}
        minWidth={"fit-content"}
        gap={2}
      >
        <Stack direction={"row"} width={"auto"} gap={2}>
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "space-between" : "center"}
            justifyContent={isMobile ? "space-between" : "center"}
            width={"auto"}
            gap={2}
            height={isMobile ? "100%" : "auto"}
          >
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="internal"
              priority={internal_priority}
            />
            <PriorityChip
              dropDown={!isComplete}
              taskId={id}
              orgId={orgId}
              type="external"
              priority={priority}
              showPriorityName
            />
            <StatusChip
              statusId={taskData?.status?.id}
              type={taskData?.type}
              dropDown
              id={id}
              orgId={orgId}
            />
            {type !== "internal_task" && !isComplete && (
              <ToggleActionPendingButton
                orgId={orgId > 0 ? orgId : taskData?.org_id}
                taskId={id}
                action_pending={action_pending}
              />
            )}
            <Stack direction={"row"} alignItems={"center"}>
              <TaskTypeIcon
                type={type}
                psd={psd}
                start_time={booking?.start_time}
                end_time={booking?.end_time}
                status_id={status_id}
                session_id={booking?.session_id ?? activeSession?.id}
                session_title={title}
                show_session_details
              />
              <MoveToSessionIcon
                taskId={id}
                orgId={taskData?.org_id ?? orgId}
                type={type}
              />
            </Stack>
            {!isComplete && CompleteTaskButton}
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          width={"auto"}
          height={"100%"}
          alignItems={isMobile ? "flex-end" : "center"}
          gap={1}
        >
          {FileIcon}
          {AvatarAndMenu}
          <Stack
            direction={"column"}
            height={"100%"}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
