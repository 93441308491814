import { ChangeEvent, useCallback, useEffect, useState } from "react";
import SearchResultBox from "../../../layouts/main/SearchResultBox";
import {
  GlobalSearchApiResponse,
  useLazyGlobalSearchQuery,
} from "../../../state/rtk-query/state/global_search";
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Pagination,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { debounce, filterBoolean } from "../../../lib";
import {
  CustomCheckBox,
  SearchIcon,
  SortableButton,
} from "../../../components";
import { DateRange } from "@mui/lab";
import { useMobile } from "../../../themes";

import { useOrgId, useQuery } from "../../../components/hooks";
import {
  useOrgsList,
  useStatusesList,
} from "../../../components/hooks/useLists";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";

export const MAX_SEARCH_RESULTS_ON_PAGE = 10;
interface QueryProps {
  q?: string;
  category?: string[];
  status?: string[];
  dates?: string;
  page?: number;
  fuzzy?: boolean;
  openTasks?: boolean;
  orgs: number[];
}

const categories = [
  "Session",
  "Topic",
  "Task Title",
  "Task Description",
  "Comment",
];

export const SearchPage = () => {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const { statuses, getStatusByName } = useStatusesList("all_tasks");
  const isInternal = useSelector(authSelectors.isInternal);
  const { orgs: orgList } = useOrgsList(!isInternal);

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<GlobalSearchApiResponse>();
  const [query, setQuery] = useQuery<QueryProps>(
    "q",
    "category",
    "status",
    "dates",
    "page",
    "fuzzy",
    "openTasks",
    "orgs",
  );
  const { q, category, status, dates, page, fuzzy, openTasks, orgs } = query;
  const hasFilter =
    filterBoolean(status ?? []).length || fuzzy || dates || category || orgs;
  const orgId = useOrgId();
  const [globalSearch] = useLazyGlobalSearchQuery();

  const searchTerm = String(q ?? "");

  const fetchSearch = useCallback(
    debounce(async () => {
      setIsSearching(true);
      const newPage = page ?? 0;
      const fields = {
        resultType:
          category
            ?.map((f) => f?.toString().replace(" ", "_").toLowerCase())
            .filter(Boolean) ?? [],
        dateRange: dates?.split(",") ?? "",
        status: status?.map((s) => getStatusByName(s)).flat(),
      };
      if (orgId) {
        const search = await globalSearch({
          fuzzySearch: fuzzy ?? false,
          q: q ?? "",
          orgId,
          field: JSON.stringify(fields),
          orgs,
          range: [
            !isNaN(newPage) && newPage > 0
              ? (newPage - 1) * MAX_SEARCH_RESULTS_ON_PAGE
              : 0,
            MAX_SEARCH_RESULTS_ON_PAGE,
          ],
        }).unwrap();
        setSearchResults(search);
      }
      setIsSearching(false);
    }, 500),
    [orgId, q, category, status, dates, page, fuzzy, orgs],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchTerm = e.target.value;
      setSearchResults(undefined);
      if (searchTerm.trim() === "") {
        setQuery(
          {},
          "q",
          "category",
          "status",
          "dates",
          "page",
          "fuzzy",
          "orgs",
        );
      } else if (q !== searchTerm) {
        setIsSearching(true);
        setQuery({ q: searchTerm, page: 1 });
      }
      fetchSearch();
    },
    [orgId, query],
  );
  function noEmptyArray(v?: any[]) {
    if (v?.length) {
      return v;
    }
    return undefined;
  }
  const sortButton = useCallback(
    (f: number | string, c: boolean, sortable: "category" | "orgs") => {
      const values = query[sortable] as any[] | undefined;
      setQuery({
        [sortable]: c
          ? [...(values || []), f]
          : noEmptyArray(values?.filter((p) => p !== f)),
      });
    },
    [orgId, category, searchTerm, orgs, categories],
  );

  const searchWithFuzzy = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSearchResults(undefined);
      setQuery({ ...query, fuzzy: checked, page: 1 });

      fetchSearch();
    },
    [orgId, fuzzy, searchTerm],
  );

  const searchOpenTasks = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSearchResults(undefined);

      setQuery({
        category: checked
          ? categories.filter((c) => c !== "Session")
          : undefined,
        status: checked
          ? statuses
              .filter((s) => s.transition_phase !== "done")
              .map((s) => s.internal_name) ?? []
          : [],
        openTasks: checked,
        page: 1,
      });

      fetchSearch();
    },
    [orgId, openTasks, searchTerm],
  );

  const dateChange = useCallback(
    (field: DateRange<any>) => {
      // mui calendar quick action is reset
      if (field[0] === null && field[1] === null) {
        setQuery({ ...query, dates: undefined, page: 1 });
      } else {
        setQuery({
          ...query,
          dates: [
            new Date(field[0]).toLocaleDateString(),
            new Date(field[1]).toLocaleDateString(),
          ].join(","),
          page: 1,
        });
      }

      fetchSearch();
    },
    [orgId, dates, searchTerm],
  );
  const handleChangePage = useCallback(
    async (_event: React.ChangeEvent<unknown>, newPage: number) => {
      setQuery({ page: newPage });
      fetchSearch();
    },
    [orgId, page, searchTerm],
  );

  useEffect(() => {
    if (q) {
      setIsSearching(true);
      fetchSearch();
    }
  }, [orgId, query]);

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      height={"100%"}
      mt={isMobile ? 1 : 0}
    >
      <Stack gap={2}>
        {Number(searchResults?.count) > 0 ? (
          <Typography variant="h2">{`${searchResults?.count ?? 0} ${
            searchResults?.count === 1 ? "Result" : "Results"
          } for "${searchTerm}"`}</Typography>
        ) : (
          <>
            {searchTerm?.trim() !== "" && (
              <Stack p={2} gap={1}>
                <Typography fontSize={20} fontWeight={600}>
                  {isSearching ? "Searching" : "No results"} for "{searchTerm}"
                </Typography>
                {isSearching ? null : (
                  <Typography fontSize={14} variant="subtitle2">
                    Please use different keywords and try again
                  </Typography>
                )}
              </Stack>
            )}
          </>
        )}
        <Box width={isMobile ? "100%" : 550}>
          <TextField
            sx={{
              width: "100%",
              height: 36,
              borderRadius: 4,
              position: "relative",
              "& :focus": {
                backgroundColor: "#FFF",
              },
            }}
            onChange={handleSearch}
            placeholder={"Search"}
            value={searchTerm}
            inputRef={(input) => input && isMobile && input.focus()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: "1em" }} />
                </InputAdornment>
              ),
            }}
          />
          {isSearching && <LinearProgress />}
        </Box>
        {searchTerm?.trim() !== "" ? (
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <Stack
              direction={"row"}
              width={isMobile ? "100%" : 650}
              my={1}
              overflow={"auto"}
            >
              <SortableButton
                title="Type"
                onClick={(f, c) => sortButton(f, c, "category")}
                isDropDown
                items={categories}
                currentSort={category}
              />
              <SortableButton
                title="Date"
                onChange={dateChange}
                isDate
                currentSort={
                  dates?.split(",").map((d) => new Date(d)) as DateRange<any>
                }
              />
              <SortableButton
                title="Status"
                onClick={(f, c) => {
                  setQuery({
                    status: c
                      ? [...(status || []), f]
                      : (status || []).filter((p) => p !== f),
                    page: 1,
                  });
                }}
                isDropDown
                displayItems={[
                  ...new Map(statuses.map((s) => [s.internal_name, s.name])),
                ].map(([internal_name, name]) => ({
                  id: internal_name,
                  label: name,
                }))}
                currentSort={status}
              />
              {isInternal && (
                <SortableButton
                  title="Orgs"
                  onClick={(f, c) => sortButton(f, c, "orgs")}
                  isDropDown
                  displayItems={orgList.map((o) => {
                    return {
                      label: o.name,
                      id: o.id,
                    };
                  })}
                  currentSort={orgs}
                />
              )}
              <Stack
                height={"min-content"}
                alignContent={"flex-start"}
                direction={"row"}
              >
                <FormControlLabel
                  sx={{
                    height: "min-content",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    "& > span:last-child": {
                      fontSize: isMobile ? 14 : 16,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    },
                  }}
                  control={
                    <CustomCheckBox
                      onChange={searchWithFuzzy}
                      checked={Boolean(fuzzy)}
                    />
                  }
                  label={"Fuzzy search"}
                />
                <FormControlLabel
                  sx={{
                    height: "min-content",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    "& > span:last-child": {
                      fontSize: isMobile ? 14 : 16,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    },
                  }}
                  control={
                    <CustomCheckBox
                      onChange={searchOpenTasks}
                      checked={Boolean(openTasks)}
                    />
                  }
                  label={"Open tasks"}
                />
              </Stack>
            </Stack>
            {hasFilter && (
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => {
                  setQuery(
                    {},
                    "status",
                    "category",
                    "page",
                    "dates",
                    "fuzzy",
                    "orgs",
                  );
                }}
              >
                <Typography fontSize={16} fontWeight={"500"}>
                  Reset filters
                </Typography>
              </Button>
            )}
          </Stack>
        ) : null}
        <Stack direction={"column"} gap={1}>
          {searchResults?.rows.map((sr) => (
            <Box
              bgcolor={"#FFF"}
              border={`1px solid ${palette.grey[500]}`}
              borderRadius={"6px"}
              // There can be multiple comments that match under the same task, there can also be multiple with the same sum
              key={Number(sr.task_id) + `${Number(sr.comment_id)}`}
              overflow={"hidden"}
            >
              <SearchResultBox sr={sr} onSearchPage />
            </Box>
          ))}
        </Stack>
      </Stack>
      {Number(searchResults?.count) > 0 ? (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          py={isMobile ? 2 : 4}
        >
          <Pagination
            count={Math.ceil(
              Number(searchResults?.count) / MAX_SEARCH_RESULTS_ON_PAGE,
            )}
            onChange={handleChangePage}
            page={Number(page ?? 1)}
            color="primary"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "1em",
                "& .MuiSvgIcon-root": {
                  fontSize: "1em",
                },
              },
            }}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};
