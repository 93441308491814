import { Box, BoxProps, Card, CardProps, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CardStyledProps extends Omit<CardProps, "color"> {
  borderRadius?: string;
  color?: "white" | "grey" | "primary" | "secondary" | "inactive";
}

export const CardStyled = styled(Card, {
  shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "color",
})<CardStyledProps>(({ theme, borderRadius = "6px", color = "white" }) => {
  let borderColor = "",
    backgroundColor = "";

  switch (color) {
    case "white":
      borderColor = theme.palette.grey[500];
      backgroundColor = theme.palette.common.white;
      break;
    case "grey":
      borderColor = theme.palette.grey[500];
      backgroundColor = theme.palette.grey[50];
      break;
    case "primary":
      borderColor = theme.palette.primary[300] ?? theme.palette.primary.main;
      backgroundColor = theme.palette.accent[50] ?? "";
      break;
    case "secondary":
      (borderColor =
        theme.palette.secondary[300] ?? theme.palette.secondary.light),
        (backgroundColor =
          theme.palette.secondary[50] ?? theme.palette.secondary.light);
      break;
    case "inactive":
      borderColor = theme.palette.primary[100] ?? theme.palette.primary.light;
      backgroundColor = theme.palette.grey[50];
      break;
    default:
      // This may need to change
      borderColor = theme.palette.grey[500];
      backgroundColor = theme.palette.common.white;
      break;
  }

  const styles = {
    borderRadius: borderRadius,
    boxShadow: "none",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor,
    backgroundColor,
  };

  return styles;
});

interface CardHeaderStyledProps extends BoxProps {
  color?: "grey" | "inherit";
}
export const CardHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})<CardHeaderStyledProps>(({ theme, color }) => {
  let backgroundColor = "";
  switch (color) {
    case "grey":
      backgroundColor = theme.palette.grey[100];
      break;
    default:
      backgroundColor = theme.palette.common.white;
  }
  return {
    backgroundColor: backgroundColor,
    borderBottom: "1px solid",
    borderColor: theme.palette.grey[500],
    padding: "24px 16px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "17px",
  };
});
export const CardContentContainerStyled = styled(Grid)(({ theme }) => {
  return {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    alignItems: "center",
  };
});
export const CardContentItemStyled = styled(Grid)(({ theme }) => {
  return {
    flex: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 600,
      flex: 1,
    },
  };
});
export const CardContentIconStyled = styled(Box)(({ theme }) => {
  return {
    height: "40px",
    width: "40px",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
    backgroundColor: theme.palette.accent[100],
    color: theme.palette.accent[500],
  };
});
