import { api } from "../api";
export const addTagTypes = ["topics"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTopics: build.query<GetTopicsApiResponse, GetTopicsApiArg>({
        query: () => ({ url: `/topics` }),
        providesTags: ["topics"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetTopicsApiResponse = /** status 200 Topics */ {
  count: number;
  rows: {
    id: number;
    internal_name: string;
    name: string;
    description: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTopicsApiArg = void;
export const { useGetTopicsQuery, useLazyGetTopicsQuery } = injectedRtkApi;
