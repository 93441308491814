import { useImpersonateMutation } from "../../state/rtk-query/state/user";
import { useDispatch, authActions } from "../../state";
import { useEffect } from "react";
import { useLocation } from "../../lib";

export const useImpersonation = () => {
  const dispatch = useDispatch();
  const [impersonate, { data, isSuccess }] = useImpersonateMutation();
  const loc = useLocation();

  useEffect(() => {
    if (isSuccess && data) {
      const { token, user } = data;
      const { id, orgs } = user!;
      if (!id || !orgs) {
        return;
      }
      dispatch(
        authActions.impersonate(
          {
            token,
            user,
          },
          loc.fullUrl,
        ),
      );
    }
  }, [data, isSuccess]);
  return impersonate;
};
