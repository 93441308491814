import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFormBox = styled(Link)(({ theme }) => ({
  "&.MuiLink-root": {
    fontWeight: 500,
    flexDirection: "column",
    padding: "16px",
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "3px",
    gap: 1,
  },
}));
