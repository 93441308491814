import { Dialog, IconButton } from "@mui/material";
import { CloseIcon } from "..";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { Dispatch, SetStateAction } from "react";
import ReactJson from "@microlink/react-json-view";

export const ModalJSONReportView = ({
  props,
  setShowDialog,
  showDialog,
}: {
  props: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  showDialog: boolean;
}) => {
  const handleClose = () => {
    setShowDialog(false);
  };
  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="json-field"
      fullWidth
      maxWidth="md"
    >
      <IconButton
        style={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <ReactJson
        src={props.value}
        displayDataTypes={false}
        style={{ fontSize: 14, padding: 24 }}
        displayObjectSize={false}
        name="fields"
        enableClipboard={false}
      />
    </Dialog>
  );
};
