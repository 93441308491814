import { Node } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { Dispatch, SetStateAction } from "react";
import { FileExtensions } from "../../lib";
import { ArrayElement } from "../../global";
interface ImagePasteOptions {
  setAttachments?: Dispatch<SetStateAction<File[]>>;
}
interface Props {
  items?: DataTransferItemList;
  setAttachments: ImagePasteOptions["setAttachments"];
  isPaste?: boolean;
}

/**
 * Returns true if we are handling the paste event
 * Returns false if the default paste behavior should happen (regular text or link)
 */
const checkAndAddImage = ({ items, setAttachments }: Props) => {
  const files = Array.from(items ?? []);

  if (files.length) {
    const pastingOther = files.filter((item) => item.kind !== "file");
    if (pastingOther.length) return false;

    files.forEach((item) => {
      const file = item?.getAsFile();
      if (!file) return;

      const ext = `.${file.name
        ?.split(".")
        .pop()
        ?.toLowerCase()}` as ArrayElement<typeof FileExtensions>;

      if (ext && FileExtensions.includes(ext) && file && setAttachments) {
        setAttachments((old) => (old?.length ? [...old, file] : [file]));
      }
    });

    return true;
  }

  return false;
};

export const ImagesPlugin = ({ setAttachments }: ImagePasteOptions) =>
  Node.create({
    name: "imagePaste",

    addProseMirrorPlugins() {
      return [
        new Plugin({
          props: {
            handleDrop(_view, event) {
              event.stopPropagation();
              event.preventDefault();
              checkAndAddImage({
                items: event.dataTransfer?.items,
                setAttachments,
              });
            },
            handlePaste(_view, event) {
              checkAndAddImage({
                items: event.clipboardData?.items,
                setAttachments,
              });
            },
          },
        }),
      ];
    },
  });
