import { Stack, Typography, useTheme } from "@mui/material";
import { FileIcon } from "../icons";
import { AvatarGroup } from "../icons/Avatar";
import { DarkGreyTextStyled } from "../styled";
import {
  AttendeeAndAssigneeForm,
  CommentsCountDisplay,
  CustomWorkDisplay,
  InternalTaskDisplay,
  PriorityChip,
  PriorityType,
  QuickQuestionDisplay,
  SessionInfoDisplay,
  StatusChip,
  TimeSpentButton,
} from "..";
import { castZero, formatDate } from "../../lib";
import {
  useTopicsList,
  useOrgUsersList,
  useAdmireUsersList,
  useCombinedUsersList,
} from "../hooks/useLists";
import { useMemo, useState, useCallback } from "react";
import { authSelectors, useSelector } from "../../state";
import { TaskMenu } from "../task";
import { useMobile } from "../../themes";
import { BaseModal } from "../forms/BaseForm";
import { stripHtml } from "string-strip-html";
import { ToggleActionPendingButton } from "../buttons/ToggleActionPendingButton";
import { useQueryParam } from "../hooks";
interface CardTitleProps {
  id?: number;
  orgId?: number;
  code?: string;
  description?: string;
  assigned_to_user?: {
    first_name?: string;
    last_name?: string;
    email?: string;
  };
  client_assignee_user?: {
    first_name?: string;
    last_name?: string;
    email?: string;
  };
  topicId?: number;
  title?: string;
  statusId?: number;
  priority?: PriorityType<"external">;
  avatarUsers?: any[];
  type?: "task" | "internal_task" | "quick_question" | "custom_work";
  manageAttendeesClick: () => void;
  hideMenuButtons?: boolean;
  action_pending?: "admire" | "client" | null;
}

function CardTitle({
  topicId,
  title,
  code,
  statusId,
  priority,
  avatarUsers,
  id,
  orgId,
  assigned_to_user,
  description,
  client_assignee_user,
  type,
  manageAttendeesClick,
  hideMenuButtons,
  action_pending,
}: CardTitleProps) {
  const { getTopic } = useTopicsList();
  const isInternal = useSelector(authSelectors.isInternal);
  const isMobile = useMobile();

  return (
    <Stack
      width={"100%"}
      direction={isMobile ? "column-reverse" : "row"}
      spacing={2}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <DarkGreyTextStyled fontWeight={600} fontSize={16}>
        {getTopic(topicId)?.name} &middot; {title}
        {code?.trim() !== "" ? ` - [${code}]` : ""}
      </DarkGreyTextStyled>
      <Stack
        direction={"row"}
        justifyContent={isMobile ? "space-between" : "flex-end"}
        alignItems={"center"}
        gap={isMobile ? 0 : 2}
      >
        {!!priority && priority !== "normal" && (
          <PriorityChip
            type="external"
            showPriorityName
            priority={priority}
            sx={{ width: "100%" }}
          />
        )}
        {!!statusId && (
          <Stack
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <StatusChip
              type={type}
              id={id}
              orgId={orgId}
              statusId={statusId}
              sx={{ p: 0 }}
              dropDown
            />
          </Stack>
        )}
        {type !== "internal_task" && (
          <ToggleActionPendingButton
            taskId={id}
            orgId={orgId}
            action_pending={action_pending}
            justShowOne
          />
        )}
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems={"center"}
          gap={2}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!!avatarUsers && (
            <AvatarGroup
              users={avatarUsers}
              maxBeforeGrouping={isMobile ? 2 : 10}
              labelForRemaining="Watchers"
              defaultLabel="Watcher"
              setOpenModal={manageAttendeesClick}
            />
          )}
          {isInternal && !hideMenuButtons && (
            <TaskMenu
              task={{
                title: title || "",
                type,
                assigned_to_user: {
                  email: assigned_to_user?.email || "",
                  first_name: assigned_to_user?.first_name || "",
                  last_name: assigned_to_user?.last_name || "",
                },
                description,
                org_id: orgId,
                client_assignee_user,
                id,
              }}
              manageAttendeeClick={manageAttendeesClick}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export interface TaskCardProps {
  hideMenuButtons?: boolean;
  task?: {
    is_custom_work?: boolean;
    title: string;
    code?: string | null;
    id?: number;
    org_id?: number;
    status_id?: number;
    topic_id?: number;
    topic?: { id?: number; name?: string };
    status?: { id?: number };
    assigned_to?: number;
    client_assignee?: number | null;
    task_watchers?: { user_id?: number }[];
    media_count?: number;
    assigned_to_user?: {
      id?: number;
      last_name: string;
      first_name: string;
      email: string;
    };
    watchers?:
      | {
          id?: number;
          last_name: string;
          first_name: string;
          email: string;
        }[]
      | null;
    description?: string | null;
    media?: any[];
    internal_priority?: string;
    priority?: PriorityType<"external">;
    type?: "task" | "internal_task" | "quick_question" | "custom_work";
    requested_by_date?: string | null;
    created_at?: string | null;
    task_chat_count?: number;
    task_chats?: { notifications?: any[] }[];
    sessions?:
      | {
          id?: number;
          booking?: {
            id?: number;
            start_time?: string;
            duration?: number;
            end_time?: string;
          };
        }[]
      | null;
    action_pending?: "admire" | "client" | null;
    time_spent?: number | null;
  };
}
export const TaskCard = ({ task, hideMenuButtons }: TaskCardProps) => {
  const { palette } = useTheme();
  if (!task) return null;
  const {
    title,
    id,
    code,
    status_id,
    org_id,
    topic_id,
    topic,
    status,
    description,
    media,
    internal_priority,
    type,
    requested_by_date,
    created_at,
    task_watchers,
    assigned_to,
    client_assignee,
    priority,
    media_count,
    action_pending,
    time_spent,
  } = task;
  const { getOrgUser } = useOrgUsersList(org_id!);
  const { getAdmireUser } = useAdmireUsersList();
  const { getUser } = useCombinedUsersList(org_id!);
  const clientAssignee = getUser(client_assignee);
  const assignee = getUser(assigned_to!);
  const isMobile = useMobile();
  const users = useMemo(() => {
    return [
      assignee && { ...assignee, label: "Assignee" },
      clientAssignee && { ...clientAssignee, label: "Client Assignee" },
      ...(task_watchers || []).map((tw) => getUser(tw.user_id!)),
    ].filter(Boolean);
  }, [getOrgUser, getAdmireUser, clientAssignee, assignee, task_watchers]);
  const [openModal, setOpenModal] = useState(false);
  const modalClick = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);
  const [_, openTask] = useQueryParam("task");
  const openClick = useCallback(() => {
    if (id) openTask(id);
  }, [id]);
  return (
    <>
      <Stack gap={isMobile ? 2 : 1} onClick={openClick} p={"18px"}>
        <CardTitle
          id={id}
          orgId={org_id}
          code={code ?? ""}
          topicId={topic_id || topic?.id}
          title={title}
          statusId={status_id || status?.id}
          avatarUsers={users}
          type={type}
          priority={priority}
          assigned_to_user={assignee}
          client_assignee_user={clientAssignee}
          description={description || ""}
          manageAttendeesClick={modalClick}
          hideMenuButtons={hideMenuButtons}
          action_pending={action_pending}
        />
        {!isMobile && (
          <DarkGreyTextStyled
            fontSize={14}
            lineHeight={"20px"}
            maxHeight={"40px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {stripHtml(description || "").result}
          </DarkGreyTextStyled>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            gap={2}
          >
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Typography
                fontSize={14}
                fontWeight={500}
                color={palette.grey[900]}
              >
                <FileIcon
                  color={palette.primary.main}
                  size={"18px"}
                  style={{ marginRight: 2, marginBottom: "-3px" }}
                />{" "}
                {`${media?.length ?? media_count ?? 0} Attachments`}
              </Typography>
              <CommentsCountDisplay task={task} />
            </Stack>
            <QuickQuestionDisplay
              shouldDisplay={type === "quick_question"}
              internal_priority={internal_priority as PriorityType<"internal">}
            />
            <CustomWorkDisplay
              shouldDisplay={type === "custom_work"}
              requestedByDate={requested_by_date || ""}
            />
            <InternalTaskDisplay
              shouldDisplay={type === "internal_task"}
              requestedByDate={requested_by_date || ""}
            />
            {type === "custom_work" && id ? (
              <TimeSpentButton
                id={id}
                orgId={castZero(org_id)}
                time_spent={castZero(time_spent)}
                hideModal
              />
            ) : null}
            <SessionInfoDisplay task={task} />
          </Stack>
          {!!created_at && !isMobile && (
            <Typography
              fontSize={14}
              fontWeight={500}
              color={palette.grey[700]}
            >{`Created on ${formatDate(new Date(created_at))}`}</Typography>
          )}
        </Stack>
      </Stack>
      <BaseModal open={openModal} onClose={modalClick} title="Manage people">
        <AttendeeAndAssigneeForm onClose={modalClick} task={task as any} />
      </BaseModal>
    </>
  );
};
