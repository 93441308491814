import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { MenuItemStyled } from "./Drawer.styles";
import {
  SignOutIcon,
  SettingsIcon,
  UserIcon,
  Tooltip,
} from "../../../components";
import { authSelectors, clientSelectors, useSelector } from "../../../state";
import { UserProfileAvatar } from "../../../components/icons/Avatar";

const ProfileMenu = () => {
  const { palette } = useTheme();
  const user = useSelector(authSelectors.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isInternal = useSelector(authSelectors.isInternal);
  const org = useSelector(clientSelectors.currentOrg);
  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const isExternalAdmin = useSelector(authSelectors.isExternalAdmin);
  return (
    <>
      <Tooltip title={"Profile"}>
        <MenuItemStyled
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          selected={Boolean(anchorEl)}
        >
          <ListItemIcon>
            <UserProfileAvatar badgeProps={{ height: 14, width: 14 }} />
          </ListItemIcon>
        </MenuItemStyled>
      </Tooltip>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 4,
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack
          direction={"column"}
          mx={2}
          my={1}
          gap={1}
          justifyContent={"center"}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <UserProfileAvatar
              sx={{ height: 35, width: 35 }}
              textProps={{ fontSize: 16 }}
            />
            <Stack direction={"column"} width={"100%"} ml={1}>
              <Typography fontSize={14} fontWeight={600}>
                {user?.first_name} {user?.last_name}
              </Typography>
              {isExternalAdmin ? (
                <Typography variant="subtitle2">Admin</Typography>
              ) : null}
            </Stack>
          </Stack>
          {org ? (
            <Stack direction={"row"} alignItems={"flex-start"}>
              <Typography variant="subtitle2">{org.name}</Typography>
            </Stack>
          ) : null}
          <Stack direction={"row"} alignItems={"flex-start"}>
            <Typography variant="subtitle2">{user?.email ?? ""}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <MenuItem onClick={handleClose} component={Link} to="/profile">
          <UserIcon color={palette.grey[700]} />
          <Typography ml={2} fontWeight={500}>
            My profile
          </Typography>
        </MenuItem>
        {!isInternal ? (
          <MenuItem onClick={handleClose} component={Link} to="/settings">
            <SettingsIcon color={palette.grey[700]} />
            <Typography ml={2} fontWeight={500}>
              Account Settings
            </Typography>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleClose} component={Link} to="/auth/logout">
          <SignOutIcon color={palette.grey[700]} />{" "}
          <Typography ml={2} fontWeight={500}>
            Log out
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
