import { SxProps, Stack, Typography, Theme } from "@mui/material";
import { UserAvatar } from "./Avatar";

interface NameWithAvatarProps {
  sx?: SxProps<Theme>;
  badgeStyles?: SxProps<Theme>;
  user?: {
    [key: string]: any;
    id?: number;
    first_name?: string;
    last_name?: string;
    email?: string;
  };
}
export const NameWithAvatar = ({
  user,
  sx,
  badgeStyles,
}: NameWithAvatarProps) => {
  if (!user) return null;
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <UserAvatar
        first_name={user?.first_name || ""}
        last_name={
          user?.last_name || (user.first_name || !user.email ? "" : user.email)
        }
        id={user.id}
        sx={{ mx: 1, ...sx }}
        textProps={{ fontSize: 12 }}
        isGroup
        badgeStyles={{ right: 2, ...badgeStyles }}
      />
      <Typography fontWeight={500}>
        {`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
      </Typography>
    </Stack>
  );
};
