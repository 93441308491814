import { api } from "../api";
export const addTagTypes = ["reports", "tasks", "sessions", "booking"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getReports: build.query<GetReportsApiResponse, GetReportsApiArg>({
        query: (queryArg) => ({
          url: `/reports`,
          params: { include_dash_reports: queryArg.includeDashReports },
        }),
        providesTags: ["reports"],
      }),
      createReport: build.mutation<CreateReportApiResponse, CreateReportApiArg>(
        {
          query: (queryArg) => ({
            url: `/reports`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["reports"],
        },
      ),
      getTablesAndColumns: build.query<
        GetTablesAndColumnsApiResponse,
        GetTablesAndColumnsApiArg
      >({
        query: () => ({ url: `/reports/get-tables-and-columns` }),
        providesTags: ["reports"],
      }),
      getMyDashboardReports: build.query<
        GetMyDashboardReportsApiResponse,
        GetMyDashboardReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/reports/my-dash`,
          params: { tab: queryArg.tab },
        }),
        providesTags: ["reports"],
      }),
      updateMyDashboardReports: build.mutation<
        UpdateMyDashboardReportsApiResponse,
        UpdateMyDashboardReportsApiArg
      >({
        query: (queryArg) => ({
          url: `/reports/my-dash`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["reports"],
      }),
      addReportToMyDashboard: build.mutation<
        AddReportToMyDashboardApiResponse,
        AddReportToMyDashboardApiArg
      >({
        query: (queryArg) => ({
          url: `/reports/my-dash/${queryArg.id}`,
          method: "POST",
          params: { tab: queryArg.tab },
        }),
        invalidatesTags: ["reports"],
      }),
      getReportById: build.query<GetReportByIdApiResponse, GetReportByIdApiArg>(
        {
          query: (queryArg) => ({
            url: `/reports/${queryArg.id}`,
            params: {
              sort: queryArg.sort,
              field: queryArg.field,
              filter: queryArg.filter,
              range: queryArg.range,
              linkType: queryArg.linkType,
              q: queryArg.q,
            },
          }),
          providesTags: ["reports", "tasks", "sessions", "booking"],
        },
      ),
      updateReport: build.mutation<UpdateReportApiResponse, UpdateReportApiArg>(
        {
          query: (queryArg) => ({
            url: `/reports/${queryArg.id}`,
            method: "PUT",
            body: queryArg.body,
          }),
          invalidatesTags: ["reports"],
        },
      ),
      deleteReport: build.mutation<DeleteReportApiResponse, DeleteReportApiArg>(
        {
          query: (queryArg) => ({
            url: `/reports/${queryArg.id}`,
            method: "DELETE",
          }),
          invalidatesTags: ["reports"],
        },
      ),
      getReportInfoById: build.query<
        GetReportInfoByIdApiResponse,
        GetReportInfoByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/reports/${queryArg.id}/info`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["reports", "tasks", "sessions", "booking"],
      }),
      runReportAction: build.mutation<
        RunReportActionApiResponse,
        RunReportActionApiArg
      >({
        query: (queryArg) => ({
          url: `/reports/${queryArg.id}/action/${queryArg.reportActionId}`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["reports"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetReportsApiResponse = /** status 200 Reports */ {
  count: number;
  rows: {
    id: number;
    name: string;
    report_permissions: {
      role_id?: number;
    }[];
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetReportsApiArg = {
  /** Should include all reports including those marked as dashboard only */
  includeDashReports: boolean;
};
export type CreateReportApiResponse = /** status 204 An empty response */ void;
export type CreateReportApiArg = {
  /** role */
  body: {
    name: string;
    dash_only: boolean;
    danger_threshold?: number;
    sql?: string | null;
    aggregate_sql?: string | null;
    roles: number[];
  };
};
export type GetTablesAndColumnsApiResponse =
  /** status 200 list of our dbs tables and columns */ {
    table_name: string;
    columns: string[];
  }[];
export type GetTablesAndColumnsApiArg = void;
export type GetMyDashboardReportsApiResponse = /** status 200 Reports */ {
  count: number;
  rows: {
    id: number;
    name: string;
    internal_name?: string;
    agg_count: number;
    danger_threshold?: number | null;
    order?: number;
    tab?: "overview" | "tasks" | "sessions" | "consultants" | "organizations";
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetMyDashboardReportsApiArg = {
  /** report tab type */
  tab: "overview" | "tasks" | "sessions" | "consultants" | "organizations";
};
export type UpdateMyDashboardReportsApiResponse =
  /** status 204 An empty response */ void;
export type UpdateMyDashboardReportsApiArg = {
  /** update order of dashboard reports */
  body: {
    list: {
      id: number;
      order: number;
    }[];
    tab: "overview" | "tasks" | "sessions" | "consultants" | "organizations";
  };
};
export type AddReportToMyDashboardApiResponse =
  /** status 204 An empty response */ void;
export type AddReportToMyDashboardApiArg = {
  /** record id */
  id: number;
  /** report tab type */
  tab: "overview" | "tasks" | "sessions" | "consultants" | "organizations";
};
export type GetReportByIdApiResponse = /** status 200 Show single Report */ {
  name: string;
  count?: number;
  roles: number[];
  data?: object[];
  default_filter?: {
    items?: {
      field?: string;
      operator?: string;
      value?: string;
      type?: "days" | "months" | "number" | "string";
    }[];
  } | null;
  actions?:
    | {
        id: number;
        report_id: number;
        action?: string;
        action_label: string;
        report_arg_key: string | null;
        is_bulk: boolean;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[]
    | null;
};
export type GetReportByIdApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** record id */
  id: number;
};
export type UpdateReportApiResponse = /** status 204 An empty response */ void;
export type UpdateReportApiArg = {
  /** record id */
  id: number;
  /** role */
  body: {
    name: string;
    dash_only: boolean;
    danger_threshold?: number;
    sql?: string | null;
    aggregate_sql?: string | null;
    roles: number[];
  };
};
export type DeleteReportApiResponse = /** status 204 An empty response */ void;
export type DeleteReportApiArg = {
  /** record id */
  id: number;
};
export type GetReportInfoByIdApiResponse =
  /** status 200 Information about a report  */ {
    data?: {
      id?: number;
      name?: string;
      sql?: string;
      default_filter?: {
        [key: string]: any;
      } | null;
      dashboard_only?: boolean;
      aggregate_sql?: string | null;
      cache_time?: number | null;
      danger_threshold?: number | null;
      internal_name?: string;
      roles?: any;
    };
  };
export type GetReportInfoByIdApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** record id */
  id: number;
};
export type RunReportActionApiResponse =
  /** status 204 An empty response */ void;
export type RunReportActionApiArg = {
  /** record id */
  id: number;
  /** report action id */
  reportActionId: number;
  /** run report action request body */
  body: {
    arg?: string | string[];
  };
};
export const {
  useGetReportsQuery,
  useLazyGetReportsQuery,
  useCreateReportMutation,
  useGetTablesAndColumnsQuery,
  useLazyGetTablesAndColumnsQuery,
  useGetMyDashboardReportsQuery,
  useLazyGetMyDashboardReportsQuery,
  useUpdateMyDashboardReportsMutation,
  useAddReportToMyDashboardMutation,
  useGetReportByIdQuery,
  useLazyGetReportByIdQuery,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useGetReportInfoByIdQuery,
  useLazyGetReportInfoByIdQuery,
  useRunReportActionMutation,
} = injectedRtkApi;
