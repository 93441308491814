import { useDropzone } from "react-dropzone";
import { Paper, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode, useEffect } from "react";
const ft = "any";
interface Props {
  filesCB: (files: File[]) => void;
  fileType?: string;
  innerText?: ReactNode | string;
  sx?: SxProps<Theme>;
}
export const PaperDropzone = ({
  filesCB,
  fileType = ft,
  innerText,
  sx,
}: Props) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const { ref, ...rootProps } = getRootProps();
  useEffect(() => {
    filesCB(acceptedFiles);
  }, [acceptedFiles]);
  const files = acceptedFiles.map((file: any) => (
    <Typography key={file?.path} variant="subtitle1">
      {file?.path}
    </Typography>
  ));
  return (
    <>
      <Paper
        sx={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...sx,
        }}
        ref={ref}
        {...rootProps}
      >
        <input {...getInputProps()} />
        <p>
          {innerText ||
            `Drag 'n' drop ${fileType} file here, or click to select files`}
        </p>
      </Paper>
      {files}
    </>
  );
};
