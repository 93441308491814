import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  UpcomingSessionsSideBar,
  ServerDataGrid,
  TasksTableInternal,
} from "../../../components";
import { createPortal } from "react-dom";
import { authSelectors, actions } from "../../../state";
import { useMobile, useTablet } from "../../../themes";
import {
  HomepageContainerStyled,
  HomepageSectionStyled,
} from "../shared/HomePage.styles";
import { Link as RouterLink } from "react-router-dom";
import { useQuery, useRtkQueryParams } from "../../../components/hooks";
import { orgInfoColumns } from "../../../columns";
import { GetMyOrgsOverviewApiResponse } from "../../../state/rtk-query/state/organization";
import { MainPages } from "..";
import { Navigation } from "../../../lib";
import { TaskStats } from "../../../components/misc";
import { GetTaskApiResponse } from "../../../state/rtk-query/state/tasks";
const { useGetMyOrgsOverviewQuery } = actions;

interface QueryParams {
  view?: "org" | "task";
  taskType?: GetTaskApiResponse["type"][];
  taskAssignee?: number[];
  dash_action: string;
}
export const ConsultantDashboard = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  const { palette } = useTheme();
  const userId = useSelector(authSelectors.userId);
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const pages = MainPages(isInternal);
  const [params, setParams] = useRtkQueryParams();
  const [portalContainer, sePortalContainer] = useState<HTMLElement | null>(
    null,
  );
  const [query, setQuery] = useQuery<QueryParams>(
    "view",
    "taskType",
    "taskAssignee",
    "dash_action",
  );

  const { currentData: orgs, isFetching } = useGetMyOrgsOverviewQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const rowClick = useCallback(
    ({ row }: { row: GetMyOrgsOverviewApiResponse["rows"][number] }) => {
      Navigation.go(pages.organization, { params: { id: row.id } });
    },
    [],
  );

  useEffect(() => {
    if (!query.view) {
      if (isCW) {
        setQuery({
          view: "task",
          taskType: ["custom_work"],
          taskAssignee: [userId],
          dash_action: "mine",
        });
      } else {
        setQuery({ view: "org" });
      }
    }
    if (!portalContainer) {
      const container = document.getElementById("homepage-container");
      if (container) sePortalContainer(container);
    }
  }, []);

  const cols = orgInfoColumns(isMobile);
  const OrgInfoGrid = useMemo(
    () => (
      <Stack
        sx={{
          minHeight: isSmallerThanPc ? "auto" : "100%",
          width: "100%",
          overflow: "hidden",
          mb: 2,
          borderRadius: "6px",
          boxShadow: "none",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: palette.grey[500],
          backgroundColor: "#FFF",
        }}
      >
        <Stack
          width={"100%"}
          direction={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          gap={1}
          p={2}
        >
          <Typography variant="h4">MY ORGANIZATIONS</Typography>
          <Link component={RouterLink} to="/organizations">
            View all organizations
          </Link>
        </Stack>
        <ServerDataGrid
          toolbarProps={{ hideToolbar: true }}
          rowCount={orgs?.count || 0}
          isFetching={isFetching}
          rows={orgs?.rows ?? []}
          columns={cols}
          setQueryParams={setParams}
          currentParams={params}
          props={{
            onRowClick: rowClick,
            density: "compact",
            sx: {
              ".MuiDataGrid-virtualScroller": {
                height: "100%",
                overflow: "auto",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                textTransform: "uppercase",
              },
              ".MuiDataGrid-row": {
                cursor: "pointer",
              },
            },
          }}
        />
      </Stack>
    ),
    [orgs, cols, params, isFetching, setParams, rowClick, isSmallerThanPc],
  );

  return (
    <Stack
      direction={isSmallerThanPc ? "column" : "row"}
      pr={0}
      overflow={"hidden"}
      flexGrow={1}
    >
      <HomepageContainerStyled
        id="homepage-container"
        sx={{
          minHeight: isSmallerThanPc ? "100%" : 0,
          flexGrow: 1,
          bgcolor: palette.grey[100],
          borderColor: palette.grey[600],
          maxWidth: "100%",
          overflow: "auto",
        }}
        fullScreen={isCW}
      >
        <HomepageSectionStyled
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack
            direction={isSmallerThanPc ? "column" : "row"}
            justifyContent={"space-between"}
            marginBottom={"1.5vw"}
            gap={4}
            width="100%"
          >
            <TaskStats orgCount={orgs?.count} isConsultantDash />
          </Stack>
          {!isSmallerThanPc && (
            <Box height={"100%"}>
              {query.view === "task" || isCW ? (
                <TasksTableInternal height="100%" isPageSubSection />
              ) : (
                OrgInfoGrid
              )}
            </Box>
          )}
        </HomepageSectionStyled>
      </HomepageContainerStyled>
      {!isSmallerThanPc && !isCW && <UpcomingSessionsSideBar />}
      {portalContainer &&
        isSmallerThanPc &&
        createPortal(
          <Stack width={"100%"} px={1}>
            {!isCW && <UpcomingSessionsSideBar />}
            {query.view === "task" || isCW ? (
              <TasksTableInternal height="100%" isPageSubSection />
            ) : (
              OrgInfoGrid
            )}
          </Stack>,
          portalContainer,
        )}
    </Stack>
  );
};
