import { deepCopy, yup } from "../../lib";
import {
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation,
} from "../../state/rtk-query/state/announcements";
import { useGetOrgsListQuery } from "../../state/rtk-query/state/organization";
import { useMobile } from "../../themes";
import { ConfirmDeleteDialog } from "../tables";
import { BaseForm } from "./BaseForm";
import { GetAnnouncementsApiResponse } from "../../state/rtk-query/state/announcements";
import { useCallback, useMemo } from "react";
import {
  DatePickerField,
  FormContainer,
  FormItem,
  InputField,
  SelectField,
  TopLabel,
  isValidDate,
} from "./FormFields";

const validationSchema = yup.object({
  announcement: yup.string().required("Announcement is required"),
  dateRange: yup.array().test({
    message: "Start date and end date are required",
    test: (arr: any) => {
      return arr?.filter(isValidDate).length === 2;
    },
  }),
  button_link: yup
    .string()
    .transform((v) => (v.includes("http") ? v : "http://" + v))
    .url("Invalid URL"),
  button: yup.string().test({
    message: "Button is required with button link",
    test: (value, { parent }) => {
      return !parent.button_link || !!value;
    },
  }),
  orgs: yup
    .array(yup.number().required())
    .min(1, "Select at least 1 org or All")
    .required(),
});
interface Props {
  onClose: () => void;
  editAnnouncement?: GetAnnouncementsApiResponse["rows"][0];
}

export const AddAnnouncementForm = ({ onClose, editAnnouncement }: Props) => {
  const isMobile = useMobile();

  const {
    id,
    announcement,
    end_date,
    start_date,
    is_global,
    type,
    button,
    button_link,
    orgs,
  } = editAnnouncement ?? {};
  const { data: orgsList } = useGetOrgsListQuery();

  const initialValues = {
    announcement: announcement || "",
    dateRange: [
      start_date ? new Date(start_date) : null,
      end_date ? new Date(end_date) : null,
    ] as [Date | null, Date | null],
    type: type || "informational",
    button: button || "",
    button_link: button_link || "",
    orgs: (is_global ? [0] : orgs?.map((o) => o.id)) || [],
  };

  const [submitAnnouncement] = useCreateAnnouncementMutation();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  const handleSubmit = async ({
    announcement,
    button,
    button_link,
    dateRange,
    orgs,
    type,
  }: typeof initialValues) => {
    const is_global = orgs[0] === 0;
    const body = deepCopy({
      announcement,
      button,
      button_link,
      start_date: dateRange[0],
      end_date: dateRange[1],
      orgs: is_global ? [] : orgs,
      is_global,
      type,
    });
    if (editAnnouncement?.id) {
      updateAnnouncement({ body, id: editAnnouncement.id });
    } else {
      submitAnnouncement({ body });
    }
    return onClose();
  };

  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      enableReinitialize
      LeftSideButton={
        id ? (
          <ConfirmDeleteDialog
            titleText="Delete"
            buttonText="Delete"
            buttonColor="primary"
            handleConfirm={() => {
              deleteAnnouncement({ id });
              onClose();
            }}
          />
        ) : undefined
      }
    >
      {({ setFieldValue }) => {
        const orgsOnChange = useCallback((e: any, c: any) => {
          const clicked = c.props.value;
          const orgs = e.target.value as number[];
          if (orgs.includes(0)) {
            if (clicked === 0) setFieldValue("orgs", [0]);
            else setFieldValue("orgs", [clicked]);
          } else setFieldValue("orgs", orgs);
        }, []);

        const orgsMenuItems = useMemo(() => {
          return [{ id: 0, name: "All" }, ...(orgsList || [])];
        }, [orgsList]);
        return (
          <FormContainer>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Applicable for">
                <SelectField
                  name="orgs"
                  items={orgsMenuItems}
                  multiple
                  onChange={orgsOnChange}
                />
              </TopLabel>
              <TopLabel label="Date range">
                <DatePickerField
                  name="dateRange"
                  disablePast={editAnnouncement ? false : true}
                />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Button text">
                <InputField name="button" />
              </TopLabel>
              <TopLabel label="Button URL">
                <InputField name="button_link" />
              </TopLabel>
            </FormItem>
            <FormItem flexDirection={isMobile ? "column" : "row"}>
              <TopLabel label="Announcement type">
                <SelectField
                  name="type"
                  items={[
                    { id: "informational", name: "Informational" },
                    { id: "alert", name: "Alert" },
                  ]}
                />
              </TopLabel>
            </FormItem>
            <FormItem>
              <TopLabel label="Announcement">
                <InputField name="announcement" multiline rows={3} />
              </TopLabel>
            </FormItem>
          </FormContainer>
        );
      }}
    </BaseForm>
  );
};
