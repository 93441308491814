import { api } from "../api";
export const addTagTypes = ["slack"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getSlackChannels: build.query<
        GetSlackChannelsApiResponse,
        GetSlackChannelsApiArg
      >({
        query: (queryArg) => ({
          url: `/slack-channels`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["slack"],
      }),
      getSlackChannel: build.query<
        GetSlackChannelApiResponse,
        GetSlackChannelApiArg
      >({
        query: (queryArg) => ({ url: `/slack-channel/${queryArg.id}` }),
        providesTags: ["slack"],
      }),
      getSlackUsers: build.query<GetSlackUsersApiResponse, GetSlackUsersApiArg>(
        {
          query: (queryArg) => ({
            url: `/slack-users`,
            params: {
              sort: queryArg.sort,
              field: queryArg.field,
              filter: queryArg.filter,
              range: queryArg.range,
              linkType: queryArg.linkType,
              q: queryArg.q,
            },
          }),
          providesTags: ["slack"],
        },
      ),
      getSlackUser: build.query<GetSlackUserApiResponse, GetSlackUserApiArg>({
        query: (queryArg) => ({ url: `/slack-user/${queryArg.id}` }),
        providesTags: ["slack"],
      }),
      syncSlackChannels: build.query<
        SyncSlackChannelsApiResponse,
        SyncSlackChannelsApiArg
      >({
        query: (queryArg) => ({
          url: `/slack/sync`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["slack"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetSlackChannelsApiResponse = /** status 200 SlackChannels */ {
  count: number;
  rows: {
    id: number;
    slack_channel_id: string;
    name: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetSlackChannelsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export type GetSlackChannelApiResponse =
  /** status 200 Show single SlackChannel */ {
    id: number;
    slack_channel_id: string;
    name: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  };
export type GetSlackChannelApiArg = {
  /** record id */
  id: number;
};
export type GetSlackUsersApiResponse = /** status 200 SlackUsers */ {
  count: number;
  rows: {
    id: number;
    slack_user_id?: string;
    real_name: string;
    email: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetSlackUsersApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export type GetSlackUserApiResponse = /** status 200 Show single SlackUser */ {
  id: number;
  slack_user_id?: string;
  real_name: string;
  email: string;
  created_at?: string | null;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type GetSlackUserApiArg = {
  /** record id */
  id: number;
};
export type SyncSlackChannelsApiResponse =
  /** status 204 An empty response */ void;
export type SyncSlackChannelsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export const {
  useGetSlackChannelsQuery,
  useLazyGetSlackChannelsQuery,
  useGetSlackChannelQuery,
  useLazyGetSlackChannelQuery,
  useGetSlackUsersQuery,
  useLazyGetSlackUsersQuery,
  useGetSlackUserQuery,
  useLazyGetSlackUserQuery,
  useSyncSlackChannelsQuery,
  useLazySyncSlackChannelsQuery,
} = injectedRtkApi;
