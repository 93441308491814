import { LinearProgress, Typography } from "@mui/material";
import {
  createPasswordLabel,
  passwordFeedback,
  passwordStrength,
  linearBarColor,
} from "../../lib";

interface Props {
  password: string;
}

export const PasswordMeter = ({ password }: Props) => {
  return (
    <>
      <LinearProgress
        sx={{ borderRadius: "1px", ml: "2px", mr: "2px" }}
        variant="determinate"
        value={passwordStrength(password) * 25 || 0}
        color={linearBarColor(passwordStrength(password))}
      />
      {!!password && (
        <Typography variant="subtitle1" sx={{ ml: 1, fontSize: 12 }}>
          {createPasswordLabel(passwordStrength(password))}{" "}
          {passwordFeedback(password)}
        </Typography>
      )}
    </>
  );
};
