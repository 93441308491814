import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Popper,
  Stack,
  useTheme,
} from "@mui/material";
import { Editor, SingleCommands } from "@tiptap/react";
import {
  bold,
  bullet_list,
  italic,
  link,
  numbered_list,
  strikethrough,
  underline,
} from "../../icons";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  MouseEvent as ReactMouseEvent,
} from "react";
import { UploadButton } from "../../buttons";
import { FormContainer, InputField, TopLabel } from "../../forms/FormFields";
import { CustomCheckBox } from "../../styled";
import { Form, Formik } from "formik";

/**
 * typescript gives a lot of issues if there are nested functions that require arguments
 * so we first convert all those with args to be type `never`
 * then we filter out the `nevers` see https://stackoverflow.com/a/69852402 for how that works
 */

type FunctionsWithoutArguments = {
  [K in keyof SingleCommands]: SingleCommands[K] extends (
    ...args: infer Args
  ) => any
    ? Args["length"] extends 0
      ? SingleCommands[K]
      : never
    : never;
};
type OmitNever<T> = { [K in keyof T as T[K] extends never ? never : K]: T[K] };

interface ButtonProps {
  src: string;
  onClick: (event?: ReactMouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
}
const EditorMenuButton = ({ src, onClick, isActive = false }: ButtonProps) => {
  const { palette } = useTheme();

  return (
    <IconButton
      onClick={onClick}
      size="small"
      className="editorHeader"
      sx={{
        color: palette.grey[700],
        backgroundColor: isActive ? palette.grey[500] : palette.grey[200],
        boxShadow: "none",
        borderRadius: "4px",
        "& > img": {
          filter: isActive ? "" : "invert(0.6)",
        },
      }}
    >
      <img src={src} alt={src.split("/")?.pop()?.replace(".svg", "") || ""} />
    </IconButton>
  );
};

interface Props {
  editor: Editor | null;
  showUpload?:
    | {
        taskId: number;
        setAttachments: Dispatch<SetStateAction<File[]>>;
      }
    | false;
}

const initialValues = {
  title: "",
  target: "",
  open_new_tab: true,
};
export const CustomEditorHeader = ({ editor, showUpload = false }: Props) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const baseCommands = (
    chainableFn?: keyof OmitNever<FunctionsWithoutArguments>,
  ) => {
    const chainEditor = editor?.chain().focus();
    if (!chainEditor || !chainableFn || !editor) return;

    return chainEditor[chainableFn]().run();
  };

  const openLinkPopper = useCallback(
    (event?: ReactMouseEvent<HTMLButtonElement>) => {
      setAnchorEl(
        anchorEl ? null : event ? event?.currentTarget ?? null : null,
      );
    },
    [anchorEl],
  );

  const onClickAway = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (anchorEl !== e.target && !anchorEl?.contains(e.target as Node)) {
        setAnchorEl(null);
      }
    },
    [anchorEl],
  );
  const setLink = useCallback(
    (values: typeof initialValues) => {
      if (!editor) return;

      const url = values.target;
      if (url === null) {
        return;
      }
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: url,
          target: values.open_new_tab ? "_blank" : "_self",
          rel: "noreferrer",
        })
        .command(({ tr }) => {
          tr.insertText(values.title);
          return true;
        })
        .run();

      setAnchorEl(null);
    },
    [anchorEl, editor],
  );
  const open = Boolean(anchorEl);
  const id = open ? "link-popper" : undefined;

  return (
    <Stack
      bgcolor={palette.grey[200]}
      direction={"row"}
      gap={1.5}
      px={2}
      py={1}
      className="editorHeader"
      overflow={"auto"}
    >
      <EditorMenuButton
        src={bold}
        onClick={() => baseCommands("toggleBold")}
        isActive={!!editor?.isActive("bold")}
      />
      <EditorMenuButton
        src={italic}
        onClick={() => baseCommands("toggleItalic")}
        isActive={!!editor?.isActive("italic")}
      />
      <EditorMenuButton
        src={underline}
        onClick={() => baseCommands("toggleUnderline")}
        isActive={!!editor?.isActive("underline")}
      />
      <EditorMenuButton
        src={strikethrough}
        onClick={() => baseCommands("toggleStrike")}
        isActive={!!editor?.isActive("strike")}
      />
      <EditorMenuButton
        src={bullet_list}
        onClick={() => baseCommands("toggleBulletList")}
        isActive={editor?.isActive("bulletList")}
      />
      <EditorMenuButton
        src={numbered_list}
        onClick={() => baseCommands("toggleOrderedList")}
        isActive={!!editor?.isActive("orderedList")}
      />
      <EditorMenuButton
        src={link}
        onClick={openLinkPopper}
        isActive={editor?.isActive("link")}
        aria-describedby={id}
      />
      <ClickAwayListener onClickAway={onClickAway}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={(theme) => ({
            zIndex: theme.zIndex.modal + 2,
            width: "12%",
            bgcolor: "#FFF",
            border: `1px solid ${palette.grey[300]}`,
            p: 2,
            borderRadius: "4px",
          })}
          placement="bottom"
        >
          <Formik initialValues={initialValues} onSubmit={setLink}>
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <FormContainer>
                    <TopLabel label="Link title">
                      <InputField name="title" />
                    </TopLabel>
                    <TopLabel label="Link target">
                      <InputField name="target" />
                    </TopLabel>
                    <FormControlLabel
                      control={
                        <CustomCheckBox
                          onChange={(_, c) => setFieldValue("open_new_tab", c)}
                          checked={values.open_new_tab}
                          props={{
                            sx: {
                              width: "18px !important",
                              height: "20px !important",
                              ml: 1,
                            },
                          }}
                        />
                      }
                      label={
                        <Box component={"span"} ml={1} fontSize={12}>
                          {"Open link in new window"}
                        </Box>
                      }
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      width={"100%"}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          values.target.trim() === "" ||
                          values?.title.trim() === ""
                        }
                      >
                        Add link
                      </Button>
                    </Stack>
                  </FormContainer>
                </Form>
              );
            }}
          </Formik>
        </Popper>
      </ClickAwayListener>
      {showUpload && (
        <UploadButton
          taskId={showUpload.taskId}
          isInline
          setAttachments={showUpload.setAttachments}
        />
      )}
    </Stack>
  );
};
