import { Stack, Typography, useTheme, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import { DarkGreyTextStyled } from "../styled";
import { Navigation, isDate, isStringifiedObj } from "../../lib";
import { stripHtml } from "string-strip-html";
import ReactJson from "@microlink/react-json-view";
type CreatedByUser = {
  first_name: string;
  last_name: string;
};
export type ActivityCardProps = {
  title: string;
  created_at: string;
  entity: string;
  entity_id: number;
  value?: string | null;
  old_value?: string | null;
  created_by_user: CreatedByUser;
  useArrow?: boolean;
  px?: string | number;
  shouldBeLink?: boolean;
};
export function ActivityCard({
  created_at,
  created_by_user,
  title,
  old_value,
  value,
  useArrow,
  entity,
  entity_id,
  px,
  shouldBeLink,
}: ActivityCardProps) {
  const { palette } = useTheme();
  const Activity = (
    <Stack px={px ?? "18px"}>
      <DarkGreyTextStyled fontSize={14} fontWeight="medium">
        <strong>
          {created_by_user.first_name} {created_by_user.last_name}
        </strong>{" "}
        {title}
      </DarkGreyTextStyled>
      <Typography fontSize={12} fontWeight="medium" color={palette.grey[800]}>
        {format(new Date(created_at), "MMMM do · h:mm aa")}
      </Typography>
      <DisplayValues value={value} old_value={old_value} useArrow={useArrow} />
    </Stack>
  );
  if (!shouldBeLink) return Activity;
  const newPath =
    entity === "sessions"
      ? `/sessions/${entity_id}`
      : Navigation.appendToUrl({ task: entity_id });
  return (
    <Link component={RouterLink} to={newPath}>
      {Activity}
    </Link>
  );
}

function DisplayValues({
  old_value,
  useArrow,
  value,
}: {
  value?: string | null;
  old_value?: string | null;
  useArrow?: boolean;
}) {
  if (!value && !old_value) return null;
  if (!value && old_value) return null;
  if (
    value === undefined ||
    value === null ||
    old_value === null ||
    old_value === undefined ||
    value === "" ||
    old_value === ""
  ) {
    const jsonVals = isStringifiedObj(value || old_value || "");

    return (
      <>
        {typeof jsonVals === "string" ? (
          <DarkGreyTextStyled
            fontSize={16}
            marginTop={1}
            fontWeight="medium"
            lineHeight={"24px"}
          >
            {stripHtml(value || old_value || "").result}
          </DarkGreyTextStyled>
        ) : (
          <ReactJson
            src={jsonVals}
            displayDataTypes={false}
            style={{ fontSize: 12, paddingTop: 12 }}
            displayObjectSize={false}
            name="fields"
            enableClipboard={false}
            collapsed
          />
        )}
      </>
    );
  }
  const v = stripHtml(value).result;
  const ov = stripHtml(old_value).result;

  const formatVal = (val: string) => {
    if (isDate(new Date(val))) {
      return format(new Date(val), "EEEE, MMMM do 'at' h:mm aa");
    }
    return val;
  };

  if (useArrow) {
    return (
      <DarkGreyTextStyled
        fontSize={16}
        marginTop={1}
        fontWeight="medium"
        lineHeight={"24px"}
      >
        From <strong>{formatVal(ov)}</strong>
        <br />↳ <strong>{formatVal(v)}</strong>
      </DarkGreyTextStyled>
    );
  }
  return (
    <DarkGreyTextStyled
      fontSize={16}
      marginTop={1}
      fontWeight="medium"
      lineHeight={"24px"}
    >
      From <strong>{formatVal(ov)}</strong> to <strong>{formatVal(v)}</strong>
    </DarkGreyTextStyled>
  );
}
