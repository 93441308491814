/**
 * @file If there are multiple charts on a page make sure to register the ones that are needed in both on the page level
 */
import { Plugin, ChartTypeRegistry, Chart } from "chart.js";
import { HORIZONTAL_BAR_THICKNESS } from "./ChartConstants";

type BarChartPlugin = Plugin<keyof ChartTypeRegistry, any>;
const backgroundColor = "#F2F3F7";

const drawRoundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height);
  ctx.lineTo(x, y + height);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};
export const BarBackgroundPlugin: BarChartPlugin = {
  id: "BarBackgroundPlugin",
  beforeDatasetDraw(chart, _args, _options) {
    const {
      ctx,
      chartArea: { top, height },
      scales: { x },
    } = chart;
    const width = (chart.getDatasetMeta(0).data[0] as any)?.width;

    chart.getDatasetMeta(0).data.forEach((_dataPoint, idx) => {
      ctx.save();
      ctx.globalCompositeOperation = "destination-over"; // Draw behind existing content
      ctx.fillStyle = backgroundColor;
      // ctx.fillRect(x.getPixelForValue(idx) - width / 2, top, width, height);
      drawRoundedRect(
        ctx,
        x.getPixelForValue(idx) - width / 2,
        top,
        width,
        height - 0.5,
        10, // Radius for the rounded corners
      );
      return ctx.restore();
    });
  },
};

function drawRoundedRectForHorizontal(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  if (height < 2 * radius) radius = height / 2;
  if (width < 2 * radius) radius = width / 2;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.closePath();
  ctx.fill();
}
export const HorizontalMultiBarBackgroundPlugin: BarChartPlugin = {
  id: "HorizontalBarBackgroundPlugin",
  beforeDatasetDraw(chart, _args, _options) {
    const {
      ctx,
      chartArea: { left, right },
    } = chart;
    const chartWidth = right - left;

    // Map to track the total height and position for each bar index
    const barGroups: {
      [key: number]: { yPos: number; totalHeight: number };
    } = {};

    chart.data.datasets.forEach((_dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((dataPoint: any, index: number) => {
        const barHeight = dataPoint.height;
        const yPos = dataPoint.y;

        // Initialize or accumulate the total height for each bar index
        if (!barGroups[index]) {
          barGroups[index] = { yPos, totalHeight: barHeight };
        } else {
          barGroups[index].totalHeight += barHeight;
        }
      });
    });

    // Draw background for each bar group
    Object.values(barGroups).forEach(({ yPos, totalHeight }) => {
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = backgroundColor;

      drawRoundedRectForHorizontal(
        ctx,
        left,
        yPos - (totalHeight - HORIZONTAL_BAR_THICKNESS) / 2,
        chartWidth,
        totalHeight,
        5,
      );

      ctx.restore();
    });
  },
};
export const HorizontalBarBackgroundPlugin: BarChartPlugin = {
  id: "HorizontalBarBackgroundPlugin",
  beforeDatasetDraw(chart, _args, _options) {
    const {
      ctx,
      chartArea: { left, right },
    } = chart;
    const chartWidth = right - left;

    chart.data.datasets.forEach((_dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((dataPoint: any) => {
        const yPos = dataPoint.y;

        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = backgroundColor;

        drawRoundedRectForHorizontal(
          ctx,
          left,
          yPos - HORIZONTAL_BAR_THICKNESS / 2,
          chartWidth,
          HORIZONTAL_BAR_THICKNESS,
          3,
        );

        ctx.restore();
      });
    });
  },
};
// https://stackoverflow.com/a/71615842 for label click
interface LabelInfo {
  label: string;
  index: number;
}
interface LabelHitbox extends LabelInfo {
  x: number;
  x2: number;
  y: number;
  y2: number;
}
const findLabel = (
  labels: { scaleId: string; labels: LabelHitbox[] }[],
  evt: any,
) => {
  let found = false;
  let res: LabelInfo | null = null;

  labels.forEach((l: any) => {
    l.labels.forEach((label: LabelHitbox, index: number) => {
      if (
        evt.x > label.x &&
        evt.x < label.x2 &&
        evt.y > label.y &&
        evt.y < label.y2
      ) {
        res = {
          label: label.label,
          index,
        };
        found = true;
      }
    });
  });

  return { found, label: res };
};
const getLabelHitBox = (scales: Chart<"bar">["scales"]) =>
  Object.values(scales).map((s) => {
    return {
      scaleId: s.id,
      labels: s
        .getLabelItems(scales.x.chart.chartArea)
        .map((e: any, i: number) => ({
          x: e.options.translation[0] - (s as any)._labelSizes.widths[i],
          x2: e.options.translation[0] + (s as any)._labelSizes.widths[i] / 2,
          y: e.options.translation[1] - (s as any)._labelSizes.heights[i] / 2,
          y2: e.options.translation[1] + (s as any)._labelSizes.heights[i] / 2,
          label: e.label,
          index: i,
        })),
    };
  });

export const getLabelClickPlugin: (
  cb: (val: LabelInfo) => void,
) => BarChartPlugin = (cb: (val: LabelInfo) => void) => ({
  id: "LabelClickPlugin",

  afterEvent: (chart, events) => {
    const evt = events.event;

    if (!["click", "mousemove"].includes(evt.type)) {
      return;
    }

    const { found, label } = findLabel(getLabelHitBox(chart.scales), evt);
    if (found && label && evt.type === "click") {
      return cb(label);
    }
    return;
  },
});
